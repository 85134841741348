import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import BaseRequest from '../../../stores/special/models/BaseRequest'
import ComponentTable from '../ComponentTable'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import BuildingAction from '../../../stores/property-management/building/BuildingAction'
import BuildingDetailsRequest from '../../../stores/property-management/building/request/BuildingDetailsRequest'
import { makeSelectBuildingsList } from '../../../stores/property-management/building/BuildingSelector'
import { useTranslation } from 'react-i18next'

export default function TableBuildings(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectBuildingsList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [BuildingAction.REQUEST_GET_BUILDINGS])
    )
    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, BuildingAction.REQUEST_DELETE_BUILDING)
    )
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, BuildingAction.REQUEST_CREATE_BUILDING)
    )

    // useEffect(() => {
    //     dispatch(BuildingAction._requestGetBuildingList(new BaseRequest()))
    // }, [isFinishedDeletion, isFinishedCreation])
    const columns = [
        {
            title: t('building.tableHeader.name'),
            dataIndex: 'name',
            key: "name",
            width: "20%"
        },
        {
            title: t('building.tableHeader.address'),
            dataIndex: 'address',
            key: "address",
            width: "25%"
        },
        {
            title: t('building.tableHeader.totalFlat'),
            dataIndex: 'totalFlat',
            key: "totalFlat",
            width: "10%"
        },
        {
            title: t('building.tableHeader.totalFloor'),
            dataIndex: 'totalFloor',
            key: "totalFloor",
            width: "10%"
        },
        {
            title: t('building.tableHeader.totalGate'),
            dataIndex: 'totalGate',
            key: "totalGate",
            width: "10%"
        },
        {
            title: t('building.tableHeader.totalParking'),
            dataIndex: 'totalParking',
            key: "totalParking",
            width: "10%"
        },
        // {
        //     title: '',
        //     align: 'center',
        //     key: "x",
        //     width: "8%",
        //     render: (text, record) => (
        //         <ModalDeleteConfirmation
        //             okText = {deleteModal.okText}
        //             cancelText = {deleteModal.cancelText}
        //             confirmationText={deleteModal.alertText}
        //             onConfirm={() =>
        //                 dispatch(
        //                     BuildingAction._requestDeleteBuilding(
        //                         new BuildingDetailsRequest(record.id)
        //                     )
        //                 )
        //             }
        //         />
        //     )
        // }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
