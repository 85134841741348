import { createSelector } from 'reselect'

export class PreAuthorizationSelector {
    static getPreAuthorizationList = (state) => state.preAuthorization.preAuthorizationList
}

export const makeSelectPreAuthorizationsList = createSelector(
    PreAuthorizationSelector.getPreAuthorizationList,
    (preAuthorization) => (preAuthorization ? preAuthorization.data : [])
)
