import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import BaseRequest from '../../special/models/BaseRequest'
import ServerResponse from '../../special/models/ServerResponse'

export default class CartEffect {
    static async _requestAddToCart(data) {
        const endpoint = ApiEndpoint.cart.addToCart
        return EffectUtility._postToModel(ServerResponse, endpoint, new BaseRequest(data))
    }
    static async _requestGetAllCart(data) {
        const endpoint = ApiEndpoint.cart.getAllCart
        return EffectUtility._postToModel(ServerResponse, endpoint, new BaseRequest(data))
    }
    static async _requestDeleteCartItem(data) {
        const endpoint = ApiEndpoint.cart.deleteCart
        return EffectUtility._postToModel(ServerResponse, endpoint, new BaseRequest(data))
    }
}
