import React from 'react'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../../../assets/constants/routes'
import {
    RedTagButton,
    BlackTagButton,
    YellowTag,
    GreenTag,
    RedTag,
    PurpleTag
} from '../../component/misc/Tags'
import MembershipIcon from '../../../assets/images/membership-colored-big.png'
import BillCountIcon from '../../../assets/images/taka-colored-big.png'
import NoticeIcon from '../../../assets/images/notice-colored-big.png'
import ComplainIcon from '../../../assets/images/complain-colored-big.png'

function DashboardSideItem({ userDetails, dashboardData }) {
    console.log(dashboardData)
    return (
        <div className='dashboard-side-item-container'>
            <div className='side-item'>
                <div>
                    <div className='side-item-header'>
                        <div className='side-item-icon'>
                            {' '}
                            <img src={MembershipIcon} alt='icon' />
                        </div>
                        <h1>Membership plan</h1>
                    </div>
                    <div className='side-item-body'>
                        <ul>
                            <li>
                                {' '}
                                <div>Current plan</div> <PurpleTag text={'Premium'} />{' '}
                            </li>
                            <li>
                                <div>Expiry Date</div>{' '}
                                {userDetails &&
                                userDetails.data &&
                                userDetails.data.licenseExpireDate &&
                                new Date(userDetails.data.licenseExpireDate) > new Date() ? (
                                    <GreenTag
                                        text={new Date(
                                            userDetails.data.licenseExpireDate
                                        ).toLocaleDateString()}
                                    />
                                ) : (
                                    <RedTag text={'Expired'} />
                                )}
                            </li>
                        </ul>
                    </div>
                    {/* <div className='button-container'>
                        <Link to={AppRoutes.PRICING_PLANS}>
                            <RedTagButton text={'View Details'} />
                        </Link>
                    </div> */}
                </div>
            </div>
            <div className='side-item'>
                <div>
                    <div className='side-item-header'>
                        <div className='side-item-icon'>
                            {' '}
                            <img src={BillCountIcon} alt='icon' />
                        </div>
                        <h1>Bill Count Summary</h1>
                    </div>

                    <div className='side-item-body'>
                        <ul>
                            <li className='step'>
                                <span className='name'>Number of Bills</span>
                                <span className='none'>
                                    {dashboardData &&
                                    dashboardData.summarizeData &&
                                    dashboardData.summarizeData[0].numberoftotalbill
                                        ? dashboardData.summarizeData[0].numberoftotalbill
                                        : 0}
                                </span>
                            </li>
                            <li className='step'>
                                <span className='name'>Fully Paid</span>
                                <span className='none'>
                                    {dashboardData &&
                                    dashboardData.summarizeData &&
                                    dashboardData.summarizeData[0].fullypaidbillcount
                                        ? dashboardData.summarizeData[0].fullypaidbillcount
                                        : 0}
                                </span>
                            </li>
                            <li className='step'>
                                <span className='name'>Partially Paid</span>
                                <span className='none'>
                                    {dashboardData &&
                                    dashboardData.summarizeData &&
                                    dashboardData.summarizeData[0].partiallypaidbillcount
                                        ? dashboardData.summarizeData[0].partiallypaidbillcount
                                        : 0}
                                </span>
                            </li>
                            <li className='step'>
                                <span className='name'>Due Bill</span>
                                <span className='none'>
                                    {dashboardData &&
                                    dashboardData.summarizeData &&
                                    dashboardData.summarizeData[0].duebillcount
                                        ? dashboardData.summarizeData[0].duebillcount
                                        : 0}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className='button-container'>
                        <Link to={`${AppRoutes.BILL_DETAILS}`}>
                            <RedTagButton text={'View Details'} />
                        </Link>
                    </div>
                </div>
            </div>
            <div className='side-item'>
                <div className='side-item-small-header'>
                    <div className='side-item-header'>
                        <div className='side-item-icon'>
                            {' '}
                            <img src={NoticeIcon} alt='icon' />
                        </div>
                        <h1>Notice</h1>
                    </div>
                    <div className='side-item-counter'>
                        {' '}
                        <span>
                            {dashboardData &&
                            dashboardData.summarizeData &&
                            dashboardData.summarizeData[0].totalnoticecount
                                ? dashboardData.summarizeData[0].totalnoticecount
                                : 0}
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                    }}
                >
                    <Link style={{ width: '70%' }} to={AppRoutes.CREATE_NOTICE}>
                        <BlackTagButton text={'Create New'} />
                    </Link>

                    <div>
                        <Link to={AppRoutes.NOTICES}>View All</Link>
                    </div>
                </div>
            </div>
            <div className='side-item'>
                <div className='side-item-small-header'>
                    <div className='side-item-header'>
                        <div className='side-item-icon'>
                            {' '}
                            <img src={ComplainIcon} alt='icon' />
                        </div>
                        <h1>Complains</h1>
                    </div>
                    <div className='side-item-counter'>
                        {' '}
                        <span>
                            {dashboardData &&
                            dashboardData.summarizeData &&
                            dashboardData.summarizeData[0].totalcompalincount
                                ? dashboardData.summarizeData[0].totalcompalincount
                                : 0}
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}
                >
                    {/* <BlackTagButton onClick={() => console.log('hello')} text={'Create New'} /> */}

                    <div>
                        <Link to={AppRoutes.COMPLAINS}>View All</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardSideItem
