import { createSelector } from 'reselect'
import { DropDownConstants, PackageStatusConstant } from '../assets/constants/GeneralConstants'
import SelectionItemModel from '../stores/special/models/SelectionItemModel'

export class DropdownDataSelector {
    static _getDropDownItemsArrayByType = (state, dropDownFor) => {
        switch (dropDownFor) {
            case DropDownConstants.TYPE_USER_ROLE:
                return _getUserRolesAsDropDownItems(state.privilege.userRolesList)
            case DropDownConstants.TYPE_SERVICE_WORKER:
                return _getUsersAsDropDownItems(state.user.userList)
            case DropDownConstants.TYPE_USERS:
                return _getUsersAsDropDownItems(state.user.userList)
            case DropDownConstants.TYPE_COMMUNITY:
                return _getCommunitiesAsDropDownItems(state.community.communityList)
            case DropDownConstants.TYPE_BUILDINGS:
                return _getBuildingsAsDropDownItems(state.building.buildingList)
            case DropDownConstants.TYPE_BUILDINGS_ALL:
                return _getBuildingsAllAsDropDownItems(state.building.buildingList)
            case DropDownConstants.TYPE_FLATS_WITHOUT_ALL:
                return _getFlatsAsDropDownItemsWithoutAll(state.flat.flatList)
            case DropDownConstants.TYPE_FLATS_WITH_ALL:
                return _getFlatsAsDropDownItemsWithAll(state.flat.flatList)
            case DropDownConstants.TYPE_FLATS:
                return _getFlatsAsDropDownItems(state.flat.flatList)
            case DropDownConstants.TYPE_EXPENSE_TYPES:
                return _getTypesAsDropDownItems(state.expense.expenseTypes)
            case DropDownConstants.TYPE_BILL_TYPES:
                return _getTypesAsDropDownItems(state.bill.billTypes)
            case DropDownConstants.TYPE_FUNDS:
                return _getTypesAsDropDownItems(state.fund.fundList)
            case DropDownConstants.TYPE_BILL_PACKAGES:
                return _getTypesAsDropDownItems(state.bill.billPackages)
            case DropDownConstants.TYPE_PACKAGE_STATUS:
                return _getPackageStatusAsDropDownItems()
            case DropDownConstants.TYPE_PACKAGE_PLAN:
                return _getPackagePlanAsDropDownItems()
            case DropDownConstants.TYPE_PACKAGE:
                return _getPackageAsDropDownItems(state.packages.packageList)
            default:
                return []
        }
    }
}

const _getUsersAsDropDownItems = (userRoleData) => {
    return userRoleData
        ? userRoleData.data.map((item) => new SelectionItemModel(item.name, item.id))
        : []
}
const _getUserRolesAsDropDownItems = (userRoleData) => {
    return userRoleData
        ? userRoleData.data.map((item) => new SelectionItemModel(item.name, item.code))
        : []
}
const _getCommunitiesAsDropDownItems = (communityList) => {
    return communityList
        ? communityList.data.map((item) => new SelectionItemModel(item.name, item.id))
        : []
}
const _getBuildingsAsDropDownItems = (communityList) => {
    return communityList
        ? communityList.data.map((item) => new SelectionItemModel(item.name, item.id))
        : []
}
const _getBuildingsAllAsDropDownItems = (communityList) => {
    return communityList
        ? communityList.data.map((item) => new SelectionItemModel(item.name, item.id))
        : []
}
const _getFlatsAsDropDownItemsWithAll = (flatList) => {
    if (flatList && flatList.data.length > 0) {
        // console.log("before unshift", flatList);
        const flats = []
        flatList.data.map((item) => flats.push(new SelectionItemModel(item.number, item.id)))
        flats.unshift(new SelectionItemModel('-- ALL --', null))
        // console.log("After unshift", flats);
        return flats
    }
    return []
    // return flatList ? flatList.data.map((item) => new SelectionItemModel(item.number, item.id)) : []
}
const _getFlatsAsDropDownItemsWithoutAll = (flatList) => {
    if (flatList && flatList.data.length > 0) {
        const flats = []
        flatList.data.map((item) => flats.push(new SelectionItemModel(item.number, item.id)))

        return flats
    }
    return []
    // return flatList ? flatList.data.map((item) => new SelectionItemModel(item.number, item.id)) : []
}
const _getFlatsAsDropDownItems = (flatList) => {
    if (flatList) {
        const flatDropDowns = flatList.data.map(
            (item) => new SelectionItemModel(item.number, item.id)
        )
        flatDropDowns.unshift(new SelectionItemModel('-- ALL --', 'all'))
        return flatDropDowns
    } else {
        return []
    }
}
const _getTypesAsDropDownItems = (types) => {
    return types ? types.data.map((item) => new SelectionItemModel(item.name, item.id)) : []
}
const _getPackageAsDropDownItems = (packages) => {
    return packages
        ? packages.data.map((item) => new SelectionItemModel(item.name, item.packageId))
        : []
}
const _getPackageStatusAsDropDownItems = () => {
    return [
        new SelectionItemModel(PackageStatusConstant.AVAILABLE, PackageStatusConstant.AVAILABLE),
        new SelectionItemModel(PackageStatusConstant.UNAVAILABLE, PackageStatusConstant.UNAVAILABLE)
    ]
}
const _getPackagePlanAsDropDownItems = () => {
    return [
        new SelectionItemModel('FREE', 'FREE'),
        new SelectionItemModel('MANAGEMENT', 'MANAGEMENT'),
        new SelectionItemModel('SECURITY', 'SECURITY')
    ]
}
export const selectDropDownItems = createSelector(
    (state, dropDownFor) => DropdownDataSelector._getDropDownItemsArrayByType(state, dropDownFor),
    (state, dropDownFor) => dropDownFor,
    (allData, dropDownFor) => allData
)
