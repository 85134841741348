import ActionUtility from '../../../utils/ActionUtility'
import FormsEffect from './FormsEffect'

export default class FormsAction {
    static REQUEST_GET_ALL_USERS_FORM = 'REQUEST_GET_ALL_USERS_FORM'
    static REQUEST_GET_ALL_USERS_FORM_FINISHED = 'REQUEST_GET_ALL_USERS_FORM_FINISHED'

    static REQUEST_CREATE_POLICE_FORM = 'REQUEST_CREATE_POLICE_FORM'
    static REQUEST_CREATE_POLICE_FORM_FINISHED = 'REQUEST_CREATE_POLICE_FORM_FINISHED'

    static REQUEST_CREATE_TENANT_DEPARTURE_FORM = 'REQUEST_CREATE_TENANT_DEPARTURE_FORM'
    static REQUEST_CREATE_TENANT_DEPARTURE_FORM_FINISHED = 'REQUEST_CREATE_TENANT_DEPARTURE_FORM_FINISHED'
    
    static REQUEST_CREATE_TENANT_OWNER_FORM = 'REQUEST_CREATE_TENANT_OWNER_FORM'
    static REQUEST_CREATE_TENANT_OWNER_FORM_FINISHED = 'REQUEST_CREATE_TENANT_OWNER_FORM_FINISHED'

    static REQUEST_GET_ALL_POLICE_FORM = 'REQUEST_GET_ALL_POLICE_FORM'
    static REQUEST_GET_ALL_POLICE_FORM_FINISHED = 'REQUEST_GET_ALL_POLICE_FORM_FINISHED'

    static _requestGetUserFormsList(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FormsAction.REQUEST_GET_ALL_USERS_FORM,
                FormsEffect._requestGetUserFormsList,
                data
            )
        }
    }
    static _requestCreateNewPoliceForm(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FormsAction.REQUEST_CREATE_POLICE_FORM,
                FormsEffect._requestCreateNewPoliceForm,
                data
            )
        }
    }
    static _requestCreateNewDepartureForm(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FormsAction.REQUEST_CREATE_TENANT_DEPARTURE_FORM,
                FormsEffect._requestCreateNewDepartureForm,
                data
            )
        }
    }
    static _requestCreateNewTanentOwnerForm(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FormsAction.REQUEST_CREATE_TENANT_OWNER_FORM,
                FormsEffect._requestCreateNewTanentOwnerForm,
                data
            )
        }
    }
    static _requestGetPoliceFormsList(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FormsAction.REQUEST_GET_ALL_POLICE_FORM,
                FormsEffect._requestGetPoliceFormsList,
                data
            )
        }
    }
}
