import BaseRequest from '../../../special/models/BaseRequest'

export default class CreateNewTanentOwnerFormRequest extends BaseRequest {
    userId = ''
    forms = {
        advanceAmount: '',
        amount: '',
        applicationDate: '',
        arrivalDate: '',
        billingDate: '',
        causeOfLeave: '',
        formSubmitDate: '',
        noticeDate: '',
        ownerAddress: '',
        ownerEduQualification: '',
        ownerFathersName: '',
        ownerFullAddress: '',
        ownerFullname: '',
        ownerMaritalStatus: '',
        ownerNID: '',
        ownerName: '',
        ownerOccupation: '',
        ownerPhone: '',
        ownerSignature: '',
        prevAddress: '',
        prevOwner: '',
        subject: '',
        tenantEduQualification: '',
        tenantFathersName: '',
        tenantFullAddress: '',
        tenantFullname: '',
        tenantMaritalStatus: '',
        tenantNID: '',
        tenantName: '',
        tenantOccupation: '',
        tenantPhone: '',
        tenantSignature: ''
    }

    constructor(data) {
        super()
        this.update(data)
    }
}
