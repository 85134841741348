import BaseRequest from '../../../../special/models/BaseRequest'

export class RecordExpenseRequest extends BaseRequest {
    name = ''
    description = ''
    amount = ''
    date = ''
    typeId = ''
    buildingId = ''
    month = ''
    image = ''

    constructor(data) {
        super()
        this.update(data)
        this.amount = parseInt(data.amount)
    }
}
