import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import ComponentTable from '../ComponentTable'
import UserAction from '../../../stores/user-management/user/UserAction'
import { makeSelectUsersList } from '../../../stores/user-management/user/UserSelector'
import GetUserDetailsRequest from '../../../stores/user-management/user/requests/GetUserDetailsRequest'
import ModalImage from '../../modals/ModalImage'
import DeleteProfileRequest from '../../../stores/user-management/user/requests/DeleteProfileRequest'
// import { PageBasicContainer } from '../../pages/PageStyles'

export default function TableFlatUsers(props) {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectUsersList)

    console.log(dataList)

    const isRequesting = useSelector((state) =>
        selectRequesting(state, [UserAction.REQUEST_GET_USERS])
    )

    const columns = [
        {
            title: 'Image',
            dataIndex: 'thumbImage',
            render: (text, record) => (
                <ModalImage imageUrl={record.thumbImage} imageTitle={'User Image'} />
            )
        },
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Phone',
            dataIndex: 'phone'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Address',
            dataIndex: 'address'
        },
        {
            title: 'Organization',
            dataIndex: 'organization'
        },
        {
            title: 'National ID',
            dataIndex: 'nid'
        },

        {
            title: 'Password',
            dataIndex: 'password'
        },
        {
            title: 'Gender',
            dataIndex: 'gender'
        },
        {
            title: 'Age',
            dataIndex: 'age'
        },
        {
            title: 'Action',
            align: 'center',
            render: (text, record) => (
                <ModalDeleteConfirmation
                    confirmationText={'Are you sure you want to delete this user ?'}
                    onConfirm={() =>
                        dispatch(
                            UserAction._requestDeleteUser(
                                new DeleteProfileRequest({ userId: record.id })
                            )
                        )
                    }
                />
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
