import React from 'react'
import { ColorConstants } from '../../../assets/constants/GeneralConstants'
import {
    CardInfoLeftColoredSvgIcon,
    FormTitleWithSvgIcon
} from '../../component/decoration/CardSingleInfo'
import { PageBasicContainer, PageDecorationContainer, PageMainBarContainer } from '../PageStyles'
import TakaIcon from '../../../assets/images/taka-small.png'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import TableDataFilter from '../../tables/TableDataFilter'
import { useEffect } from 'react'
import BillAction from '../../../stores/money-management/bill/BillAction'
import { GetRequestedBillGenerationRequest } from '../../../stores/money-management/bill/requests/GetRequestedBillGenerationRequest.js'
import {
    makeSelectRequestedGenerateBill,
    makeSelectRequestMultipleBillInvoiceReport
} from '../../../stores/money-management/bill/BillSelector'
import { Collapse, message, Spin } from 'antd'
import { GreenTag, RedTag, YellowTag } from '../../component/misc/Tags'
import { GetMultipleBillInvoiceReportRequest } from '../../../stores/money-management/bill/requests/GetMultipleBillInvoiceReporRequest'
import ComponentTable from '../../tables/ComponentTable'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'

const { Panel } = Collapse

function PageBillGenerationReport() {
    const dispatch = useDispatch()
    // const [decorationArrValues, setDecorationArrValues] = useState([])
    const [filterValues, setFilterValues] = useState({})
    const [activeKey, setActiveKey] = useState(null)

    const isRequesting = useSelector((state) =>
        selectRequesting(state, [BillAction.REQUEST_GET_MULTIPLE_BILL_INVOICE_REPORT])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_GET_MULTIPLE_BILL_INVOICE_REPORT)
    )
    useEffect(() => {
        if (filterValues) {
            dispatch(
                BillAction._requestRequestedBillGeneration(
                    new GetRequestedBillGenerationRequest({
                        buildingIdInfo: filterValues.buildingId,
                        month: filterValues.month
                    })
                )
            )
        }
    }, [filterValues])

    const dataList = useSelector((state) => makeSelectRequestedGenerateBill(state))
    const report = useSelector((state) => makeSelectRequestMultipleBillInvoiceReport(state))

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    // const decor = decorationArrValues.map((item) => (
    //     <CardInfoLeftColoredSvgIcon key={Math.random()} data={item} />
    // ))

    const handleGeneratedData = (key) => {
        if (key) {
            dispatch(
                BillAction._requestGetMultipleBillInvoiceReport(
                    new GetMultipleBillInvoiceReportRequest({ requestId: key })
                )
            )
        } else {
            // message.error({
            //     content: 'Something went wrong please try again later',
            //     key: 'billReportKeyError'
            // })
        }
    }
    return (
        <PageBasicContainer>
            {/* <PageDecorationContainer paddingTop={'0px'} item={3}>
                {decor}
            </PageDecorationContainer> */}
            <PageMainBarContainer>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '30px'
                    }}
                >
                    <FormTitleWithSvgIcon
                        title={'Generated Bill Reports'}
                        // subTitle={''}
                        icon={TakaIcon}
                        backgroundColor={ColorConstants.RED}
                    />
                </div>
                <TableDataFilter
                    setDefaultMonth={true}
                    showBuildingFilter={true}
                    showMonthFilter={true}
                    // showFlatFilter={true}
                    sendDataToParent={receiveFilterData}
                />
                {/* <TableRequestedBill /> */}
                <>
                    {dataList && dataList.length ? (
                        <Collapse
                            accordion
                            onChange={handleGeneratedData}
                            destroyInactivePanel={true}
                        >
                            {dataList.map((item) => {
                                return (
                                    <Panel
                                        key={item.id}
                                        header={
                                            <div
                                                style={{
                                                    display: 'grid',
                                                    gridTemplateColumns: '2fr 2fr 1fr 1fr',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <h1 style={{ margin: '0' }}>{item.title}</h1>
                                                <h1 style={{ margin: '0' }}>
                                                    Created At:{' '}
                                                    <span
                                                        style={{
                                                            color: '#ff3300',
                                                            marginLeft: '10px'
                                                        }}
                                                    >
                                                        {new Date(item.createdDate).toDateString()}
                                                    </span>
                                                </h1>
                                                <div
                                                    style={{
                                                        margin: '0',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <span>Amount: </span>
                                                    <span
                                                        style={{
                                                            color: '#ff3300',
                                                            marginLeft: '10px'
                                                        }}
                                                    >
                                                        {item.billItems.reduce((acc, billItem) => {
                                                            return acc + billItem.amount
                                                        }, 0)}
                                                    </span>
                                                </div>
                                                <div>
                                                    {item.deletedDate ? (
                                                        <GreenTag text={'GENERATED'} />
                                                    ) : (
                                                        <YellowTag text={'PENDING'} />
                                                    )}
                                                </div>
                                            </div>
                                        }
                                    >
                                        {isFinished && report &&
                                        report.length > 0 &&
                                        item.id === report[0].requestId ? (
                                            <ComponentTable
                                                scroll={false}
                                                pagination={false}
                                                dataList={report}
                                                columns={columns}
                                            />
                                        ) : (
                                            <div style={{ textAlign: 'center' }}>
                                                <Spin size={'large'}></Spin>
                                            </div>
                                        )}
                                    </Panel>
                                )
                            })}
                        </Collapse>
                    ) : null}
                </>
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}

const columns = [
    {
        align: 'center',
        title: 'Flat',
        dataIndex: 'flat.name'
    },
    {
        align: 'center',
        title: 'Status',
        key: 'status',
        render: (text, record) => {
            if (record.success === true) {
                return <GreenTag text={'SUCCEED'} />
            } else {
                return <RedTag text={'FAILED'} />
            }
        }
    }
]

export default PageBillGenerationReport
