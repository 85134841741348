import {
    OrderRemarkConstants,
    OrderTypeConstants
} from '../../../../assets/constants/GeneralConstants'
import BaseRequest from '../../../special/models/BaseRequest'

export class DirectPaymentRequest extends BaseRequest {
    communityId = null
    packages = {
        packageId: {
            startdate: '2021-01-19T07:55:45.114Z'
        }
    }
    type = OrderTypeConstants.PACKAGE
    remarks = OrderRemarkConstants.COMMUNITY
    paidAmount = 0

    constructor(data) {
        super()
        this.packages = {
            [data.packageId]: {
                startdate: new Date().toISOString()
            }
        }
        this.paidAmount = parseInt(data.paidAmount)
        this.communityId = data.communityId
        this.update(data)
    }
}
