import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class BillEffect {
    static async _requestCreateBillType(data) {
        const endPoint = ApiEndpoint.bill.createNewType
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestCreateBillPackage(data) {
        const endPoint = ApiEndpoint.bill.createPackage
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestAssignBillPackage(data) {
        const endPoint = ApiEndpoint.bill.assignPackage
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetBillPackages(data) {
        const endPoint = ApiEndpoint.bill.getPackages
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestDeleteBillType(data) {
        const endPoint = ApiEndpoint.bill.deleteType
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetBillTypes(data) {
        const endPoint = ApiEndpoint.bill.getTypes
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGenerateBillForFlat(data) {
        const endPoint = ApiEndpoint.bill.generateBillForFlat
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetBillItems(data) {
        const endPoint = ApiEndpoint.bill.getBillItems
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestCollectBill(data) {
        const endPoint = ApiEndpoint.bill.collectBill
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestGetDecideBill(data) {
        const endPoint = ApiEndpoint.bill.decideBill
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestGenerateMultipleBillInvoices(data) {
        const endpoint = ApiEndpoint.bill.generateMultipleBill
        return EffectUtility._postToModel(ServerResponse, endpoint, new BaseRequest(data));
    }
    static async _requestDeleteBillItem(data) {
        const endpoint = ApiEndpoint.bill.deleteBill
        return EffectUtility._postToModel(ServerResponse, endpoint, new BaseRequest(data))
    }
    static async _requsetGetDueBillItems(data) {
        const endpoint = ApiEndpoint.bill.getBillItems
        return EffectUtility._postToModel(ServerResponse, endpoint, new BaseRequest(data))
    }
    static async _requestRequestedBillGeneration(data) {
        const endpoint = ApiEndpoint.bill.getRequestedBillGeneration
        return EffectUtility._postToModel(ServerResponse, endpoint, new BaseRequest(data))
    }
    static async _requestGetMultipleBillInvoiceReport(data) {
        const endpoint = ApiEndpoint.bill.getMultipleBillInvoiceReport
        return EffectUtility._postToModel(ServerResponse, endpoint, new BaseRequest(data))
    }
}
