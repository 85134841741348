import { date } from 'yup'
import ActionUtility from '../../../utils/ActionUtility'
import CartEffect from './CartEffect'

export default class CartAction {
    static REQUEST_ADD_TO_CART = 'REQUEST_ADD_TO_CART'
    static REQUEST_ADD_TO_CART_FINISHED = 'REQUEST_ADD_TO_CART_FINISHED'

    static REQUEST_GET_ALL_CART = 'REQUEST_GET_ALL_CART'
    static REQUEST_GET_ALL_CART_FINISHED = 'REQUEST_GET_ALL_CART_FINISHED'

    static REQUEST_DELETE_CART_ITEM = 'REQUEST_DELETE_CART_ITEM'
    static REQUEST_DELETE_CART_ITEM_FINISHED = 'REQUEST_DELETE_CART_ITEM_FINISHED'

    static _requestAddToCart(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                CartAction.REQUEST_ADD_TO_CART,
                CartEffect._requestAddToCart,
                data
            )
        }
    }
    static _requestGetAllCart(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                CartAction.REQUEST_GET_ALL_CART,
                CartEffect._requestGetAllCart,
                data
            )
        }
    }
    static _requestDeleteCartItem(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                CartAction.REQUEST_DELETE_CART_ITEM,
                CartEffect._requestDeleteCartItem,
                data
            )
        }
    }
}
