import React from 'react'

function FlatInvoicePdfFooter() {
    return (
        <div style={{width: '100%', marginTop: '20px', marginBottom: '20px'}}>
            <table style={{width: '100%'}}>
                <thead></thead>
                <tbody>
                    <tr style={{ textAlign: 'center', fontSize: '14px' }}>
                        <td style={{marginLeft: '4px', marginRight: '4px', padding: '0 2px'}}>___________________________</td>
                        <td style={{marginLeft: '4px', marginRight: '4px', padding: '0 2px'}}>___________________________</td>
                        <td style={{marginLeft: '4px', marginRight: '4px', padding: '0 2px'}}>___________________________</td>
                    </tr>
                    <tr style={{ textAlign: 'center', fontSize: '14px' }}>
                        <td style={{marginLeft: '4px', marginRight: '4px', padding: '0 4px'}}>Community Authority Signature</td>
                        <td style={{marginLeft: '4px', marginRight: '4px', padding: '0 4px'}}>Building Authority Signature</td>
                        <td style={{marginLeft: '4px', marginRight: '4px', padding: '0 4px'}}>Flat Owner Signature</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default FlatInvoicePdfFooter
