import BaseRequest from '../../../special/models/BaseRequest'

export default class ComplainDetailsRequest extends BaseRequest {
    parcelId = ''

    constructor(data) {
        super()
        this.update(data)
        this.parcelId = data.id
    }
}
