import BaseRequest from '../../special/models/BaseRequest'

export default class CreateComplainRequest extends BaseRequest {
    buildingId = ''

    fromDate = null
    toDate = null

    constructor(data) {
        super()
        this.update(data)
        if (data && data.dateRange && data.dateRange.length > 0) {
            this.fromDate = data.dateRange[0]+" 00:00:00"
            this.toDate = data.dateRange[1] +" 23:59:59"
        }
    }
}
