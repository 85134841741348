import { ComplainStatusConstants } from '../../../../assets/constants/GeneralConstants'
import BaseRequest from '../../../special/models/BaseRequest'

export default class GetComplainsRequest extends BaseRequest {
    buildingId = ''
    flatId = ''
    fromDate = null
    toDate = null
    status = null

    constructor(data, complainStatus) {
        super()
        this.update(data)
        if (data && data.dateRange && data.dateRange.length > 0) {
            this.fromDate = new Date(data.dateRange[0] + ' 00:00:00')
            this.toDate = new Date(data.dateRange[1] + ' 23:59:59')
        }
        if(complainStatus!==ComplainStatusConstants.ALL){
            this.status = complainStatus
        }
    }
}
