import React, { useEffect, useState } from 'react'
import { message, Modal } from 'antd'
import { ButtonFillBlue, ButtonFillPurple } from '../component/buttons/CustomButtons'
import { ColorConstants } from '../../assets/constants/GeneralConstants'
import TotalBillIcon from '../../assets/images/total-bill-small.png'
import * as Yup from 'yup'
import { makeSelectBillTypesList } from '../../stores/money-management/bill/BillSelector'
import BillAction from '../../stores/money-management/bill/BillAction'
import { useDispatch, useSelector } from 'react-redux'
import useFormInputValidation from '../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../stores/special/finished/FinishedSelector'
import { checkIsAmountZeroOrEmpty, removeNullValueObject } from '../../utils/functions/functions'
import { CreateBillPackageRequest } from '../../stores/money-management/bill/requests/CreateBillPackageRequest'
import SingleBillInvoiceItem from '../forms/bill/SingleBillInvoiceItem'
import { FormInputText } from '../component/form-input/FormInputText'
import './createBillPackage.scss'
import ButtonFormSubmitIcon from '../forms/ButtonFormSubmitIcon'
import { FormTitleWithSvgIcon } from '../component/decoration/CardSingleInfo'

const INITIAL_STATE = {
    packageName: '',
    buildingId: ''
}

const VALIDATION_SCHEMA = Yup.object({
    packageName: Yup.string()
        .required('Please provide package name')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Maximum 40 Characters')
})

export default function ModalCreateBillPackage(props) {
    const [visibility, setVisibility] = useState(false)

    const dispatch = useDispatch()
    const [billItemsFromChild, setBillItemsFromChild] = useState({})
    const [activeBillItems, setActiveBillItems] = useState([])

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [BillAction.REQUEST_CREATE_BILL_PACKAGE])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_CREATE_BILL_PACKAGE)
    )
    const billTypes = useSelector(makeSelectBillTypesList)

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        const billItems = removeNullValueObject(billItemsFromChild)
        values['billItems'] = Object.values(billItems)
        values.billItems.map(
            (item, index) => (values.billItems[index].amount = parseFloat(item.amount))
        )
        const checkAmountZero = checkIsAmountZeroOrEmpty(billItems)
        if (Object.keys(billItems).length) {
            if (checkAmountZero) {
                const valueItems = values.billItems.map((item) => {
                    return {
                        name: item.name,
                        description: item.description,
                        amount: item.amount,
                        typeId: item.id
                    }
                })
                dispatch(
                    BillAction._requestCreateBillPackage(
                        new CreateBillPackageRequest({
                            packageName: values.packageName,
                            billItems: [...valueItems]
                        })
                    )
                )
            } else {
                message.error("Amount Can't Zero or Empty")
            }
        } else {
            message.error('No Bill Item Found')
        }
    }

    useEffect(() => {
        const billItemsFromType = billTypes.map((item) => {
            return {
                name: item.name,
                description: item.description,
                amount: item.defaultAmount,
                id: item.id
            }
        })
        setActiveBillItems(billItemsFromType)
    }, [billTypes])

    const makePreviousDataInvalid = (itemIndex) => {
        const typeId = activeBillItems[itemIndex].id
        const newState = { ...billItemsFromChild, [typeId]: null }
        setBillItemsFromChild(newState)
    }
    const removeBillItem = (itemIndex) => {
        makePreviousDataInvalid(itemIndex)
        let newItems = activeBillItems.filter((item, index) => index !== itemIndex)
        setActiveBillItems(newItems)
    }
    const receiveDataInParent = (data, index) => {
        setBillItemsFromChild((oldState) => {
            return {
                ...oldState,
                [data.id]: data
            }
        })
        let newItems = [...activeBillItems]
        newItems[index] = data
        setActiveBillItems(newItems)
    }

    return (
        <>
            <ButtonFillPurple
                disabled={props.isDisabled}
                icon={'plus'}
                size={'large'}
                text={'Create New Package'}
                onClick={() => setVisibility(true)}
            />
            <Modal
                // title={`Details`}
                className='create-bill-package'
                centered
                visible={visibility}
                bodyStyle={{ height: '70vh', overflowY: 'auto' }}
                closable={true}
                onCancel={() => setVisibility(false)}
                onOk={() => setVisibility(false)}
                okText={'Close'}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <FormTitleWithSvgIcon
                    title={'Create Bill Package'}
                    icon={TotalBillIcon}
                    backgroundColor={ColorConstants.PURPLE3}
                />
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <h2 style={{ margin: '0 10px 0 0' }}>Package Name</h2>
                    <span style={{ textAlign: 'center', marginBottom: '-8px' }}>
                        Provide a suitable name
                    </span>
                </div>
                <FormInputText control={control} name={'packageName'} />

                <div>
                    {activeBillItems.map((billItem, index) => (
                        <SingleBillInvoiceItem
                            key={index}
                            index={index}
                            removeItem={removeBillItem}
                            billItem={billItem}
                            sendDataToParent={receiveDataInParent}
                        />
                    ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ButtonFormSubmitIcon
                        title={'Save and create new'}
                        isRequesting={isRequesting}
                        control={control}
                        onSubmit={onSubmit}
                        isFinished={isFinished}
                    />
                </div>
            </Modal>
        </>
    )
}
