import React from 'react'
import { DecorationDataModel } from '../views/component/decoration/DecorationDataModel'
import {
    ColorConstants,
    GreeneTheme,
    TagColors,
    NoticeForConstants,
    MeetingStatusConstants,
    EntranceStatusConstants,
    AuthorizationStatusConstants,
    TransactionTypeConstants,
    VehicleTypeConstants,
    DefaultExpenseTypeRequest,
    UserRoleCodeConstants,
    ThreeColorConstants
} from '../assets/constants/GeneralConstants'
import {
    BillPayCostWhiteIcon,
    CarBlackIcon,
    CarWhiteIcon,
    BikeWhiteIcon,
    BusWhiteIcon,
    ChildrenIcon,
    CommonNoticeWhiteIcon,
    DeviceIcon,
    EmptyFlatWhiteIcon,
    GuardsNoticeWhiteIcon,
    MaintenanceCostWhiteIcon,
    OccupiedFlatWhiteIcon,
    OtherCostWhiteIcon,
    RepairCostWhiteIcon,
    RequestAcceptedWhiteIcon,
    RequestPendingWhiteIcon,
    RequestRejectedWhiteIcon,
    SalaryCostWhiteIcon,
    TotalCostWhiteIcon,
    TotalCreditWhiteIcon,
    TotalDebitWhiteIcon,
    TotalFlatWhiteIcon,
    TotalIncomeWhiteIcon,
    TotalNoticeWhiteIcon,
    TotalRequestWhiteIcon,
    TotalVisitorWhiteIcon,
    UserInsideWhiteIcon,
    UserNotInsideWhiteIcon,
    UsersNoticeWhiteIcon,
    TruckWhiteIcon
} from '../views/misc/IconsProvider'
import CollectedBillIcon from '../assets/images/collected-bill-small.png'
import TotalBillIcon from '../assets/images/total-bill-small.png'
import UnpaidBillIcon from '../assets/images/unpaid-bill-small.png'
export class DataBuilderUtility {
    static _getExpensesDataOverview(dataList) {
        let totalCost = 0
        let maintenanceAndRepairCost = 0
        let salaryCost = 0
        let othersCost = 0
        let billPayCost = 0
        dataList.forEach((item) => {
            switch (item.type_id) {
                case DefaultExpenseTypeRequest.MAINTENANCE_AND_REPAIR:
                    maintenanceAndRepairCost += item.amount
                    break
                case DefaultExpenseTypeRequest.BILL_PAY:
                    billPayCost += item.amount
                    break
                case DefaultExpenseTypeRequest.SALARY:
                    salaryCost += item.amount
                    break
                case DefaultExpenseTypeRequest.OTHERS:
                    othersCost += item.amount
                    break
            }
            totalCost += item.amount
        })
        return [
            new DecorationDataModel(
                'Maintenance',
                maintenanceAndRepairCost,
                GreeneTheme.COLOR1,
                <RepairCostWhiteIcon />
            ),
            new DecorationDataModel(
                'Bill Payments',
                billPayCost,
                GreeneTheme.COLOR2,
                <MaintenanceCostWhiteIcon />
            ),
            new DecorationDataModel(
                'Salary Expense',
                salaryCost,
                GreeneTheme.COLOR3,
                <SalaryCostWhiteIcon />
            ),
            new DecorationDataModel(
                'Others',
                othersCost,
                GreeneTheme.COLOR5,
                <OtherCostWhiteIcon />
            ),
            new DecorationDataModel(
                'Total',
                totalCost,
                ColorConstants.BLACK1,
                <TotalCostWhiteIcon />
            )
        ]
    }
    static _getEntranceDataOverview(dataList) {
        let totalVisitors = 0
        let totalInside = 0
        let totalOutside = 0
        dataList.forEach((item) => {
            if (item.status === EntranceStatusConstants.INSIDE_COMPOUND) totalInside++
            else totalOutside++
            totalVisitors++
        })
        return [
            new DecorationDataModel(
                'Total',
                totalVisitors,
                ColorConstants.BLACK2,
                <TotalVisitorWhiteIcon />
            ),
            new DecorationDataModel(
                'Still Inside',
                totalInside,
                TagColors.GREEN_FRONT,
                <UserInsideWhiteIcon />
            ),
            new DecorationDataModel(
                'Not Inside',
                totalOutside,
                TagColors.RED_FRONT,
                <UserNotInsideWhiteIcon />
            )
        ]
    }

    static _getUsersDataOverview(dataList) {
        let generalUser = 0
        let guard = 0
        let serviceWorker = 0
        let totalChildren = 0
        let totalUser = 0
        dataList.forEach((item) => {
            switch (item.primaryRoleCode) {
                case UserRoleCodeConstants.GENERAL_REGISTERED_USER_APP:
                    generalUser++
                    break
                case UserRoleCodeConstants.GUARD:
                    guard++
                    break
                case UserRoleCodeConstants.SERVICE_WORKER:
                    serviceWorker++
                    break
                case UserRoleCodeConstants.CHILD:
                    totalChildren++
                    break
            }
            totalUser++
        })
        return [
            new DecorationDataModel(
                'Total User',
                totalUser,
                ColorConstants.BLACK2,
                <TotalVisitorWhiteIcon />
            ),
            new DecorationDataModel(
                'General user',
                generalUser,
                TagColors.GREEN_FRONT,
                <EmptyFlatWhiteIcon />
            ),
            new DecorationDataModel('Guards', guard, TagColors.BLUE_FRONT, <UserInsideWhiteIcon />),
            new DecorationDataModel(
                'Children',
                totalChildren,
                ColorConstants.PURPLE1,
                <ChildrenIcon />
            ),
            new DecorationDataModel(
                'Service Worker',
                serviceWorker,
                TagColors.RED_FRONT,
                <DeviceIcon />
            )
        ]
    }

    static _getVehiclesDataOverview(dataList) {
        let totalVehicle = 0
        let totalPrivateCar = 0
        let totalMotorBike = 0
        let totalOthers = 0
        dataList.forEach((item) => {
            switch (item.type) {
                case VehicleTypeConstants.PRIVATE_CAR:
                    totalPrivateCar++
                    break
                case VehicleTypeConstants.MOTOR_BIKE:
                    totalMotorBike++
                    break
                case VehicleTypeConstants.OTHERS:
                    totalOthers++
                    break
            }
            totalVehicle++
        })
        return [
            new DecorationDataModel(
                'Total Vehicle',
                totalVehicle,
                ColorConstants.BLACK2,
                <BusWhiteIcon />
            ),
            new DecorationDataModel(
                'Private Car',
                totalPrivateCar,
                TagColors.GREEN_FRONT,
                <CarWhiteIcon />
            ),
            new DecorationDataModel(
                'Motor Bike',
                totalMotorBike,
                TagColors.BLUE_FRONT,
                <BikeWhiteIcon />
            ),
            new DecorationDataModel(
                'Others',
                totalOthers,
                TagColors.RED_FRONT,
                <TruckWhiteIcon />
            )
        ]
    }

    static _getPreAuthorizedDataOverview(dataList) {
        let total = 0
        let white = 0
        let black = 0
        dataList.forEach((item) => {
            if (item.status === AuthorizationStatusConstants.WHITE_LISTED) white++
            else black++
            total++
        })
        return [
            new DecorationDataModel(
                'Total White List',
                white,
                TagColors.GREEN_FRONT,
                <UserInsideWhiteIcon />
            ),
            new DecorationDataModel(
                'Total Black List',
                black,
                TagColors.RED_FRONT,
                <UserNotInsideWhiteIcon />
            )
        ]
    }

    static _getBillInvoicesDataOverview(dataList) {
        let totalBillGenerated = 0
        let totalBillCollected = 0
        let totalRemainingBill = 0
        dataList.forEach((item) => {
            totalBillGenerated += item.total_amount
            totalBillCollected += item.paid_amount
            totalRemainingBill += item.total_amount - item.paid_amount
        })
        return [
            new DecorationDataModel(
                'Total Bill for Month',
                totalBillGenerated,
                ColorConstants.BLUE,
                <TotalVisitorWhiteIcon />
            ),
            new DecorationDataModel(
                'Total Collected Bill',
                totalBillCollected,
                ColorConstants.GREEN1,
                <UserInsideWhiteIcon />
            ),
            new DecorationDataModel(
                'Bills to Collect',
                totalRemainingBill,
                ColorConstants.RED2,
                <UserNotInsideWhiteIcon />
            )
        ]
    }
    static _getBillInvoiceDetailsDataOverview(dataList) {
        let totalBillGenerated = 0
        let totalBillCollected = 0
        let totalRemainingBill = 0
        dataList.forEach((item) => {
            totalBillGenerated += item.amount
            totalBillCollected += item.paid_amount
            totalRemainingBill += item.amount - item.paid_amount
        })
        return [
            new DecorationDataModel(
                "Flat's Bill for Month",
                totalBillGenerated,
                ColorConstants.BLACK2,
                <TotalVisitorWhiteIcon />
            ),
            new DecorationDataModel(
                'Total Collected Bill',
                totalBillCollected,
                GreeneTheme.COLOR1,
                <UserInsideWhiteIcon />
            ),
            new DecorationDataModel(
                'Bills to Collect',
                totalRemainingBill,
                GreeneTheme.COLOR4,
                <UserNotInsideWhiteIcon />
            )
        ]
    }

    static _getBillDetailsDataOverview(dataList) {
        let totalBillGenerated = 0
        let totalBillCollected = 0
        let totalRemainingBill = 0
        dataList.forEach((item) => {
            totalBillGenerated += item.totalAmount
            totalBillCollected += item.paidAmount
            totalRemainingBill += item.totalAmount - item.paidAmount
        })
        return [
            new DecorationDataModel(
                "Total Bill",
                totalBillGenerated,
                ColorConstants.PURPLE3,
                TotalBillIcon
            ),
            new DecorationDataModel(
                'Collected Bill',
                totalBillCollected,
                ThreeColorConstants.RED,
                CollectedBillIcon
            ),
            new DecorationDataModel(
                'Bills to be Collected',
                totalRemainingBill,
                GreeneTheme.COLOR4,
                UnpaidBillIcon
            )
        ]
    }



    static _getFundDataOverview(dataList, cardTitle) {
        let totalCredit = 0
        let totalDebit = 0
        let totalIncome = 0

        dataList.forEach((item) => {
            if (item.type === TransactionTypeConstants.CREDIT) totalCredit += parseInt(item.amount)
            else totalDebit += parseInt(item.amount)
        })

        return [
            new DecorationDataModel(
                cardTitle.totalCredit,
                totalCredit,
                ColorConstants.GREEN1,
                <TotalCreditWhiteIcon />
            ),
            new DecorationDataModel(
                cardTitle.totalDebit,
                totalDebit,
                ColorConstants.RED2,
                <TotalDebitWhiteIcon />
            ),
            new DecorationDataModel(
                cardTitle.totalIncome,
                totalCredit - totalDebit,
                GreeneTheme.COLOR4,
                <TotalIncomeWhiteIcon />
            )
        ]
    }

    static _getLedgerDataOverview(dataList) {
        let { startBalance, endBalance, currentBalance } = dataList

        return [
            new DecorationDataModel(
                'Start Balance',
                startBalance,
                ColorConstants.GREEN1,
                <TotalIncomeWhiteIcon />
            ),
            new DecorationDataModel(
                'End Balance',
                endBalance,
                ColorConstants.RED2,
                <TotalDebitWhiteIcon />
            ),
            new DecorationDataModel(
                'Current Balance',
                currentBalance,
                GreeneTheme.COLOR4,
                <TotalCreditWhiteIcon />
            )
        ]
    }

    static _getFlatsDataOverview(dataList, cardTitle) {
        let totalFlat = 0
        let emptyFlat = 0
        let occupiedFlat = 0
        dataList.forEach((item) => {
            if (item.isVacant) emptyFlat++
            else occupiedFlat++
            totalFlat++
        })
        return [
            new DecorationDataModel(
                cardTitle.totalFlat,
                totalFlat,
                ColorConstants.BLACK2,
                <TotalFlatWhiteIcon />
            ),
            new DecorationDataModel(
                cardTitle.emptyFlat,
                emptyFlat,
                TagColors.RED_FRONT,
                <EmptyFlatWhiteIcon />
            ),
            new DecorationDataModel(
                cardTitle.occupiedFlat,
                occupiedFlat,
                GreeneTheme.COLOR1,
                <OccupiedFlatWhiteIcon />
            )
        ]
    }
    static _getCommunitiesDataOverview(dataList, cardTitle) {
        let totalCommunity = 0
        let activeCommunity = 0
        let inActiveCommunity = 0
        if (dataList) {
            dataList.forEach((item) => {
                if (item.isActive) activeCommunity++
                else inActiveCommunity++
                totalCommunity++
            })
        }
        return [
            new DecorationDataModel(
                cardTitle.totalCommunity,
                totalCommunity,
                ColorConstants.BLACK2,
                <TotalFlatWhiteIcon />
            ),
            new DecorationDataModel(
                cardTitle.activeCommunity,
                activeCommunity,
                TagColors.RED_FRONT,
                <EmptyFlatWhiteIcon />
            ),
            new DecorationDataModel(
                cardTitle.inactiveCommunity,
                inActiveCommunity,
                GreeneTheme.COLOR1,
                <OccupiedFlatWhiteIcon />
            )
        ]
    }
    static _getBuildingsDataOverview(dataList, cardTitle) {
        let totalFlat = 0
        let totalFloor = 0
        let totalGate = 0
        let totalParking = 0
        if (dataList) {
            dataList.forEach((item) => {
                totalFlat += item.totalFlat
                totalFloor += item.totalFloor
                totalGate += item.totalGate
                totalParking += item.totalParking
            })
        }
        return [
            new DecorationDataModel(
                cardTitle.totalFlat,
                totalFlat,
                ColorConstants.BLACK2,
                <TotalFlatWhiteIcon />
            ),
            new DecorationDataModel(
                cardTitle.totalFloor,
                totalFloor,
                TagColors.RED_FRONT,
                <EmptyFlatWhiteIcon />
            ),
            new DecorationDataModel(
                cardTitle.totalGate,
                totalGate,
                GreeneTheme.COLOR1,
                <OccupiedFlatWhiteIcon />
            ),
            new DecorationDataModel(
                cardTitle.totalParking,
                totalParking,
                GreeneTheme.COLOR9,
                <CommonNoticeWhiteIcon />
            )
        ]
    }

    static _getRequestsDataOverview(dataList) {
        let totalRequest = 0
        let totalAccepted = 0
        let totalPending = 0
        let totalRejected = 0
        // dataList.forEach(item => {
        //     switch(item.response) {
        //         case RequestStatusConstants.ACCEPTED :totalAccepted++;break;
        //         case RequestStatusConstants.PENDING :totalPending++;break;
        //         case RequestStatusConstants.REJECTED :totalRejected++;break
        //     }
        //     totalRequest++;
        // });
        return [
            new DecorationDataModel(
                'Request',
                totalRequest,
                ColorConstants.GREY2,
                <TotalRequestWhiteIcon />
            ),
            new DecorationDataModel(
                'Accepted',
                totalAccepted,
                TagColors.GREEN_BACK,
                <RequestAcceptedWhiteIcon />
            ),
            new DecorationDataModel(
                'Pending',
                totalPending,
                TagColors.BLUE_BACK,
                <RequestPendingWhiteIcon />
            ),
            new DecorationDataModel(
                'Rejected',
                totalRejected,
                TagColors.RED_BACK,
                <RequestRejectedWhiteIcon />
            )
        ]
    }
    static _getNoticesDataOverview(dataList) {
        let totalNotice = 0
        let usersNotice = 0
        let guardsNotice = 0
        let allNotice = 0

        dataList.forEach((item) => {
            switch (item.n_type) {
                case NoticeForConstants.FLAT_MEMBERS:
                    usersNotice++
                    break
                case NoticeForConstants.GUARDS:
                    guardsNotice++
                    break
                case NoticeForConstants.ALL:
                    allNotice++
                    break
            }
            totalNotice++
        })
        return [
            new DecorationDataModel(
                'Total Notice',
                totalNotice,
                ColorConstants.BLACK2,
                <TotalNoticeWhiteIcon />
            ),
            new DecorationDataModel(
                'Users Notice',
                usersNotice,
                GreeneTheme.COLOR1,
                <UsersNoticeWhiteIcon />
            ),
            new DecorationDataModel(
                'Guards Notice',
                guardsNotice,
                GreeneTheme.COLOR2,
                <GuardsNoticeWhiteIcon />
            ),
            new DecorationDataModel(
                'Common Notice',
                allNotice,
                GreeneTheme.COLOR3,
                <CommonNoticeWhiteIcon />
            )
        ]
    }

    static _getMeetingsDataOverview(dataList) {
        let totalMeeting = 0
        let runningMeeting = 0
        let finishedMeeting = 0
        let allNotice = 0
        dataList.forEach((item) => {
            switch (item.meeting_status) {
                case MeetingStatusConstants.RUNNING:
                    runningMeeting++
                    break
                case MeetingStatusConstants.FINISHED:
                    finishedMeeting++
                    break
            }
            totalMeeting++
        })
        return [
            new DecorationDataModel(
                'Total Meeting',
                totalMeeting,
                ColorConstants.BLACK2,
                <TotalNoticeWhiteIcon />
            ),
            new DecorationDataModel(
                'Running Meeting',
                runningMeeting,
                GreeneTheme.COLOR1,
                <UsersNoticeWhiteIcon />
            ),
            new DecorationDataModel(
                'Finished Meeting',
                finishedMeeting,
                GreeneTheme.COLOR2,
                <GuardsNoticeWhiteIcon />
            )
        ]
    }
}
