import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tag } from 'antd'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import DataValidationConstants from '../FormDataValidationConstants'
import BuildingAction from '../../../stores/property-management/building/BuildingAction'
import CreateBuildingRequest from '../../../stores/property-management/building/request/CreateBuildingRequest'
import { ColorConstants, RadioConstants } from '../../../assets/constants/GeneralConstants'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { BuildingIconWhite } from '../../misc/IconsProvider'
import { FormButtonContainer, FormIconContainer, FormItemContainer } from '../FormStyles'
import { useTranslation } from 'react-i18next'
import { AppRoutes } from '../../../assets/constants/routes'
import { Link } from 'react-router-dom'
import { ButtonFillBlack } from '../../component/buttons/CustomButtons'
import { makeSelectLastCreationBuilding } from '../../../stores/property-management/building/BuildingSelector'
import FlatAction from '../../../stores/property-management/flat/FlatAction'
import { useFlatGenerator } from './useFlatGenerator'
import CreateMultipleFlatRequest from '../../../stores/property-management/flat/request/CreateMultipleFlatRequest'
import SingleFlatItem from '../../../stores/property-management/flat/request/SingleFlatItem'
import FormInputRadio from '../../component/form-input/FormInputRadio'

const INITIAL_STATE = {
    name: '',
    address: '',
    contactInfo: '',
    contactPerson: '',
    totalFlat: '',
    totalFloor: '',
    totalGate: '',
    totalParking: '',
    flatsPerFloor: '',
    flatFormat: ''
}

const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string()
        .required('Building Name is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Maximum 40 Characters'),
    address: Yup.string()
        .required('Address is Required')
        .min(5, 'Minimum 5 Characters')
        .max(150, 'Maximum 150 Characters'),
    contactInfo: DataValidationConstants.REQUIRED_VALID_MOBILE_NUMBER,
    contactPerson: Yup.string()
        .required('Contact Person Name is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Maximum 40 Characters'),
    totalFlat: Yup.number()
        .typeError('Please Provide Digit')
        .required('Number of Flat is Required')
        .min(1, 'Minimum Digit 1')
        .max(1000, 'Maximum Digit 1000')
        .integer('Please Provide Integer'),
    totalFloor: Yup.number()
        .typeError('Please Provide Digit')
        .min(1, 'Minimum Digit 1')
        .max(30, 'Maximum Digit 30')
        .integer('Please Provide Integer'),
    totalGate: Yup.number()
        .typeError('Please Provide Digit')
        .required('Number of Gate is Required')
        .min(0, 'Minimum Digit 0')
        .max(10, 'Maximum Digit 10')
        .integer('Please Provide Integer'),
    totalParking: Yup.number()
        .typeError('Please Provide Digit')
        .required('Number of Parking is Required')
        .min(0, 'Minimum Digit 0')
        .max(1000, 'Maximum Digit 1000')
        .integer('Please Provide Integer'),
    flatsPerFloor: Yup.number()
        .typeError('Please Provide Digit')
        .min(0, 'Minimum Digit 0')
        .max(30, 'Maximum Digit 30')
        .integer('Please Provide Integer')
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === '' ? null : value)),
    flatFormat: Yup.string()
})

export default function FormCreateBuilding() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [BuildingAction.REQUEST_CREATE_BUILDING])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, BuildingAction.REQUEST_CREATE_BUILDING)
    )

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        dispatch(BuildingAction._requestCreateBuilding(new CreateBuildingRequest(values)))
    }
    const isRequestingCreateFlat = useSelector((state) =>
        selectRequesting(state, [FlatAction.REQUEST_CREATE_MULTIPLE_FLAT])
    )
    const isFinishedCreateFlat = useSelector((state) =>
        selectFinished(state, FlatAction.REQUEST_CREATE_MULTIPLE_FLAT)
    )
    const generatedFlats = useFlatGenerator(values)
    const lastCreateBuilding = useSelector(makeSelectLastCreationBuilding)
    useEffect(() => {
        if (isFinished && lastCreateBuilding.id) {
            const request = new CreateMultipleFlatRequest({
                ...values,
                buildingId: lastCreateBuilding.id
            })
            request.flats = generatedFlats.map((flatNumber) => new SingleFlatItem(flatNumber))
            dispatch(FlatAction._requestCreateMultipleFlat(request))
        }
    }, [lastCreateBuilding, isFinished])
    return (
        <>
            <FormButtonContainer>
                <Link to={AppRoutes.BUILDINGS}>
                    <ButtonFillBlack icon={'backward'} text={'Buildings'} />
                </Link>
            </FormButtonContainer>
            <FormIconContainer>
                <FormTitleWithIcon
                    icon={<BuildingIconWhite />}
                    color={ColorConstants.GREEN1}
                    formTitle={t('building.createBuilding.title')}
                />

                <FormItemContainer>
                    <FormInputText
                        label={t('building.createBuilding.name')}
                        control={control}
                        name={'name'}
                    />
                    <FormInputText
                        label={t('building.createBuilding.address')}
                        control={control}
                        name={'address'}
                    />
                    <FormInputText
                        label={t('building.createBuilding.contactPerson')}
                        control={control}
                        name={'contactPerson'}
                    />
                    <FormInputText
                        label={t('building.createBuilding.contactInfo')}
                        control={control}
                        name={'contactInfo'}
                    />
                    <FormInputText
                        label={t('building.createBuilding.totalFlat')}
                        control={control}
                        name={'totalFlat'}
                    />
                    <FormInputText
                        label={t('building.createBuilding.totalFloor')}
                        control={control}
                        name={'totalFloor'}
                    />
                    <FormInputText
                        label={t('building.createBuilding.totalGate')}
                        control={control}
                        name={'totalGate'}
                    />
                    <FormInputText
                        label={t('building.createBuilding.totalParking')}
                        control={control}
                        name={'totalParking'}
                    />
                    <FormInputText
                        label={t('flat.createMultipleFlat.flatsPerFloor')}
                        control={control}
                        name={'flatsPerFloor'}
                    />
                    <FormInputRadio
                        label={t('flat.createMultipleFlat.flatFormat')}
                        control={control}
                        name={'flatFormat'}
                        radioFor={RadioConstants.TYPE_FLAT_NUMBER_FORMAT}
                    />
                </FormItemContainer>
                {generatedFlats.map((item) => (
                    <Tag color={'blue'}> {item} </Tag>
                ))}
                <ButtonFormSubmit
                    title={t('building.createBuilding.submitButton')}
                    isRequesting={isRequesting}
                    isRequesting2={isRequestingCreateFlat}
                    control={control}
                    onSubmit={onSubmit}
                />
            </FormIconContainer>
        </>
    )
}
