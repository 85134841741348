import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import { ColorConstants, DropDownConstants } from '../../../assets/constants/GeneralConstants'
import FormInputDropDown from '../../component/form-input/FormInputDropDown'
import MeetingAction from '../../../stores/admin-management/meeting/MeetingAction'
import CreateMeetingRequest from '../../../stores/admin-management/meeting/requests/CreateMeetingRequest'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { NoticeWhiteIcon } from '../../misc/IconsProvider'
import { FormIconContainer, FormItemContainer } from '../FormStyles'
import FormInputDateTimePicker from '../../component/form-input/FormInputDateTimePicker'

const INITIAL_STATE = {
    title: '',
    agenda: '',
    date: '',
    buildingId: '',
    invitedMembersIdList: []
}

const VALIDATION_SCHEMA = Yup.object({
    title: Yup.string()
        .required('Title is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Max 40 Characters'),
    agenda: Yup.string()
        .required('Agenda is Required')
        .min(3, 'Minimum 5 Characters')
        .max(40, 'Max 150 Characters'),
    date: Yup.string().required('Date Time is Required'),
    buildingId: Yup.string().required('Building is Required')
})

export default function FormCreateMeeting(props) {
    const dispatch = useDispatch()

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [MeetingAction.REQUEST_CREATE_MEETING])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, MeetingAction.REQUEST_CREATE_MEETING)
    )

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        dispatch(MeetingAction._requestCreateMeeting(new CreateMeetingRequest(values)))
    }

    return (
        <FormIconContainer>
            <FormTitleWithIcon
                icon={<NoticeWhiteIcon />}
                color={ColorConstants.GREEN1}
                formTitle={'Schedule New Meeting'}
            />

            <FormItemContainer>
                <FormInputText label={'Meeting Title'} control={control} name={'title'} />
                <FormInputText label={'Agenda'} control={control} name={'agenda'} />
                <FormInputDateTimePicker label={'Meeting Date'} control={control} name={'date'} />
                <FormInputDropDown
                    label={'Select Building'}
                    control={control}
                    name={'buildingId'}
                    dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                />
            </FormItemContainer>

            <ButtonFormSubmit isRequesting={isRequesting} control={control} onSubmit={onSubmit} />
        </FormIconContainer>
    )
}
