import BaseRequest from '../../../special/models/BaseRequest'

export class DeleteBillItemRequest extends BaseRequest {
    billInvoiceId = 0
    paymentAmount = 0

    constructor(data) {
        super()
        this.update(data)
    }
}
