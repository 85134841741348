import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import BillAction from './BillAction'

const BillPostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case BillAction.REQUEST_GENERATE_BILL_FOR_FLAT_FINISHED:
                next(MiscAction._showModalSuccess('Bill Generated'))
                break
            case BillAction.REQUEST_CREATE_BILL_PACKAGE_FINISHED:
                next(MiscAction._showModalSuccess('Bill Package Created'))
                break
            case BillAction.REQUEST_ASSIGN_PACKAGE_FINISHED:
                next(MiscAction._showModalSuccess('Package Assigned Successfully'))
                break
            case BillAction.REQUEST_COLLECT_BILL_FINISHED:
                next(MiscAction._showModalSuccess('Bill Collected'))
                break
            case BillAction.REQUEST_CREATE_BILL_TYPE_FINISHED:
                next(MiscAction._showModalSuccess('New Type Created Successfully'))
                break
            case BillAction.REQUEST_GENERATE_MULTIPLE_BILL_FOR_FLAT_FINISHED:
                next(
                    MiscAction._showModalSuccess(
                        'Bill Invoice is Processing Please check bill after few minutes.'
                    )
                )
                break
            case BillAction.REQUEST_DELETE_BILL_ITEM_FINISHED:
                next(MiscAction._showModalSuccess('Bill Invoice Deleted Successfully'))
            default:
                break
        }
    }

    return next(action)
}
export default BillPostEffect
