import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class PrivilegeEffect {
    static async _requestGetUserRoles(data) {
        const endPoint = ApiEndpoint.privilege.getAllUserRoles
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetUserFunctions(data) {
        const endPoint = ApiEndpoint.privilege.getAllUserFunctions
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetUserFunctionsOfRole(data) {
        const endPoint = ApiEndpoint.privilege.getUserFunctionsOfRole
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestCreateUserRole(data) {
        const endPoint = ApiEndpoint.privilege.createNewRole
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestDeleteUserRole(data) {
        const endPoint = ApiEndpoint.privilege.deleteRole
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestDeleteUserFunction(data) {
        const endPoint = ApiEndpoint.privilege.deleteFunction
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestCreateUserFunction(data) {
        const endPoint = ApiEndpoint.privilege.createNewFunction
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestAssignFunctionToRole(data) {
        const endPoint = ApiEndpoint.privilege.assignFunctionToRole
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestAssignRoleToUser(data) {
        const endPoint = ApiEndpoint.privilege.assignRoleToUserBeta
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
