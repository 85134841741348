import ActionUtility from '../../../utils/ActionUtility'
import BuildingEffect from './BuildingEffect'

export default class BuildingAction {
    static REQUEST_GET_BUILDINGS = 'REQUEST_GET_BUILDINGS'
    static REQUEST_GET_BUILDINGS_FINISHED = 'REQUEST_GET_BUILDINGS_FINISHED'

    static REQUEST_CREATE_BUILDING = 'REQUEST_CREATE_BUILDING'
    static REQUEST_CREATE_BUILDING_FINISHED = 'REQUEST_CREATE_BUILDING_FINISHED'

    static REQUEST_DELETE_BUILDING = 'REQUEST_DELETE_BUILDING'
    static REQUEST_DELETE_BUILDING_FINISHED = 'REQUEST_DELETE_BUILDING_FINISHED'

    static _requestGetBuildingList(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BuildingAction.REQUEST_GET_BUILDINGS,
                BuildingEffect._requestGetBuildingList,
                data
            )
        }
    }

    static _requestCreateBuilding(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BuildingAction.REQUEST_CREATE_BUILDING,
                BuildingEffect._requestCreateBuilding,
                data
            )
        }
    }

    static _requestDeleteBuilding(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BuildingAction.REQUEST_DELETE_BUILDING,
                BuildingEffect._requestDeleteBuilding,
                data
            )
        }
    }
}
