import React from 'react'
import { useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import VehicleAction from '../../../stores/security-management/vehicle/VehicleAction'
import { makeSelectVehiclesList } from '../../../stores/security-management/vehicle/VehicleSelector'

export default function TableVehicle(props) {
    const dataList = useSelector(makeSelectVehiclesList)
    const isRequesting = useSelector((state) => selectRequesting(state, [VehicleAction.REQUEST_GET_VEHICLES]))

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            width: '8%'
        },
        {
            title: 'Number',
            dataIndex: 'number',
            width: '10%'
        },
        {
            title: 'Model',
            dataIndex: 'model',
            width: '10%'
        },
        {
            title: 'Color',
            dataIndex: 'color',
            width: '7%'
        },
        {
            title: 'Vehicle Type',
            dataIndex: 'type',
            width: '7%'
        },
        {
            title: 'Registration Number',
            dataIndex: 'registrationNumber',
            width: '12%'
        },
        {
            title: 'Tax Token Number',
            dataIndex: 'taxTokenNumber',
            width: '12%',
            textWrap: 'word-break',
            ellipsis: true
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            width: '8%'
        },
        {
            title: 'Flat',
            dataIndex: 'flatName',
            width: '8%'
        },
        {
            title: 'Building',
            dataIndex: 'building',
            width: '10%'
        },
        {
            title: 'Date',
            render: (text, record) => (
                <div> {record.createdDate && new Date(record.createdDate).toLocaleDateString()} </div>
            ),
            width: '8%'
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
