import React from 'react'
import { Helmet } from 'react-helmet'

const DynamicHelmet = (props) => {
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta name={props.description} />
        </Helmet>
    )
}

export default DynamicHelmet
