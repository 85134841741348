import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Upload, Icon, message, Form } from 'antd'
import useImageUpload from './useImageUpload'
import Compressor from 'compressorjs'
import ImageSelectModel from '../../../stores/misc/model/ImageSelectModel'
import MiscAction from '../../../stores/misc/MiscAction'

const mapStateToProps = (state, ownProps) => ({})

function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
}

function beforeUpload(file) {
    const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
    if (!isJpgOrPng) {
        message.error({content: 'You can only upload JPG/JPEG/PNG file!', key: 'imageTypeError', duration: 2})
        return false
    }
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
        message.error({ content: 'Image must be smaller than 5MB!', key: 'imageSizeError', duration: 2 })
        return false
    }
    return true //because otherwise it will try to upload image
}

//this component takes imageTitle and imageType as prop
class UploadImageButton extends Component {
    constructor(props) {
        super(props)
        this.isTouched = props.control.touched[`${props.name}`]
        this.error = props.control.errors[`${props.name}`]
        this.value = props.isControlledManually
            ? props.value
            : props.control.values[`${props.name}`]
    }
    handleChange = (info) => {
        if (info.file.status === 'error') console.log('error')
        else if (beforeUpload(info.file)) {
            const that = this
            new Compressor(info.file.originFileObj, {
                quality: 0.3,
                success(res) {
                    that._processImageAndSendToStore(res)
                }
            })
        }
    }

    _processImageAndSendToStore = async (info) => {
        getBase64(info, (imageUrl) =>
            this.props.dispatch(
                MiscAction._setImage(new ImageSelectModel(this.props.imageFor, info, imageUrl))
            )
        )
        // const { uploadImage } = useImageUpload()
        // const formData = new FormData()
        // formData.append('image', info)
        // formData.append('folderName', this.props.folderName ? this.props.folderName : 'none')
        // formData.append(
        //     'subFolderName',
        //     this.props.subFolderName ? this.props.subFolderName : 'none'
        // )
        // formData.append('fileName', Date.now())
        // const postImage = await uploadImage(formData)
        // if (postImage.success) {
        //     message.success(`File uploaded successfully`)
        //     console.log('image', postImage.success)
        //     this.props.setImageUrl(postImage.success)
        //     this.props.control.handleInputChange(this.props.name, postImage.success)
        //     // const data = {
        //     //     ...values.create,
        //     //     image: postImage.success,
        //     //     thumbImage: postImage.success
        //     // }
        //     // dispatch(EmployeeAction._requestCreateNewEmployee(new ReqCreateEmployee(data)));
        // } else if (postImage.error) {
        //     message.error('Image Upload Failed.')
        //     // this.props.control.errors[`${props.name}`]
        //     // dispatch(EmployeeAction._requestCreateNewEmployee(new ReqCreateEmployee(...values.create)));
        // }
    }

    render() {
        const uploadButton = (
            <div>
                <Icon type='inbox' />
                <div className='ant-upload-text'>Click or drag file to this area to upload</div>
            </div>
        )

        return (
            <Form.Item
                name={this.props.name}
                label={this.props.label}
                hasFeedback
                validateStatus={
                    this.isTouched
                        ? this.error
                            ? 'error'
                            : this.value && this.value.length > 0
                            ? 'success'
                            : ''
                        : ''
                }
                help={this.isTouched ? this.error : ''}
            >
                <Upload
                    accept='image/png,image/jpg,image/jpeg'
                    style={{ width: '300px' }}
                    listType='picture-card'
                    className='avatar-uploader'
                    showUploadList={false}
                    // beforeUpload={beforeUpload}
                    onChange={this.handleChange}
                >
                    {this.props.imageUrl ? (
                        <img src={this.props.imageUrl} alt='avatar' style={{ width: '100%' }} />
                    ) : (
                        uploadButton
                    )}
                </Upload>
                <p style={{ color: 'red' }}>{this.props.control.errors[`${this.props.name}`]}</p>
            </Form.Item>
        )
    }
}

export { UploadImageButton as Unconnected }
export default connect(mapStateToProps)(UploadImageButton)
