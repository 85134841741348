import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BuildingIconWhite } from '../misc/IconsProvider'
import { DropDownConstants } from '../../assets/constants/GeneralConstants'
import useFormInputValidation from '../component/form-input/useFormInputValidation'
import * as Yup from 'yup'
import BuildingAction from '../../stores/property-management/building/BuildingAction'
import GetBuildingsRequest from '../../stores/property-management/building/request/GetBuildingsRequest'
import TableFilterDropDown from '../component/form-input/TableFilterDropDown'
import { selectRequesting } from '../../stores/special/requesting/RequestingSelector'
import { PageDecorationContainer } from '../pages/PageStyles'
import { useTranslation } from 'react-i18next'
import { getCurrentMonthRange, getCurrentMonthWithYear } from '../../utils/functions/functions'
import { makeSelectBuildingsList } from '../../stores/property-management/building/BuildingSelector'
import TableFilterMonthRangePicker from '../component/form-input/TableFilterMonthRangePicker'

export default function TableDataFilterSelected(props) {
    const buildings = useSelector(makeSelectBuildingsList)
    const INITIAL_STATE = {
        buildingId: '',
        dateRange: getCurrentMonthRange(),
        monthRange: '',
        month: getCurrentMonthWithYear()
    }
    const VALIDATION_SCHEMA = Yup.object({})
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isRequestingBuildings = useSelector((state) =>
        selectRequesting(state, [BuildingAction.REQUEST_GET_BUILDINGS_FINISHED])
    )

    useEffect(() => {
        dispatch(BuildingAction._requestGetBuildingList(new GetBuildingsRequest()))
    }, [])

    useEffect(() => {
        if (values.buildingId && values.buildingId !== '') {
            props.sendDataToParent(values)
        }
    }, [values])
    //For Auto Select First Building
    // useEffect(() => {
    //     if (buildings.length > 0) {
    //         // props.sendDataToParent({ ...values, buildingId: buildings[0].id })
    //         control.setValues({
    //             ...values,
    //             buildingId: buildings[0].id
    //         })
    //     }
    // }, [buildings])

    return (
        <PageDecorationContainer item={3} style={{ padding: 10 }} paddingTop={'0px'}>
            {props.showBuildingFilter && (
                <div className='dropdown-basic-container'>
                    <div className='container-icon-dropdown background-black'>
                        {/* <BuildingIconBlack /> */}
                    </div>
                    <TableFilterDropDown
                        isLoading={isRequestingBuildings}
                        label={t('table_data_filter.selectBuilding')}
                        control={control}
                        name={'buildingId'}
                        isFirstValueDefault={true}
                        dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                    />
                </div>
            )}

            

            {props.showSimpleBuildingFilter && (
                <div className='dropdown-basic-container'>
                    <div className='container-icon-dropdown background-black'>
                        {/* <BuildingIconBlack /> */}
                    </div>
                    <TableFilterDropDown
                        isLoading={isRequestingBuildings}
                        label={t('table_data_filter.selectBuilding')}
                        control={control}
                        name={'buildingId'}
                        isFirstValueDefault={true}
                        dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                    />
                </div>
            )}
            {props.monthRangeFilter && (
                <div className='dropdown-basic-container'>
                    <div className='container-icon-dropdown background-black'>
                        {/* <BuildingIconBlack /> */}
                    </div>
                    <TableFilterMonthRangePicker
                        control={control}
                        name={'monthRange'}
                    />
                </div>
            )}

            {props.showStyledBuildingFilter && (
                <div className='dropdown-basic-container-styled'>
                    <div className='container-icon-dropdown-styled background-green'>
                        <BuildingIconWhite />
                    </div>
                    <div className='container-dropdown-content'>
                        <label>Buildings</label>
                        <TableFilterDropDown
                            isLoading={isRequestingBuildings}
                            label={t('table_data_filter.selectBuilding')}
                            control={control}
                            name={'buildingId'}
                            isFirstValueDefault={true}
                            dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                        />
                    </div>
                </div>
            )}
        </PageDecorationContainer>
    )
}
