import * as axios from 'axios'
import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'

export default function useImageUpload() {
    var downloadUrl
    var error

    const uploadImage = async (formData) => {
        await axios({
            method: 'post',
            url: ApiEndpoint.image.uploadSingle,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'XMLHttpRequest',
                jwtTokenHeader: localStorage.getItem('JWT_TOKEN')
            }
        })
            .then(function (response) {
                downloadUrl = response.data.data
            })
            .catch(function (response) {
                error = response
            })
        if (downloadUrl) {
            return { success: downloadUrl }
        } else {
            return { error: error }
        }
    }

    return { uploadImage }
}
