import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import FundAction from '../../../stores/money-management/fund/FundAction'
import { makeSelectFundVouchersList } from '../../../stores/money-management/fund/FundSelector'
import { BlueTag, GreenTag, RedTag } from '../../component/misc/Tags'
import { FundMovementTypeConstants } from '../../../assets/constants/GeneralConstants'
import { useTranslation } from 'react-i18next'

export default function TableFundVouchers(props) {
    const { t } = useTranslation();
    const dataList = useSelector(makeSelectFundVouchersList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [FundAction.REQUEST_GET_FUND_MOVEMENT_VOUCHERS])
    )
console.log(dataList);
    const columns = [
        {
            title: t('fund_movement.tableHeader.amount'),
            dataIndex: 'amount',
            width: '10%'
        },
        {
            title: t('fund_movement.tableHeader.description'),
            dataIndex: 'title',
            width: '40%'
        },
        {
            title: t('fund_movement.tableHeader.type'),
            dataIndex: 'type',
            render: (text, record) => {
                switch (record.type) {
                    case FundMovementTypeConstants.DEPOSIT:
                        return <GreenTag text={record.type} />
                    case FundMovementTypeConstants.WITHDRAW:
                        return <RedTag text={record.type} />
                    case FundMovementTypeConstants.TRANSFER:
                        return <BlueTag text={record.type} />
                }
            },
            width: '15%'
        },
        {
            title: t('fund_movement.tableHeader.date'),
            render: (text, record) => (
                <div>
                    {record.createdDate && new Date(record.createdDate).toLocaleDateString()}{' '}
                </div>
            ),
            width: '25%'
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
