import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import ExpenseAction from './ExpenseAction'

const ExpensePostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case ExpenseAction.REQUEST_RECORD_DIRECT_EXPENSE_FINISHED:
                next(MiscAction._showModalSuccess('Expense Record Saved'))
                break
            case ExpenseAction.REQUEST_CREATE_EXPENSE_TYPE_FINISHED:
                next(MiscAction._showModalSuccess('New Expense Type Created'))
                break
            case ExpenseAction.REQUEST_GENERATE_EXPENSE_VOUCHER_FINISHED:
                next(MiscAction._showModalSuccess('Voucher Generated Successfully'))
                break
            case ExpenseAction.REQUEST_AUTHORIZE_VOUCHER_FINISHED:
                next(MiscAction._showModalSuccess('Authorization Successful'))
                break
            case ExpenseAction.REQUEST_MAKE_PAYMENT_AGAINST_VOUCHER_FINISHED:
                next(MiscAction._showModalSuccess('Payment Successful'))
                break
            case ExpenseAction.REQUEST_DELETE_VOUCHER_FINISHED:
                next(MiscAction._showModalSuccess('Expense Deleted'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default ExpensePostEffect
