import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { ButtonFillBlue } from '../component/buttons/CustomButtons'
import FormMultipleBillTypeDropdown from '../component/form-input/FormMultipleBillTypeDropDown'

export default function ModalAddBillType(props) {
    const [visibility, setVisibility] = useState(false)
    const [selectedBillItem, setSelectedBillItem] = useState([])
    const [filteredDropDown, setFilteredDropDown] = useState([])

    const { activeBillItems, billTypes, setActiveBillItems } = props

    useEffect(() => {
        if (activeBillItems && billTypes) {
            let arrayFields = ['id', 'amount', 'name', 'description']
            const filteredArray = billTypes
                .filter((obj1) => {
                    return !activeBillItems.some((obj2) => {
                        return obj1.id === obj2.id
                    })
                })
                .map((obj) => {
                    return arrayFields.reduce((newObj, info) => {
                        newObj[info] = obj[info]
                        if (info === 'name') {
                            newObj.label = obj[info]
                        } else if (info === 'id') {
                            newObj.value = obj[info]
                        }
                        return newObj
                    }, {})
                })
            setFilteredDropDown(filteredArray)
        }
    }, [activeBillItems, billTypes])

    console.log('props', props)

    const onSubmit = () => {
        setActiveBillItems([...activeBillItems, ...selectedBillItem])
        setVisibility(false)
        setSelectedBillItem([])
        setFilteredDropDown([])
    }

    return (
        <div>
            <ButtonFillBlue
                icon={'plus'}
                text={'Add'}
                onClick={() => {
                    setVisibility(true)
                }}
            />
            <Modal
                title={`Add Bill Type`}
                centered
                okText={'Add'}
                visible={visibility}
                key='billTypeModal'
                // confirmLoading={isRequesting}
                onCancel={() => setVisibility(false)}
                cancelText={'Cancel'}
                onOk={onSubmit}
            >
                <FormMultipleBillTypeDropdown
                    label={'Select Bill Type'}
                    control={props.control}
                    name={'billType'}
                    flatOption={true}
                    selectedBillItem={selectedBillItem}
                    setSelectedBillItem={setSelectedBillItem}
                    dropDownItems={filteredDropDown}
                />
            </Modal>
        </div>
    )
}
