import './decoration.scss'
import React from 'react'
import { ColorConstants } from '../../../assets/constants/GeneralConstants'

export function CardInfoLeftColored(props) {
    return (
        <div className={'info-card-container container-left-colored'}>
            <div style={{ background: props.data.backgroundColor }} className='info-card-icon'>
                {props.data.icon}
            </div>
            <div className='info-card-data'>
                <h4 className={'info-card-data-title'}> {props.data.dataTitle}</h4>
                <div className={'info-card-data-divider'} />
                <h1> {props.data.dataCount} </h1>
            </div>
        </div>
    )
}

export function CardInfoBackgroundColoredLeftIcon(props) {
    return (
        <div
            style={{ background: props.data.backgroundColor }}
            className={'info-card-container container-backfill-lefticon'}
        >
            <div className='info-card-icon icon-background-left'>{props.data.icon}</div>
            <div className='info-card-data-background'>
                <h1 style={{ color: '#fff', textAlign: 'center', marginTop: '10px' }}> {props.data.dataTitle}</h1>
                {/*<div className={"info-card-data-divider"} />*/}
                <h2 style={{ color: '#fff', textAlign: 'center' }}> {props.data.dataCount} </h2>
            </div>
        </div>
    )
}

export function CardInfoColoredBoxIcon(props) {
    return (
        <div className={'info-card-container special-container'}>
            <div className={'container-boxfill-lefticon'}>
                <div
                    style={{ background: props.data.backgroundColor }}
                    className='icon-boxfill-topleft'
                >
                    {props.data.icon}
                </div>

                <div className='info-card-data-boxfill'>
                    <h5 style={{ color: ColorConstants.GREY }}> {props.data.dataTitle}</h5>
                    <h1> {props.data.dataCount} </h1>
                </div>
            </div>
            {/*  <div className={"info-card-data-divider-special"}/>
            <div className={"boxfill-bottom-container"}>
                <RefreshGreyIcon/>
                <h6 className={"boxfill-icon-bottom-text"}> updated just now </h6>
            </div>*/}
        </div>
    )
}

export function CardInfoTopColoredIconRight(props) {
    return (
        <div className={'info-card-container top-fill-container'}>
            <div
                style={{ background: props.data.backgroundColor }}
                className={'top-fll-title-icon'}
            >
                <div style={{ fontSize: '14px' }}> {props.data.dataTitle}</div>
                {props.data.icon}
            </div>
            <div className={'top-fill-data'}>৳ {props.data.dataCount}</div>
        </div>
    )
}

export function CardInfoColoredText(props) {
    return (
        <div className={'info-card-container top-fill-container'}>
            <div style={{ display: 'grid', alignItems: 'center' }}>
                <div style={{ fontSize: '14px', textAlign: 'center' }}> {props.data.dataTitle}</div>
                {/*{props.data.icon}*/}
            </div>
            <div
                style={{
                    color: props.data.backgroundColor,
                    fontSize: '32px',
                    textAlign: 'center',
                    fontWeight: '500'
                }}
            >
                ৳{props.data.dataCount}
            </div>
        </div>
    )
}

export function CardInfoBackgroundColoredRightIcon(props) {
    return (
        <div className={'back-fill-right-icon'}>
            <div className='back-fill-right-icon-info'>
                <div className={'back-fill-right-icon-icon'}> {props.data.icon}</div>

                <div className={'back-fill-right-icon-title'}> {props.data.dataTitle}</div>
            </div>
            <div
                className='back-fill-right-icon-data'
                style={{ color: props.data.backgroundColor }}
            >
                ৳ {props.data.dataCount}
            </div>
        </div>
    )
}

export function CardInfoLeftColoredIcon(props) {
    return (
        <div className={'left-color-icon'}>
            <div
                className={'left-color-icon-icon'}
                style={{ background: props.data.backgroundColor }}
            >
                {props.data.icon}
            </div>
            <div className={'left-color-icon-info'}>
                <div className={'left-color-icon-title'}> {props.data.dataTitle}</div>
                <div className={'left-color-icon-data'}>{props.data.dataCount} </div>
            </div>
        </div>
    )
}
export function CardInfoLeftColoredSvgIcon(props) {
    return (
        <div className='left-color-svg-icon-container'>
            <div
                className='left-color-svg-icon'
                style={{ backgroundColor: props.data.backgroundColor }}
            >
                <img style={{ textAlign: 'center' }} src={props.data.icon} alt='icon' />
            </div>
            <div>
                <h2 style={{ margin: 0 }}>{props.data.dataTitle}</h2>
                <div style={{ fontSize: '30px', fontWeight: '600', margin: 0, color: '#000' }}>
                    <span style={{ fontSize: '22px', fontWeight: 'normal' }}>৳</span>{' '}
                    {props.data.dataCount}
                </div>
            </div>
        </div>
    )
}
export function CardInfoLeftRoundIcon(props) {
    return (
        <div className={'left-round-icon'}>
            <div
                style={{ background: props.data.backgroundColor }}
                className='left-round-icon-icon'
            >
                {props.data.icon}
            </div>
            <div className='left-round-icon-info'>
                <div className={'left-round-icon-title'}> {props.data.dataTitle}</div>
                <div className={'left-round-icon-data'}>{props.data.dataCount} </div>
            </div>
        </div>
    )
}

export function CardColoredIconBottomInfo(props) {
    return (
        // <Col lg={{ span: 24 }} xl={{ span: 6}}>
        <div className={'icon-bottom-info'}>
            <div
                className={'icon-bottom-info-icon'}
                style={{ background: `${props.data.backgroundColor}` }}
            >
                {props.data.icon}
            </div>
            <div className={'icon-bottom-info-info'}>
                <div className={'icon-bottom-info-title'}> {props.data.dataTitle}</div>
                <div className={'icon-bottom-info-data'}>{props.data.dataCount}</div>
            </div>
        </div>
        // </Col>
    )
}

export function FormTitleWithIcon(props) {
    return (
        <div className={'form-decoration-title-container'}>
            <div className='form-decoration-icon'>
                {/*<CalenderIcon/>*/}
                {props.icon}
            </div>
            <div className='form-decoration-title'>{props.formTitle}</div>
        </div>
    )
}

export function FormTitleWithSvgIcon(props) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '30px'
            }}
        >
            <div style={{ display: 'flex' }}>
                <div
                    style={{
                        display: 'grid',
                        alignItems: 'center',
                        margin: '4px',
                        width: '50px',
                        height: '50px',
                        // @include card();
                        backgroundColor: `${props.backgroundColor ? props.backgroundColor : ''}`,
                        justifyItems: 'center',
                        padding: '10px',
                        borderRadius: '50%/50%'
                    }}
                >
                    <img src={props.icon} alt='icon' />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ margin: '0 10px 0 0', fontSize: '24px' }}>{props.title}</h1>{' '}
                    <span style={{marginBottom: '-5px'}}>{props.subTitle ? props.subTitle : ''}</span>
                </div>
            </div>
            {props.child ? props.child : null}
        </div>
    )
}

export function FormInputTitleWithSvgIcon(props) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px'
            }}
        >
            <div style={{ display: 'flex' }}>
                <div
                    style={{
                        display: 'grid',
                        alignItems: 'center',
                        margin: '2px',
                        width: '30px',
                        height: '30px',
                        backgroundColor: `${props.backgroundColor ? props.backgroundColor : ''}`,
                        justifyItems: 'center',
                        // padding: '10px',
                        borderRadius: '50%/50%'
                    }}
                >
                    <img src={props.icon} alt='icon' />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ margin: '0 10px 0 0', fontSize: '18px' }}>{props.title}</h1>{' '}
                    <span style={{marginBottom: '-2px'}}>{props.subTitle ? props.subTitle : ''}</span>
                </div>
            </div>
        </div>
    )
}

export function TableTitleWithIcon(props) {
    return (
        <div className={'table-decoration-title-container'}>
            <div className='table-decoration-icon'>
                {/*<CalenderIcon/>*/}
                {props.icon}
            </div>
            <div className='table-decoration-title'>{props.formTitle}</div>
        </div>
    )
}
