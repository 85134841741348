import ActionUtility from '../../../utils/ActionUtility'
import ExpenseEffect from './ExpenseEffect'

export default class ExpenseAction {
    static REQUEST_CREATE_EXPENSE_TYPE = 'REQUEST_CREATE_EXPENSE_TYPE'
    static REQUEST_CREATE_EXPENSE_TYPE_FINISHED = 'REQUEST_CREATE_EXPENSE_TYPE_FINISHED'

    static REQUEST_DELETE_EXPENSE_TYPE = 'REQUEST_DELETE_EXPENSE_TYPE'
    static REQUEST_DELETE_EXPENSE_TYPE_FINISHED = 'REQUEST_DELETE_EXPENSE_TYPE_FINISHED'

    static REQUEST_GET_EXPENSE_TYPES = 'REQUEST_GET_EXPENSE_TYPES'
    static REQUEST_GET_EXPENSE_TYPES_FINISHED = 'REQUEST_GET_EXPENSE_TYPES_FINISHED'

    static REQUEST_RECORD_DIRECT_EXPENSE = 'REQUEST_RECORD_DIRECT_EXPENSE'
    static REQUEST_RECORD_DIRECT_EXPENSE_FINISHED = 'REQUEST_RECORD_DIRECT_EXPENSE_FINISHED'

    static REQUEST_DELETE_EXPENSE_ITEM = 'REQUEST_DELETE_EXPENSE_ITEM'
    static REQUEST_DELETE_EXPENSE_ITEM_FINISHED = 'REQUEST_DELETE_EXPENSE_ITEM_FINISHED'

    static REQUEST_GET_EXPENSE_ITEMS = 'REQUEST_GET_EXPENSE_ITEMS'
    static REQUEST_GET_EXPENSE_ITEMS_FINISHED = 'REQUEST_GET_EXPENSE_ITEMS_FINISHED'

    static REQUEST_GENERATE_EXPENSE_VOUCHER = 'REQUEST_GENERATE_EXPENSE_VOUCHER'
    static REQUEST_GENERATE_EXPENSE_VOUCHER_FINISHED = 'REQUEST_GENERATE_EXPENSE_VOUCHER_FINISHED'

    static REQUEST_GET_EXPENSE_VOUCHERS = 'REQUEST_GET_EXPENSE_VOUCHERS'
    static REQUEST_GET_EXPENSE_VOUCHERS_FINISHED = 'REQUEST_GET_EXPENSE_VOUCHERS_FINISHED'

    static REQUEST_AUTHORIZE_VOUCHER = 'REQUEST_AUTHORIZE_VOUCHER'
    static REQUEST_AUTHORIZE_VOUCHER_FINISHED = 'REQUEST_AUTHORIZE_VOUCHER_FINISHED'

    static REQUEST_DELETE_VOUCHER = 'REQUEST_DELETE_VOUCHER'
    static REQUEST_DELETE_VOUCHER_FINISHED = 'REQUEST_DELETE_VOUCHER_FINISHED'

    static REQUEST_MAKE_PAYMENT_AGAINST_VOUCHER = 'REQUEST_MAKE_PAYMENT_AGAINST_VOUCHER'
    static REQUEST_MAKE_PAYMENT_AGAINST_VOUCHER_FINISHED =
        'REQUEST_MAKE_PAYMENT_AGAINST_VOUCHER_FINISHED'

    static _requestGenerateExpenseVoucher(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ExpenseAction.REQUEST_GENERATE_EXPENSE_VOUCHER,
                ExpenseEffect._requestGenerateExpenseVoucher,
                data
            )
        }
    }
    static _requestGetExpenseVouchers(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ExpenseAction.REQUEST_GET_EXPENSE_VOUCHERS,
                ExpenseEffect._requestGetExpenseVouchers,
                data
            )
        }
    }

    static _requestAuthorizeVoucher(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ExpenseAction.REQUEST_AUTHORIZE_VOUCHER,
                ExpenseEffect._requestAuthorizeVoucher,
                data
            )
        }
    }

    static _requestDeleteExpenseVoucher(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ExpenseAction.REQUEST_DELETE_VOUCHER,
                ExpenseEffect._requestDeleteVoucher,
                data
            )
        }
    }

    static _requestMakePaymentAgainstVoucher(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ExpenseAction.REQUEST_MAKE_PAYMENT_AGAINST_VOUCHER,
                ExpenseEffect._requestMakePaymentAgainstVoucher,
                data
            )
        }
    }

    static _requestCreateExpenseType(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ExpenseAction.REQUEST_CREATE_EXPENSE_TYPE,
                ExpenseEffect._requestCreateExpenseType,
                data
            )
        }
    }
    static _requestDeleteExpenseType(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ExpenseAction.REQUEST_DELETE_EXPENSE_TYPE,
                ExpenseEffect._requestDeleteExpenseType,
                data
            )
        }
    }

    static _requestGetExpenseTypes(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ExpenseAction.REQUEST_GET_EXPENSE_TYPES,
                ExpenseEffect._requestGetExpenseTypes,
                data
            )
        }
    }

    static _requestCreateDirectExpense(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ExpenseAction.REQUEST_RECORD_DIRECT_EXPENSE,
                ExpenseEffect._requestCreateDirectExpense,
                data
            )
        }
    }
    static _requestDeleteExpenseItem(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ExpenseAction.REQUEST_DELETE_EXPENSE_ITEM,
                ExpenseEffect._requestDeleteExpenseItem,
                data
            )
        }
    }

    static _requestGetExpenseItems(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ExpenseAction.REQUEST_GET_EXPENSE_ITEMS,
                ExpenseEffect._requestGetExpenseItems,
                data
            )
        }
    }
}
