import React, { useContext, useEffect, useState } from 'react'
import { DataBuilderUtility } from '../../../utils/DataBuilderUtility'
import { CardInfoLeftRoundIcon, TableTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { useDispatch, useSelector } from 'react-redux'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import TableCommunity from '../../tables/property/TableCommunitiy'
import CommunityAction from '../../../stores/property-management/community/CommunityAction'
import GetCommunityRequest from '../../../stores/property-management/community/requests/GetCommunityRequest'
import { Tabs } from 'antd'
import { makeSelectCommunityList } from '../../../stores/property-management/community/CommunitySelector'
import { PageBasicContainer, PageDecorationContainer, PageMainBarContainer, TableButtonContainer } from '../PageStyles'
import { useTranslation } from 'react-i18next'
import { ListWhiteIcon, NoticeWhiteIcon } from '../../misc/IconsProvider'
import { ColorConstants } from '../../../assets/constants/GeneralConstants'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../../../assets/constants/routes'
import { ButtonFillBlack } from '../../component/buttons/CustomButtons'

const { TabPane } = Tabs

export default function PageCommunities() {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [activeStatus, setActiveStatus] = useState(true)

    const dataList = useSelector(makeSelectCommunityList)
    const isFinishedDeletion = useSelector((state) => selectFinished(state, CommunityAction.REQUEST_DELETE_COMMUNITY))
    const isFinishedCreation = useSelector((state) => selectFinished(state, CommunityAction.REQUEST_CREATE_COMMUNITY))

    useEffect(() => {
        dispatch(CommunityAction._requestGetCommunityList(new GetCommunityRequest(activeStatus)))
    }, [isFinishedDeletion, isFinishedCreation, activeStatus])

    const decorationArr = DataBuilderUtility._getCommunitiesDataOverview(dataList, t('community.cardTitle', {returnObjects:true}))

    const changeActiveStatus = (status) => setActiveStatus(status)

    const decorItems = decorationArr.map((item) => <CardInfoLeftRoundIcon key={item.dataTitle} data={item} />)
    return (
        <PageBasicContainer>
            <PageDecorationContainer  paddingTop={'0px'} item={3}>{decorItems}</PageDecorationContainer>
            <TableButtonContainer>
                <Link to={AppRoutes.CREATE_COMMUNITY}>
                    <ButtonFillBlack icon={'plus'} text={'Create Community'} />
                </Link>
            </TableButtonContainer>
            <PageMainBarContainer>
                <Tabs onChange={changeActiveStatus} type='card'>
                    <TabPane tab={t('community.tabs.activeTab')} key={true} />
                    <TabPane tab={t('community.tabs.inactiveTab')} key={false} />
                </Tabs>
                <TableCommunity />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
