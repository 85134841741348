import BaseRequest from '../../../special/models/BaseRequest'

export default class CollectBillRequest extends BaseRequest {
    billInvoiceId = ''
    paymentAmount = ''

    constructor(data) {
        super()
        this.update(data)
        this.paymentAmount = parseInt(data.paymentAmount)
    }
}
