import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import VehicleAction from './VehicleAction'

const VehiclePostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case VehicleAction.REQUEST_ADD_VEHICLE_FINISHED:
                next(MiscAction._showModalSuccess('Vehicle is registered'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default VehiclePostEffect
