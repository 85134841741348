import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Tabs } from 'antd'
import { DataBuilderUtility } from '../../../utils/DataBuilderUtility'
import { CardInfoColoredBoxIcon } from '../../component/decoration/CardSingleInfo'
import { useDispatch, useSelector } from 'react-redux'
import { MeetingStatusConstants } from '../../../assets/constants/GeneralConstants'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import TableDataFilter from '../../tables/TableDataFilter'
import TableMeeting from '../../tables/admin/TableMeeting'
import { makeSelectMeetingList } from '../../../stores/admin-management/meeting/MeetingSelector'
import MeetingAction from '../../../stores/admin-management/meeting/MeetingAction'
import GetMeetingsRequest from '../../../stores/admin-management/meeting/requests/GetMeetingsRequest'
import { PageBasicContainer, PageDecorationContainer, PageMainBarContainer } from '../PageStyles'

const { TabPane } = Tabs

function PageMeetings(props) {
    const dispatch = useDispatch()
    const [decorationArrValues, setDecorationArrValues] = useState([])
    const [meetingStatus, setMeetingStatus] = useState(MeetingStatusConstants.ALL)
    const [filterValues, setFilterValues] = useState(null)
    const isFinished = useSelector((state) =>
        selectFinished(state, MeetingAction.REQUEST_DELETE_MEETING)
    )
    const dataList = useSelector(makeSelectMeetingList)

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        dispatch(
            MeetingAction._requestGetMeetingList(
                new GetMeetingsRequest(filterValues, meetingStatus)
            )
        )
    }, [filterValues, meetingStatus, isFinished])

    useEffect(() => {
        setDecorationArrValues(DataBuilderUtility._getMeetingsDataOverview(dataList))
    }, [dataList])

    const changeMeetingStatus = (status) => setMeetingStatus(status)

    const decorationItems = decorationArrValues.map((item) => (
        <CardInfoColoredBoxIcon key={item.dataTitle} data={item} />
    ))

    return (
        <>
            <PageBasicContainer>
                <PageDecorationContainer paddingTop={'20px'} item={3}>
                    {decorationItems}
                </PageDecorationContainer>
                <PageMainBarContainer>
                    <TableDataFilter
                        showBuildingFilter={true}
                        showDateRangeFilter={true}
                        sendDataToParent={receiveFilterData}
                    />
                    <Tabs onChange={changeMeetingStatus} type='card'>
                        <TabPane tab='All Meeting' key={MeetingStatusConstants.ALL} />
                        <TabPane tab='Scheduled ' key={MeetingStatusConstants.SCHEDULED} />
                        {/* <TabPane tab='Running ' key={MeetingStatusConstants.RUNNING} /> */}
                        {/* <TabPane tab='Finished ' key={MeetingStatusConstants.FINISHED} /> */}
                        <TabPane tab='Cancelled' key={MeetingStatusConstants.CANCELLED} />
                    </Tabs>
                    <TableMeeting />
                    {/* <div className={'card'} style={{backgroundColor:'gray',marginBottom:'10px'}}>
                    <h1 >Hello</h1>
                    </div>
                    <div className={'card'} style={{backgroundColor:'gray',marginBottom:'10px'}}>
                    <h1 >Hello</h1>
                    </div>
                    <div className={'card'} style={{backgroundColor:'gray',marginBottom:'10px'}}>
                    <h1 >Hello</h1>
                    </div> */}
                </PageMainBarContainer>
            </PageBasicContainer>
        </>
    )
}
export default withRouter(PageMeetings)
