import BaseRequest from '../../../special/models/BaseRequest'

export default class AddVehicleRequest extends BaseRequest {
    name = ''
    number = ''
    model = ''
    color = ''
    vehicleType = ''
    registrationNumber = ''
    taxTokenNumber = ''
    image = ''
    thumbImage = ''
    ownedBy = ''

    buildingId = ''
    flatId = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
