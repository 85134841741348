import React, { useState } from 'react'
import policeLogo from '../../assets/images/dhaka_police_logo.png'
import blankUser from '../../assets/images/blankUser.png'
import { ButtonFillBlue, ButtonMildBlue } from '../component/buttons/CustomButtons'
import { Button, message, Modal } from 'antd'
import './policeFormModal.scss'
// import './modals.scss'
import { ThemeProvider, theme } from '@chakra-ui/core'
import PoliceFormBodyElement from '../component/form-input/PoliceFormBodyElement'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
export default function ModalPoliceFormDetails(props) {
    const [verificationDetails, setVerificationDetails] = useState(props.data)
    const [visibility, setVisibility] = useState(false)
    const customTheme = {
        ...theme,
        fonts: {
            heading: "'Raleway', sans-serif",
            body: "'Raleway', sans-serif;",
            mono: 'Menlo, monospace'
        }
    }
    const {
        birthDate,
        currentHomeStartdate,
        currentLandlord_name,
        fullName,
        fatherName,
        maritalStatus,
        permanentAddress,
        workAddress,
        religion,
        educationStatus,
        mobileNumber,
        email,
        nidNumber,
        passportNumber,
        emergency_con_name,
        emergency_con_relation,
        emergency_con_address,
        emergency_con_number,
        maid_name,
        maid_nid,
        maid_number,
        maid_address,
        driver_name,
        driver_nid,
        driver_number,
        driver_address,
        prevLandlord_name,
        prevLandlord_number,
        prevLandlord_address,
        reasonToLeavePrevHouse,
        currentLandlord_number,
        passportImage,
        division,
        thana,
        floor,
        house,
        road,
        area,
        postCode,
        familyMamOne_name,
        familyMamOne_age,
        familyMamOne_occu,
        familyMamOne_num,
        familyMamTwo_name,
        familyMamTwo_age,
        familyMamTwo_occu,
        familyMamTwo_num,
        familyMamThree_name,
        familyMamThree_age,
        familyMamThree_occu,
        familyMamThree_num
    } = verificationDetails
    const print = async () => {
        // (
        //     await function () {
        //         const content = document.getElementById('printDoc')
        //         const iframe = document.createElement('iframe')
        //         iframe.setAttribute('title', 'uniqueIframeId')
        //         iframe.setAttribute('id', 'uniqueIframeId')
        //         iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
        //         document.body.appendChild(iframe)
        //         let pri = iframe.contentWindow
        //         pri.document.open()
        //         pri.document.write(content.innerHTML)
        //         pri.document.close()
        //         pri.focus()
        //         pri.print()
        //     }
        // )()
        var date = new Date()
        const content = document.getElementById('printDoc')
        if (content) {
            html2canvas(content).then((canvas) => {
                var imgData = canvas.toDataURL('image/png')
                var imgWidth = 210
                var pageHeight = 295
                var imgHeight = (canvas.height * imgWidth) / canvas.width
                var heightLeft = imgHeight
                var doc = new jsPDF('p', 'mm')
                var position = 0

                if (imgData) {
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
                    heightLeft -= pageHeight
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight
                        doc.addPage()
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
                        heightLeft -= pageHeight
                    }

                    doc.save('police-form-' + date.toLocaleDateString() + '.pdf')
                }
            })
        } else {
            message.error('Something went wrong please try again later')
        }
    }
    return (
        <>
            <ButtonMildBlue
                disabled={props.isDisabled}
                icon={'eye'}
                text={'VIEW'}
                onClick={() => setVisibility(true)}
            />
            <ThemeProvider theme={customTheme}>
                <Modal
                    className='police-verification'
                    bodyStyle={{ height: '75vh', overflowY: 'auto' }}
                    title={`Submition Details`}
                    centered
                    visible={visibility}
                    onCancel={() => setVisibility(false)}
                    onOk={() => setVisibility(false)}
                    okText={'Close'}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    footer={[
                        <>
                            <Button type='danger' key='close' onClick={() => setVisibility(false)}>
                                Close Details
                            </Button>
                            <ButtonFillBlue
                                key={1}
                                onClick={print}
                                text={'Print'}
                                icon={'printer'}
                            />
                        </>
                    ]}
                >
                    <div className='police-modal' id='printDoc'>
                        <div className={'top-section'}>
                            <div className='header-left'>
                                <div className='user-image'>
                                    <img
                                        src={passportImage ? passportImage : blankUser}
                                        alt='user-logo'
                                    />
                                </div>
                            </div>
                            <div className='header-middle flex-common'>
                                <div className='police-title'>
                                    <img src={policeLogo} alt='police-logo' />
                                    <h2>ঢাকা মেট্রোপলিটন পুলিশ</h2>
                                </div>
                                <div className=''>
                                    <div className='flex-label-input'>
                                        <label>বিভাগ :</label>
                                        <input readOnly value={division}></input>
                                    </div>
                                    <div className='flex-label-input'>
                                        <label>থানা :</label>
                                        <input readOnly value={thana}></input>
                                    </div>
                                </div>
                            </div>
                            <div className='header-right flex-common'>
                                <div className='flex-label-input'>
                                    <label>ফ্ল্যাট/তলা :</label>
                                    <input readOnly value={floor}></input>
                                </div>
                                <div className='flex-label-input'>
                                    <label>বাড়ী/হোল্ডিং :</label>
                                    <input readOnly value={house}></input>
                                </div>
                                <div className='flex-label-input'>
                                    <label>রাস্তা :</label>
                                    <input readOnly value={road}></input>
                                </div>
                                <div className='flex-label-input'>
                                    <label>এলাকা :</label>
                                    <input readOnly value={area}></input>
                                </div>
                                <div className='flex-label-input'>
                                    <label>পোস্ট কোড :</label>
                                    <input readOnly value={postCode}></input>
                                </div>
                            </div>
                        </div>
                        <div className='form-header'>
                            <u>ভাড়াটিয়া নিবন্ধন ফরম</u>
                        </div>
                        <PoliceFormBodyElement
                            itemNo={1}
                            data={[{ label: 'ভাড়াটিয়া/বাড়িওয়ালার নাম :', value: fullName }]}
                        />
                        <PoliceFormBodyElement
                            itemNo={2}
                            data={[{ label: 'পিতার নাম :', value: fatherName }]}
                        />
                        <PoliceFormBodyElement
                            itemNo={3}
                            data={[
                                { label: 'জন্ম তারিখ :', value: birthDate },
                                { label: 'বৈবাহিক অবস্থা :', value: maritalStatus }
                            ]}
                        />
                        <PoliceFormBodyElement
                            itemNo={4}
                            data={[{ label: 'স্থায়ী ঠিকানা :', value: permanentAddress }]}
                        />
                        <PoliceFormBodyElement
                            itemNo={5}
                            data={[
                                {
                                    label: 'পেশা ও প্রতিষ্টান / কর্মস্থল ঠিকানা :',
                                    value: workAddress
                                }
                            ]}
                        />
                        <PoliceFormBodyElement
                            itemNo={6}
                            data={[
                                { label: 'ধর্ম :', value: religion },
                                { label: 'শিক্ষাগত যোগ্যতা :', value: educationStatus }
                            ]}
                        />
                        <PoliceFormBodyElement
                            itemNo={7}
                            data={[
                                { label: 'মোবাইল নম্বর :', value: mobileNumber },
                                { label: 'ই-মেইল আইডি :', value: email }
                            ]}
                        />
                        <PoliceFormBodyElement
                            itemNo={8}
                            data={[{ label: 'জাতীয় পরিচয়পত্র নম্বর :', value: nidNumber }]}
                        />
                        <PoliceFormBodyElement
                            itemNo={9}
                            data={[{ label: 'পাসপোর্ট নম্বর (যদি থাকে) :', value: passportNumber }]}
                        />
                        <PoliceFormBodyElement
                            itemNo={10}
                            multipleColumn={true}
                            title={'জরুরি যোগাযোগ :'}
                            data={[
                                { label: '(ক) নাম :', value: emergency_con_name },
                                { label: '(খ) সম্পর্ক :', value: emergency_con_relation },
                                { label: '(গ) ঠিকানা :', value: emergency_con_address },
                                { label: '(ঘ) মোবাইল নম্বর :', value: emergency_con_number }
                            ]}
                        />
                        <PoliceFormBodyElement
                            itemNo={11}
                            tableTemplate={true}
                            title={'পরিবার / মেসের সদস্যদের বিবরণ'}
                            data={[
                                {
                                    name: familyMamOne_name,
                                    age: familyMamOne_age,
                                    occu: familyMamOne_occu,
                                    num: familyMamOne_num
                                },
                                {
                                    name: familyMamTwo_name,
                                    age: familyMamTwo_age,
                                    occu: familyMamTwo_occu,
                                    num: familyMamTwo_num
                                },
                                {
                                    name: familyMamThree_name,
                                    age: familyMamThree_age,
                                    occu: familyMamThree_occu,
                                    num: familyMamThree_num
                                }
                            ]}
                        />
                        <PoliceFormBodyElement
                            itemNo={12}
                            data={[
                                { label: 'গৃহকর্মীর নাম :', value: maid_name },
                                { label: 'জাতীয় পরিচয়পত্র নং :', value: maid_nid },
                                { label: 'মোবাইল নম্বর :', value: maid_number },
                                { label: 'স্থায়ী ঠিকানা :', value: maid_address }
                            ]}
                        />
                        <PoliceFormBodyElement
                            itemNo={13}
                            data={[
                                { label: 'ড্রাইভারের নাম :', value: driver_name },
                                { label: 'জাতীয় পরিচয়পত্র নং :', value: driver_nid },
                                { label: 'মোবাইল নম্বর :', value: driver_number },
                                { label: 'স্থায়ী ঠিকানা :', value: driver_address }
                            ]}
                        />
                        <PoliceFormBodyElement
                            itemNo={14}
                            data={[
                                { label: 'পূর্ববর্তী বাড়িওয়ালার নাম :', value: prevLandlord_name },
                                { label: 'মোবাইল নম্বর :', value: prevLandlord_number },
                                { label: 'ঠিকানা :', value: prevLandlord_address }
                            ]}
                        />
                        <PoliceFormBodyElement
                            itemNo={15}
                            data={[
                                {
                                    label: 'পূর্ববর্তী বাসা ছাড়ার কারণ :',
                                    value: reasonToLeavePrevHouse
                                }
                            ]}
                        />
                        <PoliceFormBodyElement
                            itemNo={16}
                            data={[
                                { label: 'বর্তমান বাড়িওয়ালার নাম :', value: currentLandlord_name },
                                { label: 'মোবাইল নম্বর :', value: currentLandlord_number }
                            ]}
                        />
                        <PoliceFormBodyElement
                            itemNo={17}
                            data={[
                                {
                                    label: 'বর্তমান বাড়িতে কোন তারিখ থেকে বসবাস :',
                                    value: currentHomeStartdate
                                }
                            ]}
                        />
                    </div>
                </Modal>
            </ThemeProvider>
        </>
    )
}
