import { VehicleTypeConstants } from '../../../../assets/constants/GeneralConstants'
import BaseRequest from '../../../special/models/BaseRequest'

export default class GetVehicleRequest extends BaseRequest {
    buildingId = ''
    flatId = ''
    vehicleType = ''

    constructor(data) {
        super()
        this.update(data)
        if (data && data.vehicleType!==VehicleTypeConstants.ALL) {
            this.vehicleType = data.vehicleType
        }else{
            this.vehicleType = ''
        }
    }
}
