import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import EntranceAction from '../../../stores/security-management/entrance/EntranceAction'
import { makeSelectVehiclesList } from '../../../stores/security-management/entrance/EntranceSelector'
import { BlueTag, GreenTag } from '../../component/misc/Tags'
import {
    EntranceStatusConstants,
    ModalTypeConstants
} from '../../../assets/constants/GeneralConstants'
import MiscAction from '../../../stores/misc/MiscAction'
import ModalStateModel from '../../../stores/misc/model/ModalStateModel'
import VehicleDetailsRequest from '../../../stores/security-management/entrance/requests/VehicleDetailsRequest'

export default function TableVehicleEntryExit() {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectVehiclesList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [EntranceAction.REQUEST_GET_VEHICLES])
    )

    const columns = [
        {
            title: 'ThumbImage',
            dataIndex: 'thumbImage',
            render: (text, record) => {
                return (
                    <img
                        onClick={() =>
                            dispatch(
                                MiscAction._setModalStatus(
                                    new ModalStateModel(
                                        ModalTypeConstants.DETAILS_VISITOR,
                                        true,
                                        record
                                    )
                                )
                            )
                        }
                        className='image-user-small'
                        src={record.thumbImage}
                        alt="thumb"
                    />
                )
            }
        },
        {
            title: 'Name',
            dataIndex: 'vehicleName'
        },
        {
            title: 'Vehicle Number',
            dataIndex: 'vehicleNumber'
        },
        {
            title: 'Vehicle Model',
            dataIndex: 'vehicleModel'
        },
        {
            title: 'Vehicle Type',
            dataIndex: 'vehicleType'
        },
        {
            title: 'Flat No',
            dataIndex: 'flatName'
        },
        {
            align: 'start',
            render: (text, record) => {
                if (record.status === EntranceStatusConstants.INSIDE_COMPOUND)
                    return <GreenTag text={'Still Inside'} />
                else return <BlueTag text={'Not Inside'} />
            }
        },
        {
            title: 'In Date',
            render: (text, record) => (
                <div> {record.inTime && new Date(record.inTime).toLocaleDateString()} </div>
            )
        },
        {
            title: 'In Time',
            render: (text, record) => (
                <div> {record.inTime && new Date(record.inTime).toLocaleTimeString()} </div>
            )
        },
        {
            title: 'Exit Date',
            render: (text, record) => (
                <div> {record.exitTime && new Date(record.exitTime).toLocaleDateString()} </div>
            )
        },
        {
            title: 'Exit Time',
            render: (text, record) => (
                <div> {record.exitTime && new Date(record.exitTime).toLocaleTimeString()} </div>
            )
        },
        {
            title: '',
            align: 'center',
            render: (text, record) => (
                <ModalDeleteConfirmation
                    icon={'logout'}
                    confirmationText={'Are you sure that this vehicle is not inside compound ?'}
                    onConfirm={() =>
                        dispatch(
                            EntranceAction._requestLetVehicleOut(new VehicleDetailsRequest(record))
                        )
                    }
                />
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
