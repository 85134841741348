import styled from 'styled-components'

export const AppContainer = styled.div`
    display: grid;
    height: 100vh;
    grid-template-columns: auto 1fr;
`

export const AppMainBarContainer = styled.div``

export const TopBarContainer = styled.div`
    height: 50px;
`

export const TopBarTitle = styled.h2`
    margin-top: 10px;
    margin-right: 10px;
    font-size: 16px;
    color: #ff3300;
    text-transform: uppercase;
    @media only screen and (max-width: 760px) {
        font-size: 14px;
    }
`

export const CardContainer = styled.div`
    background-color: white;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
    transition: 0.2s;
    border-radius: 5px;
    padding: 10px;
`