import BaseReducer from '../../../utils/BaseReducer'
import FingerPrintAction from './FingerPrintAction'

export default class FingerPrintReducer extends BaseReducer {
    initialState = {
        fingerPrintDeviceList: null,
        fingerPrintList: null
    };

    [FingerPrintAction.REQUEST_GET_FINGERPRINT_DEVICES_FINISHED](state, action) {
        return {
            ...state,
            fingerPrintDeviceList: action.payload
        }
    }
    [FingerPrintAction.REQUEST_GET_FINGERPRINTS_FINISHED](state, action) {
        return {
            ...state,
            fingerPrintList: action.payload
        }
    }
}
