import { ApiEndpoint } from "../../../assets/constants/ApiEndpoint";
import EffectUtility from "../../../utils/EffectUtility";
import BaseRequest from "../../special/models/BaseRequest";
import ServerResponse from "../../special/models/ServerResponse";

export default class NIDVerifationEffect {
    static async _requestGetUserVerification (data) {
        const endPoint = ApiEndpoint.forms.getVerifyUserNID
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data));
    }

}