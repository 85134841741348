import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ExpenseAuthorizationStatusConstants } from '../../../assets/constants/GeneralConstants'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { DataBuilderUtility } from '../../../utils/DataBuilderUtility'
import { CardInfoLeftColoredIcon } from '../../component/decoration/CardSingleInfo'
import TableDataFilter from '../../tables/TableDataFilter'
import ExpenseAction from '../../../stores/money-management/expense/ExpenseAction'
import { makeSelectExpenseItemsList } from '../../../stores/money-management/expense/ExpenseSelector'
import { GetExpenseTypeRequest } from '../../../stores/money-management/expense/requests/type/GetExpenseTypeRequest'
import { PageBasicContainer, PageDecorationContainer, PageMainBarContainer } from '../PageStyles'
import TableExpenseVouchers from '../../tables/expense/TableExpenseVouchers'
import { GetExpenseVouchersRequest } from '../../../stores/money-management/expense/requests/voucher/GetExpenseVouchersRequest'
import { Tabs } from 'antd'
const { TabPane } = Tabs
export default function PageExpenseDashBoard(props) {
    const dispatch = useDispatch()
    const [decorationArrValues, setDecorationArrValues] = useState([])
    const [authorizationStatus, setAuthorizationStatus] = useState(
        ExpenseAuthorizationStatusConstants.ALL
    )
    const [filterValues, setFilterValues] = useState({})
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, ExpenseAction.REQUEST_RECORD_DIRECT_EXPENSE)
    )
    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, ExpenseAction.REQUEST_DELETE_VOUCHER)
    )
    const dataList = useSelector(makeSelectExpenseItemsList)
    const receiveFilterData = (filterValues) => setFilterValues(filterValues)
    useEffect(() => {
        dispatch(ExpenseAction._requestGetExpenseTypes(new GetExpenseTypeRequest()))
    }, [])
    useEffect(() => {
        if(filterValues && filterValues.buildingId && filterValues.dateRange){
            filterValues['authorizationStatus'] = authorizationStatus
            dispatch(
                ExpenseAction._requestGetExpenseVouchers(new GetExpenseVouchersRequest(filterValues))
            )
        }
    }, [isFinishedDeletion, isFinishedCreation, filterValues, authorizationStatus])

    useEffect(() => {
        setDecorationArrValues(DataBuilderUtility._getExpensesDataOverview(dataList))
    }, [dataList])

    const changeStatus = (currentStatus) => {
        setAuthorizationStatus(currentStatus)
    }

    return (
        <PageBasicContainer>
            <PageDecorationContainer paddingTop={'0px'} item={5}>
                {decorationArrValues.map((item) => (
                    <CardInfoLeftColoredIcon key={Math.random()} data={item} />
                ))}
            </PageDecorationContainer>
            <PageMainBarContainer>
                <TableDataFilter
                    showDateRangeFilter={true}
                    showBuildingFilter={true}
                    sendDataToParent={receiveFilterData}
                />

                {/*<div className={'table-title-button-button'}>*/}
                {/*    <h2> Expense Details </h2>*/}
                {/*    <div></div>*/}
                {/*    <FlatInvoiceDownload monthName={this.state.month} />*/}
                {/*    <CustomButtons text={"Add Bill to Flat"} onClick={ () => {*/}
                {/*        this.props.dispatch(MiscAction._setModalStatus(new ModalStateModel( ModalTypeConstants.ADD_BILL , true ,*/}
                {/*            this.state)))*/}
                {/*    }} />*/}
                {/*</div>*/}

                <Tabs onChange={changeStatus} type='card'>
                    <TabPane tab={'All'} key={ExpenseAuthorizationStatusConstants.ALL} />
                    <TabPane
                        tab={'Authorized'}
                        key={ExpenseAuthorizationStatusConstants.AUTHORIZED}
                    />
                    <TabPane tab={'Pending'} key={ExpenseAuthorizationStatusConstants.PENDING} />
                    <TabPane tab={'Declined'} key={ExpenseAuthorizationStatusConstants.DECLINED} />
                </Tabs>
                <TableExpenseVouchers />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
