import React from 'react'
import './pages.scss'
import logo from '../../assets/images/avatar.png'
import { useSelector } from 'react-redux'
import FormChangePassword from '../forms/FormChangePassword'
import { AppRoutes } from '../../assets/constants/routes'
import { Link } from 'react-router-dom'
import { ButtonFillBlue } from '../component/buttons/CustomButtons'
import DynamicHelmet from '../misc/DynamicHelmet'
import { GreenTag, RedTag } from '../component/misc/Tags'
import ModalChangePassword from '../modals/ModalChangePassword'
import { Tag, Tooltip } from 'antd'

const PageProfile = (props) => {
    const userDetails = useSelector((state) => state.user.userDetails)
    return (
        <div className='profile-container'>
            <DynamicHelmet title={'Rokkhi | User Profile'} description={'User Profile Page'} />
            <div className='profile-info'>
                <div className='profile-title'>
                    <img src={logo} alt='' />
                    <h1 style={{ textShadow: '1px 1px 2px black' }}>Profile Information</h1>
                </div>
                <div className='info-row'>
                    <div className='single-row'>
                        <h3
                            style={{
                                textTransform: 'uppercase',
                                marginBottom: 0,
                                color: '#606469'
                            }}
                        >
                            Name
                        </h3>
                        <h1 style={{ marginBottom: 0 }}>{userDetails.data.name}</h1>
                    </div>
                    <div className='border-bottom'></div>
                </div>
                <div className='info-row'>
                    <div className='single-row'>
                        <h1
                            style={{
                                textTransform: 'uppercase',
                                marginBottom: 0,
                                color: '#606469'
                            }}
                        >
                            Email
                        </h1>
                        <h1 style={{ marginBottom: 0 }}>{userDetails.data.email}</h1>
                    </div>
                    <div className='border-bottom'></div>
                </div>
                {/* <div className="info-row">
                    <div className="single-row">
                        <h1 style={{ textTransform: 'uppercase', marginBottom: 0, color:'#606469' }}>Building</h1>
                        <h1 style={{ marginBottom: 0 }}>{userDetails.data.buildingName}</h1>
                    </div>
                    <div className="border-bottom"></div>
                </div>
                <div className="info-row">
                    <div className="single-row">
                        <h1 style={{ textTransform: 'uppercase', marginBottom: 0, color:'#606469' }}>Flat</h1>
                        <h1 style={{ marginBottom: 0 }}>{userDetails.data.flatName}</h1>
                    </div>
                    <div className="border-bottom"></div>
                </div> */}
                <div className='info-row'>
                    <div className='single-row'>
                        <h1
                            style={{
                                textTransform: 'uppercase',
                                marginBottom: 0,
                                color: '#606469'
                            }}
                        >
                            Address
                        </h1>
                        <h1 style={{ marginBottom: 0 }}>{userDetails.data.address}</h1>
                    </div>
                    <div className='border-bottom'></div>
                </div>
                <div className='info-row'>
                    <div className='single-row'>
                        <h1
                            style={{
                                textTransform: 'uppercase',
                                marginBottom: 0,
                                color: '#606469'
                            }}
                        >
                            Phone
                        </h1>
                        <h1 style={{ marginBottom: 0 }}>{userDetails.data.phone}</h1>
                    </div>
                    <div className='border-bottom'></div>
                </div>
                <div className='info-row'>
                    <div className='single-row'>
                        <h1
                            style={{
                                textTransform: 'uppercase',
                                marginBottom: 0,
                                color: '#606469'
                            }}
                        >
                            Reminder
                        </h1>
                        <Tooltip title={<span>Custom notification coming soon.</span>}>
                            <h1 style={{ marginBottom: 0, color: '#ff3300' }}>
                                Please pay your bill within the first 5th or 10th day of the month.
                            </h1>
                        </Tooltip>
                    </div>
                    <div className='border-bottom'></div>
                </div>
                <div className='info-row'>
                    <div className='single-row'>
                        <h1
                            style={{
                                textTransform: 'uppercase',
                                marginBottom: 0,
                                color: '#606469',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            Valid Till:
                        </h1>
                        <div>
                            {new Date(userDetails.data.licenseExpireDate) > new Date() ? (
                                <Tag color='green'>
                                    {new Date(
                                        userDetails.data.licenseExpireDate
                                    ).toLocaleDateString()}
                                </Tag>
                            ) : (
                                <Tag color='red'>{'Expired'}</Tag>
                            )}
                        </div>
                    </div>
                </div>
                {/* <div className='info-row'>
                    <div className='single-row'>
                        <h1
                            style={{
                                textTransform: 'uppercase',
                                marginBottom: 0,
                                color: '#606469'
                            }}
                        >
                            Change Password
                        </h1>
                    </div>
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '15px' }}>
                    <ModalChangePassword currentUser={userDetails} />
                    {/* <Link to={AppRoutes.PRICING_PLANS} style={{ width: '20%' }}>
                        <ButtonFillBlue text={'Renew Subscription'} />
                    </Link> */}
                </div>
            </div>
        </div>
    )
}

export default PageProfile
