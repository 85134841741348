import firebase from '../../utils/firebase'
import { useEffect, useState } from 'react'
import UserAction from '../../stores/user-management/user/UserAction'
import { useDispatch } from 'react-redux'
import GetUserDetailsRequest from '../../stores/user-management/user/requests/GetUserDetailsRequest'
import { useFirebaseNotificationProvider } from './useFirebaseNotificationProvider'

export const useFirebaseAuthProvider = () => {
    const dispatch = useDispatch()
    const [isSignedIn, setSignedIn] = useState(null)
    const [user, setUser] = useState(null)
    const authStateObserver = firebase.auth()

    authStateObserver.onAuthStateChanged((user) => {
        if (user) {
            setUser(user)
            setSignedIn(true)
        } else {
            setSignedIn(false)
        }
    })

    useEffect(() => {
        if (isSignedIn) getIdToken()
    }, [isSignedIn])

    const getIdToken = async () => {
        const idToken = await authStateObserver.currentUser.getIdToken(true)
        const getUserRequest = new GetUserDetailsRequest()
        getUserRequest.firebaseIdToken = idToken
        dispatch(UserAction._requestGetUserDetails(getUserRequest))
    }

    return isSignedIn
}
