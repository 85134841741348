import BaseReducer from '../../../utils/BaseReducer'
import NoticeAction from './NoticeAction'

export default class NoticeReducer extends BaseReducer {
    initialState = {
        noticeList: null
    };

    [NoticeAction.REQUEST_GET_NOTICES_FINISHED](state, action) {
        return {
            ...state,
            noticeList: action.payload
        }
    }
}
