import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import { FormInputTextArea } from '../../component/form-input/FormInputTextArea'
import FingerPrintAction from '../../../stores/device-management/fingerprint-device/FingerPrintAction'
import RegisterFingerPrintDeviceRequest from '../../../stores/device-management/fingerprint-device/request/RegisterFingerPrintDeviceRequest'
import { FormIconContainer, FormItemContainer } from '../FormStyles'
import { DeviceIcon } from '../../misc/IconsProvider'
import { ColorConstants } from '../../../assets/constants/GeneralConstants'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import DataValidationConstants from '../FormDataValidationConstants'

const INITIAL_STATE = {
    deviceName: '',
    company: '',
    purpose: '',
    macAddress: ''
}

const VALIDATION_SCHEMA = Yup.object({
    deviceName: Yup.string()
        .required('Device Name is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Maximum 40 Characters'),
    macAddress: DataValidationConstants.REQUIRED_VALID_MAC,
    company: Yup.string().max(40, 'Maximum 40 Characters'),
    purpose: Yup.string().max(40, 'Maximum 40 Characters')
})

export default function FormCreateFingerprintDevice(props) {
    const dispatch = useDispatch()

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)

    const isRequesting = useSelector((state) =>
        selectRequesting(state, [FingerPrintAction.REQUEST_REGISTER_FINGERPRINT_DEVICE])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, FingerPrintAction.REQUEST_REGISTER_FINGERPRINT_DEVICE)
    )

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        dispatch(
            FingerPrintAction._requestRegisterFingerPrintDevice(
                new RegisterFingerPrintDeviceRequest(values)
            )
        )
    }

    return (
        <FormIconContainer>
            <FormTitleWithIcon
                icon={<DeviceIcon />}
                color={ColorConstants.GREEN1}
                formTitle={'Register Device'}
            />
            <FormItemContainer>
                <FormInputText label={'Device Name'} control={control} name={'deviceName'} />
                <FormInputText label={'Device Company'} control={control} name={'company'} />
            </FormItemContainer>
            <FormInputText label={'MAC Address'} control={control} name={'macAddress'} />
            <FormInputTextArea label={'Description'} control={control} name={'purpose'} />
            <ButtonFormSubmit isRequesting={isRequesting} control={control} onSubmit={onSubmit} />
        </FormIconContainer>
    )
}
