import ActionUtility from '../../../utils/ActionUtility'
import BillEffect from './BillEffect'

export default class BillAction {
    static REQUEST_CREATE_BILL_TYPE = 'REQUEST_CREATE_BILL_TYPE'
    static REQUEST_CREATE_BILL_TYPE_FINISHED = 'REQUEST_CREATE_BILL_TYPE_FINISHED'

    static REQUEST_DELETE_BILL_TYPE = 'REQUEST_DELETE_BILL_TYPE'
    static REQUEST_DELETE_BILL_TYPE_FINISHED = 'REQUEST_DELETE_BILL_TYPE_FINISHED'

    static REQUEST_GET_BILL_TYPES = 'REQUEST_GET_BILL_TYPES'
    static REQUEST_GET_BILL_TYPES_FINISHED = 'REQUEST_GET_BILL_TYPES_FINISHED'

    static REQUEST_CREATE_BILL_PACKAGE = 'REQUEST_GENERATE_BILL_PACKAGE'
    static REQUEST_CREATE_BILL_PACKAGE_FINISHED = 'REQUEST_GENERATE_BILL_PACKAGE_FINISHED'

    static REQUEST_ASSIGN_PACKAGE = 'REQUEST_ASSIGN_PACKAGE'
    static REQUEST_ASSIGN_PACKAGE_FINISHED = 'REQUEST_ASSIGN_PACKAGE_FINISHED'

    static REQUEST_GET_BILL_PACKAGES = 'REQUEST_GET_BILL_PACKAGES'
    static REQUEST_GET_BILL_PACKAGES_FINISHED = 'REQUEST_GET_BILL_PACKAGES_FINISHED'

    static REQUEST_GENERATE_BILL_FOR_FLAT = 'REQUEST_GENERATE_BILL_FOR_FLAT'
    static REQUEST_GENERATE_BILL_FOR_FLAT_FINISHED = 'REQUEST_GENERATE_BILL_FOR_FLAT_FINISHED'

    static REQUEST_GET_BILL_ITEMS = 'REQUEST_GET_BILL_ITEMS'
    static REQUEST_GET_BILL_ITEMS_FINISHED = 'REQUEST_GET_BILL_ITEMS_FINISHED'

    static REQUEST_COLLECT_BILL = 'REQUEST_COLLECT_BILL'
    static REQUEST_COLLECT_BILL_FINISHED = 'REQUEST_COLLECT_BILL_FINISHED'

    static REQUEST_GET_DECIDE_BILL = 'REQUEST_GET_DECIDE_BILL'
    static REQUEST_GET_DECIDE_BILL_FINISHED = 'REQUEST_GET_DECIDE_BILL_FINISHED'

    static REQUEST_GENERATE_MULTIPLE_BILL_FOR_FLAT = 'REQUEST_GENERATE_MULTIPLE_BILL_FOR_FLAT'
    static REQUEST_GENERATE_MULTIPLE_BILL_FOR_FLAT_FINISHED =
        'REQUEST_GENERATE_MULTIPLE_BILL_FOR_FLAT_FINISHED'

    static REQUEST_DELETE_BILL_ITEM = 'REQUEST_DELETE_BILL_ITEM'
    static REQUEST_DELETE_BILL_ITEM_FINISHED = 'REQUEST_DELETE_BILL_ITEM_FINISHED'

    static REQUEST_DUE_BILL_ITEM = 'REQUEST_DUE_BILL_ITEM'
    static REQUEST_DUE_BILL_ITEM_FINISHED = 'REQUEST_DUE_BILL_ITEM_FINISHED'

    static REQUEST_REQUESTED_BILL_GENERATION = 'REQUEST_REQUESTED_BILL_GENERATION'
    static REQUEST_REQUESTED_BILL_GENERATION_FINISHED = 'REQUEST_REQUESTED_BILL_GENERATION_FINISHED'

    static REQUEST_GET_MULTIPLE_BILL_INVOICE_REPORT = 'REQUEST_GET_MULTIPLE_BILL_INVOICE_REPORT'
    static REQUEST_GET_MULTIPLE_BILL_INVOICE_REPORT_FINISHED = 'REQUEST_GET_MULTIPLE_BILL_INVOICE_REPORT_FINISHED'

    static _requestCreateBillPackage(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_CREATE_BILL_PACKAGE,
                BillEffect._requestCreateBillPackage,
                data
            )
        }
    }
    static _requestAssignBillPackage(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_ASSIGN_PACKAGE,
                BillEffect._requestAssignBillPackage,
                data
            )
        }
    }

    static _requestGetBillPackages(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_GET_BILL_PACKAGES,
                BillEffect._requestGetBillPackages,
                data
            )
        }
    }
    static _requestDeleteBillType(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_DELETE_BILL_TYPE,
                BillEffect._requestDeleteBillType,
                data
            )
        }
    }

    static _requestCreateBillType(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_CREATE_BILL_TYPE,
                BillEffect._requestCreateBillType,
                data
            )
        }
    }
    static _requestGetBillTypes(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_GET_BILL_TYPES,
                BillEffect._requestGetBillTypes,
                data
            )
        }
    }

    static _requestGenerateBillForFlat(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_GENERATE_BILL_FOR_FLAT,
                BillEffect._requestGenerateBillForFlat,
                data
            )
        }
    }

    static _requestGetBillItems(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_GET_BILL_ITEMS,
                BillEffect._requestGetBillItems,
                data
            )
        }
    }

    static _requestCollectBill(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_COLLECT_BILL,
                BillEffect._requestCollectBill,
                data
            )
        }
    }

    static _requestGetDecideBill(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_GET_DECIDE_BILL,
                BillEffect._requestGetDecideBill,
                data
            )
        }
    }

    static _requestGenerateMultipleBillInvoices(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_GENERATE_MULTIPLE_BILL_FOR_FLAT,
                BillEffect._requestGenerateMultipleBillInvoices,
                data
            )
        }
    }
    static _requestDeleteBillItem(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_DELETE_BILL_ITEM,
                BillEffect._requestDeleteBillItem,
                data
            )
        }
    }
    static _requestGetDueBillItems(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_DUE_BILL_ITEM,
                BillEffect._requsetGetDueBillItems,
                data
            )
        }
    }
    static _requestRequestedBillGeneration(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_REQUESTED_BILL_GENERATION,
                BillEffect._requestRequestedBillGeneration,
                data
            )
        }
    }
    static _requestGetMultipleBillInvoiceReport(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                BillAction.REQUEST_GET_MULTIPLE_BILL_INVOICE_REPORT,
                BillEffect._requestGetMultipleBillInvoiceReport,
                data
            )
        }
    }
}
