import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import { AuthorizationStatusConstants } from '../../../assets/constants/GeneralConstants'
import { GreenTag, RedTag } from '../../component/misc/Tags'
import { makeSelectPreAuthorizationsList } from '../../../stores/security-management/pre-authorization/PreAuthorizationSelector'
import PreAuthorizationAction from '../../../stores/security-management/pre-authorization/PreAuthorizationAction'
import GetPreAuthorizationDetailsRequest from '../../../stores/security-management/pre-authorization/requests/GetPreAuthorizationDetailsRequest'
import ModalImage from '../../modals/ModalImage'

export default function TablePreAuthorizationList() {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectPreAuthorizationsList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [
            PreAuthorizationAction.REQUEST_GET_PRE_AUTHORIZATION_LIST,
            PreAuthorizationAction.REQUEST_DELETE_FROM_PRE_AUTHORIZED_LIST
        ])
    )

    const columns = [
        {
            title: 'Image',
            dataIndex: 'thumbImage',
            render: (text, record) => <ModalImage imageUrl={record.thumbImage} imageTitle={'User Image'} />
        },
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Phone',
            dataIndex: 'phone'
        },
        {
            title: 'Relation',
            dataIndex: 'relation'
        },
        {
            title: 'Status',
            render: (text, record) => {
                if (record.status === AuthorizationStatusConstants.WHITE_LISTED)
                    return <GreenTag text={'White Listed'} />
                else return <RedTag text={'Black Listed'} />
            }
        },
        {
            title: 'Entry Date',
            render: (text, record) => (
                <div> {record.createdDate && new Date(record.createdDate).toLocaleDateString()} </div>
            )
        },
        {
            title: 'Entry Time',
            render: (text, record) => (
                <div> {record.createdDate && new Date(record.createdDate).toLocaleTimeString()} </div>
            )
        },
        {
            title: '',
            align: 'center',
            render: (text, record) => (
                <ModalDeleteConfirmation
                    confirmationText={'Are you sure you want to remove this person from list ?'}
                    onConfirm={() =>
                        dispatch(
                            PreAuthorizationAction._requestDeleteFromPreAuthorizationList(
                                new GetPreAuthorizationDetailsRequest({ authorizationListId: record.id })
                            )
                        )
                    }
                />
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
