import BaseReducer from '../../../utils/BaseReducer'
import ExpenseAction from './ExpenseAction'

export default class ExpenseReducer extends BaseReducer {
    initialState = {
        expenseTypes: null,
        expenseItems: null,
        vouchersList: null
    };

    [ExpenseAction.REQUEST_GET_EXPENSE_VOUCHERS_FINISHED](state, action) {
        return {
            ...state,
            vouchersList: action.payload
        }
    }

    [ExpenseAction.REQUEST_GET_EXPENSE_TYPES_FINISHED](state, action) {
        return {
            ...state,
            expenseTypes: action.payload
        }
    }

    [ExpenseAction.REQUEST_GET_EXPENSE_ITEMS_FINISHED](state, action) {
        return {
            ...state,
            expenseItems: action.payload
        }
    }
}
