import { createSelector } from 'reselect'

export class UserSelector {
    static getUserDetails = (state) => state.user.userDetails

    static getFunctionsOfUser = (state) => state.user.functionsOfUser

    static getRolesOfUser = (state) => state.user.rolesOfUser

    static getUsesList = (state) => state.user.userList

    static getDashboardOfUser = (state) => state.user.userDashboard

    static getPerspectiveOfUser = (state) => state.user.userPerspective
}

export const makeSelectUsersList = createSelector(UserSelector.getUsesList, (user) =>
    user ? user.data : []
)

export const makeSelectUserDetails = createSelector(UserSelector.getUserDetails, (user) =>
    user ? user.data : []
)

export const makeSelectFunctionsOfUser = createSelector(
    UserSelector.getFunctionsOfUser,
    (userFunctions) => (userFunctions ? userFunctions.data : [])
)

export const makeSelectRolesOfUser = createSelector(UserSelector.getRolesOfUser, (userRoles) =>
    userRoles ? userRoles.data : []
)

export const makeSelectFunctionsOfLoggedInUser = createSelector(
    UserSelector.getUserDetails,
    (userDetails) => (userDetails ? userDetails.data.userFunctions : [])
)
export const makeSelectDahboardOfUser = createSelector(
    UserSelector.getDashboardOfUser,
    (userDashboard) => (userDashboard ? userDashboard.data : [])
)
export const makeSelectUserPerspective = createSelector(
    UserSelector.getPerspectiveOfUser,
    (userPerspective) => (userPerspective ? userPerspective : {})
)
