import BaseReducer from '../../../utils/BaseReducer'
import PrivilegeAction from './PrivilegeAction'

export default class PrivilegeReducer extends BaseReducer {
    initialState = {
        userRolesList: null,
        userFunctionsList: null,
        userFunctionsOfRoleList: null
    };

    [PrivilegeAction.REQUEST_GET_USER_FUNCTIONS_FINISHED](state, action) {
        return {
            ...state,
            userFunctionsList: action.payload
        }
    }

    [PrivilegeAction.REQUEST_GET_USER_FUNCTIONS_OF_ROLE_FINISHED](state, action) {
        return {
            ...state,
            userFunctionsOfRoleList: action.payload
        }
    }

    [PrivilegeAction.REQUEST_GET_USER_ROLES_FINISHED](state, action) {
        return {
            ...state,
            userRolesList: action.payload
        }
    }
}
