import ActionUtility from "../../../utils/ActionUtility"
import NewPackageEffect from "./NewPackageEffect"


export default class NewPackageAction {
    static REQUEST_GET_ALL_PACKAGE = 'REQUEST_GET_ALL_PACKAGE'
    static REQUEST_GET_ALL_PACKAGE_FINISHED = 'REQUEST_GET_ALL_PACKAGE_FINISHED'

    static REQUEST_CREATE_PACKAGE = 'REQUEST_CREATE_PACKAGE'
    static REQUEST_CREATE_PACKAGE_FINISHED = 'REQUEST_CREATE_PACKAGE_FINISHED'

    static _requestGetPackageList(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                NewPackageAction.REQUEST_GET_ALL_PACKAGE,
                NewPackageEffect._requestGetPackages,
                data
            )
        }
    }

    static _requestCreatePackage(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                NewPackageAction.REQUEST_CREATE_PACKAGE,
                NewPackageEffect._requestCreatePackage,
                data
            )
        }
    }
}
