import BaseRequest from '../../../special/models/BaseRequest'

export default class CreatePreAuthorizationEntryRequest extends BaseRequest {
    name = ''
    phone = ''
    email = ''
    relation = ''
    authorizationStatus = ''
    image = ''
    thumbImage = ''
    flatId = ''
    buildingId = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
