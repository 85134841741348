import React from 'react'
import { Button } from 'antd'

export default function ButtonFormSubmitIcon(props) {
    return (
        <Button
            type={'submit'}
            style={{ marginTop: '10px', width: '20%', textAlign: 'center' }}
            size='large'
            loading={props.isRequesting ? props.isRequesting : props.isRequesting2}
            disabled={props.disabled}
            className='btn-orange'
            onClick={(event) => props.control.handleSubmit(props.onSubmit)}
            icon={props.icon ? props.icon : 'save'}
        >
            {props.title ? props.title : 'Submit'}
        </Button>
    )
}
