import BaseRequest from '../../../../special/models/BaseRequest'

export class AuthorizeExpenseVoucherRequest extends BaseRequest {
    voucherId = ''
    isAuthorized = ''

    constructor(voucherId, isAuthorized) {
        super()
        this.voucherId = voucherId
        this.isAuthorized = isAuthorized
    }
}
