import BaseRequest from '../../../special/models/BaseRequest'

export default class CreateBuildingRequest extends BaseRequest {
    name = ''
    address = ''
    contactInfo = ''
    contactPerson = ''
    totalFlat = ''
    totalFloor = ''
    totalGate = ''
    totalParking = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
