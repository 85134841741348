import { TransactionCategoryConstants } from '../../../../assets/constants/GeneralConstants'
import BaseRequest from '../../../special/models/BaseRequest'

export class GetTransactionsRequest extends BaseRequest {
    transactionType = ''
    transactionCategory = ''
    buildingId = ''
    fromDate = ''
    toDate = ''

    constructor(data) {
        super()
        this.update(data)
        if (data && data.dateRange && data.dateRange.length > 0) {
            this.fromDate =data.dateRange[0]
            this.toDate = data.dateRange[1]
        }
        if(data && data.transactionCategory!==TransactionCategoryConstants.ALL){
            this.transactionCategory=data.transactionCategory
        }else{
            this.transactionCategory=''
        }
    }
}
