import BaseRequest from '../../../special/models/BaseRequest'

export default class GetUserDashboardRequest extends BaseRequest {
    specBuildingId = 0
    fromDate = 0
    toDate = 0
    constructor(data) {
        super()
        this.update(data)
    }
}
