import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import FundAction from '../../../stores/money-management/fund/FundAction'
import FormFundMovement from '../../forms/fund/FormFundMovement'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'
import TableFundVouchers from '../../tables/fund/TableFundVouchers'
import { GetFundVouchersRequest } from '../../../stores/money-management/fund/requests/GetFundVouchersRequest'
import TableDataFilter from '../../tables/TableDataFilter'

export default function PageFundMovement(props) {
    const dispatch = useDispatch()
    const [filterValues, setFilterValues] = useState({})
    const isCreated = useSelector((state) => selectFinished(state, FundAction.REQUEST_ADD_TO_FUND))
    const isDeleted = useSelector((state) =>
        selectFinished(state, FundAction.REQUEST_WITHDRAW_FROM_FUND)
    )
    const isTransferred = useSelector((state) =>
        selectFinished(state, FundAction.REQUEST_TRANSFER_FUND)
    )

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        dispatch(
            FundAction._requestGetFundMovementVouchers(
                new GetFundVouchersRequest({ ...filterValues, voucherType: props.fundMovementType })
            )
        )
    }, [isCreated, isDeleted, isTransferred, filterValues, props.fundMovementType])

    return (
        <PageBasicContainer>
            <FormFundMovement fundMovementType={props.fundMovementType} />
            <PageMainBarContainer>
                <TableDataFilter showBuildingFilter={true} sendDataToParent={receiveFilterData} />
                <TableFundVouchers />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
