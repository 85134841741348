import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class FormsEffect {
    static async _requestGetUserFormsList(data) {
        const endPoint = ApiEndpoint.forms.getAll
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestCreateNewPoliceForm(data) {
        const endPoint = ApiEndpoint.forms.createNewPoliceForm
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestCreateNewDepartureForm(data) {
        const endPoint = ApiEndpoint.forms.createNewTanentDepartureForm
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestCreateNewTanentOwnerForm(data) {
        const endPoint = ApiEndpoint.forms.createNewTanentOwnerForm
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestGetPoliceFormsList(data) {
        const endPoint = ApiEndpoint.forms.getPoliceForm
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
