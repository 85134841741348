import { createSelector } from 'reselect'

export class FingerPrintSelector {
    static getFingerPrintDeviceList = (state) => state.fingerPrint.fingerPrintDeviceList
    static getFingerPrintList = (state) => state.fingerPrint.fingerPrintList
}

export const makeSelectFingerPrintDevicesList = createSelector(
    FingerPrintSelector.getFingerPrintDeviceList,
    (fingerPrintData) => (fingerPrintData ? fingerPrintData.data : [])
)
export const makeSelectFingerPrintsList = createSelector(
    FingerPrintSelector.getFingerPrintList,
    (fingerPrintData) => (fingerPrintData ? fingerPrintData.data : [])
)
