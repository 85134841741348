import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { SalaryCostWhiteIcon } from '../../misc/IconsProvider'
import { ColorConstants } from '../../../assets/constants/GeneralConstants'
import { FormInputTextArea } from '../../component/form-input/FormInputTextArea'
import FundAction from '../../../stores/money-management/fund/FundAction'
import { CreateFundRequest } from '../../../stores/money-management/fund/requests/CreateFundRequest'
import { FormIconContainer } from '../FormStyles'
import { useTranslation } from 'react-i18next'

const INITIAL_STATE = {
    fundName: '',
    description: ''
}

const VALIDATION_SCHEMA = Yup.object({
    fundName: Yup.string()
        .required('Name is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Max 40 Characters'),
    description: Yup.string().max(150, 'Maximum 150 Characters')
    //fundCode: DataValidationConstants.REQUIRED_AND_DIGIT_ONLY
})

export default function FormCreateFund() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [FundAction.REQUEST_CREATE_FUND])
    )
    const isFinished = useSelector((state) => selectFinished(state, FundAction.REQUEST_CREATE_FUND))

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        dispatch(FundAction._requestCreateFund(new CreateFundRequest(values)))
    }

    return (
        <FormIconContainer>
            <FormTitleWithIcon
                icon={<SalaryCostWhiteIcon />}
                color={ColorConstants.GREEN1}
                formTitle={t('fund.createFund.title')}
            />
            <FormInputText label={t('fund.createFund.name')} control={control} name={'fundName'} />
            <FormInputTextArea
                label={t('fund.createFund.description')}
                control={control}
                name={'description'}
            />
            <ButtonFormSubmit
                title={t('fund.createFund.submitButton')}
                isRequesting={isRequesting}
                control={control}
                onSubmit={onSubmit}
            />
        </FormIconContainer>
    )
}
