import React from 'react'
// import { AppRoutes } from '../../../assets/constants/routes'
import FormDirectPayment from '../../forms/rokkhiAdmin/FormDirectPayment'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'
import DynamicHelmet from '../../misc/DynamicHelmet'

export default function PageDirectPayment(props) {
    return (
        <PageBasicContainer>
            <DynamicHelmet title={'Rokkhi | Super Admin'} description={'Rokkhi super admin'} />
            <FormDirectPayment/>
        </PageBasicContainer>
    )
}
