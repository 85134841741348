import BaseRequest from "../../../special/models/BaseRequest"


export class GetDecideBillRequest extends BaseRequest {
    flatIdInfo = ['']
    buildingIdInfo = ''

    constructor(data) {
        super()
        this.update(data);
    }
}