import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class BuildingEffect {
    static async _requestGetBuildingList(data) {
        const endPoint = ApiEndpoint.building.getBuildings
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestCreateBuilding(data) {
        const endPoint = ApiEndpoint.building.createNew
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestDeleteBuilding(data) {
        const endPoint = ApiEndpoint.building.deleteBuilding
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
