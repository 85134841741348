import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import DataValidationConstants from '../FormDataValidationConstants'
import {
    ColorConstants,
    DropDownConstants,
    RadioConstants,
    UserRoleCodeConstants
} from '../../../assets/constants/GeneralConstants'
import FormInputDropDown from '../../component/form-input/FormInputDropDown'
import UserAction from '../../../stores/user-management/user/UserAction'
import RegisterUserRequest from '../../../stores/user-management/user/requests/RegisterUserRequest'
import FormInputRadio from '../../component/form-input/FormInputRadio'
import FlatAction from '../../../stores/property-management/flat/FlatAction'
import GetFlatsRequest from '../../../stores/property-management/flat/request/GetFlatsRequest'
import { FormIconContainer, FormItemContainer } from '../FormStyles'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { VehicleIcon } from '../../misc/IconsProvider'
import DynamicHelmet from '../../misc/DynamicHelmet'

const INITIAL_STATE = {
    userRoleCode: UserRoleCodeConstants.GENERAL_REGISTERED_USER_NON_APP,
    name: '',
    address: '',
    email: '',
    phone: '',
    contactPersonName: '',
    contactPersonPhone: '',
    gender: '',
    organization: '',
    nid: '',
    password: '',
    age: 0,
    image: '',
    thumbImage: '',
    flatId: '',
    buildingId: ''
}

const VALIDATION_SCHEMA = Yup.object({
    userRoleCode: Yup.string().required('Please Select User Type'),
    name: Yup.string()
        .required('Name is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Max 40 Characters'),
    address: Yup.string()
        .required('Address is Required')
        .min(5, 'Minimum 5 Characters')
        .max(150, 'Maximum 150 Characters'),
    email: DataValidationConstants.VALID_EMAIL,
    contactPersonName: Yup.string().min(3, 'Minimum 3 Characters').max(40, 'Max 40 Characters'),
    contactPersonPhone: DataValidationConstants.VALID_MOBILE_NUMBER,
    gender: Yup.string().when(['userRoleCode'], {
        is: (userRoleCode) => userRoleCode !== UserRoleCodeConstants.GUARD_PHONE,
        then: Yup.string().required('Gender is Required')
    }),
    organization: Yup.string().max(40, 'Maximum 40 characters'),
    nid: DataValidationConstants.VALID_NID,
    phone: DataValidationConstants.REQUIRED_VALID_MOBILE_NUMBER,
    password: Yup.string().when(['userRoleCode'], {
        is: (userRoleCode) => userRoleCode === UserRoleCodeConstants.GUARD || userRoleCode===UserRoleCodeConstants.GENERAL_REGISTERED_USER_NON_APP,
        then: Yup.string().matches(/^-?[0-9]{5}?$/, {
            message: 'Password must be 5 digits',
        })
    }),
    age: Yup.number().when(['userRoleCode'], {
        is: (userRoleCode) => userRoleCode !== UserRoleCodeConstants.GUARD_PHONE,
        then: DataValidationConstants.VALID_AGE
    }),
    flatId: Yup.string().when(['userRoleCode'], {
        is: (userRoleCode) => userRoleCode === UserRoleCodeConstants.GENERAL_REGISTERED_USER_NON_APP || 
        userRoleCode ===UserRoleCodeConstants.SERVICE_WORKER ||
        userRoleCode ===UserRoleCodeConstants.CHILD,
        then: Yup.string().required('Flat is Required')
    }),
    buildingId: Yup.string().required('Building is Required')
})

export default function FormCreateBuilding(props) {
    const dispatch = useDispatch()
    const [resetFlat, setResetFlat] = useState(false)
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [UserAction.REQUEST_REGISTER_USER])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, UserAction.REQUEST_REGISTER_USER)
    )

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        dispatch(UserAction._requestRegisterUser(new RegisterUserRequest(values)))
    }

    // useEffect(() => {
    //     if (values.communityId)
    //         dispatch(BuildingAction._requestGetBuildingList(new GetBuildingsRequest(values.communityId)))
    // }, [values.communityId])
    
    useEffect(() => {
       if(control.values.buildingId !== UserRoleCodeConstants.GUARD_PHONE || UserRoleCodeConstants.GUARD){
            if (control.values.buildingId)
            dispatch(FlatAction._requestGetFlatList(new GetFlatsRequest(control.values)))
            control.setValue('flatId', '')
       }
    }, [control.values.buildingId])
    return (
        <FormIconContainer>
            <DynamicHelmet title={'Rokkhi | Register User'} description={'Register User Page'} />
            <FormTitleWithIcon
                icon={<VehicleIcon />}
                color={ColorConstants.GREEN1}
                formTitle={'Register New User'}
            />

            <FormInputRadio
                label={'Select User Type'}
                control={control}
                name={'userRoleCode'}
                radioFor={RadioConstants.TYPE_USER_ROLE}
            />

            {control.values.userRoleCode === UserRoleCodeConstants.GUARD_PHONE && (
                <FormItemContainer>
                    <FormInputDropDown
                        label={'Select Building'}
                        control={control}
                        name={'buildingId'}
                        dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                    />

                    <FormInputText label={'Name'} control={control} name={'name'} />
                    <FormInputText label={'Address'} control={control} name={'address'} />
                    < FormInputText
                        prefix={'+88'}
                        label={'Phone Number'}
                        control={control}
                        name={'phone'}
                    />
                    <FormInputText
                        label={'Contact Person Name'}
                        control={control}
                        name={'contactPersonName'}
                    />
                    <FormInputText
                        label={'Contact Person Phone'}
                        control={control}
                        name={'contactPersonPhone'}
                    />
                    <FormInputText label={'Organization'} control={control} name={'organization'} />
                </FormItemContainer>
            )}

            {control.values.userRoleCode === UserRoleCodeConstants.SERVICE_WORKER && (
                <FormItemContainer>
                    <FormInputDropDown
                        label={'Select Building'}
                        control={control}
                        name={'buildingId'}
                        dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                    />
                    <FormInputDropDown
                        label={'Select Flat'}
                        control={control}
                        name={'flatId'}
                        dropDownFor={DropDownConstants.TYPE_FLATS_WITHOUT_ALL}
                        resetValue={resetFlat}
                    />

                    <FormInputText label={'Name'} control={control} name={'name'} />
                    <FormInputText label={'Address'} control={control} name={'address'} />
                    < FormInputText
                        prefix={'+88'}
                        label={'Phone Number'}
                        control={control}
                        name={'phone'}
                    />
                    <FormInputText
                        label={'Contact Person Name'}
                        control={control}
                        name={'contactPersonName'}
                    />
                    <FormInputText
                        label={'Contact Person Phone'}
                        control={control}
                        name={'contactPersonPhone'}
                    />
                    <FormInputRadio
                        label={'Select Gender'}
                        control={control}
                        name={'gender'}
                        radioFor={RadioConstants.TYPE_GENDER}
                    />
                    <FormInputText label={'Organization'} control={control} name={'organization'} />
                    <FormInputText label={'National ID'} control={control} name={'nid'} />
                    <FormInputText label={'Age'} control={control} name={'age'} />
                    <FormInputText label={'Image'} control={control} name={'image'} />
                    <FormInputText label={'Thumb Image'} control={control} name={'thumbImage'} />
                </FormItemContainer>
            )}

            {control.values.userRoleCode === UserRoleCodeConstants.CHILD && (
                <FormItemContainer>
                    <FormInputDropDown
                        label={'Select Building'}
                        control={control}
                        name={'buildingId'}
                        dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                    />
                    <FormInputDropDown
                        label={'Select Flat'}
                        control={control}
                        name={'flatId'}
                        dropDownFor={DropDownConstants.TYPE_FLATS_WITHOUT_ALL}
                        resetValue={resetFlat}
                    />

                    <FormInputText label={'Name'} control={control} name={'name'} />
                    <FormInputText label={'Address'} control={control} name={'address'} />
                    < FormInputText
                        prefix={'+88'}
                        label={'Phone Number'}
                        control={control}
                        name={'phone'}
                    />
                    <FormInputText
                        label={'Contact Person Name'}
                        control={control}
                        name={'contactPersonName'}
                    />
                    <FormInputText
                        label={'Contact Person Phone'}
                        control={control}
                        name={'contactPersonPhone'}
                    />
                    <FormInputRadio
                        label={'Select Gender'}
                        control={control}
                        name={'gender'}
                        radioFor={RadioConstants.TYPE_GENDER}
                    />
                    <FormInputText label={'Organization'} control={control} name={'organization'} />
                    <FormInputText label={'Age'} control={control} name={'age'} />
                    <FormInputText label={'Image'} control={control} name={'image'} />
                    <FormInputText label={'Thumb Image'} control={control} name={'thumbImage'} />
                </FormItemContainer>
            )}

            {control.values.userRoleCode === UserRoleCodeConstants.GUARD && (
                <FormItemContainer>
                    <FormInputDropDown
                        label={'Select Building'}
                        control={control}
                        name={'buildingId'}
                        dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                    />

                    <FormInputText label={'Name'} control={control} name={'name'} />
                    <FormInputText label={'Address'} control={control} name={'address'} />
                    <FormInputText label={'Email'} control={control} name={'email'} />
                    < FormInputText
                        prefix={'+88'}
                        label={'Phone Number'}
                        control={control}
                        name={'phone'}
                    />
                    <FormInputText
                        label={'Contact Person Name'}
                        control={control}
                        name={'contactPersonName'}
                    />
                    <FormInputText
                        label={'Contact Person Phone'}
                        control={control}
                        name={'contactPersonPhone'}
                    />
                    <FormInputRadio
                        label={'Select Gender'}
                        control={control}
                        name={'gender'}
                        radioFor={RadioConstants.TYPE_GENDER}
                    />
                    <FormInputText label={'Organization'} control={control} name={'organization'} />
                    <FormInputText label={'National ID'} control={control} name={'nid'} />
                    <FormInputText label={'Password'} control={control} name={'password'} />
                    <FormInputText label={'Age'} control={control} name={'age'} />
                    <FormInputText label={'Image'} control={control} name={'image'} />
                    <FormInputText label={'Thumb Image'} control={control} name={'thumbImage'} />
                </FormItemContainer>
            )}

            {control.values.userRoleCode === UserRoleCodeConstants.GENERAL_REGISTERED_USER_NON_APP && (
                <FormItemContainer>
                    <FormInputDropDown
                        label={'Select Building'}
                        control={control}
                        name={'buildingId'}
                        dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                    />
                    <FormInputDropDown
                        label={'Select Flat'}
                        control={control}
                        name={'flatId'}
                        dropDownFor={DropDownConstants.TYPE_FLATS_WITHOUT_ALL}
                        resetValue={resetFlat}
                    />

                    <FormInputText label={'Name'} control={control} name={'name'} />
                    <FormInputText label={'Address'} control={control} name={'address'} />
                    <FormInputText label={'Email'} control={control} name={'email'} />
                    < FormInputText
                        prefix={'+88'}
                        label={'Phone Number'}
                        control={control}
                        name={'phone'}
                    />
                    <FormInputText
                        label={'Contact Person Name'}
                        control={control}
                        name={'contactPersonName'}
                    />
                    <FormInputText
                        label={'Contact Person Phone'}
                        control={control}
                        name={'contactPersonPhone'}
                    />
                    <FormInputRadio
                        label={'Select Gender'}
                        control={control}
                        name={'gender'}
                        radioFor={RadioConstants.TYPE_GENDER}
                    />
                    <FormInputText label={'Organization'} control={control} name={'organization'} />
                    <FormInputText label={'National ID'} control={control} name={'nid'} />
                    <FormInputText label={'Password'} control={control} name={'password'} />
                    <FormInputText label={'Age'} control={control} name={'age'} />
                    <FormInputText label={'Image'} control={control} name={'image'} />
                    <FormInputText label={'Thumb Image'} control={control} name={'thumbImage'} />
                </FormItemContainer>
            )}

            <ButtonFormSubmit isRequesting={isRequesting} control={control} onSubmit={onSubmit} />
        </FormIconContainer>
    )
}
