export class DashboardDecorationDataModel {
    dataTitle = ''
    dataItem = ''
    dataChart = ''
    icon = ''
    constructor(dataTitle, dataItem, dataChart, icon) {
        this.dataTitle = dataTitle
        this.dataItem = dataItem
        this.dataChart = dataChart
        this.icon = icon
    }
}
