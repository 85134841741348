import ActionUtility from '../../../utils/ActionUtility'
import ComplainEffect from './ComplainEffect'

export default class ComplainAction {
    static REQUEST_GET_COMPLAINS = 'REQUEST_GET_COMPLAINS'
    static REQUEST_GET_COMPLAINS_FINISHED = 'REQUEST_GET_COMPLAINS_FINISHED'

    static REQUEST_SUBMIT_COMPLAIN = 'REQUEST_SUBMIT_COMPLAIN'
    static REQUEST_SUBMIT_COMPLAIN_FINISHED = 'REQUEST_SUBMIT_COMPLAIN_FINISHED'

    static REQUEST_GET_COMPLAIN_DETAILS = 'REQUEST_GET_COMPLAIN_DETAILS'
    static REQUEST_GET_COMPLAIN_DETAILS_FINISHED = 'REQUEST_GET_COMPLAIN_DETAILS_FINISHED'

    static _requestSubmitComplain(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ComplainAction.REQUEST_SUBMIT_COMPLAIN,
                ComplainEffect._requestSubmitComplain,
                data
            )
        }
    }

    static _requestGetComplainDetails(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ComplainAction.REQUEST_GET_COMPLAIN_DETAILS,
                ComplainEffect._requestGetComplainDetails,
                data
            )
        }
    }

    static _requestGetComplainList(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ComplainAction.REQUEST_GET_COMPLAINS,
                ComplainEffect._requestGetComplainList,
                data
            )
        }
    }
}
