import React, { Component } from 'react'
import { Modal } from 'antd'
import { ModalTypeConstants } from '../../assets/constants/GeneralConstants'
import { selectRequesting } from '../../stores/special/requesting/RequestingSelector'
import { connect } from 'react-redux'

const makeMapStateToProps = () => {
    return (state, ownProps) => ({
        modalStatus: state.misc.modalStatus,
        isRequesting: selectRequesting(state, [])
    })
}

class ModalMessage extends Component {
    componentWillReceiveProps(nextProps) {
        if (nextProps.modalStatus) {
            let message = ''
            if (nextProps.modalStatus.modalData) message = nextProps.modalStatus.modalData.message
            switch (nextProps.modalStatus.modalFor) {
                case ModalTypeConstants.TYPE_SUCCESS:
                    this.success(message)
                    break
                case ModalTypeConstants.TYPE_INFO:
                    this.warning(message)
                    break
                case ModalTypeConstants.TYPE_ERROR:
                    this.info(message)
                    break
                case ModalTypeConstants.TYPE_WARNING:
                    this.error(message)
                    break
                default:
                    break
            }
        }
    }

    info = (message) => {
        Modal.info({
            title: message
        })
    }

    success = (message) => {
        Modal.success({
            title: message
        })
    }

    error = (message) => {
        Modal.error({
            title: message
        })
    }

    warning = (message) => {
        Modal.warning({
            title: message
        })
    }

    render() {
        return <div>{/* {this.show()} */}</div>
    }
}

export { ModalMessage as Unconnected }
export default connect(makeMapStateToProps)(ModalMessage)
