import ReportAction from './ReportAction'
import HttpErrorResponseModel from '../special/models/HttpErrorResponseModel'

const ReportPostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case ReportAction.REQUEST_GET_CASH_FLOW_REPORT_FINISHED:
                //next(MiscAction._showModalSuccess('Report Submitted Successfully'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default ReportPostEffect
