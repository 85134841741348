import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import DataValidationConstants from '../FormDataValidationConstants'
import {
    AuthorizationStatusConstants,
    ColorConstants,
    DropDownConstants,
    ImageConstants
} from '../../../assets/constants/GeneralConstants'
import FormInputDropDown from '../../component/form-input/FormInputDropDown'
import FlatAction from '../../../stores/property-management/flat/FlatAction'
import GetFlatsRequest from '../../../stores/property-management/flat/request/GetFlatsRequest'
import PreAuthorizationAction from '../../../stores/security-management/pre-authorization/PreAuthorizationAction'
import CreatePreAuthorizationEntryRequest from '../../../stores/security-management/pre-authorization/requests/CreatePreAuthorizationEntryRequest'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { ExpenseIcon } from '../../misc/IconsProvider'
import UploadImageButton from '../../component/upload/UploadImageButton'
import { FormIconContainer, FormImageWithInfoContainer, FormItemContainer } from '../FormStyles'
import { isMobile } from 'react-device-detect'
import DynamicHelmet from '../../misc/DynamicHelmet'

const INITIAL_STATE = {
    name: '',
    phone: '',
    email: '',
    relation: '',
    authorizationStatus: '',
    image: '',
    thumbImage: '',
    flatId: '',
    buildingId: ''
}

const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string().required('Name is Required').min(3, 'Minimum 3 Characters')
    .max(40, 'Maximum 40 Characters'),
    image: Yup.string().required('Image is Required'),
    phone: DataValidationConstants.REQUIRED_VALID_MOBILE_NUMBER,
    email: DataValidationConstants.VALID_EMAIL,
    relation: Yup.string().max(40, 'Maximum 40 Characters'),
    thumbImage: Yup.string().max(150, 'Link is too big'),
    flatId: Yup.string().required('Flat is Required'),
    buildingId: Yup.string().required('Building is Required'),
})


export default function FormCreatePreAuthorizeEntry({ authorizationStatus }) {
    const dispatch = useDispatch()
    const [imageUrl, setImageUrl] = useState('')
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [PreAuthorizationAction.REQUEST_ADD_TO_PRE_AUTHORIZED_LIST])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, PreAuthorizationAction.REQUEST_ADD_TO_PRE_AUTHORIZED_LIST)
    )

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        values['authorizationStatus'] = authorizationStatus
        dispatch(PreAuthorizationAction._requestAddToPreAuthorizedList(new CreatePreAuthorizationEntryRequest(values)))
    }

    useEffect(() => {
        if (control.values.buildingId) dispatch(FlatAction._requestGetFlatList(new GetFlatsRequest(control.values)))
        control.setValue('flatId','')
    }, [control.values.buildingId])

    return (
        <FormIconContainer>
            <DynamicHelmet title={'Rokkhi | Register Visitors'} description={'Rokkhi Register Visitors'} />
            <FormTitleWithIcon
                icon={<ExpenseIcon />}
                color={ColorConstants.GREEN1}
                formTitle={
                    authorizationStatus === AuthorizationStatusConstants.WHITE_LISTED
                        ? 'Add to White list'
                        : 'Add to Black List'
                }
            />

            <FormImageWithInfoContainer>
                <div className={'form-image'} style={{  justifySelf:isMobile?"center":""}}>
                        <UploadImageButton
                            label={'User Image'}
                            control={control}
                            name={'image'}
                            imageFor={ImageConstants.FOR_ADD_CALCULATION_MEMO}
                            imageUrl={imageUrl}
                            setImageUrl={setImageUrl}
                            imageTitle={'User Image'}
                        />
                </div>

                <FormItemContainer>
                    <FormInputDropDown
                        label={'Select Building'}
                        control={control}
                        name={'buildingId'}
                        dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                    />
                    <FormInputDropDown
                        label={'Select Flat'}
                        control={control}
                        name={'flatId'}
                        dropDownFor={DropDownConstants.TYPE_FLATS_WITHOUT_ALL}
                    />
                    <FormInputText label={'Name'} control={control} name={'name'} />
                    <FormInputText label={'Email'} control={control} name={'email'} />
                    <FormInputText label={'Phone Number'} control={control} name={'phone'} />
                    <FormInputText label={'Relation'} control={control} name={'relation'} />
                    {/* <FormInputText label={'Image'} control={control} name={'image'} /> */}
                    <FormInputText label={'Thumb Image'} control={control} name={'thumbImage'} />
                </FormItemContainer>
            </FormImageWithInfoContainer>

            <ButtonFormSubmit isRequesting={isRequesting} control={control} onSubmit={onSubmit} />
        </FormIconContainer>
    )
}
