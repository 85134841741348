import { createSelector } from 'reselect'

export class BillSelector {
    static getBillItems = (state) => state.bill.billItems
    static getBillTypes = (state) => state.bill.billTypes
    static getBillPackages = (state) => state.bill.billPackages
    static getDecideBill = (state) => state.bill.decideBill
    static getDueBillItems = (state) => state.bill.dueBillItems
    static getRequestBillGenerate = (state) => state.bill.requestGenerateBillReport
    static getMultipleBillInvoiceReport = (state) => state.bill.getMultipleBillInvoiceReport
}

export const makeSelectBillVouchersList = createSelector(
    BillSelector.getBillItems,
    (billItemList) => {
        if (billItemList) {
            return billItemList.data.map((billItem) => {
                return {
                    ...billItem,
                    flatName: billItem.flat ? billItem.flat.number : 'Not Found'
                }
            })
        }
        return []
    }
)

export const makeSelectBillOverViewList = createSelector(
    BillSelector.getBillItems,
    (billItemList) => {
        if (billItemList) {
            return billItemList.data.map((billItem) => {
                return {
                    ...billItem,
                    flatName: billItem.flat ? billItem.flat.name : 'Not Found'
                }
            })
        }
        return []
    }
)

export const makeSelectBillTypesList = createSelector(BillSelector.getBillTypes, (billTypeList) =>
    billTypeList ? billTypeList.data : []
)

export const makeSelectBillPackagesList = createSelector(
    BillSelector.getBillPackages,
    (billTypeList) => (billTypeList ? billTypeList.data : [])
)

export const makeSelectBillDecideList = createSelector(
    BillSelector.getDecideBill,
    (decideBillList) => {
        if (decideBillList) {
            return decideBillList.data.map((billItem) => {
                return {
                    id: billItem.id,
                    name: billItem.name,
                    description: billItem.description,
                    amount: billItem.amount ? billItem.amount : billItem.defaultAmount
                }
            })
        }
    }
)
export const makeSelectDueBillVouchersList = createSelector(
    BillSelector.getDueBillItems,
    (billItemList) => {
        if (billItemList) {
            return billItemList.data.map((billItem) => {
                return {
                    ...billItem,
                    flatName: billItem.flat ? billItem.flat.number : 'Not Found'
                }
            })
        }
        return []
    }
)

export const makeSelectRequestedGenerateBill = createSelector(
    BillSelector.getRequestBillGenerate,
    (billItems) => (billItems ? billItems.data : [])
)

export const makeSelectRequestMultipleBillInvoiceReport = createSelector(
    BillSelector.getMultipleBillInvoiceReport,
    (billReport) => (billReport ? billReport.data : [])
)
