import ActionUtility from '../../../utils/ActionUtility'
import FundEffect from './FundEffect'

export default class FundAction {
    static REQUEST_CREATE_FUND = 'REQUEST_CREATE_FUND'
    static REQUEST_CREATE_FUND_FINISHED = 'REQUEST_CREATE_FUND_FINISHED'

    static REQUEST_DELETE_FUND_TYPE = 'REQUEST_DELETE_FUND_TYPE'
    static REQUEST_DELETE_FUND_TYPE_FINISHED = 'REQUEST_DELETE_FUND_TYPE_FINISHED'

    static REQUEST_GET_FUNDS = 'REQUEST_GET_FUNDS'
    static REQUEST_GET_FUNDS_FINISHED = 'REQUEST_GET_FUNDS_FINISHED'

    static REQUEST_GET_BALANCE_SHEET_DATA = 'REQUEST_GET_BALANCE_SHEET_DATA'
    static REQUEST_GET_BALANCE_SHEET_DATA_FINISHED = 'REQUEST_GET_BALANCE_SHEET_DATA_FINISHED'

    static REQUEST_ADD_TO_FUND = 'REQUEST_ADD_TO_FUND'
    static REQUEST_ADD_TO_FUND_FINISHED = 'REQUEST_ADD_TO_FUND_FINISHED'

    static REQUEST_WITHDRAW_FROM_FUND = 'REQUEST_WITHDRAW_FROM_FUND'
    static REQUEST_WITHDRAW_FROM_FUND_FINISHED = 'REQUEST_WITHDRAW_FROM_FUND_FINISHED'

    static REQUEST_TRANSFER_FUND = 'REQUEST_TRANSFER_FUND'
    static REQUEST_TRANSFER_FUND_FINISHED = 'REQUEST_TRANSFER_FUND_FINISHED'

    static REQUEST_GET_FUND_MOVEMENT_VOUCHERS = 'REQUEST_GET_FUND_MOVEMENT_VOUCHERS'
    static REQUEST_GET_FUND_MOVEMENT_VOUCHERS_FINISHED =
        'REQUEST_GET_FUND_MOVEMENT_VOUCHERS_FINISHED'

    static REQUEST_GET_TRANSACTIONS = 'REQUEST_GET_TRANSACTIONS'
    static REQUEST_GET_TRANSACTIONS_FINISHED = 'REQUEST_GET_TRANSACTIONS_FINISHED'

    static _requestCreateFund(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FundAction.REQUEST_CREATE_FUND,
                FundEffect._requestCreateFund,
                data
            )
        }
    }

    static _requestGetFunds(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FundAction.REQUEST_GET_FUNDS,
                FundEffect._requestGetFunds,
                data
            )
        }
    }

    static _requestGetBalanceSheetData(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FundAction.REQUEST_GET_BALANCE_SHEET_DATA,
                FundEffect._requestGetBalanceSheetData,
                data
            )
        }
    }

    static _requestAddToFund(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FundAction.REQUEST_ADD_TO_FUND,
                FundEffect._requestAddToFund,
                data
            )
        }
    }

    static _requestWithdrawFromFund(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FundAction.REQUEST_WITHDRAW_FROM_FUND,
                FundEffect._requestWithdrawFromFund,
                data
            )
        }
    }

    static _requestTransferFund(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FundAction.REQUEST_TRANSFER_FUND,
                FundEffect._requestTransferFund,
                data
            )
        }
    }

    static _requestGetFundMovementVouchers(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FundAction.REQUEST_GET_FUND_MOVEMENT_VOUCHERS,
                FundEffect._requestGetFundMovementVouchers,
                data
            )
        }
    }

    static _requestGetTransactions(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FundAction.REQUEST_GET_TRANSACTIONS,
                FundEffect._requestGetTransactions,
                data
            )
        }
    }
}
