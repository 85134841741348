import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import FundAction from '../../../stores/money-management/fund/FundAction'
import { makeSelectTransactionItemsList } from '../../../stores/money-management/fund/FundSelector'
import { TransactionTypeConstants } from '../../../assets/constants/GeneralConstants'
import { BlueTag, GreenTag, RedTag } from '../../component/misc/Tags'
import { useTranslation } from 'react-i18next'


export default function TableTransaction(props) {
    const { t } = useTranslation();
    const dataList = useSelector(makeSelectTransactionItemsList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [FundAction.REQUEST_GET_TRANSACTIONS])
    )

    const columns = [
        {
            title: t('fund_dashboard.tableHeader.amount'),
            dataIndex: 'amount'
        },
        {
            title: t('fund_dashboard.tableHeader.description'),
            dataIndex: 'description'
        },
        {
            title: t('fund_dashboard.tableHeader.transactionType'),
            dataIndex: 'type',
            render: (text, record) => {
                if (record.type === TransactionTypeConstants.DEBIT) return <RedTag text={'Debit'} />
                else return <GreenTag text={'Credit'} />
            }
        },
        {
            title: t('fund_dashboard.tableHeader.transactionCategory'),
            dataIndex: 'category',
            render: (text, record) => <BlueTag text={record.category} />
        },
        {
            title: t('fund_dashboard.tableHeader.payer'),
            dataIndex: 'payerName'
        },
        {
            title: t('fund_dashboard.tableHeader.receiver'),
            dataIndex: 'receiverName'
        },
        {
            title: t('fund_dashboard.tableHeader.date'),
            render: (text, record) => (
                <div> {record.date && new Date(record.date).toLocaleDateString()} </div>
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
