import BaseRequest from '../../../special/models/BaseRequest'

export default class NoticeDetailsRequest extends BaseRequest {
    noticeId = ''

    constructor(data) {
        super()
        this.update(data)
        this.noticeId = data.id
    }
}
