import { createSelector } from 'reselect'

export class CartSelector {
    static getCartInfo = (state) => state.cartInfo.cart
    static getCartItems = (state) => state.cartInfo.cartItems
}

export const makeSelectCartInfo = createSelector(CartSelector.getCartInfo, (cart) => {
    return cart ? cart.data : []
})

export const makeSelectCartItems = createSelector(CartSelector.getCartItems, (cart) => {
    return cart ? cart.data : []
})
