import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import BillAction from '../../../stores/money-management/bill/BillAction'
import { GetBillTypeRequest } from '../../../stores/money-management/bill/requests/GetBillTypeRequest'
import FormCreateBillType from '../../forms/bill/FormCreateBillType'
import TableBillType from '../../tables/bill/TableBillType'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'
import { ColorConstants } from '../../../assets/constants/GeneralConstants'
import BillDetails from '../../../assets/images/bill-details-small.png'
import EditIcon from '../../../assets/images/edit-small.png'
import ModalCreateBillTypes from '../../modals/ModalCreateBillTypes'
import { FormTitleWithSvgIcon } from '../../component/decoration/CardSingleInfo'

export default function PageBillTypes(props) {
    const dispatch = useDispatch()
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_CREATE_BILL_TYPE)
    )
    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_DELETE_BILL_TYPE)
    )

    useEffect(() => {
        dispatch(BillAction._requestGetBillTypes(new GetBillTypeRequest()))
    }, [isFinishedDeletion, isFinishedCreation])

    return (
        <PageBasicContainer>
            {/* <FormCreateBillType /> */}
            <PageMainBarContainer>
                <FormTitleWithSvgIcon
                    icon={BillDetails}
                    backgroundColor={ColorConstants.GREEN6}
                    title={'Bill Types'}
                    child={<ModalCreateBillTypes />}
                />

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                    <img
                        style={{
                            backgroundColor: 'black',
                            width: '15px',
                            height: '15px',
                            margin: '0 10px 0 0'
                        }}
                        src={EditIcon}
                        alt='alt'
                    />
                    <h2 style={{ margin: '0 10px 0 0' }}>List of All Bill Types</h2>
                    <span style={{ marginBottom: '-5px' }}>
                        You can create, delete and edit bill types
                    </span>
                </div>
                <TableBillType />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
