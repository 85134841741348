import BaseRequest from '../../../special/models/BaseRequest'

export default class GetFunctionsOfRoleRequest extends BaseRequest {
    roleCode = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
