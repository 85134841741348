import ActionUtility from '../../../utils/ActionUtility'
import MeetingEffect from './MeetingEffect'

export default class MeetingAction {
    static REQUEST_GET_MEETINGS = 'REQUEST_GET_MEETINGS'
    static REQUEST_GET_MEETINGS_FINISHED = 'REQUEST_GET_MEETINGS_FINISHED'

    static REQUEST_CREATE_MEETING = 'REQUEST_CREATE_MEETING'
    static REQUEST_CREATE_MEETING_FINISHED = 'REQUEST_CREATE_MEETING_FINISHED'

    static REQUEST_CANCEL_MEETING = 'REQUEST_CANCEL_MEETING'
    static REQUEST_CANCEL_MEETING_FINISHED = 'REQUEST_CANCEL_MEETING_FINISHED'

    static _requestCreateMeeting(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                MeetingAction.REQUEST_CREATE_MEETING,
                MeetingEffect._requestCreateMeeting,
                data
            )
        }
    }
    static _requestCancelMeeting(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                MeetingAction.REQUEST_CANCEL_MEETING,
                MeetingEffect._requestCancelMeeting,
                data
            )
        }
    }
    static _requestGetMeetingList(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                MeetingAction.REQUEST_GET_MEETINGS,
                MeetingEffect._requestGetMeetingList,
                data
            )
        }
    }
}
