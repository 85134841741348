import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import useFormInputValidation from '../component/form-input/useFormInputValidation'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../stores/special/finished/FinishedSelector'
import * as Yup from 'yup'
import { DropDownConstants } from '../../assets/constants/GeneralConstants'
import { ButtonMildBlue } from '../component/buttons/CustomButtons'
import FormInputDropDown from '../component/form-input/FormInputDropDown'
import FingerPrintAction from '../../stores/device-management/fingerprint-device/FingerPrintAction'
import AssignFingerPrintToUserRequest from '../../stores/device-management/fingerprint-device/request/AssignFingerPrintToUserRequest'

const INITIAL_STATE = {
    userId: ''
}

const VALIDATION_SCHEMA = Yup.object({
    userId: Yup.string().required('Please select user')
})

export default function ModalAssignDeviceToUser({ fingerPrintRecordId, isDisabled }) {
    const dispatch = useDispatch()
    const [visibility, setVisibility] = useState(false)
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)

    const isRequesting = useSelector((state) =>
        selectRequesting(state, [FingerPrintAction.REQUEST_ASSIGN_FINGERPRINT_TO_USER])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, FingerPrintAction.REQUEST_ASSIGN_FINGERPRINT_TO_USER)
    )

    const onSubmit = () => {
        values['fingerPrintRecordId'] = fingerPrintRecordId
        dispatch(FingerPrintAction._requestAssignFingerprintToUser(new AssignFingerPrintToUserRequest(values)))
    }

    useEffect(() => {
        if (isFinished) {
            control.resetData()
            setVisibility(false)
        }
    }, [isFinished])

    return (
        <div>
            <ButtonMildBlue
                disabled={isDisabled}
                icon={'expand'}
                text={'Assign to User'}
                onClick={() => setVisibility(true)}
            />
            <Modal
                title={`Assign Device to Service Worker`}
                centered
                okText={'Assign'}
                visible={visibility}
                confirmLoading={isRequesting}
                onCancel={() => setVisibility(false)}
                cancelText={'Cancel'}
                onOk={onSubmit}
            >
                <FormInputDropDown
                    label={'Select Service Worker'}
                    control={control}
                    name={'userId'}
                    dropDownFor={DropDownConstants.TYPE_SERVICE_WORKER}
                />
            </Modal>
        </div>
    )
}
