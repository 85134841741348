import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import ExpenseAction from '../../../stores/money-management/expense/ExpenseAction'
import { makeSelectExpenseVouchersList } from '../../../stores/money-management/expense/ExpenseSelector'
import {
    ExpenseAuthorizationStatusConstants,
    PaymentStatusConstants
} from '../../../assets/constants/GeneralConstants'
import { BlueTag, GreenTag, RedTag } from '../../component/misc/Tags'
import ExpenseInvoice from '../../component/pdf/ExpenseInvoice'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import { AuthorizeExpenseVoucherRequest } from '../../../stores/money-management/expense/requests/voucher/AuthorizeExpenseVoucherRequest'
import ModalApproveConfirmation from '../../modals/ModalApproveConfirmation'
import { DeleteExpenseVoucherRequest } from '../../../stores/money-management/expense/requests/voucher/DeleteExpenseVoucherRequest'

export default function TableExpenseVouchers({ authorizationStatus }) {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectExpenseVouchersList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [ExpenseAction.REQUEST_GET_EXPENSE_VOUCHERS])
    )
    // const isFinishedCreation = useSelector((state) =>
    //     selectFinished(state, ExpenseAction.REQUEST_GENERATE_EXPENSE_VOUCHER)
    // )
    // const isFinishedDeletion = useSelector((state) =>
    //     selectFinished(state, ExpenseAction.REQUEST_AUTHORIZE_VOUCHER)
    // )

    // useEffect(() => {
    //     const filterValues = {
    //         authorizationStatus: ExpenseAuthorizationStatusConstants.PENDING
    //     }
    //     dispatch(
    //         ExpenseAction._requestGetExpenseVouchers(new GetExpenseVouchersRequest(filterValues))
    //     )
    // }, [isFinishedDeletion, isFinishedCreation])

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            width: '30%'
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount'
        },
        {
            title: 'Month',
            dataIndex: 'month'
        },
        {
            align: 'center',
            title: 'Payment Status',
            render: (text, record) => {
                switch (record.paymentStatus) {
                    case PaymentStatusConstants.PAID:
                        return <GreenTag text={'PAID'} />
                    case PaymentStatusConstants.PARTIAL_PAID:
                        return <BlueTag text={'PARTIAL'} />
                    case PaymentStatusConstants.UNPAID:
                        return <RedTag text={'UNPAID'} />
                    default:
                        return <div></div>
                }
            }
        },
        {
            align: 'center',
            title: 'Authorization Status',
            render: (text, record) => {
                switch (record.authorizationStatus) {
                    case ExpenseAuthorizationStatusConstants.AUTHORIZED:
                        return <GreenTag text={'Authorized'} />
                    case ExpenseAuthorizationStatusConstants.PENDING:
                        return <BlueTag text={'Pending'} />
                    case ExpenseAuthorizationStatusConstants.DECLINED:
                        return <RedTag text={'Declined'} />
                    default:
                        return <div></div>
                }
            }
        },
        {
            align: 'center',
            title: '',
            render: (text, record) => <ExpenseInvoice data={record} key={record.id} />
        },
        {
            title: 'Delete',
            align: 'center',
            render: (text, record) =>
                record.authorizationStatus !== 'PENDING' && (
                    <ModalDeleteConfirmation
                        confirmationText={'Are you sure you want to delete this item?'}
                        onConfirm={() =>
                            dispatch(
                                ExpenseAction._requestDeleteExpenseVoucher(
                                    new DeleteExpenseVoucherRequest(record.id, true)
                                )
                            )
                        }
                    />
                )
        },
        {
            title: 'Approve',
            align: 'center',
            render: (text, record) =>
                record.authorizationStatus === 'PENDING' && (
                    <ModalApproveConfirmation
                        confirmationText={'Are you sure you want to approve this voucher?'}
                        onConfirm={() =>
                            dispatch(
                                ExpenseAction._requestAuthorizeVoucher(
                                    new AuthorizeExpenseVoucherRequest(record.id, true)
                                )
                            )
                        }
                    />
                )
        },
        {
            title: 'Decline',
            align: 'center',
            render: (text, record) =>
                record.authorizationStatus === 'PENDING' && (
                    <ModalDeleteConfirmation
                        confirmationText={'Are you sure you want to delete this voucher?'}
                        onConfirm={() =>
                            dispatch(
                                ExpenseAction._requestAuthorizeVoucher(
                                    new AuthorizeExpenseVoucherRequest(record.id, false)
                                )
                            )
                        }
                    />
                )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
