import React from 'react'
import { ResponsiveContainer, PieChart, Pie, Sector, Cell, Legend } from 'recharts'

const COLORS = ['#14284d','#ff3300']

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text x={x} y={y} fontSize='12' fill='white' textAnchor='middle' dominantBaseline='central'>
            {`${(percent * 100).toFixed(1)}%`}
        </text>
    )
}

export default function BillCardPieChart({ billItems }) {
    return (
        <ResponsiveContainer height={100} width='40%'>
            <PieChart>
                <Pie
                    data={billItems}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    innerRadius='10%'
                    outerRadius={40}
                    paddingAngle={5}
                    fill='#8884d8'
                    dataKey='amount'
                >
                    {billItems.map((entry, index) => (
                        <Cell
                            key={index}
                            textAnchor='middle'
                            fill={COLORS[index % COLORS.length]}
                        />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )
}
