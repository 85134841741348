import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableDataFilter from '../../tables/TableDataFilter'
import { PageBasicContainer, PageDecorationContainer, PageMainBarContainer } from '../PageStyles'
import ReportAction from '../../../stores/reporting/ReportAction'
import LedgerReportRequest from '../../../stores/reporting/requests/LedgerReportRequest'
import TableLedgerReport from '../../tables/report/TableLedgerReport'
import { CardInfoLeftColoredIcon } from '../../component/decoration/CardSingleInfo'
import { DataBuilderUtility } from '../../../utils/DataBuilderUtility'
import { makeSelectLedgerReportsList } from '../../../stores/reporting/ReportSelector'

export default function PageLedgerReport(props) {
    const dispatch = useDispatch()
    const [decorationArrValues, setDecorationArrValues] = useState([])
    const [filterValues, setFilterValues] = useState({})

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        if (filterValues.buildingId) {
            dispatch(ReportAction._requestGetLedgerReport(new LedgerReportRequest(filterValues)))
        }
    }, [filterValues])

    const dataList = useSelector(makeSelectLedgerReportsList)
    useEffect(() => {
        setDecorationArrValues(DataBuilderUtility._getLedgerDataOverview(dataList))
    }, [dataList])

    const decorItems = decorationArrValues.map((item) => (
        <CardInfoLeftColoredIcon key={Math.random()} data={item} />
    ))

    return (
        <PageBasicContainer>
            <PageDecorationContainer paddingTop={'0px'} item={3}>
                {decorItems}
            </PageDecorationContainer>

            <PageMainBarContainer>
                <TableDataFilter
                    showBuildingFilter={true}
                    showDateRangeFilter={true}
                    sendDataToParent={receiveFilterData}
                />
                <TableLedgerReport />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
