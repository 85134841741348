import React, { useEffect, useState } from 'react'
import { EntranceStatusConstants } from '../../../assets/constants/GeneralConstants'
import { Tabs } from 'antd'
import { CardInfoLeftRoundIcon } from '../../component/decoration/CardSingleInfo'
import { DataBuilderUtility } from '../../../utils/DataBuilderUtility'
import TableVisitor from '../../tables/security/TableVisitor'
import { useDispatch, useSelector } from 'react-redux'
import { makeSelectVisitorsList } from '../../../stores/security-management/entrance/EntranceSelector'
import TableDataFilter from '../../tables/TableDataFilter'
import EntranceAction from '../../../stores/security-management/entrance/EntranceAction'
import GetVisitorsRequest from '../../../stores/security-management/entrance/requests/GetVisitorsRequest'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { PageBasicContainer, PageDecorationContainer, PageMainBarContainer } from '../PageStyles'

const { TabPane } = Tabs
export default function PageVisitors(props) {
    const dispatch = useDispatch()
    const [status, setStatus] = useState(EntranceStatusConstants.ALL)
    const [filterValues, setFilterValues] = useState({})
    const isFinished = useSelector((state) =>
        selectFinished(state, EntranceAction.REQUEST_MARK_VISITOR_EXIT)
    )

    const dataList = useSelector(makeSelectVisitorsList)
    const decorationArr = DataBuilderUtility._getEntranceDataOverview(dataList)

    const statusChanged = (status) => setStatus(status)

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        dispatch(EntranceAction._requestGetVisitors(new GetVisitorsRequest(filterValues, status)))
    }, [filterValues, status, isFinished])

    const decorationItems = decorationArr.map((item) => (
        <CardInfoLeftRoundIcon key={item.dataTitle} data={item} />
    ))

    return (
        <PageBasicContainer>
            <PageDecorationContainer paddingTop={'0px'} item={3}>
                {decorationItems}
            </PageDecorationContainer>

            <PageMainBarContainer>
                <TableDataFilter
                    showBuildingFilter={true}
                    showDateRangeFilter={true}
                    showFlatFilter={true}
                    sendDataToParent={receiveFilterData}
                />

                <div>
                    <Tabs onChange={statusChanged} type='card'>
                        <TabPane tab='All' key={EntranceStatusConstants.ALL} />
                        <TabPane tab='Inside' key={EntranceStatusConstants.INSIDE_COMPOUND} />
                        <TabPane tab='Outside' key={EntranceStatusConstants.OUTSIDE_COMPOUND} />
                    </Tabs>
                    <TableVisitor />
                </div>
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
