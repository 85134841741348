import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import CommunityAction from '../../../stores/property-management/community/CommunityAction'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import CreateCommunityRequest from '../../../stores/property-management/community/requests/CreateCommunityRequest'
import ButtonFormSubmit from '../ButtonFormSubmit'
import DataValidationConstants from '../FormDataValidationConstants'
import { BuildingIconWhite } from '../../misc/IconsProvider'
import { ColorConstants } from '../../../assets/constants/GeneralConstants'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { FormButtonContainer, FormIconContainer, FormItemContainer } from '../FormStyles'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../../../assets/constants/routes'
import { ButtonFillBlack } from '../../component/buttons/CustomButtons'

const INITIAL_STATE = {
    name: '',
    address: '',
    contactInfo: '',
    contactPerson: '',
    email: '',
    password: ''
}

const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string()
        .required('Community Name is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Maximum 40 Characters'),
    email: DataValidationConstants.REQUIRED_VALID_EMAIL,
    contactInfo: DataValidationConstants.REQUIRED_VALID_MOBILE_NUMBER,
    contactPerson: Yup.string()
        .required('Contact Person Name is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Maximum 40 Characters'),
    address: Yup.string()
        .required('Address is Required')
        .min(5, 'Minimum 3 Characters')
        .max(40, 'Maximum 150 Characters'),
    password: DataValidationConstants.REQUIRED_VALID_PASSWORD
})

export default function FormCreateCommunity(props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [CommunityAction.REQUEST_CREATE_COMMUNITY])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, CommunityAction.REQUEST_CREATE_COMMUNITY)
    )

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        dispatch(CommunityAction._requestCreateCommunity(new CreateCommunityRequest(values)))
    }

    return (
        <>
            <FormButtonContainer>
                <Link to={AppRoutes.COMMUNITIES}>
                    <ButtonFillBlack icon={'backward'} text={'Communities'} />
                </Link>
            </FormButtonContainer>
            <FormIconContainer>
                <FormTitleWithIcon
                    icon={<BuildingIconWhite />}
                    color={ColorConstants.GREEN1}
                    formTitle={t('community.createCommunity.title')}
                />
                <FormItemContainer>
                    <FormInputText
                        label={t('community.createCommunity.name')}
                        control={control}
                        name={'name'}
                    />
                    <FormInputText
                        label={t('community.createCommunity.email')}
                        control={control}
                        name={'email'}
                        uniqueAlert={true}
                    />
                    <FormInputText
                        label={t('community.createCommunity.password')}
                        control={control}
                        name={'password'}
                    />
                    <FormInputText
                        label={t('community.createCommunity.contactPerson')}
                        control={control}
                        name={'contactPerson'}
                    />
                    <FormInputText
                        label={t('community.createCommunity.contactInfo')}
                        control={control}
                        name={'contactInfo'}
                        uniqueAlert={true}
                    />
                    <FormInputText
                        label={t('community.createCommunity.address')}
                        control={control}
                        name={'address'}
                    />
                </FormItemContainer>
                <ButtonFormSubmit
                    title={t('community.createCommunity.submitButton')}
                    isRequesting={isRequesting}
                    control={control}
                    onSubmit={onSubmit}
                />
            </FormIconContainer>
        </>
    )
}
