import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import PrivilegeAction from '../../../stores/user-management/privilege/PrivilegeAction'
import CreateUserFunctionRequest from '../../../stores/user-management/user/requests/CreateUserRoleRequest'
import DataValidationConstants from '../FormDataValidationConstants'
import { FormBasicContainer, FormItemContainer } from '../FormStyles'

const INITIAL_STATE = {
    name: '',
    code: '',
    description: ''
}

const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string().required('Function Name is Required'),
    code: DataValidationConstants.REQUIRED_AND_DIGIT_ONLY
})

export default function FormCreateUserFunction(props) {
    const dispatch = useDispatch()

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) => selectRequesting(state, [PrivilegeAction.REQUEST_CREATE_USER_FUNCTION]))
    const isFinished = useSelector((state) => selectFinished(state, PrivilegeAction.REQUEST_CREATE_USER_FUNCTION))

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        dispatch(PrivilegeAction._requestCreateUserFunction(new CreateUserFunctionRequest(values)))
    }

    return (
        <FormBasicContainer>
            <h2>Create New User Function </h2>
            <FormItemContainer>
                <FormInputText label={'Function Name'} control={control} name={'name'} />
                <FormInputText uniqueAlert={true} label={'Function Code'} control={control} name={'code'} />
            </FormItemContainer>
            <FormInputText label={'Description'} control={control} name={'description'} />
            <ButtonFormSubmit isRequesting={isRequesting} control={control} onSubmit={onSubmit} />
        </FormBasicContainer>
    )
}
