import React from 'react'
import { Modal } from 'antd'
import { ButtonMildGreen } from '../component/buttons/CustomButtons'
import { useSelector } from 'react-redux'
import { selectRequesting } from '../../stores/special/requesting/RequestingSelector'
import ExpenseAction from '../../stores/money-management/expense/ExpenseAction'

export default function ModalApproveConfirmation(props) {
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [ExpenseAction.REQUEST_AUTHORIZE_VOUCHER])
    )
    const showConfirmModal = () => {
        Modal.confirm({
            title: `${props.confirmationText}`,
            okText: props.okText ? props.okText : 'Yes I am Sure',
            okType: 'success',
            cancelText: props.cancelText ? props.cancelText : 'No Cancel this',
            onOk() {
                props.onConfirm()
            },
            onCancel() {}
        })
    }

    return (
        <ButtonMildGreen
            icon={props.icon ? props.icon : 'check'}
            text={''}
            isLoading={isRequesting}
            onClick={() => showConfirmModal()}
        />
    )
}
