import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { LanguageConstants } from './assets/constants/GeneralConstants'

const fallbackLng = [LanguageConstants.BN]
const availableLanguages = [LanguageConstants.BN, LanguageConstants.EN]

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng,
        detection: {
            checkWhitelist: true
        },
        debug: false,
        keySeparator: '.',
        whitelist: availableLanguages,
        interpolation: {
            escapeValue: false // no need for react. it escapes by default
        }
    })

export default i18n
