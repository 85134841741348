import BaseRequest from '../../../special/models/BaseRequest'

export default class SendQrCodeRequest extends BaseRequest {
    to = ''
    subject = ''
    image = ''
    flatNumber = ''
    iosQrCode = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
