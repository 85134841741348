import BaseReducer from '../../../utils/BaseReducer'
import NewPackageAction from './NewPackageAction'

export default class NewPackageReducer extends BaseReducer {
    initialState = {
        packageList: null
    };

    [NewPackageAction.REQUEST_GET_ALL_PACKAGE_FINISHED](state, action) {
        return {
            ...state,
            packageList: action.payload
        }
    }
}
