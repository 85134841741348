import React, { useEffect } from 'react'
import { DropDownConstants } from '../../assets/constants/GeneralConstants'
import FormInputDropdown from '../component/form-input/FormInputDropDown'
import FormInputMonthPicker from '../component/form-input/FormInputMonthPicker'
import FormMultipleInputDropdown from '../component/form-input/FormMultipleInputDropdown'
import FormMultipleInputDropdownMultipleBill from '../component/form-input/FormMultipleInputDropDownMultipleBill'
import { BuildingIconBlack, CalenderIcon, FlatIcon } from '../misc/IconsProvider'

const FormDataFilter = (props) => {
    useEffect(() => {
        props.control.setValues({
            ...props.control.values,
            flatId: undefined
        })
    }, [props.control.values.buildingId])
    return (
        <>
            {props.showBuildingFilter && (
                <div className='dropdown-basic-container'>
                    <div className='container-icon-dropdown background-black'>
                        <BuildingIconBlack />
                        <h1 style={{ fontSize: '18px', margin: '0 0 0 4px', fontWeight: '500' }}>
                            Select Building
                        </h1>
                    </div>
                    <FormInputDropdown
                        // label={'Select Building'}
                        placeholder={'Select Building'}
                        control={props.control}
                        name={'buildingId'}
                        dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                    />
                </div>
            )}
            {props.showFlatFilter && (
                <div className='dropdown-basic-container'>
                    <div className='container-icon-dropdown background-black'>
                        <FlatIcon />
                        <h1 style={{ fontSize: '18px', margin: '0 0 0 4px', fontWeight: '500' }}>
                            Select Flat
                        </h1>
                    </div>
                    <FormInputDropdown
                        placeholder={'Select Flat'}
                        control={props.control}
                        name={'flatId'}
                        flatOption={true}
                        dropDownFor={DropDownConstants.TYPE_FLATS_WITH_ALL}
                    />
                </div>
            )}
            {props.showFlatFilterWithoutAll && (
                <div className='dropdown-basic-container'>
                    <div className='container-icon-dropdown background-black'>
                        <FlatIcon />
                        <h1 style={{ fontSize: '18px', margin: '0 0 0 4px', fontWeight: '500' }}>
                            Select Flat
                        </h1>
                    </div>
                    <FormInputDropdown
                        placeholder={'Select Flat'}
                        control={props.control}
                        name={'flatId'}
                        flatOption={true}
                        dropDownFor={DropDownConstants.TYPE_FLATS_WITHOUT_ALL}
                    />
                </div>
            )}
            {props.showMultipleFlatFilter && (
                <div className='dropdown-basic-container'>
                    <div className='container-icon-dropdown background-black'>
                        <FlatIcon />
                        <h1 style={{ fontSize: '18px', margin: '0 0 0 4px', fontWeight: '500' }}>
                            Select Flat
                        </h1>
                    </div>
                    <FormMultipleInputDropdown
                        placeholder={'Select Flat'}
                        control={props.control}
                        name={'flatId'}
                        flatOption={true}
                        dropDownFor={DropDownConstants.TYPE_FLATS_WITHOUT_ALL}
                    />
                </div>
            )}
            {props.showMultipleFlatFilterMultipleBill && (
                <div className='dropdown-basic-container'>
                    <div className='container-icon-dropdown background-black'>
                        <FlatIcon />
                        <h1 style={{ fontSize: '18px', margin: '0 0 0 4px', fontWeight: '500' }}>
                            Select Flat
                        </h1>
                    </div>
                    <FormMultipleInputDropdownMultipleBill
                        placeholder={'Select Flat'}
                        control={props.control}
                        name={'flatId'}
                        flatOption={true}
                        dropDownFor={DropDownConstants.TYPE_FLATS}
                    />
                </div>
            )}
            {props.showMonthPicker && (
                <div className='dropdown-basic-container'>
                    <div className='container-icon-dropdown background-black'>
                        <CalenderIcon />
                        <h1 style={{ fontSize: '18px', margin: '0 0 0 4px', fontWeight: '500' }}>
                            Select Month
                        </h1>
                    </div>
                    <FormInputMonthPicker
                        // label={'Select Month'}
                        control={props.control}
                        name={'month'}
                    />
                </div>
            )}
        </>
    )
}

export default FormDataFilter
