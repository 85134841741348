import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import ModalImage from '../../modals/ModalImage'
import ModalPoliceFormDetails from '../../modals/ModalPoliceFormDetails'
import { makeSelectPoliceFormList } from '../../../stores/admin-management/forms/FormsSelector'
import FormsAction from '../../../stores/admin-management/forms/FormsAction'
import { GreenTag, RedTag, YellowTag } from '../../component/misc/Tags'
import { CheckGreenIcon, CloseRedIcon, WarningYellowIcon } from '../../misc/IconsProvider'

export default function TablePoliceForm(props) {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectPoliceFormList)
    console.log("dataList",dataList);
    const isRequesting = useSelector((state) => selectRequesting(state, [FormsAction.REQUEST_GET_ALL_POLICE_FORM]))
    const columns = [
        {
            title: 'Image',
            dataIndex: 'passportImage',
            render: (text, record) => <ModalImage imageUrl={record.passportImage} imageTitle={'User Image'} />
        },
        {
            title: 'Name',
            dataIndex: 'fullName'
        },
        {
            title: 'NID Number',
            dataIndex: 'nidNumber'
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobileNumber'
        },
        {
            title: 'Police Form',
            align: 'center',
            render: (text, record) =>
               <ModalPoliceFormDetails data={record} key={record.id}/>
        },
        {
            title: 'NID Status',
            align: 'center',
            render: (text, record) =><>
            {
                record.nidVerification==='verified' &&
                    <GreenTag text={'Verified'} icon={<CheckGreenIcon margin='0px 5px 0px 0px' fontSize={'16px'}/>}/>
            }
            {
                record.nidVerification==='failed' &&
                    <RedTag text={'Wrong NID'} icon={<CloseRedIcon margin='0px 5px 0px 0px' fontSize={'16px'}/>}/>   
            }
            {
                record.nidVerification===undefined &&
                <YellowTag text={'Wrong NID'} icon={<WarningYellowIcon margin='0px 5px 0px 0px' fontSize={'16px'}/>}/>     
            }
            </>
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
