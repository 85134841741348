import BaseRequest from '../../../../special/models/BaseRequest'

export class DeleteExpenseTypeRequest extends BaseRequest {
    expenseTypeId = ''

    constructor(data) {
        super()
        this.update(data)
        this.expenseTypeId = data.id
    }
}
