import BaseReducer from '../../../utils/BaseReducer'
import CommunityAction from './CommunityAction'

export default class CommunityReducer extends BaseReducer {
    initialState = {
        communityList: null
    };

    [CommunityAction.REQUEST_GET_COMMUNITY_LIST_FINISHED](state, action) {
        return {
            ...state,
            communityList: action.payload
        }
    }
}
