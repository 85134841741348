import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import NoticeAction from '../../../stores/admin-management/notice/NoticeAction'
import CreateNoticeRequest from '../../../stores/admin-management/notice/requests/CreateNoticeRequest'
import FormInputRadio from '../../component/form-input/FormInputRadio'
import {
    ColorConstants,
    DropDownConstants,
    RadioConstants
} from '../../../assets/constants/GeneralConstants'
import FormInputDropDown from '../../component/form-input/FormInputDropDown'
import { FormInputTextArea } from '../../component/form-input/FormInputTextArea'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { NoticeWhiteIcon } from '../../misc/IconsProvider'
import { FormIconContainer, FormItemContainer } from '../FormStyles'

const INITIAL_STATE = {
    title: '',
    body: '',
    noticeFor: '',
    buildingId: ''
}

const VALIDATION_SCHEMA = Yup.object({
    title: Yup.string()
        .required('Title is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Max 40 Characters'),
    body: Yup.string().max(1000, 'Maximum 1000 Characters'),
    noticeFor: Yup.string().required('Please select notice target'),
    buildingId: Yup.string().required('Please select Building')
})

export default function FormCreateNotice(props) {
    const dispatch = useDispatch()

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [NoticeAction.REQUEST_CREATE_NOTICE])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, NoticeAction.REQUEST_CREATE_NOTICE)
    )

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        dispatch(NoticeAction._requestCreateNotice(new CreateNoticeRequest(values)))
    }

    return (
        <FormIconContainer>
            <FormTitleWithIcon
                icon={<NoticeWhiteIcon />}
                color={ColorConstants.GREEN1}
                formTitle={'Add New Notice'}
            />

            <FormItemContainer>
                <FormInputDropDown
                    label={'Select Building'}
                    control={control}
                    name={'buildingId'}
                    dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                />
                <FormInputText label={'Notice Title'} control={control} name={'title'} />
            </FormItemContainer>
            <FormInputTextArea label={'Body'} control={control} name={'body'} />
            <FormInputRadio
                label={'Select Notice Target'}
                control={control}
                name={'noticeFor'}
                radioFor={RadioConstants.TYPE_NOTICE_TARGET}
            />
            <ButtonFormSubmit isRequesting={isRequesting} control={control} onSubmit={onSubmit} />
        </FormIconContainer>
    )
}
