import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import FlatAction from '../../../stores/property-management/flat/FlatAction'
import { makeSelectFlatsList } from '../../../stores/property-management/flat/FlatSelector'
import FlatDetailsRequest from '../../../stores/property-management/flat/request/FlatDetailsRequest'
import { GreenTag, RedTag } from '../../component/misc/Tags'
import ModalQrCode from '../../modals/ModalQrCode'
import { useTranslation } from 'react-i18next'
import { ButtonMildBlue, ButtonMildGreen } from '../../component/buttons/CustomButtons'
import { AppRoutes } from '../../../assets/constants/routes'
import { Link } from 'react-router-dom'
import { encode } from 'base-64'

export default function TableFlat(props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectFlatsList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [FlatAction.REQUEST_GET_FLATS])
    )
    const columns = [
        {
            title: t('flat.tableHeader.qrCode'),
            dataIndex: 'thumbImage',
            render: (text, record) => (
                <ModalQrCode key={record.id} flatId={record.id} flatNumber={record.number} />
            )
        },
        {
            title: t('flat.tableHeader.number'),
            dataIndex: 'number',
            sorter: (a, b) => a.number.localeCompare(b.number, undefined, { numeric: true }),
            defaultSortOrder: 'ascend'
        },
        // {
        //     title: tableHeader.description,
        //     dataIndex: 'description'
        // },
        // {
        //     title: tableHeader.totalRoom,
        //     dataIndex: 'totalRoom'
        // },
        // {
        //     title: tableHeader.totalWashRoom,
        //     dataIndex: 'totalWashRoom'
        // },
        // {
        //     title: tableHeader.totalBalcony,
        //     dataIndex: 'totalBalcony'
        // },
        // {
        //     title: tableHeader.size,
        //     dataIndex: 'size'
        // },
        {
            title: t('flat.tableHeader.status'),
            align: 'center',
            render: (text, record) =>
                record.isVacant ? <RedTag text={'Empty'} /> : <GreenTag text={'Occupied'} />
        },
        {
            title: '',
            align: 'center',
            render: (text, record) => (
                <Link
                    to={`${AppRoutes.ADD_FLAT_MEMBER}/${encode(
                        JSON.stringify({ buildingId: props.buildingId, flatId: record.id })
                    ).toString()}`}
                >
                    <ButtonMildBlue
                        key={record.id}
                        text={'Add member To This Flat'}
                        icon={'plus'}
                        onClick={() => AppRoutes.ADD_FLAT_MEMBER}
                    />
                </Link>
            )
        },
        {
            title: '',
            align: 'center',
            render: (text, record) => (
                <Link
                    to={`${AppRoutes.SEE_FLAT_MEMBER}/${encode(
                        JSON.stringify({ buildingId: props.buildingId, flatId: record.id })
                    ).toString()}`}
                >
                    <ButtonMildGreen
                        key={record.id}
                        text={'See All Members'}
                        onClick={() => AppRoutes.SEE_FLAT_MEMBER}
                    />
                </Link>
            )
        },
        {
            title: 'Action',
            align: 'center',
            render: (text, record) => (
                <ModalDeleteConfirmation
                    tooltip={'Delete Flat'}
                    okText={t('flat.deleteModal.okText')}
                    cancelText={t('flat.deleteModal.cancelText')}
                    confirmationText={t('flat.deleteModal.alertText')}
                    onConfirm={() =>
                        dispatch(
                            FlatAction._requestDeleteFlat(
                                new FlatDetailsRequest({ flatId: record.id })
                            )
                        )
                    }
                />
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
