import React from 'react'
import { PageBasicContainer } from '../pages/PageStyles'
import SuccessImg from '../../assets/images/transition-done-once.gif'

const PaymentSuccess = ({ orderId, amount, tran_id }) => {
    return (
        <PageBasicContainer>
            <div style={{ textAlign: 'center', margin: '50px' }}>
                <img src={SuccessImg} alt='' />
                <h1 style={{ marginTop: '25px', fontSize: '32px' }}>Transaction Successful!</h1>
                <h1 style={{ fontSize: '24px' }}>
                    Your Order ID: <span style={{ color: '#ff3300' }}>{orderId}</span>{' '}
                </h1>
                <h1 style={{ fontSize: '24px' }}>
                    Your Transaction ID: <span style={{ color: '#ff3300' }}>{tran_id}</span>{' '}
                </h1>
                <h1 style={{ fontSize: '24px' }}>
                    Paid Amount: <span style={{ color: '#ff3300' }}>{amount} TK.</span>{' '}
                </h1>
            </div>
        </PageBasicContainer>
    )
}

export default PaymentSuccess
