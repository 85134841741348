import React from 'react'
import { ClapSpinner } from 'react-spinners-kit'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import './loading.css'
import { connect } from 'react-redux'
import PrivilegeAction from '../../../stores/user-management/privilege/PrivilegeAction'
import UserAction from '../../../stores/user-management/user/UserAction'
import Loader from '../../../assets/images/app-logo-loader.gif';
import NewPackageAction from '../../../stores/rokkhi-admin/subscription/NewPackageAction'
import ReportAction from '../../../stores/reporting/ReportAction'
import BillAction from '../../../stores/money-management/bill/BillAction'

const mapStateToProps = (state, ownProps) => ({
    isRequesting: selectRequesting(state, [
        UserAction.REQUEST_GET_USER_DETAILS,
        UserAction.REQUEST_GET_FUNCTIONS_OF_USER,
        PrivilegeAction.REQUEST_GET_USER_FUNCTIONS_OF_ROLE,
        PrivilegeAction.REQUEST_GET_USER_ROLES,
        PrivilegeAction.REQUEST_ASSIGN_ROLE_TO_USER,
        PrivilegeAction.REQUEST_ASSIGN_FUNCTION_TO_ROLE,
        UserAction.REQUEST_GET_ROLES_OF_USER,
        PrivilegeAction.REQUEST_GET_USER_FUNCTIONS_OF_ROLE,
        PrivilegeAction.REQUEST_GET_USER_FUNCTIONS,
        NewPackageAction.REQUEST_GET_ALL_PACKAGE,
        ReportAction.REQUEST_GET_LEDGER_REPORT,
        UserAction.REQUEST_GET_DASHBOARD_OF_USER
    ])
})

class LoadingIndicator extends React.Component {
    render() {
        if (this.props.isRequesting || this.props.isActive) {
            return (
                <div className='container-loadingmodal'>
                    <div className='modal-content'>
                        {/* <ClapSpinner
                            size={50}
                            color='#00BFFF'
                            loading={this.props.isRequesting || this.props.isActive}
                        /> */}
                        <img src={Loader} style={{width:'100px',height:'100px'}}/>
                        {/* <h1>Loading....</h1> */}
                    </div>
                </div>
            )
        }
        return null
    }
}

export default connect(mapStateToProps)(LoadingIndicator)
