import BaseRequest from '../../../special/models/BaseRequest'

export default class AssignFunctionsToRoleRequest extends BaseRequest {
    roleCode = ''
    functionCodes = []

    constructor(roleCode, functionCodes) {
        super()
        this.roleCode = roleCode
        this.functionCodes = functionCodes
    }
}
