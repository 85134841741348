import ActionUtility from '../../../utils/ActionUtility'
import PreAuthorizationEffect from './PreAuthorizationEffect'

export default class PreAuthorizationAction {
    static REQUEST_GET_PRE_AUTHORIZATION_LIST = 'REQUEST_GET_PRE_AUTHORIZATION_LIST'
    static REQUEST_GET_PRE_AUTHORIZATION_LIST_FINISHED = 'REQUEST_GET_PRE_AUTHORIZATION_LIST_FINISHED'

    static REQUEST_ADD_TO_PRE_AUTHORIZED_LIST = 'REQUEST_ADD_TO_PRE_AUTHORIZED_LIST'
    static REQUEST_ADD_TO_PRE_AUTHORIZED_LIST_FINISHED = 'REQUEST_ADD_TO_PRE_AUTHORIZED_LIST_FINISHED'

    static REQUEST_DELETE_FROM_PRE_AUTHORIZED_LIST = 'REQUEST_DELETE_FROM_PRE_AUTHORIZED_LIST'
    static REQUEST_DELETE_FROM_PRE_AUTHORIZED_LIST_FINISHED = 'REQUEST_DELETE_FROM_PRE_AUTHORIZED_LIST_FINISHED'

    static _requestAddToPreAuthorizedList(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                PreAuthorizationAction.REQUEST_ADD_TO_PRE_AUTHORIZED_LIST,
                PreAuthorizationEffect._requestAddToPreAuthorizedList,
                data
            )
        }
    }

    static _requestGetPreAuthorizationList(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                PreAuthorizationAction.REQUEST_GET_PRE_AUTHORIZATION_LIST,
                PreAuthorizationEffect._requestGetPreAuthorizationList,
                data
            )
        }
    }
    static _requestDeleteFromPreAuthorizationList(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                PreAuthorizationAction.REQUEST_DELETE_FROM_PRE_AUTHORIZED_LIST,
                PreAuthorizationEffect._requestDeleteFromPreAuthorizationList,
                data
            )
        }
    }
}
