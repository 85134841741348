import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import ButtonFormSubmit from '../ButtonFormSubmit'
import BillAction from '../../../stores/money-management/bill/BillAction'
import { ColorConstants } from '../../../assets/constants/GeneralConstants'
import { makeSelectBillTypesList } from '../../../stores/money-management/bill/BillSelector'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { ExpenseIcon } from '../../misc/IconsProvider'
import { FormIconContainer } from '../FormStyles'
import SingleBillInvoiceItem from './SingleBillInvoiceItem'
import { FormInputText } from '../../component/form-input/FormInputText'
import { CreateBillPackageRequest } from '../../../stores/money-management/bill/requests/CreateBillPackageRequest'
import { checkIsAmountZeroOrEmpty, removeNullValueObject } from '../../../utils/functions/functions'
import { message } from 'antd'

const INITIAL_STATE = {
    packageName: '',
    buildingId: ''
}

const VALIDATION_SCHEMA = Yup.object({
    packageName: Yup.string().required('Please provide a name').min(3,'Minimum 3 Characters').max(40,'Maximum 40 Characters'),
})

export default function FormCreateBillPackage(props) {
    const dispatch = useDispatch()
    const [billItemsFromChild, setBillItemsFromChild] = useState({})
    const [activeBillItems, setActiveBillItems] = useState([])

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [BillAction.REQUEST_CREATE_BILL_PACKAGE])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_CREATE_BILL_PACKAGE_FINISHED)
    )
    const billTypes = useSelector(makeSelectBillTypesList)

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        const billItems = removeNullValueObject(billItemsFromChild)
        values['billItems'] = Object.values(billItems)
        values.billItems.map((item,index)=>values.billItems[index].amount=parseFloat(item.amount))
        const checkAmountZero = checkIsAmountZeroOrEmpty(billItems)
        if (Object.keys(billItems).length) {
            if (checkAmountZero) {
                dispatch(BillAction._requestCreateBillPackage(new CreateBillPackageRequest(values)))
            } else {
                message.error({content: "Amount Can't Zero or Empty", key: 'billPackageCreate'});
            }
        } else {
            message.error({content: 'No Bill Item Found', key: 'billPackageError'})
        }
    }

    useEffect(() => {
        const billItemsFromType = billTypes.map((item) => {
            return {
                name: item.name,
                description: item.description,
                amount: item.defaultAmount,
                id: item.id
            }
        })
        setActiveBillItems(billItemsFromType)
    }, [billTypes])

    const makePreviousDataInvalid = (itemIndex) => {
        const typeId = activeBillItems[itemIndex].id
        const newState = { ...billItemsFromChild, [typeId]: null }
        setBillItemsFromChild(newState)
    }
    const removeBillItem = (itemIndex) => {
        makePreviousDataInvalid(itemIndex)
        let newItems = activeBillItems.filter((item, index) => index !== itemIndex)
        setActiveBillItems(newItems)
    }
    const receiveDataInParent = (data, index) => {
        setBillItemsFromChild((oldState) => {
            return {
                ...oldState,
                [data.typeId]: data
            }
        })
        let newItems = [...activeBillItems]
        newItems[index] = data;
        setActiveBillItems(newItems)
    }



    return (
        <FormIconContainer>
            <FormTitleWithIcon
                icon={<ExpenseIcon />}
                color={ColorConstants.GREEN1}
                formTitle={'Create Bill Package'}
            />

            <FormInputText label={'Package Name'} control={control} name={'packageName'} />

            <div>
                {activeBillItems.map((billItem, index) => (
                    <SingleBillInvoiceItem
                        key={index}
                        index={index}
                        removeItem={removeBillItem}
                        billItem={billItem}
                        sendDataToParent={receiveDataInParent}
                    />
                ))}
            </div>

            <ButtonFormSubmit
                title={'Create Package'}
                isRequesting={isRequesting}
                control={control}
                onSubmit={onSubmit}
                isFinished={isFinished}
            />
        </FormIconContainer>
    )
}
