import BaseReducer from '../../../utils/BaseReducer'
import MeetingAction from './MeetingAction'

export default class MeetingReducer extends BaseReducer {
    initialState = {
        meetingList: null
    };

    [MeetingAction.REQUEST_GET_MEETINGS_FINISHED](state, action) {
        return {
            ...state,
            meetingList: action.payload
        }
    }
}
