import React, { useContext, useEffect, useState } from 'react'
import { DataBuilderUtility } from '../../../utils/DataBuilderUtility'
import { CardInfoLeftRoundIcon } from '../../component/decoration/CardSingleInfo'
import TableFlat from '../../tables/property/TableFlat'
import { useDispatch, useSelector } from 'react-redux'
import { makeSelectFlatsList } from '../../../stores/property-management/flat/FlatSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import FlatAction from '../../../stores/property-management/flat/FlatAction'
import GetFlatsRequest from '../../../stores/property-management/flat/request/GetFlatsRequest'
import TableDataFilter from '../../tables/TableDataFilter'
import {
    PageBasicContainer,
    PageDecorationContainer,
    PageMainBarContainer,
    TableButtonContainer
} from '../PageStyles'
import { useTranslation } from 'react-i18next'
import { ButtonFillBlack } from '../../component/buttons/CustomButtons'
import { PlusOutlined } from '@ant-design/icons'
import { AppRoutes } from '../../../assets/constants/routes'
import { Link } from 'react-router-dom'

export default function PageFlats() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [filterValues, setFilterValues] = useState(null)
    const dataList = useSelector(makeSelectFlatsList)
    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, FlatAction.REQUEST_DELETE_FLAT)
    )
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, FlatAction.REQUEST_CREATE_FLAT)
    )
    useEffect(() => {
        if(filterValues && filterValues.buildingId){
            dispatch(FlatAction._requestGetFlatList(new GetFlatsRequest(filterValues)))
        }
    }, [isFinishedDeletion, isFinishedCreation, filterValues])

    const decorationArr = DataBuilderUtility._getFlatsDataOverview(
        dataList,
        t('flat.cardTitle', { returnObjects: true })
    )

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    const decorItems = decorationArr.map((item) => (
        <CardInfoLeftRoundIcon key={item.dataTitle} data={item} />
    ))
    return (
        <PageBasicContainer>
            <PageDecorationContainer paddingTop={'0px'} item={3}>
                {decorItems}
            </PageDecorationContainer>
            <TableButtonContainer>
                {dataList.length < 1 ? (
                    <Link to={AppRoutes.CREATE_MULTIPLE_FLAT}>
                        <ButtonFillBlack icon={'plus'} text={'Add Multiple Flat'} />
                    </Link> 
                ) : null}
                <Link style={{ marginLeft: '20px' }} to={AppRoutes.CREATE_FLAT}>
                    <ButtonFillBlack icon={'plus'} text={'Add Single Flat'} />
                </Link>
            </TableButtonContainer>
            <PageMainBarContainer>
                <TableDataFilter sendDataToParent={receiveFilterData} showBuildingFilter={true} />
                <TableFlat
                    buildingId={filterValues && filterValues.buildingId && filterValues.buildingId}
                />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
