import BaseReducer from '../../../utils/BaseReducer'
import FlatAction from './FlatAction'

export default class FlatReducer extends BaseReducer {
    initialState = {
        flatList: null,
        associatedBillPackage: null,
        qrCode: null
    };

    [FlatAction.REQUEST_GET_FLATS_FINISHED](state, action) {
        return {
            ...state,
            flatList: action.payload
        }
    }

    [FlatAction.REQUEST_GET_FLATS_Bill_PACKAGE_FINISHED](state, action) {
        return {
            ...state,
            associatedBillPackage: action.payload
        }
    }
    [FlatAction.REQUEST_QRCODE_OF_FLAT_FINISHED](state, action) {
        return {
            ...state,
            qrCode: action.payload
        }
    }
}
