import HttpErrorResponseModel from '../stores/special/models/HttpErrorResponseModel'

export default class ActionUtility {
    static async _createThunkEffect(dispatch, actionType, effect, ...args) {
        dispatch(ActionUtility._createAction(actionType))
        const model = await effect(...args)
        const isError = model instanceof HttpErrorResponseModel
        dispatch(ActionUtility._createAction(`${actionType}_FINISHED`, model, isError))
        return model
    }

    static _createAction(type, payload = undefined, error = false, meta = null) {
        return { type, payload, error, meta }
    }
}
