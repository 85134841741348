import React from 'react'
import BuildingIcon from '../../../assets/images/building-black-big.png'
import FlatIcon from '../../../assets/images/flat-full-black-big.png'
import TenantIcon from '../../../assets/images/tenants-black-big.png'
import ParkingIcon from '../../../assets/images/parking-black-big.png'
import VehicleIcon from '../../../assets/images/vehicles-black-big.png'

function DashboardBottomBar({ dashboardData }) {
    return (
        <>
            <div className='bottom-bar-container'>
                <div className='bottom-item-container'>
                    <img src={BuildingIcon} alt='building' />
                    <div>
                        <h2>{dashboardData.totalBuilding || 0}</h2>
                        <p>Building</p>
                    </div>
                </div>
                <div className='bottom-item-container'>
                    <img src={FlatIcon} alt='flats' />
                    <div>
                        <h2>{dashboardData.totalFlats || 0}</h2>
                        <p>Flats</p>
                    </div>
                </div>
                <div className='bottom-item-container'>
                    <img src={TenantIcon} alt='Tenant' />

                    <div>
                        <h2>{dashboardData.totalUsers || 0}</h2>
                        <p>Tenants</p>
                    </div>
                </div>
                <div className='bottom-item-container'>
                    <img src={VehicleIcon} alt='Tenant' />

                    <div>
                        <h2>{dashboardData.numOfVehicles || 0}</h2>
                        <p>Vehicles</p>
                    </div>
                </div>
                <div className='bottom-item-container'>
                    <img src={ParkingIcon} alt='Tenant' />

                    <div>
                        <h2>{dashboardData.numOfParkings || 0}</h2>
                        <p>Parkings</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardBottomBar
