import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import MeetingAction from './MeetingAction'

const MeetingPostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case MeetingAction.REQUEST_CREATE_MEETING_FINISHED:
                next(MiscAction._showModalSuccess('Meeting Created Successfully'))
                break
            case MeetingAction.REQUEST_CANCEL_MEETING_FINISHED:
                next(MiscAction._showModalSuccess('Meeting Cancelled Successfully'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default MeetingPostEffect
