import React from 'react'
import './dashboard.css'
import DashboardItem from './DashboardItem'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import UserAction from '../../../stores/user-management/user/UserAction'
import GetUserDashboardRequest from '../../../stores/user-management/user/requests/GetUserDashboardRequest'
import {
    makeSelectDahboardOfUser,
    makeSelectUserDetails,
    makeSelectUserPerspective
} from '../../../stores/user-management/user/UserSelector'
import { useState } from 'react'
import DashboardSideItem from './DashboardSideItem'
import { DashboardDecorationDataModel } from '../../component/decoration/DashboardDecorationDataModel'
import BillCardPieChart from './BillCardPieChart'
import billIcon from '../../../assets/images/taka-black-big.png'
import incomeIcon from '../../../assets/images/income-black-big.png'
import fundIcon from '../../../assets/images/fund-summary.svg'
import { billSummary } from '../../../utils/functions/functions'
import IncomeExpensePiechart from './IncomeExpensePiechart'
import DashboardBottomBar from './DashboardBottomBar'
import TableDataFilterSelected from '../../tables/TableDataFilterSelected'
import BuildingAction from '../../../stores/property-management/building/BuildingAction'
import GetBuildingsRequest from '../../../stores/property-management/building/request/GetBuildingsRequest'
import MonthlyBillBarChart from './MonthlyBillBarChart'

export default function PageDashBoard(props) {
    const dispatch = useDispatch()
    const [filterValues, setFilterValues] = useState(null)
    const userDetails = useSelector(makeSelectUserDetails)
    const userPerspective = useSelector((state) => makeSelectUserPerspective(state))

    useEffect(() => {
        dispatch(BuildingAction._requestGetBuildingList(new GetBuildingsRequest()))
    }, [userPerspective])

    useEffect(() => {
        if (filterValues && filterValues.buildingId && filterValues.monthRange) {
            dispatch(
                UserAction._requestGetDashboardOfUser(
                    new GetUserDashboardRequest({
                        specBuildingId: filterValues.buildingId,
                        fromDate: filterValues.monthRange[0],
                        toDate: filterValues.monthRange[1]
                    })
                )
            )
        } else {
            dispatch(UserAction._requestGetDashboardOfUser(new GetUserDashboardRequest()))
        }
    }, [filterValues, userPerspective])

    const dashboardData = useSelector(makeSelectDahboardOfUser)

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    const billSummaryData = billSummary({
        totalBill:
            dashboardData && dashboardData.summarizeData
                ? dashboardData.summarizeData[0].totalbillamount
                : 0,
        dueBill:
            dashboardData && dashboardData.summarizeData
                ? dashboardData.summarizeData[0].duebill
                : 0
    })

    const data = [
        new DashboardDecorationDataModel(
            'Total Bill',
            dashboardData &&
            dashboardData.summarizeData &&
            dashboardData.summarizeData[0].totalbillamount
                ? dashboardData.summarizeData[0].totalbillamount
                : 0,
            dashboardData &&
            dashboardData.summarizeData &&
            dashboardData.summarizeData[0].totalbillamount ? (
                <BillCardPieChart billItems={billSummaryData} />
            ) : null,
            billIcon
        ),
        // new DashboardDecorationDataModel(
        //     'Fund Summary',
        //     ['Total amount'],
        //     <BillCardPieChart />,
        //     fundIcon
        // ),
        new DashboardDecorationDataModel(
            'Income Balance',
            dashboardData && dashboardData.summarizeData && dashboardData.summarizeData[0].income
                ? dashboardData.summarizeData[0].income
                : 0,
            dashboardData && dashboardData.summarizeData ? (
                <IncomeExpensePiechart ledgerReport={dashboardData.summarizeData[0]} />
            ) : null,
            incomeIcon
        )
    ]

    return (
        <>
            <div className='dash-main-container'>
                <div className={'dashboard-container'}>
                    <TableDataFilterSelected
                        showSimpleBuildingFilter={true}
                        monthRangeFilter={true}
                        sendDataToParent={receiveFilterData}
                    />
                    <div className={'dashboard-info-container'}>
                        {data.map((item, index) => (
                            <DashboardItem key={index} data={item} />
                        ))}
                    </div>
                    <div className='bar-chart-container'>
                        {dashboardData && dashboardData.monthlyData ? (
                            <div
                                style={{
                                    padding: '20px 10px'
                                }}
                            >
                                <MonthlyBillBarChart monthlyData={dashboardData.monthlyData} />
                            </div>
                        ) : null}
                    </div>
                    <DashboardBottomBar dashboardData={dashboardData} />
                </div>
                <div>
                    {userDetails && (
                        <DashboardSideItem
                            userDetails={userDetails}
                            dashboardData={dashboardData}
                        />
                    )}
                </div>
            </div>
        </>
    )
}
