import BaseRequest from '../../../special/models/BaseRequest'

export class CreateBillPackageRequest extends BaseRequest {
    billItems = {
        name: '',
        description: '',
        amount: 0,
        typeId: ''
    }

    buildingId = ''
    packageName = ''

    constructor(data) {
        super()
        this.update(data)
        this.billItems.amount = parseFloat(data.billItems.amount)
    }
}
