import { BaseModel } from 'sjs-base-model'

export default class SubmenuOptionModel extends BaseModel {
    submenuName = ''
    userFunction = ''
    route = ''

    constructor(submenuName, userFunction, route) {
        super()
        this.submenuName = submenuName
        this.userFunction = userFunction
        this.route = route
    }
}
