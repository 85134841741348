import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import BillAction from '../../../stores/money-management/bill/BillAction'
import { ColorConstants } from '../../../assets/constants/GeneralConstants'
import FlatAction from '../../../stores/property-management/flat/FlatAction'
import GetFlatsRequest from '../../../stores/property-management/flat/request/GetFlatsRequest'
import {
    makeSelectBillDecideList,
    makeSelectBillTypesList
} from '../../../stores/money-management/bill/BillSelector'
import {
    FormInputTitleWithSvgIcon,
    FormTitleWithSvgIcon
} from '../../component/decoration/CardSingleInfo'
import { FormIconContainer } from '../FormStyles'
import FormDataFilter from '../FormDataFilter'
import { PageDecorationContainer } from '../../pages/PageStyles'
import SingleBillInvoiceItem from './SingleBillInvoiceItem'
import { FormInputText } from '../../component/form-input/FormInputText'
import {
    getCurrentMonthWithYear,
    removeNullValueObject,
    getMonthNameAndYear
} from '../../../utils/functions/functions'
import { Button, message, notification, Spin } from 'antd'
import ModalAddBillType from '../../modals/ModalAddBillType'
import { GetDecideBillRequest } from '../../../stores/money-management/bill/requests/GetDecideBillRequest'
import GenerateMultipleBillInvoices from '../../../stores/money-management/bill/requests/GenerateMultipleBillInvoice'
import GenerateBillIcon from '../../../assets/images/collected-bill-small.png'
import TitleIcon from '../../../assets/images/title-small.png'
import ButtonFormSubmitIcon from '../ButtonFormSubmitIcon'
import { ButtonFillPurple } from '../../component/buttons/CustomButtons'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../../../assets/constants/routes'

const INITIAL_STATE = {
    title: '',
    month: getCurrentMonthWithYear(),
    // lastDateOfPayment: '',
    buildingId: '',
    flatId: ''
}

const VALIDATION_SCHEMA = Yup.object({
    title: Yup.string()
        .required('Name is Required')
        .min(5, 'Minimum 5 Characters')
        .max(50, 'Max 50 Characters'),
    month: Yup.string().required('Month is Required'),
    buildingId: Yup.string().required('Building is Required'),
    flatId: Yup.string().required('Flat is Required')
})

export default function FormGenerateBillInvoice(props) {
    const dispatch = useDispatch()

    const [billItemsFromChild, setBillItemsFromChild] = useState({})
    const [activeBillItems, setActiveBillItems] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)

    const isRequesting = useSelector((state) =>
        selectRequesting(state, [BillAction.REQUEST_GENERATE_MULTIPLE_BILL_FOR_FLAT])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_GENERATE_MULTIPLE_BILL_FOR_FLAT)
    )

    //ALL SELECTORS
    const billTypes = useSelector(makeSelectBillTypesList)
    // const decideBillTypes = useSelector((state) => makeSelectBillDecideList(state))

    useEffect(() => {
        if (control.values.flatId && control.values.flatId.length && billTypes) {
            const filteredArray = billTypes.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    amount: item.defaultAmount
                }
            })
            setActiveBillItems(filteredArray)
        }
    }, [control.values.flatId, billTypes])

    useEffect(() => control.resetData(), [isFinished])

    useEffect(() => {
        if (control.values.buildingId)
            dispatch(
                FlatAction._requestGetFlatList(
                    new GetFlatsRequest({ buildingId: control.values.buildingId })
                )
            )
    }, [control.values.buildingId])

    // useEffect(() => {
    //     if (
    //         control.values.flatId &&
    //         control.values.flatId.length === 1 &&
    //         control.values.flatId !== ''
    //     ) {
    //         dispatch(
    //             BillAction._requestGetDecideBill(
    //                 new GetDecideBillRequest({
    //                     flatIdInfo: control.values.flatId,
    //                     buildingIdInfo: control.values.buildingId
    //                 })
    //             )
    //         )
    //         setActiveBillItems([])
    //     }
    // }, [control.values.flatId])

    useEffect(() => {
        if (activeBillItems.length > 0) {
            const total = activeBillItems
                .map((item) => {
                    console.log(item.amount)
                    if (item.amount === undefined) {
                        return 0
                    } else if (item.amount === '') {
                        return 0
                    } else {
                        return parseInt(item.amount)
                    }
                })
                .reduce((acc, val) => {
                    return acc + val
                }, 0)
            setTotalAmount(total)
        }
    }, [activeBillItems])

    const onSubmit = () => {
        const billItems = removeNullValueObject(activeBillItems)
        values['billItems'] = Object.values(
            billItems.map((item) => {
                return {
                    typeId: item.id,
                    name: item.name,
                    description: item.description,
                    amount: item.amount
                }
            })
        )
        const parsedArray = values.billItems.map(
            (item, index) => (values.billItems[index].amount = parseFloat(item.amount))
        )
        const isZero = parsedArray.includes(0)
        // const checkAmountZero = checkIsAmountZeroOrEmpty(billItems)

        if (Object.keys(billItems).length) {
            if (!isZero && values.flatId) {
                dispatch(
                    BillAction._requestGenerateMultipleBillInvoices(
                        new GenerateMultipleBillInvoices(values)
                    )
                )
                // console.log(new GenerateMultipleBillInvoices(values))
            } else {
                message.error("Amount can't be zero or empty")
            }
        } else {
            message.error('No bill item found')
        }
    }
    const receiveDataInParent = (data, index) => {
        setActiveBillItems((oldState) => {
            return {
                ...oldState,
                [data.typeId]: data
            }
        })
        let newItems = [...activeBillItems]
        newItems[index] = data
        setActiveBillItems(newItems)
    }

    const makePreviousDataInvalid = (itemIndex) => {
        const typeId = activeBillItems[itemIndex].id
        const newState = { ...billItemsFromChild, [typeId]: null }
        setBillItemsFromChild(newState)
    }

    const removeBillItem = (itemIndex) => {
        makePreviousDataInvalid(itemIndex)
        let newItems = activeBillItems.filter((item, index) => index !== itemIndex)
        setActiveBillItems(newItems)
    }

    // useEffect(() => {
    //     let interval
    //     if (isFinished) {
    //         interval = setInterval(() => {
    //             openNotification();
    //         }, 10000)
    //     } else {
    //         clearInterval(interval)
    //     }
    //     return () => clearInterval(interval)
    // }, [isFinished])

    // const close = () => {
    //     console.log(
    //         'Notification was closed. Either the close button was clicked or duration time elapsed.'
    //     )
    // }

    // const openNotification = () => {
    //     const key = `open${Date.now()}`
    //     const btn = (
    //         <Button type='primary' size='small' onClick={() => notification.close(key)}>
    //             Cancel
    //         </Button>
    //     )
    //     notification.open({
    //         message: 'Notification Title',
    //         description:
    //             'A function will be be called after the notification is closed (automatically after the "duration" time of manually).',
    //         btn,
    //         key,
    //         duration: 10,
    //         onClose: close
    //     })
    // }

    return (
        <FormIconContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormTitleWithSvgIcon
                    title={'Generate Multiple Bill'}
                    icon={GenerateBillIcon}
                    backgroundColor={ColorConstants.GREEN4}
                />
                <div>
                    <Link to={AppRoutes.REQUESTED_BILL_GENERATION}>
                        <ButtonFillPurple
                            text={'Generated Bill Report'}
                            icon={'container'}
                            size={'large'}
                        />
                    </Link>
                </div>
            </div>
            <PageDecorationContainer item={3} style={{ padding: 10 }} paddingTop={'0px'}>
                <FormDataFilter
                    control={control}
                    showMultipleFlatFilterMultipleBill={true}
                    showMonthPicker={true}
                    showBuildingFilter={true}
                />
            </PageDecorationContainer>
            <FormInputTitleWithSvgIcon
                title={'Invoice Title'}
                subTitle={'Please provide a proper title of the invoice'}
                icon={TitleIcon}
            />
            <FormInputText
                placeholder={'Bill For ' + getMonthNameAndYear(getCurrentMonthWithYear())}
                // label={'Invoice Title'}
                name={'title'}
                control={control}
            />
            <div>
                {activeBillItems
                    ? activeBillItems.map((billItem, index) => (
                          <SingleBillInvoiceItem
                              key={index}
                              index={index}
                              removeItem={removeBillItem}
                              billItem={billItem}
                              sendDataToParent={receiveDataInParent}
                          />
                      ))
                    : null}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {activeBillItems.length > 0 && activeBillItems.length < billTypes.length ? (
                    <ModalAddBillType
                        activeBillItems={activeBillItems}
                        billTypes={billTypes}
                        control={control}
                        setActiveBillItems={setActiveBillItems}
                    />
                ) : null}
            </div>
            {activeBillItems && activeBillItems.length > 0 ? (
                <div>
                    <h2>
                        <span
                            style={{
                                color: `${ColorConstants.RED1}`
                            }}
                        >
                            Total Amount ({activeBillItems.length} Items):{' '}
                        </span>
                        <span
                            style={{
                                color: `${ColorConstants.RED}`,
                                fontSize: '16px',
                                marginLeft: '11%'
                            }}
                        >
                            ৳
                        </span>{' '}
                        {totalAmount}
                    </h2>
                </div>
            ) : null}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonFormSubmitIcon
                    title={'Generate Bill'}
                    isRequesting={isRequesting}
                    control={control}
                    onSubmit={onSubmit}
                    isFinished={isFinished}
                />
            </div>
        </FormIconContainer>
    )
}
