import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { makeSelectNoticeList } from '../../../stores/admin-management/notice/NoticeSelector'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import ComponentTable from '../ComponentTable'
import NoticeAction from '../../../stores/admin-management/notice/NoticeAction'
import GetNoticesRequest from '../../../stores/admin-management/notice/requests/GetNoticesRequest'
import NoticeDetailsRequest from '../../../stores/admin-management/notice/requests/NoticeDetailsRequest'
import { NoticeForConstants } from '../../../assets/constants/GeneralConstants'
import { BlueTag, GreenTag, YellowTag } from '../../component/misc/Tags'

export default function TableNotice(props) {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectNoticeList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [NoticeAction.REQUEST_GET_NOTICES])
    )
    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, NoticeAction.REQUEST_DELETE_NOTICE)
    )
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, NoticeAction.REQUEST_CREATE_NOTICE)
    )

    useEffect(() => {
        dispatch(NoticeAction._requestGetNoticeList(new GetNoticesRequest()))
    }, [isFinishedDeletion, isFinishedCreation])

    const columns = [
        {
            title: 'Notice Title',
            dataIndex: 'title'
        },
        {
            title: 'Notice Body',
            dataIndex: 'body'
        },
        {
            title: 'Date',
            render: (text, record) => (
                <div>
                    {' '}
                    {record.createdDate && new Date(record.createdDate).toLocaleDateString()}{' '}
                </div>
            )
        },
        {
            title: 'Notice From',
            render: (text, record) => <div>{record.addedBy}</div>
        },
        {
            align: 'center',
            title: 'For Whom',
            render: (text, record) => {
                switch (record.noticeFor) {
                    case NoticeForConstants.FLAT_MEMBERS:
                        return <GreenTag text={'Flat Member'} />
                    case NoticeForConstants.GUARDS:
                        return <BlueTag text={'Guards'} />
                    case NoticeForConstants.GENERAL:
                        return <YellowTag text={'General'} />
                    default:
                        return <div></div>
                }
            }
        },
        {
            title: 'Action',
            align: 'center',
            render: (text, record) => (
                <ModalDeleteConfirmation
                    tooltip={'Delete Notice'}
                    confirmationText={'Are you sure you want to delete this notice ?'}
                    onConfirm={() =>
                        dispatch(
                            NoticeAction._requestDeleteNotice(new NoticeDetailsRequest(record))
                        )
                    }
                />
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
