import ReportAction from './ReportAction'
import BaseReducer from '../../utils/BaseReducer'

export default class ReportReducer extends BaseReducer {
    initialState = {
        ledgerReport: null,
        incomeReport: null,
        expenseReport: null,
        cashFlowReport: null,
        incomeStatement: null
    };

    [ReportAction.REQUEST_GET_LEDGER_REPORT_FINISHED](state, action) {
        return {
            ...state,
            ledgerReport: action.payload
        }
    }

    [ReportAction.REQUEST_GET_INCOME_REPORT_FINISHED](state, action) {
        return {
            ...state,
            incomeReport: action.payload
        }
    }

    [ReportAction.REQUEST_GET_EXPENSE_REPORT_FINISHED](state, action) {
        return {
            ...state,
            expenseReport: action.payload
        }
    }

    [ReportAction.REQUEST_GET_CASH_FLOW_REPORT_FINISHED](state, action) {
        return {
            ...state,
            cashFlowReport: action.payload
        }
    }

    [ReportAction.REQUEST_GET_INCOME_STATEMENT_FINISHED](state, action) {
        return {
            ...state,
            incomeStatement: action.payload
        }
    }
}
