import React from 'react'

function FlatInvoicePdfBody({ data }) {
    let total = data.items.reduce((acc, item) => acc + item.amount, 0)
    return (
        <div>
            <table style={{ width: '100%', marginTop: '20px', border: '1px solid black' }}>
                <thead style={{ textAlign: 'center', border: '1px solid', padding: '2px' }}>
                    <th style={{ border: '1px solid' }}>Bill Item Name</th>
                    <th>Amount</th>
                </thead>
                <tbody>
                    {data.items.map((item) => {
                        return (
                            <tr style={{ border: '1px solid' }}>
                                <td style={{ border: '1px solid', padding: '2px 4px' }}>
                                    {item.name}
                                </td>
                                <td style={{ border: '1px solid', padding: '2px 4px' }}>
                                    {item.amount}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <table style={{width: '100%', marginTop: '20px'}}>
                <thead></thead>
                <tbody>
                    <tr>
                        <td style={{padding: '2px 4px'}}>Total Amount</td>
                        <td>&#2547; <span>{total}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default FlatInvoicePdfBody
