import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    PaymentStatusConstants,
    TransactionCategoryConstants
} from '../../../assets/constants/GeneralConstants'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { DataBuilderUtility } from '../../../utils/DataBuilderUtility'
import { CardInfoLeftColoredIcon } from '../../component/decoration/CardSingleInfo'
import TableDataFilter from '../../tables/TableDataFilter'
import { Tabs } from 'antd'
import TableTransaction from '../../tables/fund/TableTransaction'
import FundAction from '../../../stores/money-management/fund/FundAction'
import { GetTransactionsRequest } from '../../../stores/money-management/fund/requests/GetTransactionsRequest'
import { makeSelectTransactionItemsList } from '../../../stores/money-management/fund/FundSelector'
import { PageBasicContainer, PageDecorationContainer, PageMainBarContainer } from '../PageStyles'
import { useTranslation } from 'react-i18next'

const { TabPane } = Tabs

export default function PageFundDashboard(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [decorationArrValues, setDecorationArrValues] = useState([])
    const [transactionCategory, setTransactionCategory] = useState(PaymentStatusConstants.ALL)
    const [filterValues, setFilterValues] = useState({})
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, FundAction.REQUEST_CREATE_FUND)
    )
    const isFinishedAddToFund = useSelector((state) =>
        selectFinished(state, FundAction.REQUEST_ADD_TO_FUND)
    )
    const isFinishedWithdrawFromFund = useSelector((state) =>
        selectFinished(state, FundAction.REQUEST_WITHDRAW_FROM_FUND)
    )

    const dataList = useSelector(makeSelectTransactionItemsList)

    const changeTransactionCategory = (type) => setTransactionCategory(type)

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        dispatch(FundAction._requestGetTransactions(new GetTransactionsRequest()))
    }, [])

    useEffect(() => {
        filterValues['transactionCategory'] = transactionCategory
        dispatch(FundAction._requestGetTransactions(new GetTransactionsRequest(filterValues)))
    }, [
        isFinishedAddToFund,
        isFinishedCreation,
        isFinishedWithdrawFromFund,
        filterValues,
        transactionCategory
    ])
    useEffect(() => {
        setDecorationArrValues(DataBuilderUtility._getFundDataOverview(dataList, t('fund_dashboard.cardTitle', {returnObjects:true})))
    }, [dataList,t('fund_dashboard.cardTitle')])
    const decorItems = decorationArrValues.map((item) => (
        <CardInfoLeftColoredIcon key={Math.random()} data={item} />
    ))

    return (
        <PageBasicContainer>
            <PageDecorationContainer paddingTop={'0px'} item={3}>
                {decorItems}
            </PageDecorationContainer>
            <PageMainBarContainer>
                <TableDataFilter showDateRangeFilter={true} sendDataToParent={receiveFilterData} />
                <Tabs onChange={changeTransactionCategory} type='card'>
                    <TabPane tab={t('fund_dashboard.tabs.all')} key={TransactionCategoryConstants.ALL} />
                    <TabPane
                        tab={t('fund_dashboard.tabs.billCollection')}
                        key={TransactionCategoryConstants.BILL_COLLECTION}
                    />
                    <TabPane tab={t('fund_dashboard.tabs.expense')} key={TransactionCategoryConstants.EXPENSE_ENTRY} />
                    <TabPane tab={t('fund_dashboard.tabs.addFund')} key={TransactionCategoryConstants.ADD_FUND} />
                    <TabPane tab={t('fund_dashboard.tabs.withdrawFund')} key={TransactionCategoryConstants.WITHDRAW} />
                </Tabs>
                <TableTransaction />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
