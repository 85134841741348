import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NoticeForConstants } from '../../../assets/constants/GeneralConstants'
import { Tabs } from 'antd'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { DataBuilderUtility } from '../../../utils/DataBuilderUtility'
import { CardInfoLeftColoredIcon } from '../../component/decoration/CardSingleInfo'
import { withRouter } from 'react-router'
import { makeSelectNoticeList } from '../../../stores/admin-management/notice/NoticeSelector'
import TableDataFilter from '../../tables/TableDataFilter'
import NoticeAction from '../../../stores/admin-management/notice/NoticeAction'
import GetNoticesRequest from '../../../stores/admin-management/notice/requests/GetNoticesRequest'
import TableNotice from '../../tables/admin/TableNotice'
import { PageBasicContainer, PageDecorationContainer, PageMainBarContainer } from '../PageStyles'

const { TabPane } = Tabs

const PageNotices = (props) => {
    const dispatch = useDispatch()
    const [decorationArrValues, setDecorationArrValues] = useState([])
    const [noticeFor, setNoticeFor] = useState(NoticeForConstants.ALL)
    const [filterValues, setFilterValues] = useState('')
    const isFinished = useSelector((state) =>
        selectFinished(state, NoticeAction.REQUEST_DELETE_NOTICE)
    )
    const dataList = useSelector(makeSelectNoticeList)

    const changeNoticeFor = (noticeFor) => setNoticeFor(noticeFor)

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        dispatch(NoticeAction._requestGetNoticeList(new GetNoticesRequest(filterValues, noticeFor)))
    }, [filterValues, noticeFor, isFinished])

    useEffect(() => {
        setDecorationArrValues(DataBuilderUtility._getNoticesDataOverview(dataList))
    }, [dataList])

    const decorationItems = decorationArrValues.map((item) => (
        <CardInfoLeftColoredIcon key={item.dataTitle} data={item} />
    ))

    return (
        <PageBasicContainer>
            <PageDecorationContainer paddingTop={'0px'} item={4}>
                {decorationItems}
            </PageDecorationContainer>
            <PageMainBarContainer>
                <TableDataFilter
                    showBuildingFilter={true}
                    showDateRangeFilter={true}
                    sendDataToParent={receiveFilterData}
                />

                <Tabs onChange={changeNoticeFor} type='card'>
                    <TabPane tab='ALL' key={NoticeForConstants.ALL} />
                    <TabPane tab='General Notice' key={NoticeForConstants.GENERAL} />
                    <TabPane tab='Flat Members' key={NoticeForConstants.FLAT_MEMBERS} />
                    <TabPane tab='Guards' key={NoticeForConstants.GUARDS} />
                </Tabs>

                <TableNotice />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
export default withRouter(PageNotices)
