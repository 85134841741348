import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { UserRoleCodeConstants } from '../../../assets/constants/GeneralConstants';
import { makeSelectFunctionsOfUser } from '../../../stores/user-management/user/UserSelector';
import FreeSubscriptionPage from '../../misc/FreeSubscriptionPage';
import RestrictedPage from '../../misc/RestrictedPage';
import firebase from '../../../utils/firebase/index';

const PublicRoute = ({component: Component, functionCode, ...rest}) => {
    const analytics = firebase.analytics();
    const userFunctions = useSelector((state) => makeSelectFunctionsOfUser(state))
    const userfunctionKeys = userFunctions.map((item) => item.code);
    const userDetails = useSelector((state) => state.user.userDetails)
    const userRoleCodes = userDetails.data.userRoles.map((item) => item.code);
    const [grantedRoute,setGrantedRoute] = useState(userfunctionKeys.includes(functionCode.toString()))
    useEffect(()=>{
        setGrantedRoute(userfunctionKeys.includes(functionCode.toString()))
    },[functionCode,Component])
    const {path} = {...rest}
    analytics.logEvent('page_view',{path})
    return (
        <Route {...rest} render={() => (
            !grantedRoute ?
               (
                userRoleCodes.includes(UserRoleCodeConstants.COMMUNITY_SUPER_ADMIN_FREE.toString()) ?
                <FreeSubscriptionPage/> : <RestrictedPage/>
               )
            : <Component {...rest} />
        )} />
    );
};

export default PublicRoute;