import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalMessage from './ModalMessage'

const mapStateToProps = (state) => ({
    modalStatus: state.misc.modalStatus
})

class ModalHolder extends Component {
    render() {
        return (
            <div>
                <ModalMessage />
            </div>
        )
    }
}

export { ModalHolder as Unconnected }
export default connect(mapStateToProps)(ModalHolder)
