import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import TableDataFilter from '../../tables/TableDataFilter'
import ExpenseAction from '../../../stores/money-management/expense/ExpenseAction'
import { GetExpenseTypeRequest } from '../../../stores/money-management/expense/requests/type/GetExpenseTypeRequest'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'
import TableExpenseVouchers from '../../tables/expense/TableExpenseVouchers'
import { GetExpenseVouchersRequest } from '../../../stores/money-management/expense/requests/voucher/GetExpenseVouchersRequest'
import { ExpenseAuthorizationStatusConstants } from '../../../assets/constants/GeneralConstants'

export default function PagePendingExpenses() {
    const dispatch = useDispatch()
    const [filterValues, setFilterValues] = useState({})

    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, ExpenseAction.REQUEST_DELETE_EXPENSE_ITEM)
    )

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        dispatch(ExpenseAction._requestGetExpenseTypes(new GetExpenseTypeRequest()))
    }, [])

    useEffect(() => {
        if (filterValues && filterValues.buildingId && filterValues.dateRange) {
            filterValues['authorizationStatus'] = ExpenseAuthorizationStatusConstants.PENDING
            dispatch(
                ExpenseAction._requestGetExpenseVouchers(
                    new GetExpenseVouchersRequest(filterValues)
                )
            )
        }
    }, [isFinishedDeletion, filterValues])

    return (
        <PageBasicContainer>
            <PageMainBarContainer>
                <TableDataFilter
                    showDateRangeFilter={true}
                    showBuildingFilter={true}
                    sendDataToParent={receiveFilterData}
                />
                <TableExpenseVouchers
                    authorizationStatus={ExpenseAuthorizationStatusConstants.PENDING}
                />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
