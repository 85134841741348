import SelectionItemModel from '../../stores/special/models/SelectionItemModel'

export class VisitorStatusConstants {
    static ALL = '-1'
    static STILL_INSIDE = 'STILL_INSIDE'
    static NOT_INSIDE = 'NOT_INSIDE'
}

export class ItemTableTypeConstants {
    static INCOME = 'income'
    static EXPENSE = 'expense'
}

export class TableType {
    static VISITOR = 'VISITOR'
    static PARCEL = 'PARCEL'
    static NOTICES = 'NOTICES'
    static GUARDS = 'GUARDS'
    static ADMIN = 'ADMIN'
    static USERS = 'USERS'
    static SERVICE_WORKER = 'SERVICE_WORKER'
    static MEETING = 'MEETING'
    static BILLS_DETAILS = 'BILLS_DETAILS'
    static BILL_TYPES = 'BILL_TYPES'
    static BILL_PACKAGES = 'BILL_PACKAGES'
    static BILL_INVOICES = 'BILL_INVOICES'
    static COMPLAIN = 'COMPLAIN'
    static CALCULATION = 'CALCULATION'
    static LEDGER = 'LEDGER'
    static BUILDING_FUND = 'BUILDING_FUND'
    static REQUEST = 'REQUEST'
    static FLATS = 'FLATS'

    static ADMIN_TYPE = 'ADMIN_TYPE'
    static SERVICE_TYPE = 'SERVICE_TYPE'
    static BUILDINGS = 'BUILDINGS'
    static DECISIONS = 'DECISIONS'
}

export class DropDownConstants {
    static TYPE_FUNDS = 'TYPE_FUNDS'
    static TYPE_BUILDINGS = 'TYPE_BUILDINGS'
    static TYPE_BUILDINGS_ALL = 'TYPE_BUILDINGS_ALL'
    static TYPE_FLATS = 'TYPE_FLATS'
    static TYPE_FLATS_WITH_ALL = 'TYPE_FLATS_WITH_ALL'
    static TYPE_FLATS_WITHOUT_ALL = 'TYPE_FLATS_WITHOUT_ALL'
    static TYPE_BILL_TYPES = 'TYPE_BILL_TYPES'
    static TYPE_EXPENSE_TYPES = 'TYPE_EXPENSE_TYPES'
    static TYPE_ADMIN_TYPES = 'TYPE_ADMIN_TYPES'
    static TYPE_ADMIN_DESIGNATIONS = 'TYPE_ADMIN_DESIGNATIONS'
    static TYPE_BUILDING_USERS = 'TYPE_BUILDING_USERS'
    static TYPE_BILL_PACKAGES = 'TYPE_BILL_PACKAGES'

    static TYPE_SERVICE_TYPES = 'TYPE_SERVICE'
    static TYPE_FLAT_STATUS = 'TYPE_FLAT_STATUS'
    static TYPE_VISITOR_STATUS = 'TYPE_VISITOR_STATUS'
    static TYPE_NOTICE_FOR = 'TYPE_NOTICE_FOR'
    static TYPE_MEETING_STATUS = 'TYPE_MEETING_STATUS'
    static TYPE_REQUEST_STATUS = 'TYPE_REQUEST_STATUS'

    static TYPE_USERS = 'TYPE_USERS'
    static TYPE_USER_ROLE = 'TYPE_USER_ROLE'
    static TYPE_COMMUNITY = 'TYPE_COMMUNITY'
    static TYPE_FINGERPRINT = 'TYPE_FINGERPRINT'
    static TYPE_SERVICE_WORKER = 'TYPE_SERVICE_WORKER'
    static TYPE_PACKAGE_STATUS = 'TYPE_PACKAGE_STATUS'
    static TYPE_PACKAGE_PLAN = 'TYPE_PACKAGE_PLAN'
    static TYPE_PACKAGE = 'TYPE_PACKAGE'
}

export class ModalTypeConstants {
    static DETAILS_WORKER = 'DETAILS_WORKER'
    static DETAILS_VISITOR = 'DETAILS_VISITOR'
    static DETAILS_ADMIN = 'DETAILS_ADMIN'
    static DETAILS_COMMON_FUND_VOUCHER = 'DETAILS_COMMON_FUND_VOUCHER'
    static DETAILS_USER = 'DETAILS_USER'
    static DETAILS_PARCEL = 'DETAILS_PARCEL'
    static DETAILS_EXPENSE = 'DETAILS_EXPENSE'
    static DETAILS_GUARD = 'DETAILS_GUARD'
    static ADD_BILL = 'ADD_BILL'
    static COLLECT_BILL = 'COLLECT_BILL'

    static TYPE_SUCCESS = 'TYPE_SUCCESS'
    static TYPE_INFO = 'TYPE_INFO'
    static TYPE_ERROR = 'TYPE_ERROR'
    static TYPE_WARNING = 'TYPE_WARNING'
    static TYPE_SUBSCRIPTION_WARNING = 'TYPE_SUBSCRIPTION_WARNING'
}

export class MonthConstants {
    static FOR_ADD_CALCULATION = 'FOR_ADD_CALCULATION'
    static FOR_ADD_BUILDING_FUND = 'FOR_ADD_BUILDING_FUND'
    static FOR_QUERY_ON_TABLE = 'FOR_QUERY_CALCULATION'
    static FOR_QUERY_BILL = 'FOR_QUERY_BILL'
}

export class DateConstants {
    static FOR_QUERY_ON_TABLE = 'FOR_QUERY_ON_TABLE'
    static FOR_GUARD_JOIN = 'FOR_GUARD_JOIN'
    static FOR_GUARD_DOB = 'FOR_GUARD_DOB'
    static FOR_ADD_BUILDING_FUND = 'FOR_ADD_BUILDING_FUND'
}
export class DateRangeConstants {
    static FOR_QUERY_ON_TABLE = 'FOR_QUERY_ON_TABLE'
}

export class ImageConstants {
    static FOR_ADD_GUARD_PROFILE_PIC = 'FOR_ADD_GUARD_PROFILE_PIC'
    static FOR_ADD_CALCULATION_MEMO = 'FOR_ADD_CALCULATION_MEMO'
    static FOR_ADD_BUILDING_FUND_MEMO = 'FOR_ADD_BUILDING_FUND_MEMO'
    static FOR_EXPENSE_ENTRY_MEMO = 'FOR_EXPENSE_ENTRY_MEMO'
    static FOR_ADD_POLICE_FORM = 'FOR_ADD_POLICE_FORM'
    static FOR_ADD_FLAT_MEMBER = 'FOR_ADD_FLAT_MEMBER'
}
export class FundDefaultConstants {
    static CASH_FUND = '2001'
    static ACCOUNTS_RECEIVABLE = '2003'
}

export class ColorConstants {
    static BLUE = '#0079bf'
    static BLUE1 = '#55bcc9'
    static BLUE2 = '#00acc1'
    static SKY = '#ç'

    static RED = '#dc143c'
    static RED1 = '#e53935'
    static RED2 = '#fe3935'

    static ORANGE = '#ff8c00'
    static ORANGE1 = '#fb8c00'

    static GREEN1 = '#14AB72'
    static GREEN2 = '#5cdb95'
    static GREEN3 = '#8ee4af'
    static GREEN4 = '#116466'
    static GREEN5 = '#45a29e'
    static GREEN6 = '#43a047'

    static PURPLE1 = '#99738e'
    static PURPLE2 = '#c38d9e'
    static PURPLE3 = '#c38d9e'

    static WOOD1 = '#bc986a'
    static WOOD2 = '#daad86'

    static BLACK1 = '#0b0c10'
    static BLACK2 = '#1f2833'

    static GREY = '#8d9091'
    static GREY1 = '#9BB0A5'
    static GREY2 = '#e9efed'
}
export class ThreeColorConstants {
    static GREEN = '#5ABC91'
    static BLUE = '#6BA9FF'
    static RED = '#FF8868'
}

export class GreeneTheme {
    static COLOR1 = '#14ab72'
    static COLOR2 = '#009880'
    static COLOR3 = '#00938F'
    static COLOR4 = '#008697'
    static COLOR5 = '#007899'
    static COLOR6 = '#006995'
    static COLOR7 = '#F1FCF6'
    static COLOR8 = '#4D7D94'
    static COLOR9 = '#394B41'
}

export class TagColors {
    static GREEN_BACK = '#F1FCF6'
    static GREEN_FRONT = '#14ab72'

    static RED_BACK = 'rgba(253, 238, 233, 0.5)'
    static RED_FRONT = '#e53935'

    static BLUE_BACK = '#e1eff7'
    static BLUE_FRONT = '#0079bf'

    static BLUE_BACK = '#e1eff7'
    static BLUE_FRONT = '#0079bf'

    static GEEK_BLUE = '#0d5eaf'

    static YELLOW_BACK = 'rgb(248 245 230)'
    static YELLOW_FRONT = 'rgb(213 188 4)'

    static PURPLE_BACK = '#d8d4ff'
    static PURPLE_FRONT = '#0004A1'
}

export class AuthorizationTypeConstants {
    static TYPE_RANDOM_PERSON = 'TYPE_RANDOM_PERSON'
    static TYPE_CAR = 'TYPE_CAR'
    static TYPE_SERVICE_WORKER = 'TYPE_SERVICE_WORKER'
    static TYPE_GUARD = 'TYPE_GUARD'
}

export class AuthorizationStatusConstants {
    static ALL = '-1'
    static WHITE_LISTED = 'WHITE_LISTED'
    static BLACK_LISTED = 'BLACK_LISTED'
}

export class FlatFormatConstants {
    static STRING_DIGIT = 'STRING_DIGIT'
    static DIGIT_STRING = 'DIGIT_STRING'
    static THREE_DIGIT = 'THREE_DIGIT'
    static FOUR_DIGIT = 'FOUR_DIGIT'
}

export class RadioConstants {
    static TYPE_ADD_BUILDING_FUND = 'TYPE_ADD_BUILDING_FUND'
    static TYPE_GENDER = 'TYPE_GENDER'
    static TYPE_USER_ROLE = 'TYPE_USER_ROLE'
    static AUTHORIZATION_STATUS = 'AUTHORIZATION_STATUS'
    static TYPE_NOTICE_TARGET = 'TYPE_NOTICE_TARGET'
    static TYPE_FLAT_NUMBER_FORMAT = 'TYPE_FLAT_NUMBER_FORMAT'
    static TYPE_VEHICLE = 'TYPE_VEHICLE'

    static radioOptionsFlatNumberFormat = [
        new SelectionItemModel('A1', FlatFormatConstants.STRING_DIGIT),
        new SelectionItemModel('1A', FlatFormatConstants.DIGIT_STRING),
        new SelectionItemModel('101', FlatFormatConstants.THREE_DIGIT)
    ]

    static radioOptionsAddBuildingFund = [
        new SelectionItemModel('Cash Deposit', 'deposit'),
        new SelectionItemModel('Cash Withdraw', 'withdraw')
    ]

    static radioOptionsGender = [
        new SelectionItemModel('Male', 'm'),
        new SelectionItemModel('Female', 'f'),
        new SelectionItemModel('Other', 'o')
    ]

    static radioOptionsUserRole = [
        new SelectionItemModel('Non-App User', '1010'),
        new SelectionItemModel('Guard', '1008'),
        new SelectionItemModel('Service Worker', '1003'),
        new SelectionItemModel('Children', '1005'),
        // new SelectionItemModel('General Registered User', '1002'),
        new SelectionItemModel('Guard Phone', '1009')
    ]

    static radioOptionsNoticeFor = [
        new SelectionItemModel('General', 'ALL'),
        new SelectionItemModel('Flat Members', 'FLAT_MEMBERS'),
        // new SelectionItemModel('Building Owners', 'BUILDING_OWNERS'),
        // new SelectionItemModel('Committee Member', 'COMMITTEE_MEMBERS'),
        new SelectionItemModel('Guards', 'GUARDS')
    ]

    static radioOptionsPreAuthorizationStatus = [
        new SelectionItemModel('White Listed', AuthorizationStatusConstants.WHITE_LISTED),
        new SelectionItemModel('Black Listed', AuthorizationStatusConstants.BLACK_LISTED)
    ]

    static radioOptionsVehicleType = [
        new SelectionItemModel('Private Car', 'PRIVATE_CAR'),
        new SelectionItemModel('Motor Bike', 'MOTOR_BIKE'),
        new SelectionItemModel('Others', 'OTHERS')
    ]
}

export class UserRoleCodeConstants {
    static ROKKHI_SUPER_ADMIN = '1000'
    static COMMUNITY_SUPER_ADMIN_MANAGEMENT = '1001'
    static COMMUNITY_SUPER_ADMIN_SECURITY = '1011'
    static COMMUNITY_SUPER_ADMIN_FREE = '2000'
    static GENERAL_REGISTERED_USER_APP = '1002'
    static SERVICE_WORKER = '1003'
    static GENERAL_FLAT_MEMBER = '1004'
    static CHILD = '1005'
    static CARETAKER = '1006'
    static COMMITTEE_MEMBER = '1007'
    static GUARD = '1008'
    static GUARD_PHONE = '1009'
    static GENERAL_REGISTERED_USER_NON_APP = '1010'
}

export class UserFunctionConstants {
    static CREATE_AND_VIEW_BILL_TYPE = '2001'
    static GENERATE_BILL_FOR_FLAT = '2002'
    static COLLECT_BILL_OF_FLAT = '2003'
    static BILLS_DASHBOARD = '2004'
    static CREATE_AND_VIEW_EXPENSE_TYPE = '2005'

    static ADD_EXPENSE_ENTRY = '2006'
    static EXPENSE_DASHBOARD = '2007'
    static CREATE_FUND = '2008'
    static ADD_TO_FUND = '2009'
    static WITHDRAW_FROM_FUND = '2010'
    static TRANSACTION_HISTORY = '2011'
    static VISITOR_ENTRANCE_DASHBOARD = '2012'
    static PARCEL_ENTRANCE_DASHBOARD = '2013'
    static VEHICLE_ENTRANCE_DASHBOARD = '2014'
    static CREATE_NEW_MEETING = '2015'
    static MEETINGS_DASHBOARD = '2016'
    static SEND_NOTICE_FROM_ADMIN = '2017'
    static VIEW_ALL_NOTICE_OF_COMMUNITY = '2018'
    static SUBMIT_COMPLAIN = '2019'
    static VIEW_ALL_COMPLAIN_OF_COMMUNITY = '2020'
    static ADD_MODIFY_USER_ROLE = '2021'
    static ADD_MODIFY_USER_FUNCTION = '2022'
    static ASSIGN_FUNCTIONS_TO_ROLE = '2023'
    static ASSIGN_USER_TO_ROLE = '2024'

    static CREATE_COMMUNITY = '2025'
    static VIEW_COMMUNITIES = '2026'
    static CREATE_BUILDING = '2027'
    static VIEW_BUILDINGS = '2028'
    static CREATE_FLAT = '2029'
    static VIEW_FLATS = '2030'

    static REGISTER_USER = '2031'
    static VIEW_USERS = '2032'

    static ADD_VEHICLE = '2033'
    static VEHICLES_LIST = '2034'

    static ADD_NEW_DEVICE = '2035'
    static ASSIGN_FINGERPRINT_TO_SERVICE_WORKERS = '2036'

    static ADD_TO_WHITE_LIST = '2037'
    static ADD_TO_BLACK_LIST = '2038'
    static VIEW_PRE_AUTHORIZED_LIST_OF_COMMUNITY = '2039'
    static CREATE_MULTIPLE_FLAT = '2040'
    static GENERATE_EXPENSE_VOUCHER = '2041'
    static AUTHORIZE_EXPENSE_VOUCHER = '2042'
    static CREATE_BILL_PACKAGE = '2043'

    static VIEW_LEDGER_REPORT = '2044'
    static VIEW_INCOME_REPORT = '2045'
    static VIEW_EXPENSE_REPORT = '2046'
    static VIEW_INCOME_STATEMENT = '2047'

    static TRANSFER_FUND = '2048'

    static CREATE_VIEW_ALL_FORMS = '2049'

    static FREE_USER = '2050'

    static CREATE_PACKAGE_ADMIN = '2051'
    static PAYMENT = '2052'
    static DIRECT_PAYMENT = '2053'
    static TENANT_FORM = '2054'
    static TENANT_DEPARTURE_FORM = '2055'
    static POLICE_FORM_ADMIN = '2056'
    static BILL_GENERATE_BACKUP = '2057'
    static NID_VERIFICATION = '2058'
    static GENERATED_BILL_REPORT = '2059'
    static VIEW_BALANCE_SHEET = '2060'
}

export class EntranceTypeConstants {
    static TYPE_VISITOR = 'TYPE_VISITOR'
    static TYPE_PARCEL = 'TYPE_PARCEL'
    static TYPE_SERVICE_WORKER = 'TYPE_SERVICE_WORKER'
    static TYPE_CAR = 'TYPE_CAR'
}
export class EntranceStatusConstants {
    static ALL = '-1'
    static PARCEL_RECEIVED = 'PARCEL_RECEIVED'
    static PARCEL_DELIVERED = 'PARCEL_DELIVERED'
    static INSIDE_COMPOUND = 'INSIDE_COMPOUND'
    static OUTSIDE_COMPOUND = 'OUTSIDE_COMPOUND'
}

export class VehicleTypeConstants {
    static ALL = '-1'
    static OTHERS = 'OTHERS'
    static PRIVATE_CAR = 'PRIVATE_CAR'
    static MOTOR_BIKE = 'MOTOR_BIKE'
    static MICRO_BUS = 'MICRO_BUS'
}

export class FundMovementTypeConstants {
    static DEPOSIT = 'DEPOSIT'
    static WITHDRAW = 'WITHDRAW'
    static TRANSFER = 'TRANSFER'
}

export class NoticeForConstants {
    static ALL = '-1'
    static GENERAL = 'ALL'
    static FLAT_MEMBERS = 'FLAT_MEMBERS'
    static BUILDING_OWNERS = 'BUILDING_OWNERS'
    static COMMITTEE_MEMBERS = 'COMMITTEE_MEMBERS'
    static GUARDS = 'GUARDS'
}

export class PaymentStatusConstants {
    static ALL = ''
    static PAID = 'PAID'
    static PARTIAL_PAID = 'PARTIAL_PAID'
    static UNPAID = 'UNPAID'
}

export class ExpenseAuthorizationStatusConstants {
    static ALL = '-1'
    static PENDING = 'PENDING'
    static AUTHORIZED = 'AUTHORIZED'
    static DECLINED = 'DECLINED'
}

export class ComplainStatusConstants {
    static ALL = '-1'
    static ACTIVE = 'ACTIVE'
    static IN_PROGRESS = 'IN_PROGRESS'
    static RESOLVED = 'RESOLVED'
}

export class MeetingStatusConstants {
    static ALL = '-1'
    static SCHEDULED = 'SCHEDULED'
    static RUNNING = 'RUNNING'
    static FINISHED = 'FINISHED'
    static CANCELLED = 'CANCELLED'
}

export class PaymentMethodConstants {
    static CASH_EXCHANGE = 'CASH_EXCHANGE'
    static ONLINE_PAYMENT = 'ONLINE_PAYMENT'
}

export class TransactionTypeConstants {
    static DEBIT = 'DEBIT'
    static CREDIT = 'CREDIT'
}

export class TransactionCategoryConstants {
    static ALL = '-1'
    static BILL_COLLECTION = 'BILL_COLLECTION'
    static EXPENSE_ENTRY = 'EXPENSE_ENTRY'
    static ADD_FUND = 'ADD_FUND'
    static WITHDRAW = 'WITHDRAW'
}
export class BuildingStatusConstants {
    static ALL = '-1'
    static ACTIVE = true
    static INACTIVE = false
}

export class FundTypeConstants {
    static ASSET = 'asset'
    static LIABILITY = 'liability'
    static EQUITY = 'equity'
}

export class DefaultExpenseTypeRequest {
    static MAINTENANCE_AND_REPAIR = '1001'
    static BILL_PAY = '1002'
    static SALARY = '1003'
    static OTHERS = '1004'
}

export class DefaultBillTypeTypeRequest {
    static ELECTRICITY_BILL = '1001'
    static GAS_BILL = '1002'
    static SERVICE_CHARGE = '1004'
    static OTHERS = '1005'
}
export class LanguageConstants {
    static BN = 'bn'
    static EN = 'en'
}

export class PackageStatusConstant {
    static AVAILABLE = 'AVAILABLE'
    static UNAVAILABLE = 'UNAVAILABLE'
}
export class OrderTypeConstants {
    static PACKAGE = 'PACKAGE'
    static PRODUCT = 'PRODUCT'
    static SERVICE = 'SERVICE'
}
export class OrderRemarkConstants {
    static BUILDING = 'BUILDING'
    static INDIVIDUAL = 'INDIVIDUAL'
    static COMMUNITY = 'COMMUNITY'
}
export class DefaultPackageConstants {
    static MANAGEMENT = 'MANAGEMENT'
    static FREE = 'FREE'
    static MANAGEMENT_SECURITY = 'SECURITY'
}
