import BaseRequest from '../../../special/models/BaseRequest'

export class GetBillItemsRequest extends BaseRequest {
    month = ''
    paymentStatus = ''
    typeId = ''
    flatId = ''
    buildingId = ''
    getPreviousDueAndPartial = false

    constructor(data, paymentStatus) {
        super()
        this.update(data)
        if (paymentStatus) {
            this.paymentStatus = paymentStatus
        }
    }
}
