import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import ComponentTable from '../ComponentTable'
import { makeSelectPackageList } from '../../../stores/rokkhi-admin/subscription/NewPackageSelector'
import NewPackageAction from '../../../stores/rokkhi-admin/subscription/NewPackageAction'
import { useEffect } from 'react'
import GetNewPackageRequest from '../../../stores/rokkhi-admin/subscription/request/GetNewPackageRequest'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { GreenTag, RedTag } from '../../component/misc/Tags'

export default function TableNewPackage(props) {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectPackageList)
    console.log(dataList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [NewPackageAction.REQUEST_GET_ALL_PACKAGE])
    )

    const isFinished = useSelector((state) =>
        selectFinished(state, NewPackageAction.REQUEST_CREATE_PACKAGE)
    )

    useEffect(() => {
        dispatch(NewPackageAction._requestGetPackageList(new GetNewPackageRequest()))
    }, [isFinished])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Info',
            dataIndex: 'info'
        },
        {
            title: 'Type',
            dataIndex: 'type'
        },
        {
            title: 'Price',
            dataIndex: 'price'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                if (record.status === 'AVAILABLE') {
                    return <GreenTag text={'Available'} />
                } else if (record.status === 'UNAVAILABLE') {
                    return <RedTag text={'Unavailable'} />
                }
            }
        },
        {
            title: 'Duration',
            dataIndex: 'duration'
        },
        {
            title: 'Month',
            dataIndex: 'month'
        }
        // {
        //     title: 'Action',
        //     align: 'center',
        //     render: (text, record) => (
        //         <ModalDeleteConfirmation
        //             confirmationText={'Are you sure you want to delete this type ?'}
        //             // onConfirm={() =>
        //             //     dispatch(ExpenseAction._requestDeleteExpenseType(new DeleteExpenseTypeRequest(record)))
        //             // }
        //         />
        //     )
        // }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
