import { Document, Font, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { ButtonMildGreen } from '../buttons/CustomButtons'
import Kalpurush from '../../../assets/font/Nikosh.ttf'

Font.register({
    family: 'Kalpurush',
    format: 'truetype',
    fonts: [{ src: Kalpurush }]
})

export default function TenantOwnerPdf() {
    const [ready, setReady] = useState(false)
    const [click, setClick] = useState(false)
    useEffect(() => {
        click && document.getElementById('autoClickBtn').click()
        setReady(false)
        setClick(false)
    }, [click])

    return !ready ? (
        <ButtonMildGreen
            // key={props.data.id}
            onClick={() => setReady(true)}
            text={'PDF'}
            icon={'printer'}
        />
    ) : (
        true && (
            <PDFDownloadLink document={<PDFDocument />} fileName={`Tenant owner pdf`}>
                {({ blob, url, loading, error }) =>
                    loading ? (
                        <ButtonMildGreen text={'Loading'} icon={'printer'} />
                    ) : (
                        <a target='_blank' href={url} id='autoClickBtn' rel='noreferrer'>
                            <ButtonMildGreen text={'PDF'} icon={'printer'} />
                            {setClick(true)}
                        </a>
                    )
                }
            </PDFDownloadLink>
        )
    )
}

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Kalpurush',
        fontSize: 14,
        // paddingTop: 35,
        // paddingBottom: 65,
        paddingHorizontal: 30,
        textAlign: 'justify',
        wordWrap: 'normal',
        letterSpacing: 0,
        textOverflow: 'hidden'
        // margin: 8
        // margin: '27mm 16mm 27mm 16mm'
    },
    headerText: {
        marginTop: '30px',
        marginBottom: '30px',
        textAlign: 'center'
    }
})

function PDFDocument() {
    return (
        <Document>
            <Page size='A4' style={styles.page} wrap>
                <View wrap>
                    <Text style={styles.headerText}>বাড়িওয়ালা ভাড়াটিয়া চুক্তিপত্র</Text>
                    <Text>তারিখঃ </Text>
                    <Text>বাড়ির মালিকের নাম </Text>
                    <Text>ঠিকানাঃ </Text>
                    <Text>বিষয়ঃ </Text>
                    <Text>জনাব, </Text>
                    <View>
                        <Text style={{ textAlign: 'left' }}>
                            এই মর্মে জানাচ্ছি যে, আমি user one পিতা: user two ঠিকানাঃ Dhanmondi,
                            Dhaka এই ঠিকানায় বসবাস করতেন গত 12.01.2019 তারিখ থেকে আমার জানামতে,
                            এতদিন এই ঠিকানায় অবস্থানরত থাকাকালে তিনি সমাজ বা রাষ্ট্রবিরোধী অথবা
                            অনৈতিক কোন কাজের সাথে জড়িত ছিলেন না এবং তার স্বভাব ও নৈতিকতা ছিল
                            প্রশংসনীয়। তার সাথে আমার পূর্বের কোন লেনদেন বাকি নেই। আমি তার ভবিষ্যৎ
                            মঙ্গল কামনা করি।
                        </Text>
                    </View>
                    <Text>ব্যক্তিগত তথ্যঃ</Text>

                    <Text>মালিক</Text>
                    <Text>ভাড়াটিয়া {''}</Text>
                </View>
            </Page>
        </Document>
    )
}
