import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { NoticeWhiteIcon } from '../../misc/IconsProvider'
import {
    ColorConstants,
    DropDownConstants,
    FundMovementTypeConstants,
    ImageConstants
} from '../../../assets/constants/GeneralConstants'
import { FormInputTextArea } from '../../component/form-input/FormInputTextArea'
import FundAction from '../../../stores/money-management/fund/FundAction'
import { AddToFundRequest } from '../../../stores/money-management/fund/requests/AddToFundRequest'
import {
    FormDoubleItemContainer,
    FormIconContainer,
    FormImageWithInfoContainer,
    FormItemContainer
} from '../FormStyles'
import FormInputDropDown from '../../component/form-input/FormInputDropDown'
import FormInputMonthPicker from '../../component/form-input/FormInputMonthPicker'
import { FundTransferRequest } from '../../../stores/money-management/fund/requests/FundTransferRequest'
import { useTranslation } from 'react-i18next'
import { getCurrentMonthWithYear } from '../../../utils/functions/functions'
import { isMobile } from 'react-device-detect'
import UploadImageButton from '../../component/upload/UploadImageButton'
import { _getSelectedImageFile, _getSelectedImageUrl } from '../../../selectors/ImageSelector'
import useImageUpload from '../../component/upload/useImageUpload'
import { message } from 'antd'
import MiscAction from '../../../stores/misc/MiscAction'
import ImageSelectModel from '../../../stores/misc/model/ImageSelectModel'

export default function FormFundMovement(props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const imageUrl = useSelector((state) =>
        _getSelectedImageUrl(state, ImageConstants.FOR_ADD_BUILDING_FUND_MEMO)
    )
    const imageFile = useSelector((state) =>
        _getSelectedImageFile(state, ImageConstants.FOR_ADD_BUILDING_FUND_MEMO)
    )
    const userDetails = useSelector((state) => state.user.userDetails)
    const fundChooserVisibility = () =>
        props.fundMovementType === FundMovementTypeConstants.TRANSFER
    const INITIAL_STATE = {
        fromFund: '',
        toFund: '',
        amount: '',
        date: '',
        month: getCurrentMonthWithYear(),
        description: '',
        buildingId: '',
        image: ''
    }
    const VALIDATION_SCHEMA = Yup.object({
        fromFund: fundChooserVisibility() && Yup.string().required('Transfer From is Required'),
        toFund: fundChooserVisibility() && Yup.string().required('Transfer To is Required'),
        buildingId: Yup.string().required('Building is Required'),
        month: Yup.string().required('Month is Required'),
        description: Yup.string()
            .required('Description is Required')
            .min(3, 'Minimum 3 Characters')
            .max(150, 'Maximum 150 Characters'),
        amount: Yup.number()
            .typeError('Amount must be Digit')
            .required('Amount is required.')
            .min(1, 'Minimum amount 1')
            .max(100000000, 'Maximum amount 100000000')
    })

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [
            FundAction.REQUEST_ADD_TO_FUND,
            FundAction.REQUEST_WITHDRAW_FROM_FUND,
            FundAction.REQUEST_TRANSFER_FUND
        ])
    )
    const isFinishedAdd = useSelector((state) =>
        selectFinished(state, FundAction.REQUEST_ADD_TO_FUND)
    )
    const isFinishedWithdraw = useSelector((state) =>
        selectFinished(state, FundAction.REQUEST_WITHDRAW_FROM_FUND)
    )
    const isFinishedTransfer = useSelector((state) =>
        selectFinished(state, FundAction.REQUEST_TRANSFER_FUND)
    )

    useEffect(() => {
        control.resetData()
        dispatch(
            MiscAction._setImage(
                new ImageSelectModel(ImageConstants.FOR_ADD_BUILDING_FUND_MEMO, null, null)
            )
        )
    }, [isFinishedAdd, isFinishedWithdraw, isFinishedTransfer])

    const uploadImageAndSaveData = async () => {
        if (imageUrl) {
            const hideUploading = message.loading('Image Uploading...', 0)
            const { uploadImage } = useImageUpload()
            const formData = new FormData()
            formData.append('image', imageFile)
            formData.append('folderName', 'fund')
            formData.append('subFolderName', userDetails.data.userId)
            formData.append('fileName', Date.now())
            const postImage = await uploadImage(formData)
            if (postImage.success) {
                setTimeout(hideUploading, 0)
                message.success(`Image upload successful`)
                const newValues = {
                    ...control.values,
                    image: postImage.success
                }
                dispatch(FundAction._requestAddToFund(new AddToFundRequest(newValues)))
            } else if (postImage.error) {
                setTimeout(hideUploading, 0)
                message.error('Image Upload Failed! Please Try Again')
                return false
            }
        } else {
            dispatch(FundAction._requestAddToFund(new AddToFundRequest(values)))
        }
    }
    const onSubmit = () => {
        switch (props.fundMovementType) {
            case FundMovementTypeConstants.DEPOSIT:
                uploadImageAndSaveData(values)
                break
            case FundMovementTypeConstants.WITHDRAW:
                dispatch(FundAction._requestWithdrawFromFund(new AddToFundRequest(values)))
                break
            case FundMovementTypeConstants.TRANSFER:
                dispatch(FundAction._requestTransferFund(new FundTransferRequest(values)))
            default:
                break
        }
    }

    const getTitle = () => {
        switch (props.fundMovementType) {
            case FundMovementTypeConstants.DEPOSIT:
                return t('fund_movement.addCashToFund.title')
            case FundMovementTypeConstants.WITHDRAW:
                return t('fund_movement.withdrawCash.title')
            case FundMovementTypeConstants.TRANSFER:
                return t('fund_movement.transferFund.title')
        }
    }
    const getButtonTitle = () => {
        switch (props.fundMovementType) {
            case FundMovementTypeConstants.DEPOSIT:
                return t('fund_movement.addCashToFund.submitButton')
            case FundMovementTypeConstants.WITHDRAW:
                return t('fund_movement.withdrawCash.submitButton')
            case FundMovementTypeConstants.TRANSFER:
                return t('fund_movement.transferFund.submitButton')
        }
    }
    return (
        <FormIconContainer>
            <FormTitleWithIcon
                formTitle={getTitle()}
                icon={<NoticeWhiteIcon />}
                color={ColorConstants.GREEN1}
            />
            {props.fundMovementType === FundMovementTypeConstants.DEPOSIT ? (
                <FormImageWithInfoContainer>
                    <div className={'form-image'} style={{ justifySelf: isMobile ? 'center' : '' }}>
                        <UploadImageButton
                            label={'Memo Image'}
                            control={control}
                            name={'image'}
                            imageFor={ImageConstants.FOR_ADD_BUILDING_FUND_MEMO}
                            imageUrl={imageUrl}
                        />
                    </div>

                    <FormItemContainer>
                        {fundChooserVisibility() && (
                            <FormInputDropDown
                                label={t('fund_movement.fundCommonInput.fromFund')}
                                control={control}
                                name={'fromFund'}
                                dropDownFor={DropDownConstants.TYPE_FUNDS}
                            />
                        )}
                        {fundChooserVisibility() && (
                            <FormInputDropDown
                                label={t('fund_movement.fundCommonInput.toFund')}
                                control={control}
                                name={'toFund'}
                                dropDownFor={DropDownConstants.TYPE_FUNDS}
                            />
                        )}

                        <FormInputDropDown
                            label={t('fund_movement.fundCommonInput.selectBuilding')}
                            control={control}
                            name={'buildingId'}
                            dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                        />
                        <FormInputText
                            label={t('fund_movement.fundCommonInput.amount')}
                            control={control}
                            name={'amount'}
                        />
                        <FormInputMonthPicker
                            label={t('fund_movement.fundCommonInput.month')}
                            control={control}
                            name={'month'}
                        />
                        <FormInputTextArea
                            label={t('fund_movement.fundCommonInput.description')}
                            control={control}
                            name={'description'}
                        />
                    </FormItemContainer>
                </FormImageWithInfoContainer>
            ) : (
                <>
                    <FormDoubleItemContainer>
                        {fundChooserVisibility() && (
                            <FormInputDropDown
                                label={t('fund_movement.fundCommonInput.fromFund')}
                                control={control}
                                name={'fromFund'}
                                dropDownFor={DropDownConstants.TYPE_FUNDS}
                            />
                        )}
                        {fundChooserVisibility() && (
                            <FormInputDropDown
                                label={t('fund_movement.fundCommonInput.toFund')}
                                control={control}
                                name={'toFund'}
                                dropDownFor={DropDownConstants.TYPE_FUNDS}
                            />
                        )}
                    </FormDoubleItemContainer>

                    <FormItemContainer triple>
                        <FormInputDropDown
                            label={t('fund_movement.fundCommonInput.selectBuilding')}
                            control={control}
                            name={'buildingId'}
                            dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                        />
                        <FormInputText
                            label={t('fund_movement.fundCommonInput.amount')}
                            control={control}
                            name={'amount'}
                        />
                        <FormInputMonthPicker
                            label={t('fund_movement.fundCommonInput.month')}
                            control={control}
                            name={'month'}
                        />
                    </FormItemContainer>
                    <FormInputTextArea
                        label={t('fund_movement.fundCommonInput.description')}
                        control={control}
                        name={'description'}
                    />
                </>
            )}

            <ButtonFormSubmit
                title={getButtonTitle()}
                isRequesting={isRequesting}
                control={control}
                onSubmit={onSubmit}
            />
        </FormIconContainer>
    )
}
