import BaseRequest from '../../../special/models/BaseRequest'

export default class GetAllPoliceFormRequest extends BaseRequest {
    fullName = ''
    mobileNumber = ''
    nidNumber = ''
    email = ''
    division = ""
    thana = ""

    constructor(data) {
        super()
        this.update(data)
    }
}