import BaseRequestWithoutPerspective from '../../../special/models/BaseRequestWithoutPerspective'

export default class GetUserDetailsRequest extends BaseRequestWithoutPerspective {
    userId = ''
    deviceToken = 'WEB_PORTAL'
    deviceType = 'BROWSER'
    firebaseIdToken = ''
    constructor(data) {
        super()
        if (data) this.userId = data.userId
        this.deviceToken = localStorage.getItem('deviceToken')
            ? localStorage.getItem('deviceToken')
            : 'default'
    }
}
