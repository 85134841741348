import { decode } from 'base-64'
import React from 'react'
import { useParams } from 'react-router-dom'
import PaymentFail from './PaymentFail'
import PaymentSuccess from './PaymentSuccess'

export default function PaymentStatus() {
    const { encryptedData } = useParams()

    try {
        const decodedData = JSON.parse(decode(encryptedData))
        var { orderId, tran_id, amount, status } = decodedData
    } catch (error) {}

    // console.log(decodedData)
    return (
        <>
            {status === 'SUCCESS' ? (
                <PaymentSuccess orderId={orderId} amount={amount} tran_id={tran_id} />
            ) : (
                <PaymentFail orderId={orderId} />
            )}
        </>
    )
}
