import BaseReducer from '../../../utils/BaseReducer'
import EntranceAction from './EntranceAction'

export default class EntranceReducer extends BaseReducer {
    initialState = {
        parcelList: null,
        visitorList: null,
        vehicleList: null
    };

    [EntranceAction.REQUEST_GET_VISITORS_FINISHED](state, action) {
        return {
            ...state,
            visitorList: action.payload
        }
    }

    [EntranceAction.REQUEST_GET_PARCELS_FINISHED](state, action) {
        return {
            ...state,
            parcelList: action.payload
        }
    }

    [EntranceAction.REQUEST_GET_VEHICLES_FINISHED](state, action) {
        return {
            ...state,
            vehicleList: action.payload
        }
    }
}
