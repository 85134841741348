import { createSelector } from 'reselect'

export class FormsSelector {
    static getAllUsersFormList = (state) => state.forms.usersFormList
    static getPoliceFormList = (state) => state.forms.policeFormList
}

export const makeSelectUsersFormList = createSelector(FormsSelector.getAllUsersFormList, (usersForm) =>
usersForm ? usersForm.data: []
)
export const makeSelectPoliceFormList = createSelector(FormsSelector.getPoliceFormList, (policeForm) =>
policeForm ? policeForm.data && policeForm.data.map(item=>item.forms && item.forms.policeForm && item.forms.policeForm): []
)
// export const makeSelectPoliceFormList = createSelector(FormsSelector.getPoliceFormList, (policeForm) =>
// policeForm ? policeForm.data && policeForm.data.forms && policeForm.data.forms.policeForm && policeForm.data.forms.policeForm: []
// )
