import BaseRequest from '../../../special/models/BaseRequest'

export default class CreateMeetingRequest extends BaseRequest {
    title = ''
    agenda = ''
    date = ''
    buildingId = ''
    invitedMembersIdList = []

    constructor(data) {
        super()
        this.update(data)
    }
}
