import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class FlatEffect {
    static async _requestGetBillPackageOfFlat(data) {
        const endPoint = ApiEndpoint.flat.getBillPackage
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestGetFlatList(data) {
        const endPoint = ApiEndpoint.flat.getFlats
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestCreateFlat(data) {
        const endPoint = ApiEndpoint.flat.createNew
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestCreateMultipleFlat(data) {
        const endPoint = ApiEndpoint.flat.createMultipleFlat
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestDeleteFlat(data) {
        const endPoint = ApiEndpoint.flat.deleteFlat
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestQrCodeOfFlat(data) {
        const endPoint = ApiEndpoint.flat.getQrCode
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestSendQrCodeOfFlat(data) {
        const endPoint = ApiEndpoint.flat.sendQrCode
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data));
    }
}