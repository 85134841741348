import { message } from 'antd'
import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import CartAction from './CartAction'

const CartPostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case CartAction.REQUEST_DELETE_CART_ITEM_FINISHED:
                next(MiscAction._showModalSuccess('Package Removed Successfully'))
            default:
                break
        }
    }

    return next(action)
}
export default CartPostEffect
