import { applyMiddleware, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import rootReducer from './rootReducer'
import errorToastMiddleware from './special/middlewares/errorToastMiddleware'
import storage from 'redux-persist/lib/storage'
import CommunityPostEffect from './property-management/community/CommunityPostEffect'
import BuildingPostEffect from './property-management/building/BuildingPostEffect'
import FlatPostEffect from './property-management/flat/FlatPostEffect'
import UserPostEffect from './user-management/user/UserPostEffect'
import PrivilegePostEffect from './user-management/privilege/PrivilegePostEffect'
import NoticePostEffect from './admin-management/notice/NoticePostEffect'
import MeetingPostEffect from './admin-management/meeting/MeetingPostEffect'
import ComplainPostEffect from './admin-management/complain/ComplainPostEffect'
import EntrancePostEffect from './security-management/entrance/EntrancePostEffect'
import PreAuthorizationPostEffect from './security-management/pre-authorization/PreAuthorizationPostEffect'
import BillPostEffect from './money-management/bill/BillPostEffect'
import ExpensePostEffect from './money-management/expense/ExpensePostEffect'
import FundPostEffect from './money-management/fund/FundPostEffect'
import VehiclePostEffect from './security-management/vehicle/VehiclePostEffect'
import FingerPrintPostEffect from './device-management/fingerprint-device/FingerPrintPostEffect'
import ReportPostEffect from './reporting/ReportPostEffect'
import NewPackagePostEffect from './rokkhi-admin/subscription/NewPackagePostEffect'
import DirectPaymentPostEffect from './money-management/direct-payment/DirectPaymentPostEffect'
import FormsPostEffect from './admin-management/forms/FormsPostEffect'
import CartPostEffect from './money-management/cart/CartPostEffect'

const persistConfig = {
    key: 'rokkhi-home-web',
    storage: storage,
    whitelist: ['user']
}

export default (initialState, history) => {
    const tempReducer = rootReducer(history)
    const persistedReducer = persistReducer(persistConfig, tempReducer)

    const store = createStore(
        persistedReducer,
        initialState,
        applyMiddleware(
            thunk,
            routerMiddleware(history),
            errorToastMiddleware(),

            CommunityPostEffect(),
            BuildingPostEffect(),
            FlatPostEffect(),
            PrivilegePostEffect(),
            UserPostEffect(),

            NoticePostEffect(),
            MeetingPostEffect(),
            ComplainPostEffect(),
            EntrancePostEffect(),
            PreAuthorizationPostEffect(),
            BillPostEffect(),
            ExpensePostEffect(),
            FundPostEffect(),
            VehiclePostEffect(),
            FingerPrintPostEffect(),
            ReportPostEffect(),
            NewPackagePostEffect(),
            DirectPaymentPostEffect(),
            FormsPostEffect(),
            CartPostEffect()
        )
    )
    let persistor = persistStore(store)
    return { store, persistor }
}
