import BaseRequest from '../../../special/models/BaseRequest'

export default class RegisterFingerPrintDeviceRequest extends BaseRequest {
    deviceName = ''
    company = ''
    type = ''
    purpose = ''
    macAddress = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
