import BaseRequest from '../../../special/models/BaseRequest'

export default class GetFlatsRequest extends BaseRequest {
    buildingId = ''

    constructor(data) {
        super()
        if(data && data.buildingId) this.buildingId = data.buildingId
    }
}
