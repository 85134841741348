import BaseReducer from '../../../utils/BaseReducer'
import FundAction from './FundAction'

export default class FundReducer extends BaseReducer {
    initialState = {
        fundList: null,
        transactionList: null,
        fundVouchers: null,
        balanceSheetData: null
    };

    [FundAction.REQUEST_GET_FUNDS_FINISHED](state, action) {
        return {
            ...state,
            fundList: action.payload
        }
    }

    [FundAction.REQUEST_GET_FUND_MOVEMENT_VOUCHERS_FINISHED](state, action) {
        return {
            ...state,
            fundVouchers: action.payload
        }
    }
    [FundAction.REQUEST_GET_BALANCE_SHEET_DATA_FINISHED](state, action) {
        return {
            ...state,
            balanceSheetData: action.payload
        }
    }

    [FundAction.REQUEST_GET_TRANSACTIONS_FINISHED](state, action) {
        return {
            ...state,
            transactionList: action.payload
        }
    }
}
