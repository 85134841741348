import { DatePicker, Form } from 'antd'
import React from 'react'
import * as moment from 'moment'

export default function FormInputDateTimePicker(props) {
    const isTouched = props.control.touched[`${props.name}`]
    const error = props.control.errors[`${props.name}`]
    const value = props.control.values[`${props.name}`]
    
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
      }
    return (
        <Form.Item
            label={props.label}
            hasFeedback
            validateStatus={isTouched ? (error ? 'error' : '') : ''}
            help={isTouched ? error : ''}
        >
            <DatePicker
                disabledDate={disabledDate}
                showTime={{ defaultValue: moment('08:00:00', 'HH:mm:ss') }}
                value={value ? moment(value) : null}
                onChange={(date, dateString) =>
                    props.control.handleInputChange(props.name, dateString)
                }
                placeholder={props.placeholder}
                size={'default'}
            />
        </Form.Item>
    )
}
