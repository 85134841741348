import { DatePicker, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import * as moment from 'moment'

export default function TableFilterMonthPicker(props) {
    const [selectedMonth, setSelectedMonth] = useState()

    const isTouched = props.control.touched[`${props.name}`]
    const error = props.control.errors[`${props.name}`]
    const value = props.control.values[`${props.name}`]

    const handleMonthChange = (date, dateString) => {
        props.control.handleInputChange(props.name, dateString)
    }

    const { MonthPicker } = DatePicker
    return (
        <MonthPicker
            format={'MM/YYYY'}
            value={value ? moment(value,'MM/YYYY') :null }
            style={{ width: '100%' }}
            onChange={handleMonthChange}
            size={'default'}
            placeholder={props.label ? props.label : 'Select Month'}
        />
    )
}
