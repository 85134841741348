import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class CommunityEffect {
    static async _requestGetAllCommunities(data) {
        const endPoint = ApiEndpoint.community.getAll
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestCreateCommunity(data) {
        const endPoint = ApiEndpoint.community.createNew
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestselfRegisterCommunity(data) {
        const endPoint = ApiEndpoint.community.selfRegisterCommunity
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestDeleteCommunity(data) {
        const endPoint = ApiEndpoint.community.deleteOne
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
