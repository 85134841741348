import React from 'react'
import FlatInvoicePdfBody from './FlatInvoicePdfBody'
import FlatInvoicePdfFooter from './FlatInvoicePdfFooter'
import FlatInvoicePdfHeader from './FlatInvoicePdfHeader'
import './flatInvoicePdf.css'

export const FlatInvoicePdf = React.forwardRef((props, ref) => {
    return (
        <div ref={ref} className='print-source'>
            <div>
                <FlatInvoicePdfHeader forWhom={'Management Copy'} data={props.data}></FlatInvoicePdfHeader>
                <FlatInvoicePdfBody data={props.data}></FlatInvoicePdfBody>
                <FlatInvoicePdfFooter></FlatInvoicePdfFooter>
                <hr></hr>
                <FlatInvoicePdfHeader forWhom={'Flat Owner Copy'} data={props.data}></FlatInvoicePdfHeader>
                <FlatInvoicePdfBody data={props.data}></FlatInvoicePdfBody>
                <FlatInvoicePdfFooter></FlatInvoicePdfFooter>
            </div>
        </div>
    )
})
