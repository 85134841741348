import BaseRequest from '../../../special/models/BaseRequest'

export default class GetUsersRequest extends BaseRequest {
    flatId = ''
    buildingId = ''
    userRoleCode = ''

    constructor(data, userRoleCode) {
        super()
        this.update(data)
        if (userRoleCode) this.userRoleCode = userRoleCode
    }
}
