import BaseRequest from '../../../special/models/BaseRequest'

export class AddToFundRequest extends BaseRequest {
    amount = ''
    description = ''
    date = ''
    buildingId = ''
    month = ''
    image = ''

    constructor(data) {
        super()
        this.update(data)
        this.amount = parseInt(data.amount)
        this.date = new Date(data.date)
    }
}
