import BaseRequest from '../../../special/models/BaseRequest'

export default class GetParcelsRequest extends BaseRequest {
    buildingId = ''
    flatId = ''
    fromDate = null
    toDate = null

    constructor(data) {
        super()
        this.update(data)

        if (data && data.dateRange && data.dateRange.length > 0) {
            this.fromDate = data.dateRange[0]
            this.toDate = data.dateRange[1]
        }
    }
}
