import BaseRequest from '../../../special/models/BaseRequest'

export class CreateBillTypeRequest extends BaseRequest {
    name = ''
    code = ''
    description = ''
    defaultAmount = ''
    buildingId = ''

    constructor(data) {
        super()
        this.update(data)
        this.defaultAmount = parseInt(data.defaultAmount)
    }
}
