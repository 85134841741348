import { ApiEndpoint } from "../../../assets/constants/ApiEndpoint";
import EffectUtility from "../../../utils/EffectUtility";
import BaseRequest from "../../special/models/BaseRequest";
import ServerResponse from "../../special/models/ServerResponse";


export default class DirectPaymentEffect {
    static async _requestDirectPayment(data) {
        const endpoint = ApiEndpoint.subscription.directPayment
        return EffectUtility._postToModel(ServerResponse, endpoint, new BaseRequest(data))
    }
}