import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DropDownConstants } from '../../../assets/constants/GeneralConstants'
import PrivilegeAction from '../../../stores/user-management/privilege/PrivilegeAction'
import BaseRequest from '../../../stores/special/models/BaseRequest'
import { selectDropDownItems } from '../../../selectors/DropDownDataSelector'
import CommunityAction from '../../../stores/property-management/community/CommunityAction'
import Select from 'react-select'

export default function TableFilterDropDown(props) {
    const dispatch = useDispatch()

    useEffect(() => {
        switch (props.dropDownFor) {
            case DropDownConstants.TYPE_USER_ROLE:
                dispatch(PrivilegeAction._requestGetUserRoles(new BaseRequest()))
                break
            case DropDownConstants.TYPE_COMMUNITY:
                dispatch(CommunityAction._requestGetCommunityList(new BaseRequest()))
                break
            default:
                break
        }
    }, [])

    const dropDownItems = useSelector((state) => selectDropDownItems(state, props.dropDownFor))
    // const isTouched = props.control.touched[`${props.name}`]
    // const error = props.control.errors[`${props.name}`]
    const value = dropDownItems.find((item) => item.value === props.control.values[`${props.name}`])
    return (
        <Select
            disabled={props.disabled}
            isDisabled={props.disabled}
            onChange={(selectedValue) => {
                props.control.handleInputChange(props.name, selectedValue.value)
            }}
            className='basic-single'
            placeholder={
                props.isFirstValueDefault
                    ? dropDownItems.length === 1
                        ? dropDownItems[0].label
                        : props.label
                    : props.label
            }
            value={
                props.flatOption
                    ? props.control.values['flatId'] !== undefined
                        ? value
                        : []
                    : value
            }
            isLoading={props.isLoading}
            // isClearable={true}
            // validateStatus={(isTouched ?  error? "error": "success" : "")}
            isSearchable={true}
            options={
                !props.isLoading
                    ? dropDownItems.sort((a, b) =>
                          a.label.localeCompare(b.label, undefined, { numeric: true })
                      )
                    : []
            }
        ></Select>
    )
}
