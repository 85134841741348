import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import { ColorConstants, DropDownConstants } from '../../../assets/constants/GeneralConstants'
import FormInputDropDown from '../../component/form-input/FormInputDropDown'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { NoticeWhiteIcon } from '../../misc/IconsProvider'
import { FormIconContainer, FormItemContainer } from '../FormStyles'
import NewPackageAction from '../../../stores/rokkhi-admin/subscription/NewPackageAction'
import CreateNewPackageRequest from '../../../stores/rokkhi-admin/subscription/request/CreateNewPackageRequest'

const INITIAL_STATE = {
    name: '',
    info: '',
    type: '',
    plan: '',
    price: null,
    duration: '',
    month: '',
    // invitedMembersIdList: []
}

const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string()
        .required('Name is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Max 40 Characters'),
    price: Yup.number()
        .typeError('Price must be Digit')
        .required('Price is required.')
        .min(0, 'Minimum price 1')
        .max(100000000, 'Maximum price 100000000'),
    month: Yup.number().typeError('Month must be Digit').required('Month is required')
    // buildingId: Yup.string().required('Building is Required')
})

export default function FormCreateNewPackage(props) {
    const dispatch = useDispatch()

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [NewPackageAction.REQUEST_CREATE_PACKAGE])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, NewPackageAction.REQUEST_CREATE_PACKAGE)
    )

    useEffect(() => control.resetData(), [isFinished]);

    const onSubmit = () => {
        dispatch(NewPackageAction._requestCreatePackage(new CreateNewPackageRequest(values)))
        // console.log(new CreateNewPackageRequest(values));
    }

    return (
        <FormIconContainer>
            <FormTitleWithIcon
                icon={<NoticeWhiteIcon />}
                color={ColorConstants.GREEN1}
                formTitle={'Create New Package'}
            />
            <FormItemContainer>
                <FormInputText label={'Name'} control={control} name={'name'} />
                <FormInputText label={'Info'} control={control} name={'info'} />
                <FormInputText label={'Type'} control={control} placeholder={'Security, Management'} name={'type'} />
                <FormInputText label={'Price'} control={control} name={'price'} />
                <FormInputDropDown
                    label={'Select Status'}
                    control={control}
                    name={'status'}
                    dropDownFor={DropDownConstants.TYPE_PACKAGE_STATUS}
                />
                <FormInputDropDown
                    label={'Select Plan'}
                    control={control}
                    name={'plan'}
                    dropDownFor={DropDownConstants.TYPE_PACKAGE_PLAN}
                />
                <FormInputText label={'Duration'} placeholder={'Monthly, Yearly'} control={control} name={'duration'} />
                <FormInputText label={'Month'} control={control} name={'month'} />
            </FormItemContainer>

            <ButtonFormSubmit isRequesting={isRequesting} control={control} onSubmit={onSubmit} />
        </FormIconContainer>
    )
}
