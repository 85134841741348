import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'
import BaseRequestWithoutPerspective from '../../special/models/BaseRequestWithoutPerspective'

export default class UserEffect {
    static async _requestGetUserDetails(data) {
        const endPoint = ApiEndpoint.user.getUserDetails
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequestWithoutPerspective(data))
    }

    static async _requestGetUserList(data) {
        const endPoint = ApiEndpoint.user.getUsersList
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestRegisterUser(data) {
        const endPoint = ApiEndpoint.user.register
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestDeleteUser(data) {
        const endPoint = ApiEndpoint.user.delete
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetFunctionsOfUser(data) {
        const endPoint = ApiEndpoint.user.getFunctionsOfUser
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetRolesOfUser(data) {
        const endPoint = ApiEndpoint.user.getRolesOfUser
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestGetDashboardOfUser(data) {
        const endPoint = ApiEndpoint.dashboard.getDash
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
