import BaseRequest from '../../../../special/models/BaseRequest'

export class DeleteExpenseVoucherRequest extends BaseRequest {
    voucherId = ''

    constructor(voucherId) {
        super()
        this.voucherId = voucherId
    }
}
