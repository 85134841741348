import React from 'react'
import FormCreateCommunity from '../../forms/property/FormCreateCommunity'
import { AppRoutes } from '../../../assets/constants/routes'
import FormCreateBuilding from '../../forms/property/FormCreateBuilding'
import FormCreateFlat from '../../forms/property/FormCreateFlat'
import FormRegisterFlatMember from '../../forms/user/FormRegisterFlatMember'
import FormCreateMultipleFlat from '../../forms/property/FormCreateMultipleFlat'

export default function PageProperty(props) {
    switch (props.route) {
        case AppRoutes.CREATE_BUILDING:
            return <FormCreateBuilding />

        case AppRoutes.CREATE_COMMUNITY:
            return <FormCreateCommunity />

        case AppRoutes.CREATE_FLAT:
            return <FormCreateFlat />
        case AppRoutes.CREATE_MULTIPLE_FLAT:
            return <FormCreateMultipleFlat />
        case AppRoutes.DYNAMIC_ADD_FLAT_MEMBER:
            return <FormRegisterFlatMember />
        default:
            return <div></div>
    }
}
