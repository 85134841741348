import { WarningFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppRoutes } from '../../assets/constants/routes';
import { getCookie, getDayDiff, setCookie } from '../../utils/functions/functions';

const ModalSubscriptionWarning = ({userDetails}) => {
    const [visibility,setVisibility] = useState(false);
    const [expireDays, setExpireDays] = useState(null);
    const [showAlertToday, setShowAlertToday] = useState(getCookie('ex_days')?JSON.parse(getCookie('ex_days')):false)
    useEffect(()=>{
        if(userDetails.data.licenseExpireDate){
            setExpireDays(getDayDiff(new Date(userDetails.data.licenseExpireDate) , new Date()))
        }
    },[userDetails])
    const dispatch = useDispatch()
    const renewSubscription = ()=>{
        dispatch(push(AppRoutes.PRICING_PLANS))
        setVisibility(false)
    }
    console.log("showAlertToday",showAlertToday);
    useEffect(()=>{
        if(expireDays && expireDays<15 && showAlertToday===false){
            console.log("expireDays",expireDays);
            setCookie('ex_days', true, 1)
            setVisibility(true)
        }
    },[expireDays])
    return  (
           <Modal
                type={'warning'}
                title={`Subscription Warning`}
                centered
                visible={visibility}
                // confirmLoading={isRequesting}
                onCancel={() => setVisibility(false)}
                cancelText={'Cancel'}
                okText={'Renew Now'}
                onOk={renewSubscription}
            >
                <div style={{textAlign:'center'}}>
                    <WarningFilled size={'large'} style={{ fontSize: '50px', color: '#e8b012' }}/>
                    <h2 style={{marginTop:'10px'}}>Your Subscription Will Expire in {expireDays} Days</h2>
                    <h3>Please Renew as soon as possible</h3>
                </div>
            </Modal>
    );
};

export default ModalSubscriptionWarning;