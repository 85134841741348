import ActionUtility from '../../../utils/ActionUtility'
import FingerPrintEffect from './FingerPrintEffect'

export default class FingerPrintAction {
    static REQUEST_REGISTER_FINGERPRINT_DEVICE = 'REQUEST_REGISTER_FINGERPRINT_DEVICE'
    static REQUEST_REGISTER_FINGERPRINT_DEVICE_FINISHED = 'REQUEST_REGISTER_FINGERPRINT_DEVICE_FINISHED'

    static REQUEST_DELETE_FINGERPRINT_DEVICE = 'REQUEST_DELETE_FINGERPRINT_DEVICE'
    static REQUEST_DELETE_FINGERPRINT_DEVICE_FINISHED = 'REQUEST_DELETE_FINGERPRINT_DEVICE_FINISHED'

    static REQUEST_GET_FINGERPRINT_DEVICES = 'REQUEST_GET_FINGERPRINT_DEVICES'
    static REQUEST_GET_FINGERPRINT_DEVICES_FINISHED = 'REQUEST_GET_FINGERPRINT_DEVICES_FINISHED'

    static REQUEST_GET_FINGERPRINTS = 'REQUEST_GET_FINGERPRINTS'
    static REQUEST_GET_FINGERPRINTS_FINISHED = 'REQUEST_GET_FINGERPRINTS_FINISHED'

    static REQUEST_DELETE_FINGERPRINT = 'REQUEST_DELETE_FINGERPRINT'
    static REQUEST_DELETE_FINGERPRINT_FINISHED = 'REQUEST_DELETE_FINGERPRINT_FINISHED'

    static REQUEST_ASSIGN_FINGERPRINT_TO_USER = 'REQUEST_ASSIGN_FINGERPRINT_TO_USER'
    static REQUEST_ASSIGN_FINGERPRINT_TO_USER_FINISHED = 'REQUEST_ASSIGN_FINGERPRINT_TO_USER_FINISHED'

    static _requestRegisterFingerPrintDevice(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FingerPrintAction.REQUEST_REGISTER_FINGERPRINT_DEVICE,
                FingerPrintEffect._requestRegisterFingerPrintDevice,
                data
            )
        }
    }

    static _requestDeleteFingerPrintDevice(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FingerPrintAction.REQUEST_DELETE_FINGERPRINT_DEVICE,
                FingerPrintEffect._requestDeleteFingerPrintDevice,
                data
            )
        }
    }

    static _requestGetFingerPrintDevices(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FingerPrintAction.REQUEST_GET_FINGERPRINT_DEVICES,
                FingerPrintEffect._requestGetFingerPrintDevices,
                data
            )
        }
    }

    static _requestGetFingerprints(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FingerPrintAction.REQUEST_GET_FINGERPRINTS,
                FingerPrintEffect._requestGetFingerprints,
                data
            )
        }
    }

    static _requestAssignFingerprintToUser(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FingerPrintAction.REQUEST_ASSIGN_FINGERPRINT_TO_USER,
                FingerPrintEffect._requestAssignFingerprintToUser,
                data
            )
        }
    }
}
