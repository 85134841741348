import React from 'react'
import { TableTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import FormCreateFingerprintDevice from '../../forms/device/FormCreateFingerprintDevice'
import { ListWhiteIcon } from '../../misc/IconsProvider'
import TableFingerprintDevice from '../../tables/device/TableFingerprintDevice'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'

export default function PageFingerprintDevice() {
    return (
        <PageBasicContainer>
            <FormCreateFingerprintDevice />
            <PageMainBarContainer>
             <TableTitleWithIcon icon={<ListWhiteIcon />} formTitle={'Registered Devices'} />
             <TableFingerprintDevice />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
