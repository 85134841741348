import React from 'react'

export const DEFAULT = '/'
export const PLAY = '/play'

export const ADD_NOTICES = '/addnotice'
export const ADD_ADMIN = '/add-admin'
export const GUARDS = '/guards'
export const FLATS = '/flats'
export const ADD_GUARD = '/addguard'
export const REQUESTS = '/requests'
export const CREATE_COMMUNITY = '/create-community'
export const CREATE_ROLE = '/create-role'
export const ADMINS = '/admins'
export const SERVICE_WORKERS = '/serviceworkers'

export const CALCULATIONS = '/calc'
export const LEDGER = '/ledger'
export const COMMON_FUND = '/common-fund'
export const BUILDING_USERS = '/building-users'
export const ADD_CALCULATIONS = '/addcalculation'
export const ADD_BUILDING_FUND = '/addBuildingFund'
export const ADD_PACKAGE = '/add-package'

export const PROFILE = '/profile'

export const PASSWORDCHANGE = '/change-password'
export const EMPTY_FLAT = '/emptyflat'

export const BUILDING_PROFILE = '/building_profile'
export const COMMUNITY_PROFILE = '/community_profile'

export class AppRoutes {
    static DEFAULT = '/'
    static PERSPECTIVE = '/perspective'
    static USER_DASHBOARD = '/dashboard'
    static FORGOT_PASSWORD = '/reset-password'
    static PROFILE = '/profile'
    static CREATE_COMMUNITY = '/create-community'
    static COMMUNITIES = '/communities'
    static CREATE_BUILDING = '/create-building'
    static BUILDINGS = '/buildings'
    static CREATE_FLAT = '/create-flat'
    static CREATE_MULTIPLE_FLAT = '/create-multiple-flat'
    static FLATS = '/flats'
    static ADD_FLAT_MEMBER = '/flat/add-member'
    static DYNAMIC_ADD_FLAT_MEMBER = '/flat/add-member/:encryptData'
    static SEE_FLAT_MEMBER = '/flat/view-user'
    static DYNAMIC_SEE_FLAT_MEMBER = '/flat/view-user/:encryptData'
    static QRCODE = '/qr-codes'
    static USER_ROLE = '/user-role'
    static USER_FUNCTION = '/user-function'
    static ASSIGN_FUNCTION_TO_ROLE = '/assign-funciton-to-role'
    static ASSIGN_ROLE_TO_USER = '/assign-role-to-user'

    static REGISTER_USER = '/register-user'
    static VIEW_USER = '/view-user'
    static ADD_TO_PRE_AUTHORIZED_LIST = '/add-to-pre-authorized-list'
    static ADD_TO_WHITE_LIST = '/add-to-white-list'
    static ADD_TO_BLACK_LIST = '/add-to-black-list'
    static VIEW_PRE_AUTHORIZED_LIST = '/view-preauthorized-list'

    static ADD_VEHICLE = '/add-vehicle'
    static VEHICLE_LIST = '/vehicles-list'
    static VEHICLE_ENTRY_EXIT = '/vehicle-exit-entry'

    static FINGERPRINT_DEVICE = '/add-fingerprint-device'
    static ASSIGN_FINGERPRINT = '/assign-fingerprint'

    static FUNDS = '/funds'
    static BALANCE_SHEET = '/balance-sheet'
    static ADD_TO_FUND = '/add-to-fund'
    static WITHDRAW_FROM_FUND = '/withdraw-from-fund'
    static FUND_TRANSFER = '/fund-transfer'
    static FUND_DASHBOARD = '/fund-dashboard'

    static BILL_TYPES = '/bill-types'
    static BILL_DETAILS = '/bills-details'
    static BILL_DASHBOARD = '/bill-dashboard'
    static BILL_GENERATION = '/bill-generation'
    static BILL_GENERATION_BACKUP = '/bill-generation-new'
    static BILL_PACKAGE = '/bill-package'

    static EXPENSE_TYPES = '/expense-types'
    static EXPENSE_DASHBOARD = '/expense-dashboard'
    static EXPENSE_VOUCHER_GENERATION = '/expense-voucher-generation'
    static EXPENSE_VOUCHER_AUTHORIZATION = '/expense-voucher-authorization'
    static EXPENSE_RECORD = '/expense-generation'

    static LEDGER_REPORT = '/ledger-report'
    static INCOME_REPORT = '/income-report'
    static EXPENSE_REPORT = '/expense-report'
    static INCOME_STATEMENT = '/income-statement'
    static CASH_FLOW_REPORT = '/ledger-report'

    static PARCELS = '/parcels'
    static VEHICLES = '/vehicles'
    static CHILDS = '/childs'

    static CREATE_MEETING = '/create-meeting'
    static CREATE_NOTICE = '/create-notice'
    static CREATE_COMPLAIN = '/create-complain'

    static NOTICES = '/notices'
    static VISITORS = '/visitors'
    static COMPLAINS = '/complains'
    static MEETINGS = '/meetings'
    static POLICE_FORMS = '/police-form'
    static ALL_FORMS = '/forms'
    static PRICING_PLANS = '/pricing-plans'
    static POLICE_FORM = '/police-form/add'
    static DYNAMIC_CREATE_POLICE_FORM = '/police-form/add/:encryptedData'

    static CREATE_PACKAGE = '/create-package'
    static PAYMENT = '/payment'
    static DYNAMIC_PAYMENT = '/payment/:encryptedData'
    static DIRECT_PAYMENT = '/direct-payment'
    static DIRECT_PAYMENT_DYNAMIC = '/direct-payment/:encryptedData'
    static TENANT_FORM = '/tenant-form/add'
    // static TENANT_DEPARTURE_FORM = '/departure-form'
    static MODAL_TENANT = '/modal-tenant'
    static CREATE_TENANT_FORM = '/tenant-form/add'
    static DYNAMIC_CREATE_TENANT_FORM = '/tenant-form/add/:encryptedData'
    static CREATE_TENANT_DEPARTURE_FORM = '/departure-form/add'
    static DYNAMIC_CREATE_TENANT_DEPARTURE_FORM = '/departure-form/add/:encryptedData'
    static ADMIN_NID_VERIFICATION = '/nid-verification'
    static BILL_GENERATE_REPORT = '/bill-generate-report'
    static REQUESTED_BILL_GENERATION = '/requested-bill-generation'
}
