import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import BillAction from '../../../stores/money-management/bill/BillAction'
import { makeSelectBillPackagesList } from '../../../stores/money-management/bill/BillSelector'
import BaseRequest from '../../../stores/special/models/BaseRequest'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import ModalAssignBillPackage from '../../modals/ModalAssignBillPackage'
import ModalBillPackageItems from '../../modals/ModalBillPackageItems'
import { BlueBackgroundTag } from '../../component/misc/Tags'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'

export default function TableBillPackages() {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectBillPackagesList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [BillAction.REQUEST_GET_BILL_ITEMS])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, [BillAction.REQUEST_CREATE_BILL_PACKAGE])
    )
    useEffect(() => {
        dispatch(BillAction._requestGetBillPackages(new BaseRequest()))
    }, [isFinished])

    const columns = [
        {
            title: '#',
            align: 'center',
            // key: 'index',
            render: (text, record, index) => <BlueBackgroundTag text={index + 1} />,
        },
        {
            title: 'Package Name',
            dataIndex: 'name'
        },
        {
            title: 'Package Amount',
            dataIndex: 'totalAmount'
        },
        {
            title: 'Details',
            align: 'center',
            render: (text, record) => <ModalBillPackageItems data={record} key={record.id} />
        },
        {
            title: 'Assign To Flats',
            align: 'center',
            render: (text, record) => (
                <ModalAssignBillPackage packageId={record.id} key={record.id} />
            )
        },
        {
            title: 'Action',
            align: 'center',
            render: (text, record) => (
                <ModalDeleteConfirmation
                    tooltip={'Delete Package'}
                    confirmationText={'Are you sure you want to delete this package ?'}
                    onConfirm={() =>
                        console.log('hello')
                    }
                />
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
