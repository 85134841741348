import React, { useEffect, useState } from 'react'
import { EntranceStatusConstants } from '../../../assets/constants/GeneralConstants'
import { Tabs } from 'antd'
import { CardInfoColoredBoxIcon } from '../../component/decoration/CardSingleInfo'
import { DataBuilderUtility } from '../../../utils/DataBuilderUtility'
import { useDispatch, useSelector } from 'react-redux'
import TableDataFilter from '../../tables/TableDataFilter'
import EntranceAction from '../../../stores/security-management/entrance/EntranceAction'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import GetVehicleEntryRequest from '../../../stores/security-management/entrance/requests/GetVehicleEntryRequest'
import TableVehicleEntryExit from '../../tables/vehicle/TableVehicleEntryExit'
import { makeSelectVehiclesList } from '../../../stores/security-management/entrance/EntranceSelector'
import { PageBasicContainer, PageDecorationContainer, PageMainBarContainer } from '../PageStyles'

const { TabPane } = Tabs
export default function PageVehicleEntryExit(props) {
    const dispatch = useDispatch()
    const [status, setStatus] = useState(EntranceStatusConstants.ALL)
    const [filterValues, setFilterValues] = useState(null)
    const isFinished = useSelector((state) =>
        selectFinished(state, EntranceAction.REQUEST_MARK_VEHICLE_EXIT)
    )

    const dataList = useSelector(makeSelectVehiclesList)

    const decorationArr = DataBuilderUtility._getEntranceDataOverview(dataList)

    const statusChanged = (status) => setStatus(status)

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        dispatch(
            EntranceAction._requestGetVehicles(new GetVehicleEntryRequest(filterValues, status))
        )
    }, [filterValues, status, isFinished])

    const decorationItems = decorationArr.map((item) => (
        <CardInfoColoredBoxIcon key={item.dataTitle} data={item} />
    ))

    return (
        <PageBasicContainer>
            <PageDecorationContainer item={3}>{decorationItems}</PageDecorationContainer>

            <PageMainBarContainer>
                <TableDataFilter
                    showBuildingFilter={true}
                    showDateRangeFilter={true}
                    showFlatFilter={true}
                    sendDataToParent={receiveFilterData}
                />

                <Tabs onChange={statusChanged} type='card'>
                    <TabPane tab='All' key={EntranceStatusConstants.ALL} />
                    <TabPane tab='Inside' key={EntranceStatusConstants.INSIDE_COMPOUND} />
                    <TabPane tab='Outside' key={EntranceStatusConstants.OUTSIDE_COMPOUND} />
                </Tabs>
                <TableVehicleEntryExit />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
