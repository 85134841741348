import { ExpenseAuthorizationStatusConstants } from '../../../../../assets/constants/GeneralConstants'
import BaseRequest from '../../../../special/models/BaseRequest'

export class GetExpenseVouchersRequest extends BaseRequest {
    buildingId = ''
    authorizationStatus = ''
    fromDate = ''
    toDate = ''

    constructor(data) {
        super()
        this.update(data)
        if (data && data.dateRange && data.dateRange.length > 0) {
            this.fromDate = data.dateRange[0]
            this.toDate = data.dateRange[1]
        }
        if(data && data.authorizationStatus!==ExpenseAuthorizationStatusConstants.ALL){
            this.authorizationStatus=data.authorizationStatus
        }else{
            this.authorizationStatus=''
        }
    }
}
