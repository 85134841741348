import BaseReducer from '../../../utils/BaseReducer'
import VehicleAction from './VehicleAction'

export default class VehicleReducer extends BaseReducer {
    initialState = {
        vehicleList: null
    };

    [VehicleAction.REQUEST_GET_VEHICLES_FINISHED](state, action) {
        return {
            ...state,
            vehicleList: action.payload
        }
    }
}
