import React, { useEffect, useState } from 'react'
import { message, Modal, Spin } from 'antd'
import * as Yup from 'yup'
import QRCode from 'qrcode.react'
import { makeSelectFlatsQrCode } from '../../stores/property-management/flat/FlatSelector'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../stores/special/requesting/RequestingSelector'
import FlatAction from '../../stores/property-management/flat/FlatAction'
import FlatDetailsRequest from '../../stores/property-management/flat/request/FlatDetailsRequest'
import RokkhiLogo from '../../assets/images/rokkhi_logo.png'
import { ButtonFillBlue, ButtonFillGreen, ButtonMildRed } from '../component/buttons/CustomButtons'
import SendQrCodeRequest from '../../stores/property-management/flat/request/SendQrCodeRequest'
import useFormInputValidation from '../component/form-input/useFormInputValidation'
import { FormInputText } from '../component/form-input/FormInputText'

const initData = {
    email: ''
}

export default function ModalQrCode(props) {
    const VALIDATION_SCHEMA = Yup.object({
        email: Yup.string().email('Please provide a valid email').required('Email is required')
    })
    const dispatch = useDispatch()
    const [visibility, setVisibility] = useState(false)
    const [flatId, setFlatId] = useState(props.flatId)
    const [qrCodeId, setQrCodeId] = useState(0)
    const [emailView, setEmailView] = useState(false)
    const dataList = useSelector(makeSelectFlatsQrCode)

    const { control, values } = useFormInputValidation(initData, VALIDATION_SCHEMA)

    const userDetails = useSelector((state) => state.user.userDetails)

    const isRequesting = useSelector((state) =>
        selectRequesting(state, [FlatAction.REQUEST_QRCODE_OF_FLAT])
    )

    const request = useSelector((state) =>
        selectRequesting(state, [FlatAction.REQUEST_SEND_QRCODE_OF_FLAT])
    )
    useEffect(() => {
        visibility &&
            dispatch(FlatAction._requestQrCodeOfFlat(new FlatDetailsRequest({ flatId: flatId })))
    }, [visibility])
    useEffect(() => {
        dataList.length && setQrCodeId(dataList[0].id)
    }, [dataList])
    const print = () => {
        window.print()
        // const content = document.getElementById('printDoc')
        // const iframe = document.createElement('iframe')
        // iframe.setAttribute('title', 'uniqueIframeId')
        // iframe.setAttribute('id', 'uniqueIframeId')
        // iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
        // document.body.appendChild(iframe)
        // let pri = iframe.contentWindow
        // pri.document.open()
        // pri.document.write(content.innerHTML)
        // pri.document.close()
        // pri.focus()
        // pri.print()
    }

    const handleSubmit = () => {
        if (values.email && props.flatNumber && qrCodeId) {
            const imageUrl = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrCodeId}`
            dispatch(
                FlatAction._requestSendQrCodeOfFlat(
                    new SendQrCodeRequest({
                        to: values.email,
                        subject: `QR code for flat ${props.flatNumber}`,
                        image: imageUrl,
                        flatNumber: props.flatNumber,
                        iosQrCode: qrCodeId
                    })
                )
            )
            control.resetData()
        } else {
            message.error('Failed please try again!')
        }
    }

    return (
        <div>
            <div className='image-user-small' onClick={(e) => setVisibility(true)}>
                <QRCode key={flatId} style={{ width: '50px', height: '50px' }} value={''} />
            </div>
            <Modal
                key={flatId}
                title={'QrCode to Join Flat'}
                centered
                okText={'Close'}
                visible={visibility}
                onCancel={() => setVisibility(false)}
                onOk={() => setVisibility(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                footer={
                    <>
                        {/* <ButtonFillGreen  key={flatId}  text={'Email'} icon={'mail'} /> */}
                        {!emailView ? (
                            <>
                                <ButtonFillBlue
                                    key={flatId}
                                    onClick={print}
                                    text={'Print'}
                                    icon={'printer'}
                                />
                                <ButtonFillGreen
                                    icon={'mail'}
                                    text={'Send as e-mail'}
                                    onClick={() => setEmailView(true)}
                                />
                            </>
                        ) : (
                            <>
                                <FormInputText control={control} name='email' placeholder='Email' />
                                <ButtonFillGreen
                                    isRequesting={request}
                                    onClick={handleSubmit}
                                    text={'Send'}
                                />{' '}
                                <ButtonMildRed
                                    text={'Cancel'}
                                    onClick={() => setEmailView(false)}
                                />
                            </>
                        )}
                    </>
                }
            >
                {!isRequesting && qrCodeId ? (
                    <>
                        <div
                            onClick={(e) => setVisibility(true)}
                            style={{ textAlign: 'center', padding: '20px 0px' }}
                        >
                            <div className='qr-code-header'>
                                <h2>
                                    Scan this QR code to join <span>{props.flatNumber}</span> flat
                                </h2>
                                <h3>
                                    Flat Number: <span>{props.flatNumber}</span>
                                </h3>
                            </div>
                            <QRCode
                                key={qrCodeId}
                                style={{ alignSelf: 'center' }}
                                value={qrCodeId.toString()}
                            />
                        </div>
                        <div className='qr-code-footer'>
                            <h2>QR CODE ID (For IOS) : {qrCodeId}</h2>
                            <div className='logo-image'>
                                <img src={RokkhiLogo} alt='rokkhi-logo' />
                            </div>
                        </div>
                    </>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <Spin size='large' />
                    </div>
                )}
            </Modal>
        </div>
    )
}
