import BaseReducer from '../../../utils/BaseReducer'
import FlatAction from '../flat/FlatAction';
import BuildingAction from './BuildingAction'

export default class BuildingReducer extends BaseReducer {
    initialState = {
        buildingList: null,
        lastBuildingCreation : null
    };

    [BuildingAction.REQUEST_GET_BUILDINGS_FINISHED](state, action) {
        return {
            ...state,
            buildingList: action.payload
        }
    }
    [BuildingAction.REQUEST_CREATE_BUILDING_FINISHED](state, action) {
        return {
            ...state,
            lastBuildingCreation: action.payload
        }
    }
    [FlatAction.REQUEST_CREATE_MULTIPLE_FLAT_FINISHED](state, action) {
        return {
            ...state,
            lastBuildingCreation: null
        }
    }
}
