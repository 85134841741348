import React, { useEffect, useState } from 'react'
import { Page, View, Document, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer'
import InvoiceHeader from './InvoiceHeader'
import InvoiceFooter from './InvoiceFooter'
import { ButtonMildGreen } from '../buttons/CustomButtons'
import { getMonthNameAndYear } from '../../../utils/functions/functions'
import InvoiceBody from './InvoiceBody'
const FlatInvoice = (props) => {
    const [ready, setReady] = useState(false)
    const [click, setClick] = useState(false)
    useEffect(() => {
        click && document.getElementById('autoClickBtn').click()
        setReady(false)
        setClick(false)
    }, [click])
    return !ready ? (
        <ButtonMildGreen
            key={props.data.id}
            onClick={() => setReady(true)}
            text={'PDF'}
            icon={'printer'}
        />
    ) : (
        props.data && props.userDetails && (
            <PDFDownloadLink
                document={
                    <PDFDocument
                        data={props.data}
                        key={props.data.id}
                        communityName={props.userDetails.name}
                    />
                }
                fileName={`Invoice for ${props.data.flatName} ${getMonthNameAndYear(
                    props.data.month
                )}`}
            >
                {({ blob, url, loading, error }) =>
                    loading ? (
                        <ButtonMildGreen text={'Loading'} icon={'printer'} />
                    ) : (
                        <a target='_blank' href={url} id='autoClickBtn' rel='noreferrer'>
                            <ButtonMildGreen text={'PDF'} icon={'printer'} />
                            {setClick(true)}
                        </a>
                    )
                }
            </PDFDownloadLink>
        )
    )
}

export default FlatInvoice

function PDFDocument(props) {
    return (
        <Document>
            <Page size='A4' style={styles.body}>
                <View wrap={false}>
                    <InvoiceHeader
                        forWhom={'Management Copy'}
                        data={props.data}
                        key={props.data.id}
                        invoiceHeader={props.data.building.name}
                        communityName={props.communityName}
                        invoiceForFlat={true}
                    />
                    <InvoiceBody data={props.data} invoiceForFlat={true} />
                    <InvoiceFooter
                        item={['Community Authority', 'Building Authority', 'Flat Owner']}
                    />
                </View>
                <View style={styles.horizontalDivider}></View>
                <View wrap={false}>
                    <InvoiceHeader
                        forWhom={'Flat Owner Copy'}
                        key={props.data.id}
                        data={props.data}
                        invoiceHeader={props.data.building.name}
                        communityName={props.communityName}
                        invoiceForFlat={true}
                    />
                    <InvoiceBody data={props.data} invoiceForFlat={true} key={props.data.id} />
                    <InvoiceFooter
                        key={props.data.id}
                        item={['Community Authority', 'Building Authority', 'Flat Owner']}
                    />
                </View>
            </Page>
        </Document>
    )
}
const styles = StyleSheet.create({
    body: {
        fontFamily: 'Times-Roman',
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 25
    },
    horizontalDivider: {
        paddingTop: 15,
        marginBottom: 10
    }
})
