import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    BuildingIconBlack,
    BuildingIconWhite,
    CalenderIcon,
    CalenderIconWhite,
    FlatIcon,
    FlatIconWhite
} from '../misc/IconsProvider'
import { DropDownConstants, GreeneTheme } from '../../assets/constants/GeneralConstants'
import useFormInputValidation from '../component/form-input/useFormInputValidation'
import * as Yup from 'yup'
import FlatAction from '../../stores/property-management/flat/FlatAction'
import GetFlatsRequest from '../../stores/property-management/flat/request/GetFlatsRequest'
import BuildingAction from '../../stores/property-management/building/BuildingAction'
import GetBuildingsRequest from '../../stores/property-management/building/request/GetBuildingsRequest'
import TableFilterDropDown from '../component/form-input/TableFilterDropDown'
import { selectRequesting } from '../../stores/special/requesting/RequestingSelector'
import TableFilterDateRangePicker from '../component/form-input/TableFilterDateRangePicker'
import TableFilterMonthPicker from '../component/form-input/TableFilterMonthPicker'
import { PageDecorationContainer } from '../pages/PageStyles'
import { useTranslation } from 'react-i18next'
import { getCurrentMonthRange, getCurrentMonthWithYear } from '../../utils/functions/functions'
import { makeSelectBuildingsList } from '../../stores/property-management/building/BuildingSelector'

export default function TableDataFilter(props) {
    const buildings = useSelector(makeSelectBuildingsList)
    const INITIAL_STATE = {
        buildingId: '',
        flatId: '',
        dateRange: getCurrentMonthRange(),
        date: '',
        month: getCurrentMonthWithYear()
    }
    const VALIDATION_SCHEMA = Yup.object({})
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isRequestingBuildings = useSelector((state) =>
        selectRequesting(state, [BuildingAction.REQUEST_GET_BUILDINGS])
    )
    const isRequestingFlats = useSelector((state) =>
        selectRequesting(state, [FlatAction.REQUEST_GET_FLATS])
    )

    useEffect(() => {
        dispatch(BuildingAction._requestGetBuildingList(new GetBuildingsRequest()))
    }, [])

    useEffect(() => {
        dispatch(FlatAction._requestGetFlatList(new GetFlatsRequest(control.values)))
    }, [control.values.buildingId])

    useEffect(() => {
        props.sendDataToParent(values)
    }, [values])
    //For Auto Select First Building
    useEffect(() => {
        if (buildings.length === 1) {
            props.sendDataToParent({ ...values, buildingId: buildings[0].id })
            control.setValues({
                ...values,
                buildingId: buildings[0].id
            })
        }
    }, [buildings])
    useEffect(() => {
        control.setValues({
            ...values,
            flatId: undefined
        })
    }, [control.values.buildingId])
    return (
        <PageDecorationContainer item={3} style={{ padding: 10 }} paddingTop={'0px'}>
            {props.showBuildingFilter && (
                <div className='dropdown-basic-container'>
                    <div className='container-icon-dropdown background-black'>
                        <BuildingIconBlack />
                        <h1>Select Building</h1>
                    </div>
                    <TableFilterDropDown
                        isLoading={isRequestingBuildings}
                        label={t('table_data_filter.selectBuilding')}
                        control={control}
                        name={'buildingId'}
                        isFirstValueDefault={true}
                        dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                    />
                </div>
            )}

            {props.showStyledBuildingFilter && (
                <div className='dropdown-basic-container-styled'>
                    <div className='container-icon-dropdown-styled background-green'>
                        <BuildingIconWhite />
                    </div>
                    <div className='container-dropdown-content'>
                        <label>Buildings</label>
                        <TableFilterDropDown
                            isLoading={isRequestingBuildings}
                            label={t('table_data_filter.selectBuilding')}
                            control={control}
                            name={'buildingId'}
                            isFirstValueDefault={true}
                            dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                        />
                    </div>
                </div>
            )}

            {props.showFlatFilter && (
                <div className='dropdown-basic-container'>
                    <div className='container-icon-dropdown background-black'>
                        <FlatIcon />
                        <h1>Select Flat</h1>
                    </div>
                    <TableFilterDropDown
                        isLoading={isRequestingFlats}
                        label={t('table_data_filter.selectFlat')}
                        control={control}
                        name={'flatId'}
                        flatOption={true}
                        isFirstValueDefault={false}
                        dropDownFor={DropDownConstants.TYPE_FLATS_WITH_ALL}
                    />
                </div>
            )}
            {props.showStyledFlatFilter && (
                <div className='dropdown-basic-container-styled'>
                    <div
                        className='container-icon-dropdown-styled'
                        style={{ background: GreeneTheme.COLOR2 }}
                    >
                        <FlatIconWhite />{' '}
                    </div>
                    <div className='container-dropdown-content'>
                        <label> Flats</label>
                        <TableFilterDropDown
                            isLoading={isRequestingFlats}
                            label={t('table_data_filter.selectFlat')}
                            control={control}
                            name={'flatId'}
                            flatOption={true}
                            isFirstValueDefault={false}
                            dropDownFor={DropDownConstants.TYPE_FLATS_WITH_ALL}
                        />
                    </div>
                </div>
            )}

            {/*{props.showDateFilter &&*/}
            {/*<div className="dropdown-basic-container">*/}
            {/*    <div className="container-icon-dropdown background-black">*/}
            {/*        <CalenderIcon/>*/}
            {/*    </div>*/}
            {/*    <DateSelectorComponent dateFor={DateConstants.FOR_QUERY_ON_TABLE}/>*/}
            {/*</div>*/}
            {/*}*/}

            {props.showStyledDateRangeFilter && (
                <div className='dropdown-basic-container-styled'>
                    <div
                        className='container-icon-dropdown-styled'
                        style={{ background: GreeneTheme.COLOR3 }}
                    >
                        <CalenderIconWhite />
                    </div>
                    <div className='container-dropdown-content'>
                        <label> Date Range</label>
                        <TableFilterDateRangePicker
                            name={'dateRange'}
                            control={control}
                            label={t('table_data_filter.selectDate')}
                        />
                    </div>
                </div>
            )}

            {props.showDateRangeFilter && (
                <div className='dropdown-basic-container'>
                    <div className='container-icon-dropdown background-black'>
                        <CalenderIcon />
                        <h1>Select Date Range</h1>
                    </div>
                    <TableFilterDateRangePicker
                        name={'dateRange'}
                        control={control}
                        label={t('table_data_filter.selectDate')}
                        placeholder={{
                            startDate: t('table_data_filter.startDate'),
                            endDate: t('table_data_filter.endDate')
                        }}
                    />
                </div>
            )}

            {props.showMonthFilter && (
                <div className='dropdown-basic-container'>
                    <div className='container-icon-dropdown background-black'>
                        <CalenderIcon />
                        <h1>Select Month</h1>
                    </div>
                    <TableFilterMonthPicker
                        name={'month'}
                        control={control}
                        label={t('table_data_filter.selectMonth')}
                    />
                </div>
            )}

            {/*{props.showStyledMonthFilter &&*/}
            {/*<div className="dropdown-basic-container-styled">*/}
            {/*    <div className="container-icon-dropdown-styled background-magenta">*/}
            {/*        <CalenderIconWhite/>*/}
            {/*    </div>*/}
            {/*    <div className="container-dropdown-content">*/}
            {/*        <label> Date Range</label>*/}
            {/*        <MonthPickerComponent setDefaultMonth={ props.setDefaultMonth } monthFor={MonthConstants.FOR_QUERY_ON_TABLE}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*}*/}
        </PageDecorationContainer>
    )
}
