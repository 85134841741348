import { createSelector } from 'reselect'

export class ReportSelector {
    static getLedgerReportList = (state) => state.report.ledgerReport
    static getIncomeReportList = (state) => state.report.incomeReport
    static getExpenseReportList = (state) => state.report.expenseReport
    static getCashFlowReportList = (state) => state.report.cashFlowReport
    static getIncomeStatement = (state) => state.report.incomeStatement
}

export const makeSelectLedgerReportsList = createSelector(
    ReportSelector.getLedgerReportList,
    (report) => (report ? report.data : [])
)

export const makeSelectIncomeReportsList = createSelector(
    ReportSelector.getIncomeReportList,
    (report) => (report ? report.data : [])
)

export const makeSelectExpenseReportsList = createSelector(
    ReportSelector.getExpenseReportList,
    (report) => (report ? report.data : [])
)

export const makeSelectCashFlowReportsList = createSelector(
    ReportSelector.getCashFlowReportList,
    (report) => (report ? report.data : [])
)

export const makeSelectIncomeStatementReportsList = createSelector(
    ReportSelector.getIncomeStatement,
    (report) => (report ? report.data : { incomeByType: [], expenseByType: [] })
)
