import BaseRequest from '../../../special/models/BaseRequest'

export default class MeetingDetailsRequest extends BaseRequest {
    meetingId = ''

    constructor(data) {
        super()
        this.update(data)
        this.meetingId = data.id
    }
}
