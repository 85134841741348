import BaseRequest from '../../../special/models/BaseRequest'

export default class CreateUserFunctionRequest extends BaseRequest {
    name = ''
    code = ''
    description = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
