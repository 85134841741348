import React from 'react'
import { Table } from 'antd'
import { isMobile } from 'react-device-detect'

export default function ComponentTable(props) {
    return (
        <Table
            rowClassName={(record, index) =>
                index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
            }
            showHeader={true}
            bordered={true}
            columns={props.columns}
            dataSource={props.dataList}
            pagination={
                props.pagination === false
                    ? false
                    : {
                          defaultPageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: ['5', '10', '20', '30', '50']
                      }
            }
            size='small'
            rowKey={(data) => data.id}
            loading={props.isRequesting}
            scroll={{ x: !isMobile ? '' : 'max-content' }}
        />
    )
}
