import React from 'react'

const PoliceFormBodyElement = (props) => {
    return (
        <>
            <div className='form-element'>
                <div className='item-no'>{props.itemNo}</div>
                {props.multipleColumn ? (
                    <div className='emergency-section'>
                        <div className='emergency-title'>{props.title}</div>
                        <div
                            className='form-item-warp'
                            style={{ gridTemplateColumns: `repeat(${props.data.length}, 1fr)` }}
                        >
                            {props.data.map((item, index) => (
                                <div className='label-input-wrap' key={index}>
                                    <label>{item.label}</label>
                                    <input readOnly value={item.value}></input>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : props.tableTemplate ? (
                    <div className='table-section'>
                        <div className='table-title'>{props.title}</div>
                        <table>
                            <tr>
                                <td style={{ width: '7%' }}>ক্রম নং</td>
                                <td style={{ width: '35%' }}>নাম</td>
                                <td style={{ width: '10%' }}>বয়স</td>
                                <td style={{ width: '18%' }}>পেশা</td>
                                <td style={{ width: '24%' }}>মোবাইল নম্বর</td>
                            </tr>

                            {props.data.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1 + '.'}</td>
                                    <td>
                                        <input readOnly type='text' value={item.name} />
                                    </td>
                                    <td>
                                        <input readOnly type='number' value={item.age} />
                                    </td>
                                    <td>
                                        <input readOnly type='text' value={item.occu} />
                                    </td>
                                    <td>
                                        <input readOnly type='text' value={item.num} />
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                ) : (
                    <div className='emergency-section'>
                        <div className='emergency-title'>{props.title}</div>
                        <div
                            className='form-item-warp'
                            style={{ gridTemplateColumns: `repeat(${props.data.length}, 1fr)` }}
                        >
                            {props.data.map((item, index) => (
                                <div className='label-input-wrap' key={index}>
                                    <label>{item.label}</label>
                                    <input readOnly value={item.value}></input>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default PoliceFormBodyElement
{
    /* {props.totalItem === 1 && (
                <div className='form-element'>
                    <div className='item-no'>{props.itemNo}</div>
                    <div className='label-input-wrap'>
                        <label>{props.label}</label>
                        <input readOnly value={props.value}></input>
                    </div>
                </div>
            )} */
}
