import BaseReducer from '../../../utils/BaseReducer'
import ComplainAction from './ComplainAction'

export default class ComplainReducer extends BaseReducer {
    initialState = {
        complainList: null,
        complainDetails: null
    };

    [ComplainAction.REQUEST_GET_COMPLAIN_DETAILS_FINISHED](state, action) {
        return {
            ...state,
            complainDetails: action.payload
        }
    }

    [ComplainAction.REQUEST_GET_COMPLAINS_FINISHED](state, action) {
        return {
            ...state,
            complainList: action.payload
        }
    }
}
