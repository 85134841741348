import React, { useState } from 'react'
import { Modal } from 'antd'

export default function ModalImage({ imageUrl, imageTitle }) {
    const [visibility, setVisibility] = useState(false)

    return (
        <div>
            <img className='image-user-small'  alt="user" src={imageUrl} onClick={(e) => setVisibility(true)} />
            <Modal
                title={imageTitle}
                centered
                okText={'Close'}
                visible={visibility}
                onCancel={() => setVisibility(false)}
                onOk={() => setVisibility(false)}
            >
                <div style={{ display: 'grid' }}>
                    <img
                        style={{ justifySelf: 'center' }}
                        className='image-user-large'
                        src={imageUrl}
                        id={imageUrl}
                        onClick={(e) => setVisibility(true)}
                        alt="user"
                    />
                </div>
            </Modal>
        </div>
    )
}
