import BaseRequest from '../../../special/models/BaseRequest'

export default class RoleDetailsRequest extends BaseRequest {
    roleId = ''

    constructor(id) {
        super()
        this.roleId = id
    }
}
