import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import FlatAction from './FlatAction'

const FlatPostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case FlatAction.REQUEST_CREATE_FLAT_FINISHED:
            case FlatAction.REQUEST_CREATE_MULTIPLE_FLAT_FINISHED:
                next(MiscAction._showModalSuccess('Building & Flat Created Successfully'))
                break
            case FlatAction.REQUEST_DELETE_FLAT_FINISHED:
                next(MiscAction._showModalSuccess('Flat Deleted Successfully'))
                break
            case FlatAction.REQUEST_SEND_QRCODE_OF_FLAT_FINISHED:
                next(MiscAction._showModalSuccess('Successfully sent'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default FlatPostEffect
