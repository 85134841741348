import BaseRequest from '../../../special/models/BaseRequest'

export default class SingleFlatItem extends BaseRequest {
    name = ''
    number = ''

    constructor(flatNumber) {
        super()
        this.name = flatNumber
        this.number = flatNumber
    }
}
