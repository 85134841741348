import React from 'react'
import { AppRoutes } from '../../../assets/constants/routes'
import FormRegisterUser from '../../forms/user/FormRegisterUser'
import TableUsers from '../../tables/privilege/TableUsers'
import FormCreatePreAuthorizeEntry from '../../forms/user/FormCreatePreAuthorizeEntry'
import { AuthorizationStatusConstants } from '../../../assets/constants/GeneralConstants'

export default function PageUserManagement(props) {
    switch (props.route) {
        case AppRoutes.REGISTER_USER:
            return (
                    <FormRegisterUser />
            )
        case AppRoutes.VIEW_USER:
            return (
                    <TableUsers />
            )
        case AppRoutes.ADD_TO_WHITE_LIST:
            return (
                    <FormCreatePreAuthorizeEntry authorizationStatus={AuthorizationStatusConstants.WHITE_LISTED} />
            )
        case AppRoutes.ADD_TO_BLACK_LIST:
            return (
                    <FormCreatePreAuthorizeEntry authorizationStatus={AuthorizationStatusConstants.BLACK_LISTED} />
            )
        default:
            return <div></div>
    }
}
