import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ComplainStatusConstants } from '../../../assets/constants/GeneralConstants'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { makeSelectNoticeList } from '../../../stores/admin-management/notice/NoticeSelector'
import ComplainAction from '../../../stores/admin-management/complain/ComplainAction'
import GetComplainsRequest from '../../../stores/admin-management/complain/requests/GetComplainsRequest'
import TableComplain from '../../tables/admin/TableComplain'
import TableDataFilter from '../../tables/TableDataFilter'
import { Tabs } from 'antd'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'

const { TabPane } = Tabs

function PageComplains(props) {
    const dispatch = useDispatch()

    const [complainStatus, setComplainStatus] = useState(ComplainStatusConstants.ALL)
    const [filterValues, setFilterValues] = useState(null)

    const isFinished = useSelector((state) =>
        selectFinished(state, ComplainAction.REQUEST_DELETE_NOTICE)
    )

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    const changeComplainStatus = (status) => setComplainStatus(status)

    useEffect(() => {
        dispatch(
            ComplainAction._requestGetComplainList(
                new GetComplainsRequest(filterValues, complainStatus)
            )
        )
    }, [filterValues, complainStatus, isFinished])

    return (
        <>
            <PageBasicContainer>
                <TableDataFilter
                    showStyledDateRangeFilter={true}
                    showStyledBuildingFilter={true}
                    showStyledFlatFilter={true}
                    sendDataToParent={receiveFilterData}
                />
                <PageMainBarContainer>
                    <Tabs onChange={changeComplainStatus} type='card'>
                        <TabPane tab='All' key={ComplainStatusConstants.ALL} />
                        <TabPane tab='Active' key={ComplainStatusConstants.ACTIVE} />
                        {/* <TabPane tab='In Progress' key={ComplainStatusConstants.IN_PROGRESS} />
                        <TabPane tab='Resolved' key={ComplainStatusConstants.RESOLVED} /> */}
                    </Tabs>

                    <TableComplain />
                </PageMainBarContainer>
            </PageBasicContainer>
        </>
    )
}
export default withRouter(PageComplains)
