import { createSelector } from 'reselect'

export class ExpenseSelector {
    static getExpenseTypesList = (state) => state.expense.expenseTypes
    static getExpenseItemsList = (state) => state.expense.expenseItems
    static getExpenseVouchersList = (state) => state.expense.vouchersList
}

export const makeSelectExpenseVouchersList = createSelector(ExpenseSelector.getExpenseVouchersList, (Expense) =>
    Expense ? Expense.data : []
)

export const makeSelectExpenseTypesList = createSelector(ExpenseSelector.getExpenseTypesList, (Expense) =>
    Expense ? Expense.data : []
)

export const makeSelectExpenseItemsList = createSelector(ExpenseSelector.getExpenseItemsList, (expenseItemsList) =>
    expenseItemsList
        ? expenseItemsList.data.map((expenseItem) => {
              return {
                  ...expenseItem,
                  typeName: expenseItem.type.name
              }
          })
        : []
)
