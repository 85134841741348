import ActionUtility from '../../../utils/ActionUtility'
import NoticeEffect from './NoticeEffect'

export default class NoticeAction {
    static REQUEST_GET_NOTICES = 'REQUEST_GET_NOTICES'
    static REQUEST_GET_NOTICES_FINISHED = 'REQUEST_GET_NOTICES_FINISHED'

    static REQUEST_CREATE_NOTICE = 'REQUEST_CREATE_NOTICE'
    static REQUEST_CREATE_NOTICE_FINISHED = 'REQUEST_CREATE_NOTICE_FINISHED'

    static REQUEST_DELETE_NOTICE = 'REQUEST_DELETE_NOTICE'
    static REQUEST_DELETE_NOTICE_FINISHED = 'REQUEST_DELETE_NOTICE_FINISHED'

    static _requestCreateNotice(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                NoticeAction.REQUEST_CREATE_NOTICE,
                NoticeEffect._requestCreateNotice,
                data
            )
        }
    }

    static _requestDeleteNotice(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                NoticeAction.REQUEST_DELETE_NOTICE,
                NoticeEffect._requestDeleteNotice,
                data
            )
        }
    }
    static _requestGetNoticeList(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                NoticeAction.REQUEST_GET_NOTICES,
                NoticeEffect._requestGetNoticeList,
                data
            )
        }
    }
}
