import React, { useEffect } from 'react'
import { AppRoutes } from '../../../assets/constants/routes'

import FormCreateMeeting from '../../forms/admin/FormCreateMeeting'
import FormCreateNotice from '../../forms/admin/FormCreateNotice'
import FormCreateComplain from '../../forms/admin/FormCreateComplain'

export default function PageAdminManagement(props) {
    switch (props.route) {
        case AppRoutes.CREATE_MEETING:
            return <FormCreateMeeting />
        case AppRoutes.CREATE_NOTICE:
            return <FormCreateNotice />
        case AppRoutes.CREATE_COMPLAIN:
            return <FormCreateComplain />
        default:
            return <div></div>
    }
}
