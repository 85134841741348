import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { push } from 'connected-react-router'
import { Icon, Menu, Layout } from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import _ from 'lodash'
import SubmenuOptionModel from './SubMenuOptionModel'
import { UserFunctionConstants } from '../../../assets/constants/GeneralConstants'
import { AppRoutes } from '../../../assets/constants/routes'
import { makeSelectFunctionsOfUser } from '../../../stores/user-management/user/UserSelector'
import logo from '../../../assets/images/rokkhi_logo.png'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
const { Header, Sider } = Layout
const SideNavBarForFreeUser = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const NavigationMenu = {
        [t('sidebar_menu_property')]: {
            isActive: true,
            menuIcon: 'bank',
            menuOptions: [
                // new SubmenuOptionModel(
                //     t('sidebar_submenu_communities'),
                //     UserFunctionConstants.VIEW_COMMUNITIES,
                //     AppRoutes.COMMUNITIES
                // ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_buildings'),
                    UserFunctionConstants.VIEW_BUILDINGS,
                    AppRoutes.BUILDINGS
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_createMultipleFlat'),
                    UserFunctionConstants.CREATE_MULTIPLE_FLAT,
                    AppRoutes.CREATE_MULTIPLE_FLAT
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_flats'),
                    UserFunctionConstants.VIEW_FLATS,
                    AppRoutes.FLATS
                )
            ]
        },
        [t('sidebar_menu_bill')]: {
            isActive: true,
            menuIcon: 'snippets',
            menuOptions: [
                new SubmenuOptionModel(
                    t('sidebar_submenu_billTypes'),
                    UserFunctionConstants.FREE_USER,
                    AppRoutes.BILL_TYPES
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_billPackages'),
                    UserFunctionConstants.FREE_USER,
                    AppRoutes.BILL_PACKAGE
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_billGeneration'),
                    UserFunctionConstants.FREE_USER,
                    AppRoutes.BILL_GENERATION
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_billCollection'),
                    UserFunctionConstants.FREE_USER,
                    AppRoutes.BILL_DETAILS
                )
                // new SubmenuOptionModel( t('sidebar_submenu_billDashboard') , UserFunctionConstants.BILLS_DASHBOARD , AppRoutes.BILL_DASHBOARD) ,
            ]
        },
        [t('sidebar_menu_expense')]: {
            isActive: true,
            menuIcon: 'pay-circle',
            menuOptions: [
                new SubmenuOptionModel(
                    t('sidebar_submenu_createExpenseType'),
                    UserFunctionConstants.CREATE_AND_VIEW_EXPENSE_TYPE,
                    AppRoutes.EXPENSE_TYPES
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_createExpenseEntry'),
                    UserFunctionConstants.ADD_EXPENSE_ENTRY,
                    AppRoutes.EXPENSE_RECORD
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_createExpenseVoucher'),
                    UserFunctionConstants.GENERATE_EXPENSE_VOUCHER,
                    AppRoutes.EXPENSE_VOUCHER_GENERATION
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_authorizeVoucher'),
                    UserFunctionConstants.AUTHORIZE_EXPENSE_VOUCHER,
                    AppRoutes.EXPENSE_VOUCHER_AUTHORIZATION
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_expenseDashboard'),
                    UserFunctionConstants.EXPENSE_DASHBOARD,
                    AppRoutes.EXPENSE_DASHBOARD
                )
            ]
        },
        [t('sidebar_menu_fund')]: {
            isActive: true,
            menuIcon: 'pie-chart',
            menuOptions: [
                new SubmenuOptionModel(
                    t('sidebar_submenu_createFund'),
                    UserFunctionConstants.CREATE_FUND,
                    AppRoutes.FUNDS
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_addFund'),
                    UserFunctionConstants.ADD_TO_FUND,
                    AppRoutes.ADD_TO_FUND
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_withdrawFund'),
                    UserFunctionConstants.WITHDRAW_FROM_FUND,
                    AppRoutes.WITHDRAW_FROM_FUND
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_transferFund'),
                    UserFunctionConstants.TRANSFER_FUND,
                    AppRoutes.FUND_TRANSFER
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_transactionHistory'),
                    UserFunctionConstants.TRANSACTION_HISTORY,
                    AppRoutes.FUND_DASHBOARD
                )
            ]
        },
        [t('sidebar_menu_reports')]: {
            isActive: true,
            menuIcon: 'dot-chart',
            menuOptions: [
                new SubmenuOptionModel(
                    t('sidebar_submenu_ledgerReport'),
                    UserFunctionConstants.VIEW_LEDGER_REPORT,
                    AppRoutes.LEDGER_REPORT
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_incomeReport'),
                    UserFunctionConstants.VIEW_INCOME_REPORT,
                    AppRoutes.INCOME_REPORT
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_expenseReport'),
                    UserFunctionConstants.VIEW_EXPENSE_REPORT,
                    AppRoutes.EXPENSE_REPORT
                ),
                // new SubmenuOptionModel(
                //     t('sidebar_submenu_cashflowReport'),
                //     UserFunctionConstants.COLLECT_BILL_OF_FLAT,
                //     AppRoutes.CASH_FLOW_REPORT
                // ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_statement'),
                    UserFunctionConstants.VIEW_INCOME_STATEMENT,
                    AppRoutes.INCOME_STATEMENT
                )
            ]
        },
        [t('sidebar_menu_admin')]: {
            isActive: true,
            menuIcon: 'block',
            menuOptions: [
                new SubmenuOptionModel(
                    t('sidebar_submenu_createMeeting'),
                    UserFunctionConstants.CREATE_NEW_MEETING,
                    AppRoutes.CREATE_MEETING
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_meetings'),
                    UserFunctionConstants.MEETINGS_DASHBOARD,
                    AppRoutes.MEETINGS
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_createNotice'),
                    UserFunctionConstants.SEND_NOTICE_FROM_ADMIN,
                    AppRoutes.CREATE_NOTICE
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_notice'),
                    UserFunctionConstants.VIEW_ALL_NOTICE_OF_COMMUNITY,
                    AppRoutes.NOTICES
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_createComplain'),
                    UserFunctionConstants.SUBMIT_COMPLAIN,
                    AppRoutes.CREATE_COMPLAIN
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_complains'),
                    UserFunctionConstants.VIEW_ALL_COMPLAIN_OF_COMMUNITY,
                    AppRoutes.COMPLAINS
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_all_form'),
                    UserFunctionConstants.CREATE_VIEW_ALL_FORMS,
                    AppRoutes.ALL_FORMS
                )
            ]
        },
        [t('sidebar_menu_user')]: {
            isActive: true,
            menuIcon: 'user',
            menuOptions: [
                new SubmenuOptionModel(
                    t('sidebar_submenu_registerUser'),
                    UserFunctionConstants.REGISTER_USER,
                    AppRoutes.REGISTER_USER
                ),
                new SubmenuOptionModel(
                    t('sidebar_submenu_users'),
                    UserFunctionConstants.VIEW_USERS,
                    AppRoutes.VIEW_USER
                )
            ]
        }
    }
    const dispatch = useDispatch()
    const userFunctions = useSelector((state) => makeSelectFunctionsOfUser(state))
    const [collapsed, setCollapsed] = useState(isMobile)
    const [openKeys, setOpenKeys] = useState([])

    const rootSubmenuKeys = [
        t('sidebar_menu_property'),
        t('sidebar_menu_privilege'),
        t('sidebar_menu_admin'),
        t('sidebar_menu_entry'),
        t('sidebar_menu_fund'),
        t('sidebar_menu_expense'),
        t('sidebar_menu_bill'),
        t('sidebar_menu_reports'),
        t('sidebar_menu_user'),
        t('sidebar_menu_vehicle'),
        t('sidebar_menu_fingerprint'),
        t('sidebar_menu_preAuthorizedEntry')
    ]

    const onOpenChange = (items) => {
        const latestOpenKey = items.find((key) => openKeys.indexOf(key) === -1)
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(items)
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
        }
    }

    // const toggle = setCollapsed((collapsed) => !collapsed)

    const handleMenuClick = (e) => {
        const routeName = e.key
        dispatch(push(routeName))
    }

    const _getSubmenuList = () => {
        let submenuOptions = []
        const functionKeys = userFunctions.map((item) => item.code)
        _.forOwn(NavigationMenu, function (value, key) {
            const isMenuActive = value.isActive
            let menuItems = []
            for (const item of value.menuOptions) {
                // if (functionKeys.includes(item.userFunction.toString())) {
                //     menuItems.push(<Menu.Item key={item.route}>{item.submenuName}</Menu.Item>)
                // }
                menuItems.push(<Menu.Item key={item.route}>{item.submenuName}</Menu.Item>)
            }

            if (menuItems.length > 0 && isMenuActive) {
                submenuOptions.push(
                    <SubMenu
                        key={key}
                        title={
                            <span>
                                <Icon
                                    style={{ fontSize: '20px', color: '#ff3300' }}
                                    type={value.menuIcon}
                                />
                                <span> {key} </span>
                            </span>
                        }
                    >
                        {menuItems}
                    </SubMenu>
                )
            }
        })

        return submenuOptions
    }

    return (
        <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
            style={{ backgroundColor: '#FFF' }}
        >
            <>
                <div className='sidebar-title'>
                <Link to={AppRoutes.DEFAULT}>
               
       
                    <img
                        style={{
                            height: '50px',
                            maxWidth: '80px',
                            alignSelf: 'center',
                            width: '100%'
                        }}
                        src={logo}
                    />
                         </Link>
                </div>
                {/* <Header className="site-layout-background trigger" style={{ padding: 0 }} onClick={ () => setCollapsed(!collapsed)}>
                    {
                        collapsed?
                        <MenuUnfoldOutlined  style={{ fontSize: "30px" }}/>:
                        <MenuFoldOutlined  style={{ fontSize: "30px" }}/>
                    }
                </Header> */}
                <Menu
                    defaultSelectedKeys={[location.pathname]}
                    subMenuCloseDelay={0.3}
                    mode='inline'
                    forceSubMenuRender={true}
                    style={{ minHeight: '90vh', marginTop: '10px' }}
                    onClick={handleMenuClick}
                    theme='light'
                    // mode='inline'
                    inlineCollapsed={collapsed}
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                >
                    {_getSubmenuList()}
                </Menu>
            </>
        </Sider>
    )
}
export default SideNavBarForFreeUser
