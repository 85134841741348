import { Button } from 'antd'
import React from 'react'

export function CustomButtons(props) {
    return (
        <Button onClick={props.onClick} className={'btn-fill-green'} size={'default'} icon='plus'>
            {props.text}
        </Button>
    )
}

export function DownloadExcelButton(props) {
    return (
        <Button className={'btn-fill-blue'} size={'default'} icon='download'>
            Download Excel
        </Button>
    )
}

export function DownloadButton(props) {
    return (
        <Button className={'btn-mild-blue'} size={'default'} icon='download'>
            {props.text}
        </Button>
    )
}

export function ButtonMildGreen(props) {
    return (
        <Button
            onClick={props.onClick}
            loading={props.isLoading}
            className={'btn-mild-green'}
            size={'default'}
            icon={props.icon}
        >
            {props.text}
        </Button>
    )
}
export function ButtonFillGreen(props) {
    return (
        <Button
            disabled={props.disabled}
            onClick={props.onClick}
            className={'btn-fill-green'}
            size={'default'}
            loading={props.isRequesting}
            icon={props.icon}
        >
            {props.text}
        </Button>
    )
}
export function ButtonFillPurple(props) {
    return (
        <Button
            onClick={props.onClick}
            className={'btn-fill-purple'}
            size={props.size}
            loading={props.isRequesting}
            icon={props.icon}
        >
            {props.text}
        </Button>
    )
}
export function ButtonMildRed(props) {
    return (
        <Button
            loading={props.loading}
            onClick={props.onClick}
            className={'btn-mild-red'}
            size={'default'}
            icon={props.icon}
            disabled={props.disabled}
        >
            {props.text}
        </Button>
    )
}
export function ButtonFillBlack(props) {
    return (
        <Button
            onClick={props.onClick}
            className={'btn-fill-black'}
            size={'default'}
            icon={props.icon}
        >
            {props.text}
        </Button>
    )
}

export function ButtonMildBlue(props) {
    return (
        <Button
            disabled={props.disabled}
            onClick={props.onClick}
            className={'btn-mild-blue'}
            size={'default'}
            icon={props.icon}
        >
            {props.text}
        </Button>
    )
}
export function ButtonFillBlue(props) {
    return (
        <Button
            disabled={props.disabled}
            onClick={props.onClick}
            className={'btn-fill-blue'}
            size={'default'}
            icon={props.icon}
        >
            {props.text}
        </Button>
    )
}
