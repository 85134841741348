import MiscAction from "../../misc/MiscAction";
import HttpErrorResponseModel from "../../special/models/HttpErrorResponseModel"
import NewPackageAction from "./NewPackageAction";

const NewPackagePostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel;

    if(!isError) {
        switch(action.type) {
            case NewPackageAction.REQUEST_GET_ALL_PACKAGE_FINISHED:
                break
            case NewPackageAction.REQUEST_CREATE_PACKAGE_FINISHED:
                next(MiscAction._showModalSuccess('Package Created Successfully'))
                break
            default:
                break
        }

    }

    return next(action)
}

export default NewPackagePostEffect;