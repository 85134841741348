import ActionUtility from '../../../utils/ActionUtility'
import EntranceEffect from './EntranceEffect'

export default class EntranceAction {
    static REQUEST_GET_VISITORS = 'REQUEST_GET_VISITORS'
    static REQUEST_GET_VISITORS_FINISHED = 'REQUEST_GET_VISITORS_FINISHED'

    static REQUEST_GET_PARCELS = 'REQUEST_GET_PARCELS'
    static REQUEST_GET_PARCELS_FINISHED = 'REQUEST_GET_PARCELS_FINISHED'

    static REQUEST_GET_VEHICLES = 'REQUEST_GET_VEHICLES'
    static REQUEST_GET_VEHICLES_FINISHED = 'REQUEST_GET_VEHICLES_FINISHED'

    static REQUEST_MARK_VISITOR_EXIT = 'REQUEST_MARK_VISITOR_EXIT'
    static REQUEST_MARK_VISITOR_EXIT_FINISHED = 'REQUEST_MARK_VISITOR_EXIT_FINISHED'

    static REQUEST_MARK_VEHICLE_EXIT = 'REQUEST_MARK_VEHICLE_EXIT'
    static REQUEST_MARK_VEHICLE_EXIT_FINISHED = 'REQUEST_MARK_VEHICLE_EXIT_FINISHED'

    static REQUEST_MARK_PARCEL_DELIVERED = 'REQUEST_MARK_PARCEL_DELIVERED'
    static REQUEST_MARK_PARCEL_DELIVERED_FINISHED = 'REQUEST_MARK_PARCEL_DELIVERED_FINISHED'

    static _requestGetVisitors(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                EntranceAction.REQUEST_GET_VISITORS,
                EntranceEffect._requestGetVisitors,
                data
            )
        }
    }

    static _requestGetParcels(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                EntranceAction.REQUEST_GET_PARCELS,
                EntranceEffect._requestGetParcels,
                data
            )
        }
    }

    static _requestGetVehicles(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                EntranceAction.REQUEST_GET_VEHICLES,
                EntranceEffect._requestGetVehicles,
                data
            )
        }
    }

    static _requestLetVisitorOut(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                EntranceAction.REQUEST_MARK_VISITOR_EXIT,
                EntranceEffect._requestLetVisitorOut,
                data
            )
        }
    }
    static _requestLetVehicleOut(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                EntranceAction.REQUEST_MARK_VEHICLE_EXIT,
                EntranceEffect._requestLetVehicleOut,
                data
            )
        }
    }

    static _requestMarkParcelAsReceived(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                EntranceAction.REQUEST_MARK_PARCEL_DELIVERED,
                EntranceEffect._requestMarkParcelAsReceived,
                data
            )
        }
    }
}
