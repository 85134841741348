import ActionUtility from '../../../utils/ActionUtility'
import UserEffect from './UserEffect'

export default class UserAction {
    static REQUEST_GET_USERS = 'REQUEST_GET_USERS'
    static REQUEST_GET_USERS_FINISHED = 'REQUEST_GET_USERS_FINISHED'

    static REQUEST_GET_USER_DETAILS = 'REQUEST_GET_USER_DETAILS'
    static REQUEST_GET_USER_DETAILS_FINISHED = 'REQUEST_GET_USER_DETAILS_FINISHED'

    static REQUEST_REGISTER_USER = 'REQUEST_REGISTER_USER'
    static REQUEST_REGISTER_USER_FINISHED = 'REQUEST_REGISTER_USER_FINISHED'

    static REQUEST_DELETE_USER = 'REQUEST_DELETE_USER'
    static REQUEST_DELETE_USER_FINISHED = 'REQUEST_DELETE_USER_FINISHED'

    static REQUEST_GET_FUNCTIONS_OF_USER = 'REQUEST_GET_FUNCTIONS_OF_USER'
    static REQUEST_GET_FUNCTIONS_OF_USER_FINISHED = 'REQUEST_GET_FUNCTIONS_OF_USER_FINISHED'

    static REQUEST_GET_ROLES_OF_USER = 'REQUEST_GET_ROLES_OF_USER'
    static REQUEST_GET_ROLES_OF_USER_FINISHED = 'REQUEST_GET_ROLES_OF_USER_FINISHED'

    static SET_USER_FUNCTIONS = 'SET_USER_FUNCTIONS'

    static REQUEST_GET_DASHBOARD_OF_USER = 'REQUEST_GET_DASHBOARD_OF_USER'
    static REQUEST_GET_DASHBOARD_OF_USER_FINISHED = 'REQUEST_GET_DASHBOARD_OF_USER_FINISHED'

    static SET_USER_PERSPECTIVE = 'SET_USER_PERSPECTIVE'

    static _requestGetUserDetails(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                UserAction.REQUEST_GET_USER_DETAILS,
                UserEffect._requestGetUserDetails,
                data
            )
        }
    }
    static _requestGetUserList(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                UserAction.REQUEST_GET_USERS,
                UserEffect._requestGetUserList,
                data
            )
        }
    }

    static _requestRegisterUser(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                UserAction.REQUEST_REGISTER_USER,
                UserEffect._requestRegisterUser,
                data
            )
        }
    }
    static _requestDeleteUser(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                UserAction.REQUEST_DELETE_USER,
                UserEffect._requestDeleteUser,
                data
            )
        }
    }

    static _requestGetFunctionsOfUser(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                UserAction.REQUEST_GET_FUNCTIONS_OF_USER,
                UserEffect._requestGetFunctionsOfUser,
                data
            )
        }
    }

    static _serUserFunctions(data) {
        return ActionUtility._createAction(UserAction.SET_USER_FUNCTIONS, data)
    }

    static _requestGetRolesOfUser(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                UserAction.REQUEST_GET_ROLES_OF_USER,
                UserEffect._requestGetRolesOfUser,
                data
            )
        }
    }
    static _requestGetDashboardOfUser(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                UserAction.REQUEST_GET_DASHBOARD_OF_USER,
                UserEffect._requestGetDashboardOfUser,
                data
            )
        }
    }
    static _setPerspective(data) {
        console.log("USER", data);
        return ActionUtility._createAction(UserAction.SET_USER_PERSPECTIVE, data)
    }
}
