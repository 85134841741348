import React, { useEffect, useState } from 'react'
import { VehicleTypeConstants } from '../../../assets/constants/GeneralConstants'
import { Tabs } from 'antd'
import { CardInfoBackgroundColoredLeftIcon } from '../../component/decoration/CardSingleInfo'
import { DataBuilderUtility } from '../../../utils/DataBuilderUtility'
import { useDispatch, useSelector } from 'react-redux'
import TableDataFilter from '../../tables/TableDataFilter'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import TableVehicle from '../../tables/vehicle/TableVehicle'
import { makeSelectVehiclesList } from '../../../stores/security-management/vehicle/VehicleSelector'
import VehicleAction from '../../../stores/security-management/vehicle/VehicleAction'
import GetVehicleRequest from '../../../stores/security-management/vehicle/requests/GetVehicleRequest'
import { PageBasicContainer, PageDecorationContainer, PageMainBarContainer } from '../PageStyles'
import DynamicHelmet from '../../misc/DynamicHelmet'

const { TabPane } = Tabs
export default function PageVehicles() {
    const dispatch = useDispatch()
    const [vehicleType, setVehicleType] = useState(VehicleTypeConstants.ALL)
    const [filterValues, setFilterValues] = useState({})
    const isFinished = useSelector((state) =>
        selectFinished(state, VehicleAction.REQUEST_ADD_VEHICLE)
    )

    const dataList = useSelector(makeSelectVehiclesList)
    const decorationArr = DataBuilderUtility._getVehiclesDataOverview(dataList)

    const handleVehicleTypeChange = (status) => setVehicleType(status)

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        filterValues['vehicleType'] = vehicleType
        dispatch(VehicleAction._requestGetVehicles(new GetVehicleRequest(filterValues || '')))
    }, [filterValues, vehicleType])

    const decorItems = decorationArr.map((item, index) => (
        <CardInfoBackgroundColoredLeftIcon data={item} key={index + item.backgroundColor} />
    ))
    return (
        <PageBasicContainer>
            <DynamicHelmet
                title={'Rokkhi | Vehicle Management'}
                description={'Vehicle Management Page'}
            />
            <PageDecorationContainer paddingTop={'0px'} item={4}>
                {decorItems}
            </PageDecorationContainer>

            <PageMainBarContainer>
                <TableDataFilter
                    showBuildingFilter={true}
                    showFlatFilter={true}
                    sendDataToParent={receiveFilterData}
                />
                <div>
                    <Tabs onChange={handleVehicleTypeChange} type='card'>
                        <TabPane tab='All' key={VehicleTypeConstants.ALL} />
                        <TabPane tab='Private Car' key={VehicleTypeConstants.PRIVATE_CAR} />
                        <TabPane tab='Motor Bike' key={VehicleTypeConstants.MOTOR_BIKE} />
                        <TabPane tab='Others' key={VehicleTypeConstants.OTHERS} />
                    </Tabs>
                    <TableVehicle />
                </div>
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
