import { FlatFormatConstants } from '../../../assets/constants/GeneralConstants'

export function useFlatGenerator({ flatFormat, totalFloor, flatsPerFloor, floorCount}) {
    const flatArrays = []
    let digit = '1'
    let string2 = 'A'
    const floorCountUpdate = totalFloor?totalFloor:floorCount;
    for (let floorCountIndex = 0; floorCountIndex < floorCountUpdate; floorCountIndex++) {
        let string = 'A'
        let digit2 = '1'
        for (let flatCountIndex = 0; flatCountIndex < flatsPerFloor; flatCountIndex++) {
            if (flatFormat === FlatFormatConstants.STRING_DIGIT) {
                flatArrays.push(`${string2}${digit2}`)
                digit2++
            } else if (flatFormat === FlatFormatConstants.DIGIT_STRING) {
                flatArrays.push(`${digit}${string}`)
                string = String.fromCharCode(string.charCodeAt() + 1)
            } else if (flatFormat === FlatFormatConstants.THREE_DIGIT) {
                flatArrays.push(`${floorCountIndex + 1}0${flatCountIndex + 1}`)
                string = String.fromCharCode(string.charCodeAt() + 1)
            }
            
        }
        digit++
        if (flatFormat === FlatFormatConstants.STRING_DIGIT) {
            string2 = String.fromCharCode(string2.charCodeAt() + 1)
        } 
        
    }

    return flatArrays
}
