import { DatePicker } from 'antd'
import React from 'react'
import * as moment from 'moment'
import { useState } from 'react'

const { RangePicker } = DatePicker
export default function TableFilterMonthRangePicker(props) {
    const value = props.control.values[`${props.name}`]
    return (
        <RangePicker
            defaultValue={
                value ? [moment(value[0], 'MM/YYYY'), moment(value[1], 'MM/YYYY')] : undefined
            }
            placeholder={
                props.placeholder
                    ? [props.placeholder.startMonth, props.placeholder.endMonth]
                    : ['Start month', 'End month']
            }
            format='MM/YYYY'
            onChange={(date, dateStrings) => {
                return props.control.handleInputChange(props.name, dateStrings)
            }}
        />
    )
}
