import MiscAction from "../../misc/MiscAction"
import HttpErrorResponseModel from "../../special/models/HttpErrorResponseModel"
import FormsAction from "./FormsAction"

const FormsPostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if(!isError) {
        switch(action.type) {
            case FormsAction.REQUEST_CREATE_TENANT_OWNER_FORM_FINISHED:
                next(MiscAction._showModalSuccess('Tenant Owner Form Submitted Successfully'))
                break
            case FormsAction.REQUEST_CREATE_TENANT_DEPARTURE_FORM_FINISHED:
                next(MiscAction._showModalSuccess('Departure Form Submitted Successfully'))
                break
            case FormsAction.REQUEST_CREATE_POLICE_FORM_FINISHED:
                next(MiscAction._showModalSuccess('Police Form Submitted Successfully'))
                break
            default:
                break
        }
    }

    return next(action);
}

export default FormsPostEffect;