import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import BillAction from '../../../stores/money-management/bill/BillAction'
import { GetBillTypeRequest } from '../../../stores/money-management/bill/requests/GetBillTypeRequest'
import { PageBasicContainer } from '../PageStyles'
import FormGenerateBillInvoice from '../../forms/bill/FormGenerateBillInvoice'

export default function PageBillGeneration(props) {
    const dispatch = useDispatch()
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_CREATE_BILL_TYPE)
    )
    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_DELETE_BILL_TYPE)
    )

    useEffect(() => {
        dispatch(BillAction._requestGetBillTypes(new GetBillTypeRequest()))
    }, [isFinishedCreation, isFinishedDeletion])

    return (
        <PageBasicContainer>
            <FormGenerateBillInvoice />
        </PageBasicContainer>
    )
}
