import React, { useEffect } from 'react'
import FormCreateUserRole from '../forms/privilege/FormCreateUserRole'
import TableUserRole from '../tables/privilege/TableUserRole'
import FormCreateUserFunction from '../forms/privilege/FormCreateUserFunction'
import { AppRoutes } from '../../assets/constants/routes'
import TableUserFunctions from '../tables/privilege/TableUserFunctions'
import FormAssignFunctionToRole from '../forms/privilege/FormAssignFunctionToRole'
import FormAssignRoleToUser from '../forms/privilege/FormAssignRoleToUser'

export default function PagePrivilege(props) {
    switch (props.route) {
        case AppRoutes.USER_ROLE:
            return (
                <div style={{ marginBottom: '20px' }}>
                    <FormCreateUserRole />
                    <div className={'card'}>
                        <h2> User Roles</h2>
                        <TableUserRole />
                    </div>
                </div>
            )
        case AppRoutes.USER_FUNCTION:
            return (
                <div style={{ marginBottom: '20px' }}>
                    <FormCreateUserFunction />
                    <div className={'card'}>
                        <h2> User Functions</h2>
                        <TableUserFunctions />
                    </div>
                </div>
            )
        case AppRoutes.ASSIGN_FUNCTION_TO_ROLE:
            return <FormAssignFunctionToRole />
        case AppRoutes.ASSIGN_ROLE_TO_USER:
            return (
                <div>
                    <FormAssignRoleToUser />
                </div>
            )
    }
}
