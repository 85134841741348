import { AuthorizationStatusConstants } from '../../../../assets/constants/GeneralConstants'
import BaseRequest from '../../../special/models/BaseRequest'

export default class CreatePreAuthorizationEntryRequest extends BaseRequest {
    buildingId = ''
    flatId = ''
    authorizationStatus = ''

    constructor(data, authorizationStatus) {
        super()
        this.update(data)
        if (authorizationStatus!==AuthorizationStatusConstants.ALL) this.authorizationStatus = authorizationStatus
    }
}
