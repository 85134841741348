import React from 'react'
import ResultTypePage from './ResultTypePage'
import NotFound from '../../assets/images/does_not_exist.png'

const NotFoundPage = (props) => {
    return (
        <ResultTypePage
            status='404'
            subTitle='Sorry, The page you requested does not exists.'
            image={NotFound}
        />
    )
}
export default NotFoundPage
