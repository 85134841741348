import React, { useEffect, useState } from 'react'
import TablePreAuthorizationList from '../../tables/security/TablePreAuthorizationList'
import { useDispatch, useSelector } from 'react-redux'
import { AuthorizationStatusConstants } from '../../../assets/constants/GeneralConstants'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { DataBuilderUtility } from '../../../utils/DataBuilderUtility'
import { CardInfoLeftColoredIcon } from '../../component/decoration/CardSingleInfo'
import TableDataFilter from '../../tables/TableDataFilter'
import { Tabs } from 'antd'
import PreAuthorizationAction from '../../../stores/security-management/pre-authorization/PreAuthorizationAction'
import { makeSelectPreAuthorizationsList } from '../../../stores/security-management/pre-authorization/PreAuthorizationSelector'
import GetPreAuthorizationListRequest from '../../../stores/security-management/pre-authorization/requests/GetPreAuthorizationListRequest'
import { PageBasicContainer, PageDecorationContainer, PageMainBarContainer } from '../PageStyles'
import DynamicHelmet from '../../misc/DynamicHelmet'

const { TabPane } = Tabs
export default function PagePreAuthorizedList(props) {
    const dispatch = useDispatch()
    const [decorationArrValues, setDecorationArrValues] = useState([])
    const [status, setStatus] = useState(AuthorizationStatusConstants.ALL)
    const [filterValues, setFilterValues] = useState(null)
    const isFinished = useSelector((state) =>
        selectFinished(state, PreAuthorizationAction.REQUEST_DELETE_FROM_PRE_AUTHORIZED_LIST)
    )
    const dataList = useSelector(makeSelectPreAuthorizationsList)

    const isFinishedCreate = useSelector((state) =>
        selectFinished(state, PreAuthorizationAction.REQUEST_ADD_TO_PRE_AUTHORIZED_LIST)
    )
    const isFinishedDelete = useSelector((state) =>
        selectFinished(state, PreAuthorizationAction.REQUEST_DELETE_FROM_PRE_AUTHORIZED_LIST)
    )

    const changeStatus = (status) => setStatus(status)

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        dispatch(
            PreAuthorizationAction._requestGetPreAuthorizationList(
                new GetPreAuthorizationListRequest(filterValues, status)
            )
        )
    }, [filterValues, status, isFinished, isFinishedCreate, isFinishedDelete])

    useEffect(() => {
        setDecorationArrValues(DataBuilderUtility._getPreAuthorizedDataOverview(dataList))
    }, [dataList])

    const decorItems = decorationArrValues.map((item) => <CardInfoLeftColoredIcon data={item} />)
    return (
        <PageBasicContainer>
            <DynamicHelmet title={'Rokkhi | Authorized Visitors'} description={'Rokkhi Home Pre Authorized Visitors.'} />
            <PageDecorationContainer paddingTop={'0px'} item={2}>{decorItems}</PageDecorationContainer>
            <PageMainBarContainer>
                <TableDataFilter showBuildingFilter={true} showFlatFilter={true} sendDataToParent={receiveFilterData} />

                <Tabs onChange={changeStatus} type='card'>
                    <TabPane tab='ALL' key={AuthorizationStatusConstants.ALL} />
                    <TabPane tab='White List' key={AuthorizationStatusConstants.WHITE_LISTED} />
                    <TabPane tab='Black List' key={AuthorizationStatusConstants.BLACK_LISTED} />
                </Tabs>

                <TablePreAuthorizationList />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
