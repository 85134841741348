import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import ComponentTable from '../ComponentTable'
import { makeSelectBillTypesList } from '../../../stores/money-management/bill/BillSelector'
import BillAction from '../../../stores/money-management/bill/BillAction'
import { DeleteBillTypeRequest } from '../../../stores/money-management/bill/requests/DeleteBillTypeRequest'

export default function TableBillType(props) {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectBillTypesList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [
            BillAction.REQUEST_DELETE_BILL_TYPE,
            BillAction.REQUEST_GET_BILL_TYPES
        ])
    )

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        // {
        //     title: 'Code',
        //     dataIndex: 'code'
        // },
        {
            title: 'Description',
            dataIndex: 'description'
        },
        {
            title: 'Default Amount',
            dataIndex: 'defaultAmount'
        },
        {
            title: 'Created',
            render: (text, record) => (
                <div>
                    {' '}
                    {record.createdDate && new Date(record.createdDate).toLocaleDateString()}{' '}
                </div>
            )
        },
        {
            title: 'Action',
            align: 'center',
            render: (text, record) => (
                <ModalDeleteConfirmation
                    confirmationText={'Are you sure you want to delete this type ?'}
                    onConfirm={() =>
                        dispatch(
                            BillAction._requestDeleteBillType(new DeleteBillTypeRequest(record))
                        )
                    }
                />
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
