import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import EntranceAction from '../../../stores/security-management/entrance/EntranceAction'
import VisitorDetailsRequest from '../../../stores/security-management/entrance/requests/VisitorDetailsRequest'
import { makeSelectVisitorsList } from '../../../stores/security-management/entrance/EntranceSelector'
import { BlueTag, GreenTag } from '../../component/misc/Tags'
import { EntranceStatusConstants } from '../../../assets/constants/GeneralConstants'
import ModalImage from '../../modals/ModalImage'

export default function TableVisitor() {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectVisitorsList)
    const isRequesting = useSelector((state) => selectRequesting(state, [EntranceAction.REQUEST_GET_VISITORS]))

    const columns = [
        {
            title: 'Image',
            dataIndex: 'thumbImage',
            render: (text, record) => <ModalImage imageUrl={record.thumbImage} imageTitle={'User Image'} />
        },
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Address',
            dataIndex: 'address'
        },
        {
            title: 'Contact',
            dataIndex: 'contact'
        },
        {
            title: 'Flat No',
            dataIndex: 'flatName'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Purpose',
            dataIndex: 'purpose'
        },
        {
            align: 'start',
            render: (text, record) => {
                if (record.status === EntranceStatusConstants.INSIDE_COMPOUND) return <GreenTag text={'Still Inside'} />
                else return <BlueTag text={'Not Inside'} />
            }
        },
        {
            title: 'In Date',
            render: (text, record) => <div> {record.inDate} </div>
        },
        {
            title: 'In Time',
            render: (text, record) => <div> {record.inTime} </div>
        },
        {
            title: 'Exit Date',
            render: (text, record) => <div> {record.exitDate} </div>
        },
        {
            title: 'Exit Time',
            render: (text, record) => <div> {record.exitTime} </div>
        },
        {
            title: '',
            align: 'center',
            render: (text, record) => (
                <ModalDeleteConfirmation
                    icon={'logout'}
                    confirmationText={'Are you sure that this visitor is not inside compound ?'}
                    onConfirm={() => dispatch(EntranceAction._requestLetVisitorOut(new VisitorDetailsRequest(record)))}
                />
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
