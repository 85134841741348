import BaseRequest from '../../../special/models/BaseRequest'

export default class CreateMultipleFlatRequest extends BaseRequest {
    flats = []
    buildingId = ''

    constructor(data) {
        super()
        this.update(data)
        this.name = data.number
    }
}
