import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import { GreenTag, RedTag } from '../../component/misc/Tags'
import ReportAction from '../../../stores/reporting/ReportAction'
import { makeSelectLedgerReportsList } from '../../../stores/reporting/ReportSelector'
import { Table } from 'antd'

export default function TableIncome() {
    const [creditItems, setCreditItems] = useState([])
    const dataList = useSelector(makeSelectLedgerReportsList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [ReportAction.REQUEST_GET_LEDGER_REPORT])
    )

    useEffect(() => {
        if (dataList && dataList.list) {
            const creditItems = dataList.list.filter((item) => item.entryType == 'debit')
            setCreditItems(creditItems)
        }
    }, [dataList])

    // console.log(dataList.list)

    const columns = [
        {
            title: 'Date',
            dataIndex: 'entryType',
            render: (text, record) => <b> {new Date(record.date).toLocaleDateString()} </b>
        },
        {
            title: 'Description',
            dataIndex: 'description'
        },
        {
            title: 'Amount',
            dataIndex: 'amount'
        }
    ]

    // return <ComponentTable isRequesting={isRequesting} dataList={creditItems} columns={columns} />

    return (
        <Table
            dataSource={creditItems}
            columns={columns}
            bordered
            rowKey={(data) => data.id}
        />
    )
}
