import ActionUtility from '../../../utils/ActionUtility'
import FlatEffect from './FlatEffect'

export default class FlatAction {
    static REQUEST_GET_FLATS = 'REQUEST_GET_FLATS'
    static REQUEST_GET_FLATS_FINISHED = 'REQUEST_GET_FLATS_FINISHED'

    static REQUEST_GET_FLATS_Bill_PACKAGE = 'REQUEST_GET_FLATS_Bill_PACKAGE'
    static REQUEST_GET_FLATS_Bill_PACKAGE_FINISHED = 'REQUEST_GET_FLATS_Bill_PACKAGE_FINISHED'

    static REQUEST_CREATE_FLAT = 'REQUEST_CREATE_FLAT'
    static REQUEST_CREATE_FLAT_FINISHED = 'REQUEST_CREATE_FLAT_FINISHED'

    static REQUEST_CREATE_MULTIPLE_FLAT = 'REQUEST_CREATE_MULTIPLE_FLAT'
    static REQUEST_CREATE_MULTIPLE_FLAT_FINISHED = 'REQUEST_CREATE_MULTIPLE_FLAT_FINISHED'

    static REQUEST_DELETE_FLAT = 'REQUEST_DELETE_FLAT'
    static REQUEST_DELETE_FLAT_FINISHED = 'REQUEST_DELETE_FLAT_FINISHED'

    static REQUEST_QRCODE_OF_FLAT = 'REQUEST_QRCODE_OF_FLAT'
    static REQUEST_QRCODE_OF_FLAT_FINISHED = 'REQUEST_QRCODE_OF_FLAT_FINISHED'

    static REQUEST_SEND_QRCODE_OF_FLAT = 'REQUEST_SEND_QRCODE_OF_FLAT'
    static REQUEST_SEND_QRCODE_OF_FLAT_FINISHED = 'REQUEST_SEND_QRCODE_OF_FLAT_FINISHED'

    static _requestGetBillPackageOfFlat(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FlatAction.REQUEST_GET_FLATS_Bill_PACKAGE,
                FlatEffect._requestGetBillPackageOfFlat,
                data
            )
        }
    }

    static _requestGetFlatList(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FlatAction.REQUEST_GET_FLATS,
                FlatEffect._requestGetFlatList,
                data
            )
        }
    }

    static _requestCreateMultipleFlat(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FlatAction.REQUEST_CREATE_MULTIPLE_FLAT,
                FlatEffect._requestCreateMultipleFlat,
                data
            )
        }
    }

    static _requestCreateFlat(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FlatAction.REQUEST_CREATE_FLAT,
                FlatEffect._requestCreateFlat,
                data
            )
        }
    }

    static _requestDeleteFlat(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FlatAction.REQUEST_DELETE_FLAT,
                FlatEffect._requestDeleteFlat,
                data
            )
        }
    }
    static _requestQrCodeOfFlat(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FlatAction.REQUEST_QRCODE_OF_FLAT,
                FlatEffect._requestQrCodeOfFlat,
                data
            )
        }
    }
    static _requestSendQrCodeOfFlat(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                FlatAction.REQUEST_SEND_QRCODE_OF_FLAT,
                FlatEffect._requestSendQrCodeOfFlat,
                data,
            )
        }
    }
}