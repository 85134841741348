import React, { PureComponent } from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts'

// const data = [
//   { name: 'Group A', value: 400 },
//   { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 },
//   { name: 'Group D', value: 200 },
// ];

const COLORS = ['#00acc1', '#ff3300']

export default function IncomeExpensePiechart({ ledgerReport }) {
    console.log(ledgerReport)
    const data = generateChartData(ledgerReport)
    return (
        <ResponsiveContainer height={140} minWidth='35%' width='45%'>
            <BarChart
                data={data}
                margin={{bottom: 10}}
            >
                {/* <CartesianGrid strokeDasharray='3 3' /> */}
                <XAxis dataKey='name' />
                {/* <YAxis /> */}
                <Tooltip />
                {/* <Legend /> */}
                <Bar barSize={16} dataKey='income' fill='#14284d' />
                <Bar barSize={16} dataKey='expense' fill='#ff3300' />
            </BarChart>
        </ResponsiveContainer>
    )
}

const generateChartData = (chartData) => {
    const data = [
        {
            name: 'ledger',
            income: 0,
            expense: 0,
        }
       
    ]

    if (chartData.income && chartData.expense) {
        data[0] = {
            income: parseFloat(chartData.income),
            expense: parseFloat(chartData.expense),
            name: 'ledger'
        }
    }

    return data
}

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text x={x} y={y} fontSize='12' fill='white' dominantBaseline='central'>
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    )
}
