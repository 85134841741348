import BaseReducer from '../../../utils/BaseReducer'
import UserAction from './UserAction'

export default class UserReducer extends BaseReducer {
    initialState = {
        userList: null,
        userDetails: null,
        functionsOfUser: null,
        rolesOfUser: null,
        userDashboard: null,
        userPerspective: null
    };

    [UserAction.REQUEST_GET_USER_DETAILS_FINISHED](state, action) {
        return {
            ...state,
            userDetails: action.payload
        }
    }

    [UserAction.SET_USER_FUNCTIONS](state, action) {
        return {
            ...state,
            functionsOfUser: action.payload
        }
    }

    [UserAction.REQUEST_GET_FUNCTIONS_OF_USER_FINISHED](state, action) {
        return {
            ...state,
            functionsOfUser: action.payload
        }
    }

    [UserAction.REQUEST_GET_ROLES_OF_USER_FINISHED](state, action) {
        return {
            ...state,
            rolesOfUser: action.payload
        }
    }

    [UserAction.REQUEST_GET_USERS_FINISHED](state, action) {
        return {
            ...state,
            userList: action.payload
        }
    }
    [UserAction.REQUEST_GET_DASHBOARD_OF_USER_FINISHED](state, action) {
        return {
            ...state,
            userDashboard: action.payload
        }
    }
    [UserAction.SET_USER_PERSPECTIVE](state, action) {
        return {
            ...state,
            userPerspective: action.payload
        }
     
    }
}
