import BaseReducer from '../../../utils/BaseReducer'
import FormsAction from './FormsAction';

export default class FormsReducer extends BaseReducer {
    initialState = {
        usersFormList: null,
        policeFormList: null
    };

    [FormsAction.REQUEST_GET_ALL_USERS_FORM_FINISHED](state, action) {
        return {
            ...state,
            usersFormList: action.payload
        }
    }
    [FormsAction.REQUEST_GET_ALL_POLICE_FORM_FINISHED](state, action) {
        return {
            ...state,
            policeFormList: action.payload
        }
    }
}
