import { EntranceStatusConstants } from '../../../../assets/constants/GeneralConstants'
import BaseRequest from '../../../special/models/BaseRequest'

export default class GetVisitorsRequest extends BaseRequest {
    buildingId = ''
    flatId = ''
    status = ''
    fromDate = null
    toDate = null

    constructor(data, status) {
        super()
        this.update(data)
        if (data && data.dateRange && data.dateRange.length > 0) {
            this.fromDate = data.dateRange[0]
            this.toDate = data.dateRange[1]
        }
        if(status!==EntranceStatusConstants.ALL){
            this.status = status
        }
    }
}
