import BaseRequest from '../../../special/models/BaseRequest'

export default class GenerateMultipleBillInvoices extends BaseRequest {
    billItems = {
        name: '',
        description: '',
        amount: 0,
        typeId: 0
    }

    title = ''
    lastDateOfPayment = ''
    month = ''
    flatId = ['']
    buildingId = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
