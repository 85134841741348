import React from 'react'
import { useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import ReportAction from '../../../stores/reporting/ReportAction'
import { makeSelectIncomeStatementReportsList } from '../../../stores/reporting/ReportSelector'
import { ItemTableTypeConstants } from '../../../assets/constants/GeneralConstants'

export default function TableItemizedReport({ itemType }) {
    let dataList = useSelector(makeSelectIncomeStatementReportsList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [ReportAction.REQUEST_GET_INCOME_STATEMENT])
    )
    if (itemType === ItemTableTypeConstants.INCOME) dataList = dataList.incomeByType
    else dataList = dataList.expenseByType

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Amount',
            dataIndex: 'amount'
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
