import ActionUtility from '../../../utils/ActionUtility'
import PrivilegeEffect from './PrivilegeEffect'

export default class PrivilegeAction {
    static REQUEST_GET_USER_ROLES = 'REQUEST_GET_USER_ROLES'
    static REQUEST_GET_USER_ROLES_FINISHED = 'REQUEST_GET_USER_ROLES_FINISHED'

    static REQUEST_GET_USER_FUNCTIONS = 'REQUEST_GET_USER_FUNCTIONS'
    static REQUEST_GET_USER_FUNCTIONS_FINISHED = 'REQUEST_GET_USER_FUNCTIONS_FINISHED'

    static REQUEST_GET_USER_FUNCTIONS_OF_ROLE = 'REQUEST_GET_USER_FUNCTIONS_OF_ROLE'
    static REQUEST_GET_USER_FUNCTIONS_OF_ROLE_FINISHED =
        'REQUEST_GET_USER_FUNCTIONS_OF_ROLE_FINISHED'

    static REQUEST_CREATE_USER_ROLE = 'REQUEST_CREATE_USER_ROLE'
    static REQUEST_CREATE_USER_ROLE_FINISHED = 'REQUEST_CREATE_USER_ROLE_FINISHED'

    static REQUEST_DELETE_USER_ROLE = 'REQUEST_DELETE_USER_ROLE'
    static REQUEST_DELETE_USER_ROLE_FINISHED = 'REQUEST_DELETE_USER_ROLE_FINISHED'

    static REQUEST_DELETE_USER_FUNCTION = 'REQUEST_DELETE_USER_FUNCTION'
    static REQUEST_DELETE_USER_FUNCTION_FINISHED = 'REQUEST_DELETE_USER_FUNCTION_FINISHED'

    static REQUEST_CREATE_USER_FUNCTION = 'REQUEST_CREATE_USER_FUNCTION'
    static REQUEST_CREATE_USER_FUNCTION_FINISHED = 'REQUEST_CREATE_USER_FUNCTION_FINISHED'

    static REQUEST_ASSIGN_FUNCTION_TO_ROLE = 'REQUEST_ASSIGN_FUNCTION_TO_ROLE'
    static REQUEST_ASSIGN_FUNCTION_TO_ROLE_FINISHED = 'REQUEST_ASSIGN_FUNCTION_TO_ROLE_FINISHED'

    static REQUEST_ASSIGN_ROLE_TO_USER = 'REQUEST_ASSIGN_ROLE_TO_USER'
    static REQUEST_ASSIGN_ROLE_TO_USER_FINISHED = 'REQUEST_ASSIGN_ROLE_TO_USER_FINISHED'

    static _requestGetUserRoles(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                PrivilegeAction.REQUEST_GET_USER_ROLES,
                PrivilegeEffect._requestGetUserRoles,
                data
            )
        }
    }

    static _requestGetUserFunctions(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                PrivilegeAction.REQUEST_GET_USER_FUNCTIONS,
                PrivilegeEffect._requestGetUserFunctions,
                data
            )
        }
    }
    static _requestGetUserFunctionsOfRole(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                PrivilegeAction.REQUEST_GET_USER_FUNCTIONS_OF_ROLE,
                PrivilegeEffect._requestGetUserFunctionsOfRole,
                data
            )
        }
    }

    static _requestCreateUserRole(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                PrivilegeAction.REQUEST_CREATE_USER_ROLE,
                PrivilegeEffect._requestCreateUserRole,
                data
            )
        }
    }

    static _requestDeleteUserRole(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                PrivilegeAction.REQUEST_DELETE_USER_ROLE,
                PrivilegeEffect._requestDeleteUserRole,
                data
            )
        }
    }

    static _requestDeleteUserFunction(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                PrivilegeAction.REQUEST_DELETE_USER_FUNCTION,
                PrivilegeEffect._requestDeleteUserFunction,
                data
            )
        }
    }

    static _requestCreateUserFunction(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                PrivilegeAction.REQUEST_CREATE_USER_FUNCTION,
                PrivilegeEffect._requestCreateUserFunction,
                data
            )
        }
    }

    static _requestAssignFunctionToRole(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                PrivilegeAction.REQUEST_ASSIGN_FUNCTION_TO_ROLE,
                PrivilegeEffect._requestAssignFunctionToRole,
                data
            )
        }
    }

    static _requestAssignRoleToUser(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                PrivilegeAction.REQUEST_ASSIGN_ROLE_TO_USER,
                PrivilegeEffect._requestAssignRoleToUser,
                data
            )
        }
    }
}
