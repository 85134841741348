import BaseRequest from '../../../special/models/BaseRequest'

export class GetRequestedBillGenerationRequest extends BaseRequest {
    buildingIdInfo = 0
    month = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
