import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class FundEffect {
    static async _requestCreateFund(data) {
        const endPoint = ApiEndpoint.fund.createFund
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetFunds(data) {
        const endPoint = ApiEndpoint.fund.getFunds
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetBalanceSheetData(data) {
        const endPoint = ApiEndpoint.fund.getBalanceSheetData
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestAddToFund(data) {
        const endPoint = ApiEndpoint.fund.addToFund
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestWithdrawFromFund(data) {
        const endPoint = ApiEndpoint.fund.withdrawFromFund
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestTransferFund(data) {
        const endPoint = ApiEndpoint.fund.transferFund
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetFundMovementVouchers(data) {
        const endPoint = ApiEndpoint.fund.getVouchers
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestGetTransactions(data) {
        const endPoint = ApiEndpoint.fund.getTransactions
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
