import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import FundAction from './FundAction'

const FundPostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case FundAction.REQUEST_ADD_TO_FUND_FINISHED:
                next(MiscAction._showModalSuccess('Fund added successfully'))
                break
            case FundAction.REQUEST_WITHDRAW_FROM_FUND_FINISHED:
                next(MiscAction._showModalSuccess('Withdraw Successful'))
                break
            case FundAction.REQUEST_TRANSFER_FUND_FINISHED:
                next(MiscAction._showModalSuccess('Fund Transfer Successful'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default FundPostEffect
