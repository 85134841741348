import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import DataValidationConstants from '../FormDataValidationConstants'
import FormInputDropDown from '../../component/form-input/FormInputDropDown'
import { ColorConstants, DropDownConstants } from '../../../assets/constants/GeneralConstants'
import FlatAction from '../../../stores/property-management/flat/FlatAction'
import CreateFlatRequest from '../../../stores/property-management/flat/request/CreateFlatRequest'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { FlatIconWhite } from '../../misc/IconsProvider'
import { FormButtonContainer, FormIconContainer, FormItemContainer } from '../FormStyles'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../../../assets/constants/routes'
import { ButtonFillBlack } from '../../component/buttons/CustomButtons'

const INITIAL_STATE = {
    number: '',
    description: '',
    contactInfo: '',
    contactPerson: '',
    totalRoom: '',
    totalWashRoom: '',
    totalBalcony: '',
    size: '',
    buildingId: ''
}

const VALIDATION_SCHEMA = Yup.object({
    number: Yup.string()
        .required('Flat Number is Required')
        .min(1, 'Minimum 3 Characters')
        .max(50, 'Maximum 50 Characters'),
    // totalRoom: Yup.number()
    //     .typeError('Please Provide Digit')
    //     .max(100, 'Minimum Digit 1000')
    //     .integer('Please Provide Integer'),
    // totalWashRoom: Yup.number()
    //     .typeError('Please Provide Digit')
    //     .max(1000, 'Maximum Digit 1000')
    //     .integer('Please Provide Integer'),
    // totalBalcony: Yup.number()
    //     .typeError('Please Provide Digit')
    //     .max(1000, 'Maximum Digit 1000')
    //     .integer('Please Provide Integer'),
    // size: Yup.number()
    //     .typeError('Please Provide Digit')
    //     .max(10000, 'Maximum Digit 10000')
    //     .integer('Please Provide Integer'),
    buildingId: Yup.string().required('Please select a building')
})

export default function FormCreateFlat(props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [FlatAction.REQUEST_CREATE_FLAT])
    )
    const isFinished = useSelector((state) => selectFinished(state, FlatAction.REQUEST_CREATE_FLAT))

    useEffect(() => control.resetData(), [isFinished])
    const onSubmit = () => {
        dispatch(FlatAction._requestCreateFlat(new CreateFlatRequest(values)))
    }
    return (
        <>
            <FormButtonContainer>
                <Link to={AppRoutes.FLATS}>
                    <ButtonFillBlack icon={'backward'} text={'Flats'} />
                </Link>
            </FormButtonContainer>
            <FormIconContainer>
                <FormTitleWithIcon
                    icon={<FlatIconWhite />}
                    color={ColorConstants.GREEN1}
                    formTitle={t('flat.createFlat.title')}
                />

                <FormItemContainer>
                    <FormInputDropDown
                        label={t('flat.createFlat.selectBuilding')}
                        control={control}
                        name={'buildingId'}
                        dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                    />
                    <FormInputText
                        label={t('flat.createFlat.number')}
                        control={control}
                        name={'number'}
                    />
                </FormItemContainer>
                <FormItemContainer>
                    <FormInputText
                        label={t('flat.createFlat.totalRoom')}
                        control={control}
                        name={'totalRoom'}
                    />
                    <FormInputText
                        label={t('flat.createFlat.totalWashRoom')}
                        control={control}
                        name={'totalWashRoom'}
                    />
                    <FormInputText
                        label={t('flat.createFlat.totalBalcony')}
                        control={control}
                        name={'totalBalcony'}
                    />
                    <FormInputText
                        label={t('flat.createFlat.size')}
                        control={control}
                        name={'size'}
                    />
                </FormItemContainer>
                <ButtonFormSubmit
                    title={t('flat.createFlat.submitButton')}
                    isRequesting={isRequesting}
                    control={control}
                    onSubmit={onSubmit}
                />
            </FormIconContainer>
        </>
    )
}
