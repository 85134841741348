import CommunityAction from './CommunityAction'
import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'

const CommunityPostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case CommunityAction.REQUEST_CREATE_COMMUNITY_FINISHED:
                next(MiscAction._showModalSuccess('Community Created Successfully'))
                break
            case CommunityAction.REQUEST_SELF_REGISTER_COMMUNITY_FINISHED:
                next(MiscAction._showModalSuccess('Community Created Successfully! You Can Login'))
                break
            case CommunityAction.REQUEST_DELETE_COMMUNITY_FINISHED:
                next(MiscAction._showModalSuccess('Community Deleted Successfully'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default CommunityPostEffect
