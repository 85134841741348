import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import ComponentTable from '../ComponentTable'
import { makeSelectComplainsList } from '../../../stores/admin-management/complain/ComplainSelector'
import ComplainAction from '../../../stores/admin-management/complain/ComplainAction'
import ComplainDetailsRequest from '../../../stores/admin-management/complain/requests/ComplainDetailsRequest'
import { ComplainStatusConstants } from '../../../assets/constants/GeneralConstants'
import { BlueTag, GreenTag, RedTag } from '../../component/misc/Tags'
export default function TableComplain() {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectComplainsList)
    const isRequesting = useSelector((state) => selectRequesting(state, [ComplainAction.REQUEST_GET_COMPLAINS]))

    const columns = [
        {
            title: 'Complain Title',
            dataIndex: 'title'
        },
        {
            title: 'Complain Body',
            dataIndex: 'body'
        },
        {
            title: 'Complain Submitted',
            render: (text, record) => (
                <div> {record.createdDate && new Date(record.createdDate).toLocaleDateString()} </div>
            )
        },
        {
            align: 'center',
            title: 'Status',
            render: (text, record) => {
                switch (record.status) {
                    case ComplainStatusConstants.ACTIVE:
                        return <RedTag text={'Active'} />
                    case ComplainStatusConstants.IN_PROGRESS:
                        return <BlueTag text={'In Progress'} />
                    case ComplainStatusConstants.RESOLVED:
                        return <GreenTag text={'Resolved'} />
                    default:
                        return <div></div>
                }
            }
        },
        // {
        //     title: '',
        //     align: 'center',
        //     render: (text, record) => (
        //         <ModalDeleteConfirmation
        //             confirmationText={'Are you sure you want to delete this complain?'}
        //             onConfirm={() =>
        //                 dispatch(ComplainAction._requestGetComplainDetails(new ComplainDetailsRequest(record)))
        //             }
        //         />
        //     )
        // }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
