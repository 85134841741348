import { DatePicker, Form } from 'antd'
import React from 'react'
import * as moment from 'moment'

const { MonthPicker } = DatePicker
export default function FormInputMonthPicker(props) {
    const isTouched = props.control.touched[`${props.name}`]
    const error = props.control.errors[`${props.name}`]
    const value = props.control.values[`${props.name}`]
    return (
        <Form.Item
            label={props.label}
            hasFeedback
            validateStatus={isTouched ? (error ? 'error' : '') : ''}
            help={isTouched ? error : ''}
        >
            <MonthPicker
                format={'MM/YYYY'}
                defaultValue={value ? moment(value, 'MM/YYYY') : null}
                onChange={(date, dateString) =>
                    props.control.handleInputChange(props.name, dateString)
                }
                placeholder={props.label}
                size={'default'}
            />
        </Form.Item>
    )
}
