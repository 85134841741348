import { ApiEndpoint } from "../../../assets/constants/ApiEndpoint";
import EffectUtility from "../../../utils/EffectUtility";
import BaseRequest from "../../special/models/BaseRequest";
import ServerResponse from "../../special/models/ServerResponse";


export default class PackagePaymentEffect {
    static async _requestCreateOrder(data) {
        const endpoint = ApiEndpoint.subscription.createOrder
        return EffectUtility._postToModel(ServerResponse, endpoint, new BaseRequest(data))
    }
}