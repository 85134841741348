import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class EntranceEffect {
    static async _requestGetVisitors(data) {
        const endPoint = ApiEndpoint.visitor.getVisitors
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetParcels(data) {
        const endPoint = ApiEndpoint.parcel.getParcels
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetVehicles(data) {
        const endPoint = ApiEndpoint.vehicle.getVehicleEntryList
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestLetVisitorOut(data) {
        const endPoint = ApiEndpoint.visitor.changeVisitorStatus
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestLetVehicleOut(data) {
        const endPoint = ApiEndpoint.vehicle.changeVehicleStatus
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestMarkParcelAsReceived(data) {
        const endPoint = ApiEndpoint.parcel.markParcelAsReceived
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
