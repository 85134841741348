import BaseRequest from '../../../special/models/BaseRequest'

export default class GetAllUsersFormRequest extends BaseRequest {
    flatId = null
    buildingId = null

    constructor(data) {
        super()
        this.update(data)
    }
}