import BaseRequest from '../../../special/models/BaseRequest'

export default class GetCommunityRequest extends BaseRequest {
    activeStatus = null

    constructor(activeStatus) {
        super()
        this.activeStatus = activeStatus
    }
}
