import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import { Tag } from 'antd'
import FingerPrintAction from '../../../stores/device-management/fingerprint-device/FingerPrintAction'
import { makeSelectFingerPrintsList } from '../../../stores/device-management/fingerprint-device/FingerPrintSelector'
import GetFingerPrintsRequest from '../../../stores/device-management/fingerprint-device/request/GetFingerPrintsRequest'
import { GreenTag, RedTag } from '../../component/misc/Tags'
import ModalAssignDeviceToUser from '../../modals/ModalAssignDeviceToUser'

export default function TableFingerprint(props) {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectFingerPrintsList)
    const isRequesting = useSelector((state) => selectRequesting(state, [FingerPrintAction.REQUEST_GET_FINGERPRINTS]))
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, FingerPrintAction.REQUEST_ASSIGN_FINGERPRINT_TO_USER)
    )
    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, FingerPrintAction.REQUEST_ASSIGN_FINGERPRINT_TO_USER)
    )

    useEffect(() => {
        dispatch(FingerPrintAction._requestGetFingerprints(new GetFingerPrintsRequest()))
    }, [isFinishedDeletion, isFinishedCreation])

    const columns = [
        {
            title: 'Fingerprint ID',
            dataIndex: 'fingerprintIdOnDevice'
        },
        {
            title: 'Fingerprint Type',
            dataIndex: 'type'
        },
        {
            title: 'MAC Address',
            dataIndex: 'macAddressOfDevice'
        },
        {
            title: 'Is already assigned',
            render: (text, record) =>
                record.isAssigned ? <GreenTag text={'ASSIGNED'} /> : <RedTag text={'NOT_ASSIGNED'} />
        },
        {
            title: 'Created',
            render: (text, record) => (
                <Tag> {record.createdDate && new Date(record.createdDate).toLocaleDateString()} </Tag>
            )
        },
        {
            title: '',
            align: 'center',
            render: (text, record) => (
                <ModalAssignDeviceToUser isDisabled={record.isAssigned} fingerPrintRecordId={record.id} />
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
