import { createSelector } from 'reselect'
import { DefaultPackageConstants } from '../../../assets/constants/GeneralConstants'

export class NewPackageSelector {
    static getPackages = (state) => state.packages.packageList
}


export const makeSelectPackageList = createSelector(NewPackageSelector.getPackages, (packages) =>
    packages ? packages.data : []
)
export const makeSelectDefaultPackage = createSelector(NewPackageSelector.getPackages, (packages) =>
    packages ?
        packages.data.filter((element) => {
            if(element.plan==DefaultPackageConstants.FREE || element.plan==DefaultPackageConstants.MANAGEMENT || element.plan==DefaultPackageConstants.MANAGEMENT_SECURITY){
            // if(element.name=='Free' || element.name=='Management' || element.name=='Management & Security'){
              return {
                  ...element
              }
            }
          })
     : []
)