import React, { useEffect } from 'react'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'
import TablePoliceForm from '../../tables/admin/TablePoliceForm'
import FormSearchPoliceForm from '../../forms/admin/FormSearchPoliceForm'
import { useDispatch } from 'react-redux'
import FormsAction from '../../../stores/admin-management/forms/FormsAction'
import GetAllPoliceFormRequest from '../../../stores/admin-management/forms/requests/GetAllPoliceFormRequest'

export default function PagePoliceForm(props) {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(FormsAction._requestGetPoliceFormsList(new GetAllPoliceFormRequest()))
    }, [])
    return (
        <PageBasicContainer className='card'>
            <h2>Search Police Form</h2>
            <FormSearchPoliceForm />
            <TablePoliceForm />
        </PageBasicContainer>
    )
}
