import BaseRequest from '../../../special/models/BaseRequest'

export default class FlatDetailsRequest extends BaseRequest {
    flatId = null

    constructor(data) {
        super()
        this.update(data)
    }
}
