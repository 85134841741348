import ActionUtility from '../../../utils/ActionUtility'
import PackagePaymentEffect from './PackagePaymentEffect'

export default class PackagePaymentAction {
    static REQUEST_CREATE_ORDER = 'REQUEST_CREATE_ORDER'
    static REQUEST_CREATE_ORDER_FINISHED = 'REQUEST_CREATE_ORDER_FINISHED'

    static _requestCreateOrder(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                PackagePaymentAction.REQUEST_CREATE_ORDER,
                PackagePaymentEffect._requestCreateOrder,
                data
            )
        }
    }
}
