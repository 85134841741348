import BaseRequest from '../../../../special/models/BaseRequest'

export class GenerateExpenseVoucherRequest extends BaseRequest {
    items = [
        {
            name: '',
            description: '',
            amount: '',
            date: '',
            typeId: ''
        }
    ]

    month = ''
    title = ''
    buildingId = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
