import { createSelector } from 'reselect'

export class FlatSelector {
    static getFlatsList = (state) => state.flat.flatList
    static getAssociatedPackage = (state) => state.flat.associatedBillPackage
    static getQrCode = (state) => state.flat.qrCode
}

export const makeSelectFlatsList = createSelector(FlatSelector.getFlatsList, (flat) =>
    flat ? flat.data : []
)

export const makeSelectFlatsBillPackage = createSelector(
    FlatSelector.getAssociatedPackage,
    (flat) => (flat ? flat.data : [])
)
export const makeSelectFlatsQrCode = createSelector(
    FlatSelector.getQrCode,
    (flat) => (flat ? flat.data : [])
)