export class DecorationDataModel {
    dataTitle = ''
    dataCount = ''
    backgroundColor = ''
    icon = ''
    constructor(dataTitle, dataCount, backgroundColor, icon) {
        this.dataTitle = dataTitle
        this.dataCount = dataCount
        this.backgroundColor = backgroundColor
        this.icon = icon
    }
}
