import React from 'react'
import { useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import { GreenTag, RedTag } from '../../component/misc/Tags'
import ReportAction from '../../../stores/reporting/ReportAction'
import { makeSelectLedgerReportsList } from '../../../stores/reporting/ReportSelector'

export default function TableLedgerReport() {
    const dataList = useSelector(makeSelectLedgerReportsList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [ReportAction.REQUEST_GET_LEDGER_REPORT])
    )

    const columns = [
        {
            title: 'Date',
            dataIndex: 'entryType',
            render: (text, record) => <b> {new Date(record.date).toLocaleDateString()} </b>
        },
        {
            title: 'Description',
            dataIndex: 'description'
        },
        {
            title: 'Amount',
            dataIndex: 'amount'
        },
        {
            align: 'center',
            title: 'Type',
            render: (text, record) => {
                switch (record.entryType) {
                    case 'debit':
                        return <GreenTag text={'Income'} />
                    case 'credit':
                        return <RedTag text={'Expense'} />
                    default:
                        return <div></div>
                }
            }
        },
        {
            title: 'Running Balance',
            dataIndex: 'balance'
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList.list} columns={columns} />
}
