import React from 'react'
import ResultTypePage from './ResultTypePage'
import FreeUser from '../../assets/images/free_user.png'

const FreeSubscriptionPage = (props) => {
    return (
        <ResultTypePage
            status='402'
            title='You are now Free User!!'
            subTitle='To Unlock This Feature Please Contact Rokkhi Team: '
            image={FreeUser}
        />
    )
}

export default FreeSubscriptionPage
