import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class VehicleEffect {
    static async _requestAddVehicle(data) {
        const endPoint = ApiEndpoint.vehicle.addNewVehicle
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetVehicles(data) {
        const endPoint = ApiEndpoint.vehicle.getVehicles
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
