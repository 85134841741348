import { Document, Font, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { ButtonMildGreen } from '../buttons/CustomButtons'
import SiyamRupali from '../../../assets/font/Siyamrupali.ttf'
import KalpurushANSI from '../../../assets/font/kalpurush-ANSI.ttf'
import SutonnyMJ from '../../../assets/font/SolaimanLipi.ttf'

Font.register({
    family: 'Kalpurush',
    fonts: [{ src: SutonnyMJ }]
})

export default function TenantDeparturePdf() {
    const [ready, setReady] = useState(false)
    const [click, setClick] = useState(false)
    useEffect(() => {
        click && document.getElementById('autoClickBtn').click()
        setReady(false)
        setClick(false)
    }, [click])

    return !ready ? (
        <ButtonMildGreen
            // key={props.data.id}
            onClick={() => setReady(true)}
            text={'PDF'}
            icon={'printer'}
        />
    ) : (
        true && (
            <PDFDownloadLink document={<PDFDocument />} fileName={`Tenant owner pdf`}>
                {({ blob, url, loading, error }) =>
                    loading ? (
                        <ButtonMildGreen text={'Loading'} icon={'printer'} />
                    ) : (
                        <a target='_blank' href={url} id='autoClickBtn' rel='noreferrer'>
                            <ButtonMildGreen text={'PDF'} icon={'printer'} />
                            {setClick(true)}
                        </a>
                    )
                }
            </PDFDownloadLink>
        )
    )
}

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: 'Kalpurush'
    },
    headerText: {
        marginTop: '30px',
        marginBottom: '30px',
        textAlign: 'center'
        // fontFamily: 'SiyamRupali, sans-serif'
    }
})

function PDFDocument() {
    return (
        <Document>
            <Page style={styles.body}>
                <Text>Tenant Departure</Text>
                <Text>তারিখঃ </Text>
                <Text>বাড়ির মালিকের নাম </Text>
                <Text>ঠিকানাঃ </Text>
                <Text>বিষয়ঃ </Text>
                <Text>জনাব, </Text>
                <Text>
                    এই মর্মে জানাচ্ছি যে, আমি পিতা: ঠিকানাঃ এই ঠিকানায় বসবাস করতেন গত তারিখ থেকে
                    আমার জানামতে, এতদিন এই ঠিকানায় অবস্থানরত থাকাকালে তিনি সমাজ বা রাষ্ট্রবিরোধী
                    অথবা অনৈতিক কোন কাজের সাথে জড়িত ছিলেন না এবং তার স্বভাব ও নৈতিকতা ছিল প্রশংসনীয়।
                    তার সাথে আমার পূর্বের কোন লেনদেন বাকি নেই। আমি তার ভবিষ্যৎ মঙ্গল কামনা করি।
                </Text>
                <Text>বাড়ির মালিক</Text>
                <Text></Text>
                <Text>স্বাক্ষর</Text>
            </Page>
        </Document>
    )
}
