import React, { useEffect, useState } from 'react'
import { Button, Icon, Input, message } from 'antd'
import firebase from '../../utils/firebase'
import { useDispatch, useSelector } from 'react-redux'
import PhoneLogin from './modalPhoneLogin'
import './auth.css'
import './signin.scss'
import { Tabs } from 'antd'
import PrimaryTopbar from '../component/navigation/PrimaryTopbar'
import CommunityAction from '../../stores/property-management/community/CommunityAction'
import CreateCommunityRequest from '../../stores/property-management/community/requests/CreateCommunityRequest'
import { selectRequesting } from '../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../stores/special/finished/FinishedSelector'
import PagePerspective from '../pages/PagePerspective'
import ResetAction from '../../stores/special/reset/ResetAction'

const { TabPane } = Tabs

const LogIn = (props) => {
    const analytics = firebase.analytics()
    const dispatch = useDispatch()
    const [forgot, setForgot] = useState(false)

    const forgotPassword = () => {
        setForgot(!forgot)
    }
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [contactInfo, setContactInfo] = useState('')
    const [loading, setLoading] = useState(false)
    // STATE TO HANDLE TAB SWITCHING
    const [isActive, setActive] = useState('1')

    const isRequestingRegister = useSelector((state) =>
        selectRequesting(state, [CommunityAction.REQUEST_SELF_REGISTER_COMMUNITY])
    )
    const isFinishedRegister = useSelector((state) =>
        selectFinished(state, CommunityAction.REQUEST_SELF_REGISTER_COMMUNITY)
    )

    useEffect(() => {
        if (isFinishedRegister) {
            analytics.logEvent('sign_up', {
                // userIP:,
                name,
                email
            })
            setActive('1')
            setName('')
            setAddress('')
            setContactInfo('')
        }
    }, [isFinishedRegister])

    const doLogin = () => {
        setLoading(true)
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((u) => {
                props.userDetails && setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                message.error(error.message)
            })
    }

    const doRegister = () => {
        const values = {
            name: name,
            address: address,
            contactInfo: contactInfo,
            contactPerson: name,
            email: email,
            password: password
        }
        dispatch(CommunityAction._requestselfRegisterCommunity(new CreateCommunityRequest(values)))
    }
    // console.log(isActive);
    useEffect(() => {
        if (
            props.userDetails &&
            props.userDetails.data &&
            props.userDetails.data.userRoles &&
            !props.userDetails.data.userRoles.length
        ) {
            dispatch(ResetAction.resetStorage())
            firebase.auth().signOut()
            localStorage.clear()
            message.error('Something went wrong please try again later')
            setLoading(false)
        }
    }, [props.userDetails])
    return (
        <>
            <PrimaryTopbar />
            {props.userDetails &&
            props.userDetails.data &&
            props.userDetails.data.userRoles &&
            props.userDetails.data.userRoles.length ? (
                <PagePerspective userDetails={props.userDetails} userRoles={props.userDetails.data.userRoles} />
            ) : (
                <div className='signin-container'>
                    <div className='form'>
                        <Tabs
                            className={'signin-tab-group'}
                            activeKey={isActive}
                            onChange={(key) => setActive(key)}
                        >
                            <TabPane tab='SignIn' key='1'>
                                <div id='signin'>
                                    <p></p>
                                    <Input
                                        size='large'
                                        name='email'
                                        style={{ margin: '10px 0px' }}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        prefix={<Icon type='mail' />}
                                        placeholder='Email'
                                    />
                                    <Input.Password
                                        name='password'
                                        style={{ margin: '10px 0px' }}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        prefix={<Icon type='lock' />}
                                        type='password'
                                        placeholder='Password'
                                        onKeyDown={(e) => (e.keyCode === 13 ? doLogin() : '')}
                                        size='large'
                                    />
                                    <Button
                                        loading={loading}
                                        type='submit'
                                        size='default'
                                        onClick={(event) => doLogin()}
                                        className='button button-block'
                                    >
                                        Sign In
                                    </Button>
                                    <div
                                        style={{
                                            marginTop: '5px',
                                            fontSize: '16px',
                                            textAlign: 'end'
                                        }}
                                    >
                                        <a onClick={props.forgotPassword}>
                                            Forgot Password? Click here
                                        </a>
                                    </div>
                                    <PhoneLogin />
                                </div>
                            </TabPane>
                            <TabPane tab='Register' key='2'>
                                <div id='signup'>
                                    <p></p>
                                    <Input
                                        size='large'
                                        name='name'
                                        style={{ margin: '10px 0px' }}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        prefix={<Icon type='user' />}
                                        placeholder='Community/Building Name'
                                    />
                                    <Input
                                        size='large'
                                        name='email'
                                        style={{ margin: '10px 0px' }}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        prefix={<Icon type='mail' />}
                                        placeholder='Email'
                                    />
                                    <Input
                                        size='large'
                                        name='contactInfo'
                                        style={{ margin: '10px 0px' }}
                                        value={contactInfo}
                                        onChange={(e) => setContactInfo(e.target.value)}
                                        prefix={<Icon rotate={90} type='phone' />}
                                        placeholder='Mobile No.'
                                    />
                                    <Input
                                        size='large'
                                        name='address'
                                        style={{ margin: '10px 0px' }}
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        prefix={<Icon type='home' />}
                                        placeholder='Address'
                                    />
                                    <Input.Password
                                        name='password'
                                        style={{ margin: '10px 0px' }}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        prefix={<Icon type='lock' />}
                                        type='password'
                                        placeholder='Password'
                                        size='large'
                                        onKeyDown={(e) => (e.keyCode === 13 ? doRegister() : '')}
                                    />
                                    <Button
                                        loading={isRequestingRegister}
                                        type='submit'
                                        size='default'
                                        onClick={(event) => doRegister()}
                                        className='button button-block'
                                    >
                                        Register
                                    </Button>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            )}
        </>
    )
}
export default LogIn
