import React from 'react'
import listOneImg from '../../../assets/images/list-one-small.png'
import listTwoImg from '../../../assets/images/list-two-small.png'
import listThreeImg from '../../../assets/images/list-three-small.png'

export default function DashboardItem({ data }) {
    return (
        <div className='dashboard-item-container'>
            <div className='card-header'>
                <img src={data.icon} alt='icon' width='30px' />
                <h1>{data.dataTitle}</h1>
            </div>
            <div className='dashboard-card-body'>
                {/* <div className='list-items'>
                    <ul>
                        <li>
                            <img src={listOneImg} alt='icon' />{' '}
                            <span style={{ marginRight: '18px', fontSize: '14px' }}>
                                {data.dataItems[0].title}
                            </span>{' '}
                            <span style={{ float: 'right', fontSize: '14px', fontWeight: 'bold' }}>
                                {data.dataItems[0].amount}{' '}
                            </span>
                        </li>
                        <li>
                            <img src={listTwoImg} alt='icon' />{' '}
                            <span style={{ marginRight: '18px', fontSize: '14px' }}>
                                {data.dataItems[1].title}
                            </span>{' '}
                            <span style={{ float: 'right', fontSize: '14px', fontWeight: 'bold' }}>
                                {data.dataItems[1].amount}{' '}
                            </span>
                        </li>
                        <li>
                            <img src={listThreeImg} alt='icon' />{' '}
                            <span style={{ marginRight: '18px', fontSize: '14px' }}>
                                {data.dataItems[2].title}
                            </span>{' '}
                            <span style={{ float: 'right', fontSize: '14px', fontWeight: 'bold' }}>
                                {data.dataItems[2].amount}{' '}
                            </span>
                        </li>
                    </ul>
                </div> */}
                <h1>&#2547; {data.dataItem}</h1>
                <>{data.dataChart}</>
            </div>
        </div>
    )
}
