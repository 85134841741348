import ActionUtility from "../../../utils/ActionUtility"
import NIDVerifationEffect from "./NIDVerificationEffect"

export default class NIDVerifationAction {
    static REQUEST_GET_USER_VERIFICATION = 'REQUEST_GET_USER_VERIFICATION'
    static REQUEST_GET_USER_VERIFICATION_FINISHED = 'REQUEST_GET_USER_VERIFICATION_FINISHED'

    static _requestGetUserVerification(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                NIDVerifationAction.REQUEST_GET_USER_VERIFICATION,
                NIDVerifationEffect._requestGetUserVerification,
                data
            )
        }
    }
}