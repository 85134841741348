import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import { PaymentStatusConstants } from '../../../assets/constants/GeneralConstants'
import { BlueTag, GreenTag, RedTag } from '../../component/misc/Tags'
import BillAction from '../../../stores/money-management/bill/BillAction'
import { makeSelectBillVouchersList } from '../../../stores/money-management/bill/BillSelector'
import ModalCollectBill from '../../modals/ModalCollectBill'
import ModalBillItems from '../../modals/ModalBillItems'
import FlatInvoice from '../../component/pdf/FlatInvoice'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import { ButtonMildRed } from '../../component/buttons/CustomButtons'
import { DeleteBillItemRequest } from '../../../stores/money-management/bill/requests/DeleteBillItemRequest'
import FlatInvoicePdfPage from '../../component/pdf/FlatInvoicePdfPage'

export default function TableBillInvoice({ paymentStatus }) {
    const dispatch = useDispatch()
    const userDetails = useSelector((state) => state.user.userDetails)
    const dataList = useSelector(makeSelectBillVouchersList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [BillAction.REQUEST_GET_BILL_ITEMS])
    )
    const columns = [
        {
            title: 'Flat Name',
            key: 'flatName',
            dataIndex: 'flatName',
            sorter: (a, b) => a.flatName.localeCompare(b.flatName, undefined, { numeric: true }),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Total',
            dataIndex: 'totalAmount',
            key: 'totalAmount'
        },
        {
            title: 'Paid',
            dataIndex: 'paidAmount',
            key: 'paidAmount'
        },
        {
            title: 'Month',
            dataIndex: 'month',
            key: 'month'
        },
        {
            align: 'center',
            title: 'Payment Status',
            key: 'paymentStatus',
            sorter: (a, b) => a.paymentStatus.localeCompare(b.paymentStatus),
            // defaultSortOrder: 'ascend',
            render: (text, record) => {
                switch (record.paymentStatus) {
                    case PaymentStatusConstants.PAID:
                        return <GreenTag text={'PAID'} />
                    case PaymentStatusConstants.PARTIAL_PAID:
                        return <BlueTag text={'PARTIAL'} />
                    case PaymentStatusConstants.UNPAID:
                        return <RedTag text={'UNPAID'} />
                    default:
                        return <div></div>
                }
            }
        },
        {
            title: 'Details',
            align: 'center',
            key: 'details',
            render: (text, record) =>
                record.paymentStatus === PaymentStatusConstants.PAID ? (
                    <ModalCollectBill data={record} />
                ) : (
                    <ModalCollectBill data={record} />
                )
        },
        {
            title: 'Collect',
            align: 'center',
            key: 'collect',
            render: (text, record) => (
                <ModalBillItems data={record} key={record.id} paymentStatus={paymentStatus} />
            )
        },
        {
            title: 'Document',
            align: 'center',
            key: 'doc',
            render: (text, record) => (
                <FlatInvoicePdfPage
                    data={record}
                    key={record.id}
                    userDetails={userDetails ? userDetails : []}
                    invoiceHeader={userDetails.data}
                />
            )
        },
        {
            title: 'Action',
            align: 'center',
            key: 'action',
            render: (text, record) => {
                if (record.paymentStatus === 'UNPAID') {
                    return (
                        <ModalDeleteConfirmation
                            tooltip={'Delete Bill'}
                            confirmationText={'Are you sure you want to delete this bill ?'}
                            onConfirm={() =>
                                dispatch(
                                    BillAction._requestDeleteBillItem(
                                        new DeleteBillItemRequest({
                                            billInvoiceId: record.id,
                                            paymentAmount: 0
                                        })
                                    )
                                )
                            }
                        />
                    )
                } else {
                    return <ButtonMildRed icon={'delete'} text='' disabled={true} />
                }
            }
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
