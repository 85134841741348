import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class ExpenseEffect {
    static async _requestCreateExpenseType(data) {
        const endPoint = ApiEndpoint.expense.createNewType
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestDeleteExpenseType(data) {
        const endPoint = ApiEndpoint.expense.deleteType
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetExpenseTypes(data) {
        const endPoint = ApiEndpoint.expense.getTypes
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestCreateDirectExpense(data) {
        const endPoint = ApiEndpoint.expense.creatNewEntry
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGenerateExpenseVoucher(data) {
        const endPoint = ApiEndpoint.expense.generateVoucher
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestGetExpenseVouchers(data) {
        const endPoint = ApiEndpoint.expense.getVouchers
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestMakePaymentAgainstVoucher(data) {
        const endPoint = ApiEndpoint.expense.makePaymentAgainstVoucher
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestAuthorizeVoucher(data) {
        const endPoint = ApiEndpoint.expense.authorizeVoucher
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestDeleteVoucher(data) {
        const endPoint = ApiEndpoint.expense.deleteVoucher
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestDeleteExpenseItem(data) {
        const endPoint = ApiEndpoint.expense.deleteItem
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetExpenseItems(data) {
        const endPoint = ApiEndpoint.expense.getExpenseEntries
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
