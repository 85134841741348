import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import ButtonFormSubmit from '../ButtonFormSubmit'
import { ColorConstants } from '../../../assets/constants/GeneralConstants'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { ExpenseIcon } from '../../misc/IconsProvider'
import { FormIconContainer } from '../FormStyles'
import FormDataFilter from '../FormDataFilter'
import { PageDecorationContainer } from '../../pages/PageStyles'
import ExpenseAction from '../../../stores/money-management/expense/ExpenseAction'
import { GetExpenseTypeRequest } from '../../../stores/money-management/expense/requests/type/GetExpenseTypeRequest'
import { makeSelectExpenseTypesList } from '../../../stores/money-management/expense/ExpenseSelector'
import SingleExpenseVoucherItem from './SingleExpenseVoucherItem'
import { GenerateExpenseVoucherRequest } from '../../../stores/money-management/expense/requests/voucher/GenerateExpenseVoucherRequest'
import { FormInputText } from '../../component/form-input/FormInputText'
import {
    checkIsAmountZeroOrEmpty,
    getCurrentMonthWithYear,
    removeNullValueObject
} from '../../../utils/functions/functions'
import { message } from 'antd'

const INITIAL_STATE = {
    title: '',
    month: getCurrentMonthWithYear(),
    buildingId: ''
}

const VALIDATION_SCHEMA = Yup.object({
    title: Yup.string()
        .required('Voucher Title is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Max 40 Characters'),
    month: Yup.string().required('Month is Required'),
    buildingId: Yup.string().required('Building is Required')
})

export default function FormGenerateExpenseVoucher() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(ExpenseAction._requestGetExpenseTypes(new GetExpenseTypeRequest()))
    }, [])
    const [activeExpenseTypes, setActiveExpenseTypes] = useState([])
    const [expenseItemsFromChild, setExpenseItemsFromChild] = useState({})

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [ExpenseAction.REQUEST_GENERATE_EXPENSE_VOUCHER])
    )
    const isFinished = useSelector((state) =>
    selectFinished(state, ExpenseAction.REQUEST_GENERATE_EXPENSE_VOUCHER)
    )
    const expenseTypes = useSelector(makeSelectExpenseTypesList)
    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        const expenseItems = removeNullValueObject(activeExpenseTypes)
        values['items'] = Object.values(expenseItems)
        const parsedArray = values.items.map((item,index)=>values.items[index].amount=parseFloat(item.amount))
        // const checkAmountZero = checkIsAmountZeroOrEmpty(expenseItems)
        const isZero = parsedArray.includes(0);

        if (Object.keys(expenseItems).length) {
            if (!isZero) {
                dispatch(ExpenseAction._requestGenerateExpenseVoucher(new GenerateExpenseVoucherRequest(values)))
            } else {
                message.error("Amount Can't Zero or Empty")
            }
        } else {
            message.error('No Expense Item Found')
        }
    }
    useEffect(() => {
        const ExpenseItemsFromType = expenseTypes.map((expenseType) => {
            return {
                name: expenseType.name,
                description: expenseType.description,
                amount: expenseType.defaultAmount,
                typeId: expenseType.id
            }
        })
        setActiveExpenseTypes(ExpenseItemsFromType)
    }, [expenseTypes])




    const makePreviousDataInvalid = (itemIndex) => {
        const typeId = activeExpenseTypes[itemIndex].id
        const newState = { ...expenseItemsFromChild, [typeId]: null }
        setExpenseItemsFromChild(newState)
    }

    const removeBillItem = (itemIndex) => {
        makePreviousDataInvalid(itemIndex)
        let newItems = activeExpenseTypes.filter((item, index) => index !== itemIndex)
        setActiveExpenseTypes(newItems)
    }

    const receiveDataInParent = (data,index) => {
        setExpenseItemsFromChild((oldState) => {
            return {
                ...oldState,
                [data.typeId]: data
            }
        })
        let newItems = [...activeExpenseTypes]
        newItems[index] = data;
        setActiveExpenseTypes(newItems)
    }

    return (
        <FormIconContainer>
            <FormTitleWithIcon
                icon={<ExpenseIcon />}
                color={ColorConstants.GREEN1}
                formTitle={'Generate Expense Voucher'}
            />
            <PageDecorationContainer item={3} style={{ padding: 10 }} paddingTop={'0px'}>
                <FormDataFilter
                    control={control}
                    label={'Select Building'}
                    showBuildingFilter={true}
                />
                <FormDataFilter control={control} showMonthPicker={true} />
            </PageDecorationContainer>
            <FormInputText label={'Voucher Title'} control={control} name={'title'} />
            <h2> Items</h2>
            <div>
                {activeExpenseTypes.map((expenseType, index) => (
                    <SingleExpenseVoucherItem
                        key={index}
                        index={index}
                        removeItem={removeBillItem}
                        expenseType={expenseType}
                        sendDataToParent={receiveDataInParent}
                    />
                ))}
            </div>
            <ButtonFormSubmit
                title={'Generate Voucher'}
                isRequesting={isRequesting}
                control={control}
                onSubmit={onSubmit}
                isFinished={isFinished}
            />
        </FormIconContainer>
    )
}
