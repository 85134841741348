import React, { useEffect, useState } from 'react'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'
import './pages.scss'
import TableDataFilter from '../../tables/TableDataFilter'
import { useDispatch, useSelector } from 'react-redux'
import { makeSelectUsersList } from '../../../stores/user-management/user/UserSelector'
import TableUsersForAddForm from '../../tables/admin/TableUsersForAddForm'
import FormsAction from '../../../stores/admin-management/forms/FormsAction'
import GetAllUsersFormRequest from '../../../stores/admin-management/forms/requests/GetAllUsersFormRequest'
import NIDVerifationAction from '../../../stores/admin-management/nid-verification/NIDVerificationAction'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'

const PageAllForms = () => {
    const dispatch = useDispatch()

    const [filterValues, setFilterValues] = useState(null)

    const dataList = useSelector(makeSelectUsersList)

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    console.log(receiveFilterData)
    const isFinishedNIDVerify = useSelector((state) =>
        selectFinished(state, NIDVerifationAction.REQUEST_GET_USER_VERIFICATION)
    )
    useEffect(() => {
        dispatch(FormsAction._requestGetUserFormsList(new GetAllUsersFormRequest(filterValues)))
    }, [filterValues, isFinishedNIDVerify])
    return (
        <PageBasicContainer>
            <PageMainBarContainer>
                <TableDataFilter
                    showBuildingFilter={true}
                    showFlatFilter={true}
                    sendDataToParent={receiveFilterData}
                />
                <TableUsersForAddForm />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}

export default PageAllForms
