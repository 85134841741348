import BaseRequest from '../../../special/models/BaseRequest'

export class GenerateBillInvoiceForFlatRequest extends BaseRequest {
    billItems = {
        name: '',
        description: '',
        amount: '',
        typeId: ''
    }
    title = ''
    lastDateOfPayment = ''
    month = ''
    flatId = ''
    buildingId = ''

    constructor(data) {
        super()
        this.flatId = data.flatId
        this.update(data)
    }
}
