import BaseReducer from '../../../utils/BaseReducer'
import PackagePaymentAction from './PackagePaymentAction'

export default class PackagePaymentReducer extends BaseReducer {
    initialState = {
        order: {}
    };

    [PackagePaymentAction.REQUEST_CREATE_ORDER_FINISHED](state, action) {
        // console.log(action.payload);
        return {
            ...state,
            order: action.payload
        }
    }
}
