import ReportEffect from './ReportEffect'
import ActionUtility from '../../utils/ActionUtility'

export default class ReportAction {
    static REQUEST_GET_LEDGER_REPORT = 'REQUEST_GET_LEDGER_REPORT'
    static REQUEST_GET_LEDGER_REPORT_FINISHED = 'REQUEST_GET_LEDGER_REPORT_FINISHED'

    static REQUEST_GET_INCOME_REPORT = 'REQUEST_GET_INCOME_REPORT'
    static REQUEST_GET_INCOME_REPORT_FINISHED = 'REQUEST_GET_INCOME_REPORT_FINISHED'

    static REQUEST_GET_EXPENSE_REPORT = 'REQUEST_GET_EXPENSE_REPORT'
    static REQUEST_GET_EXPENSE_REPORT_FINISHED = 'REQUEST_GET_EXPENSE_REPORT_FINISHED'

    static REQUEST_GET_CASH_FLOW_REPORT = 'REQUEST_GET_CASH_FLOW_REPORT'
    static REQUEST_GET_CASH_FLOW_REPORT_FINISHED = 'REQUEST_GET_CASH_FLOW_REPORT_FINISHED'

    static REQUEST_GET_INCOME_STATEMENT = 'REQUEST_GET_INCOME_STATEMENT'
    static REQUEST_GET_INCOME_STATEMENT_FINISHED = 'REQUEST_GET_INCOME_STATEMENT_FINISHED'

    static _requestGetLedgerReport(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ReportAction.REQUEST_GET_LEDGER_REPORT,
                ReportEffect._requestGetLedgerReport,
                data
            )
        }
    }

    static _requestGetIncomeReport(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ReportAction.REQUEST_GET_INCOME_REPORT,
                ReportEffect._requestGetIncomeReport,
                data
            )
        }
    }

    static _requestGetExpenseReport(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ReportAction.REQUEST_GET_EXPENSE_REPORT,
                ReportEffect._requestGetExpenseReport,
                data
            )
        }
    }

    static _requestGetCashFlowReport(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ReportAction.REQUEST_GET_CASH_FLOW_REPORT,
                ReportEffect._requestGetCashFlowReport,
                data
            )
        }
    }

    static _requestGetIncomeStatement(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                ReportAction.REQUEST_GET_INCOME_STATEMENT,
                ReportEffect._requestGetIncomeStatement,
                data
            )
        }
    }
}
