import { createSelector } from 'reselect'

export class CommunitySelector {
    static getCommunityList = (state) => state.community.communityList
}

export const makeSelectCommunityList = createSelector(
    CommunitySelector.getCommunityList,
    (communityList) => (communityList ? communityList.data : [])
)
