import BaseRequest from '../../../special/models/BaseRequest'

export default class AssignRolesToUserRequest extends BaseRequest {
    toUserId = 0
    toBuildingId = 0
    toFlatId = 0
    roleIds = []

    constructor(userId, roleIds, toBuildingId, toFlatId) {
        super()
        this.toUserId = parseInt(userId)
        this.roleIds = roleIds
        if (toBuildingId) {
            this.toBuildingId = parseInt(toBuildingId)
        }
        if (toFlatId) {
            this.toFlatId = parseInt(toFlatId)
        }
    }
}
