import { Button } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import UserAction from '../../stores/user-management/user/UserAction'
import { ButtonFillPurple } from '../component/buttons/CustomButtons'
import ResetAction from '../../stores/special/reset/ResetAction'
import firebase from '../../utils/firebase'

export default function PagePerspective({ userRoles }) {
    const dispatch = useDispatch()
    const selectPerspective = (selectedRole) => {
        localStorage.setItem('perspective', JSON.stringify(selectedRole))
        dispatch(UserAction._setPerspective(selectedRole))
        console.log('role', selectedRole)
    }
    const logout = () => {
        dispatch(ResetAction.resetStorage())
        firebase.auth().signOut()
        localStorage.clear()
    }

    return (
        <div className='page-perspective-container'>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '30px',
                    flexWrap: 'wrap'
                }}
            >
                <Button onClick={() => logout()}> Log Out</Button>

                <h2 style={{ textAlign: 'center', margin: '0' }}>
                    You Have Access to Those Community, Buildings & Flats{' '}
                </h2>
                <span style={{ fontWeight: '300', margin: '0 0 -4px 20px', color: '#ff3300' }}>
                    Please Select One to Access
                </span>
            </div>
            <div className='page-perspective-card-container'>
                {userRoles.map((role) =>
                    role.communityName && role.communityId ? (
                        <div className='perspective-card'>
                            <h1>{role.communityName}</h1>
                            <h3>{role.userRoleName}</h3>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <ButtonFillPurple
                                    text={'Select'}
                                    onClick={() => selectPerspective(role)}
                                />
                            </div>
                        </div>
                    ) : (
                        ''
                    )
                )}
            </div>
        </div>
    )
}
