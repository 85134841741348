import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import NoticeAction from './NoticeAction'

const NoticePostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case NoticeAction.REQUEST_CREATE_NOTICE_FINISHED:
                next(MiscAction._showModalSuccess('Notice Created Successfully'))
                break
            case NoticeAction.REQUEST_DELETE_NOTICE_FINISHED:
                next(MiscAction._showModalSuccess('Notice Deleted Successfully'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default NoticePostEffect
