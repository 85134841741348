import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import BuildingAction from './BuildingAction'

const BuildingPostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            // case BuildingAction.REQUEST_CREATE_BUILDING_FINISHED:
            //     next(MiscAction._showModalSuccess('Building Created Successfully'))
            //     break
            case BuildingAction.REQUEST_DELETE_BUILDING_FINISHED:
                next(MiscAction._showModalSuccess('Building Deleted Successfully'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default BuildingPostEffect
