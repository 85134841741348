import ActionUtility from '../../../utils/ActionUtility'
import CommunityEffect from './CommunityEffect'

export default class CommunityAction {
    static REQUEST_GET_COMMUNITY_LIST = 'REQUEST_GET_COMMUNITY_LIST'
    static REQUEST_GET_COMMUNITY_LIST_FINISHED = 'REQUEST_GET_COMMUNITY_LIST_FINISHED'

    static REQUEST_CREATE_COMMUNITY = 'REQUEST_CREATE_COMMUNITY'
    static REQUEST_CREATE_COMMUNITY_FINISHED = 'REQUEST_CREATE_COMMUNITY_FINISHED'
    
    static REQUEST_SELF_REGISTER_COMMUNITY = 'REQUEST_SELF_REGISTER_COMMUNITY'
    static REQUEST_SELF_REGISTER_COMMUNITY_FINISHED = 'REQUEST_SELF_REGISTER_COMMUNITY_FINISHED'

    static REQUEST_DELETE_COMMUNITY = 'REQUEST_DELETE_COMMUNITY'
    static REQUEST_DELETE_COMMUNITY_FINISHED = 'REQUEST_DELETE_COMMUNITY_FINISHED'

    static _requestGetCommunityList(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                CommunityAction.REQUEST_GET_COMMUNITY_LIST,
                CommunityEffect._requestGetAllCommunities,
                data
            )
        }
    }

    static _requestCreateCommunity(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                CommunityAction.REQUEST_CREATE_COMMUNITY,
                CommunityEffect._requestCreateCommunity,
                data
            )
        }
    }
    static _requestselfRegisterCommunity(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                CommunityAction.REQUEST_SELF_REGISTER_COMMUNITY,
                CommunityEffect._requestselfRegisterCommunity,
                data
            )
        }
    }

    static _requestDeleteCommunity(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                CommunityAction.REQUEST_DELETE_COMMUNITY,
                CommunityEffect._requestDeleteCommunity,
                data
            )
        }
    }
}
