import React, { useEffect, useState } from 'react'
import { Empty, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import {
    DropDownConstants,
    UserRoleCodeConstants
} from '../../../assets/constants/GeneralConstants'
import PrivilegeAction from '../../../stores/user-management/privilege/PrivilegeAction'
import BaseRequest from '../../../stores/special/models/BaseRequest'
import { selectDropDownItems } from '../../../selectors/DropDownDataSelector'
import CommunityAction from '../../../stores/property-management/community/CommunityAction'
import BuildingAction from '../../../stores/property-management/building/BuildingAction'
import GetBuildingsRequest from '../../../stores/property-management/building/request/GetBuildingsRequest'
import BillAction from '../../../stores/money-management/bill/BillAction'
import { GetBillTypeRequest } from '../../../stores/money-management/bill/requests/GetBillTypeRequest'
import ExpenseAction from '../../../stores/money-management/expense/ExpenseAction'
import { GetExpenseTypeRequest } from '../../../stores/money-management/expense/requests/type/GetExpenseTypeRequest'
import UserAction from '../../../stores/user-management/user/UserAction'
import GetUsersRequest from '../../../stores/user-management/user/requests/GetUsersRequest'
import FingerPrintAction from '../../../stores/device-management/fingerprint-device/FingerPrintAction'
import GetFingerPrintsRequest from '../../../stores/device-management/fingerprint-device/request/GetFingerPrintsRequest'
import FundAction from '../../../stores/money-management/fund/FundAction'
import { GetFundsRequest } from '../../../stores/money-management/fund/requests/GetFundsRequest'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import FlatAction from '../../../stores/property-management/flat/FlatAction'

const { Option } = Select

export default function FormMultipleInputDropdown(props) {
    const dispatch = useDispatch()
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [
            BuildingAction.REQUEST_GET_BUILDINGS,
            FlatAction.REQUEST_GET_FLATS
        ])
    )
    useEffect(() => {
        switch (props.dropDownFor) {
            case DropDownConstants.TYPE_USERS:
                dispatch(UserAction._requestGetUserList(new GetUsersRequest()))
                break
            case DropDownConstants.TYPE_SERVICE_WORKER:
                dispatch(
                    UserAction._requestGetUserList(
                        new GetUsersRequest({}, UserRoleCodeConstants.SERVICE_WORKER)
                    )
                )
                break
            case DropDownConstants.TYPE_USER_ROLE:
                dispatch(PrivilegeAction._requestGetUserRoles(new BaseRequest()))
                break
            case DropDownConstants.TYPE_BILL_PACKAGES:
                dispatch(BillAction._requestGetBillPackages(new BaseRequest()))
                break
            case DropDownConstants.TYPE_COMMUNITY:
                dispatch(CommunityAction._requestGetCommunityList(new BaseRequest()))
                break
            case DropDownConstants.TYPE_BUILDINGS:
                dispatch(BuildingAction._requestGetBuildingList(new GetBuildingsRequest()))
                break
            // case DropDownConstants.TYPE_FLATS:
            //     dispatch(FlatAction._requestGetFlatList(new GetFlatsRequest()))
            //     break
            case DropDownConstants.TYPE_BILL_TYPES:
                dispatch(BillAction._requestGetBillTypes(new GetBillTypeRequest()))
                break
            case DropDownConstants.TYPE_EXPENSE_TYPES:
                dispatch(ExpenseAction._requestGetExpenseTypes(new GetExpenseTypeRequest()))
                break
            case DropDownConstants.TYPE_FINGERPRINT:
                dispatch(FingerPrintAction._requestGetFingerprints(new GetFingerPrintsRequest()))
                break
            case DropDownConstants.TYPE_FUNDS:
                dispatch(FundAction._requestGetFunds(new GetFundsRequest()))
                break
            default:
                break
        }
    }, [])

    const dropDownItems = useSelector((state) => selectDropDownItems(state, props.dropDownFor))

    const isTouched = props.control.touched[`${props.name}`]
    const error = props.control.errors[`${props.name}`]
    const value = props.control.values[`${props.name}`]
    const [buildingId, setBuildingId] = useState(undefined)
    useEffect(() => {
        if (props.dropDownFor === DropDownConstants.TYPE_BUILDINGS && dropDownItems.length === 1) {
            setBuildingId(dropDownItems[0].value)
        }
    }, [dropDownItems.length])
    useEffect(() => {
        buildingId && props.control.setValue(props.name, buildingId)
    }, [buildingId])
    return (
        <Form.Item
            label={props.label}
            hasFeedback
            validateStatus={isTouched ? (error ? 'error' : 'success') : ''}
            help={isTouched ? error : ''}
        >
            <Select
                mode='multiple'
                placeholder={props.placeholder}
                showSearch
                style={{ width: '100%' }}
                optionFilterProp='children'
                size={'large'}
                value={
                    value
                        ? props.flatOption
                            ? props.control.values['flatId'] !== undefined
                                ? value
                                : []
                            : value
                        : undefined
                }
                allowClear={true}
                onChange={(selectedValue) => {
                    console.log(selectedValue)
                    props.control.handleInputChange(props.name, selectedValue)
                }}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                loading={isRequesting}
                notFoundContent={
                    isRequesting ? (
                        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Loading Items...</p>
                    ) : (
                        <Empty
                            size={'small'}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            className={'ant-empty-small'}
                        />
                    )
                }
            >
                {!isRequesting &&
                    dropDownItems &&
                    dropDownItems
                        .sort((a, b) => a.label.localeCompare(b.label, undefined, {numeric: true}))
                        .map((item) => (
                            <Option key={item.value} value={item.value}>
                                {item.label}
                            </Option>
                        ))}
            </Select>
        </Form.Item>
    )
}
