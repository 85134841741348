import React, { useEffect, useState } from 'react'
import { UserRoleCodeConstants } from '../../../assets/constants/GeneralConstants'
import { Tabs } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import TableDataFilter from '../../tables/TableDataFilter'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { makeSelectUsersList } from '../../../stores/user-management/user/UserSelector'
import UserAction from '../../../stores/user-management/user/UserAction'
import GetUsersRequest from '../../../stores/user-management/user/requests/GetUsersRequest'
import TableUsers from '../../tables/privilege/TableUsers'
import { DataBuilderUtility } from '../../../utils/DataBuilderUtility'
import { CardInfoColoredBoxIcon } from '../../component/decoration/CardSingleInfo'
import { PageBasicContainer, PageDecorationContainer, PageMainBarContainer } from '../PageStyles'
import DynamicHelmet from '../../misc/DynamicHelmet'

const { TabPane } = Tabs

export default function PageUsers(props) {
    const dispatch = useDispatch()
    const [userRoleCode, setUserRoleCode] = useState(
        UserRoleCodeConstants.GENERAL_REGISTERED_USER_APP
    )
    const [filterValues, setFilterValues] = useState(null)
    const isFinished = useSelector((state) => selectFinished(state, UserAction.REQUEST_DELETE_USER))

    const dataList = useSelector(makeSelectUsersList)
    const decorationArr = DataBuilderUtility._getUsersDataOverview(dataList)

    const changeUserType = (userRoleCode) => setUserRoleCode(userRoleCode)

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        dispatch(UserAction._requestGetUserList(new GetUsersRequest(filterValues, userRoleCode)))
    }, [filterValues, userRoleCode, isFinished])

    const decorItems = decorationArr.map((item) => <CardInfoColoredBoxIcon data={item} />)
    return (
        <PageBasicContainer>
            <DynamicHelmet title={'Rokkhi | Flat Members'} description={'Flat members description'} />
            <PageDecorationContainer paddingTop={'0px'} item={5}>
                {decorItems}
            </PageDecorationContainer>
            <PageMainBarContainer>
                <TableDataFilter
                    showBuildingFilter={true}
                    showFlatFilter={true}
                    sendDataToParent={receiveFilterData}
                />
                <div>
                    <Tabs onChange={changeUserType} type='card'>
                        <TabPane
                            tab='General User App'
                            key={UserRoleCodeConstants.GENERAL_REGISTERED_USER_APP}
                        />
                        <TabPane
                            tab='General User Non App'
                            key={UserRoleCodeConstants.GENERAL_REGISTERED_USER_NON_APP}
                        />
                        <TabPane tab='Guard' key={UserRoleCodeConstants.GUARD} />
                        <TabPane tab='Children' key={UserRoleCodeConstants.CHILD} />
                        <TabPane tab='Service Worker' key={UserRoleCodeConstants.SERVICE_WORKER} />
                        <TabPane tab='Guard Phone' key={UserRoleCodeConstants.GUARD_PHONE} />
                    </Tabs>
                    <TableUsers />
                </div>
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
