import React, { useState } from 'react'
import { ButtonMildBlue } from '../component/buttons/CustomButtons'
import { Modal, Button } from 'antd'
import './tenantOwnerForm.scss'
import './modals.scss'
import { Box, Flex, Heading, ListItem, ThemeProvider, Text, theme } from '@chakra-ui/react'
import { UnorderedList } from '@chakra-ui/react'
import { Th, Td } from '../component/table/Table'
import { PageFormContainer } from '../pages/PageStyles'
import TenantOwnerPdf from '../component/pdf/TenantOwnerPdf'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

export default function ModalTenantOwnerForm({ data }) {
    const [visibility, setVisibility] = useState(false)

    const customTheme = {
        ...theme
    }

    const handlePrint = async () => {
        var date = new Date()
        const content = document.getElementById('printDoc')
        html2canvas(content).then((canvas) => {
            var doc = new jsPDF('p', 'mm', 'a4')
            var width = doc.internal.pageSize.getWidth()
            var height = doc.internal.pageSize.getHeight()
            const imgData = canvas.toDataURL('image/png')
            doc.addImage(imgData, 'JPEG', 0, 0, width, height);
            doc.save('tenant-owner-form-'+date.toLocaleDateString()+'.pdf');
        })
    }

    return (
        <>
            <ButtonMildBlue icon={'eye'} text={'View'} onClick={() => setVisibility(true)} />
            <ThemeProvider theme={customTheme}>
                <Modal
                    className='tenant-modal-form'
                    bodyStyle={{ height: '70vh', overflowY: 'auto' }}
                    title={`Tenant Details`}
                    centered
                    visible={visibility}
                    onCancel={() => setVisibility(false)}
                    onOk={() => setVisibility(false)}
                    okText={'Close'}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    footer={[
                        <Button
                            type='danger'
                            key='close'
                            cursor='pointer'
                            onClick={() => setVisibility(false)}
                        >
                            Close Details
                        </Button>,
                        <Button
                        type='danger'
                        key='close'
                        cursor='pointer'
                        onClick={() => handlePrint()}
                    >
                        Print
                    </Button>
                    ]}
                >
                    <PageFormContainer id={'printDoc'}>
                        <Box bg='white' borderRadius={6} py={5} px={5} color='black'>
                            <Box>
                                <Heading as='h3' size='md' my={3} textAlign='center'>
                                    বাড়িওয়ালা-ভাড়াটিয়া চুক্তিপত্র
                                </Heading>
                                <Box>
                                    <form>
                                        <Flex justifyContent='space-between'>
                                            <Box w='80%'>
                                                <Text fontSize='lg' p={2}>
                                                    তারিখঃ {data.applicationDate}
                                                </Text>
                                            </Box>
                                            {/* <TenantOwnerPdf></TenantOwnerPdf> */}
                                        </Flex>
                                        <Box>
                                            <Text fontSize='lg' p={2}>
                                                বাড়ির মালিকের নাম: {data.ownerName}
                                            </Text>
                                        </Box>

                                        <Text fontSize='lg' p={2}>
                                            ঠিকানা: {data.ownerAddress}
                                        </Text>

                                        <Text fontSize='lg' p={2}>
                                            বিষয়ঃ {data.subject}
                                        </Text>

                                        <Box>
                                            <Text fontSize='lg' pt={2} pl={2} m={0}>
                                                জনাব,
                                            </Text>
                                            <Text fontSize='lg' p={2}>
                                                সম্মানপূর্বক জানাচ্ছি যে, আমি {data.tenantName}{' '}
                                                আগামী {data.arrivalDate} তারিখ হতে জনাব{' '}
                                                {data.ownerName} এর বাসা, ঠিকানাঃ{' '}
                                                {data.ownerAddress} তে {data.advanceAmount} টাকা
                                                ফেরতযোগ্য অগ্রিম ভাড়া এবং {data.amount} টাকা মাসিক
                                                ভাড়ায় বাড়ি ভাড়া নিয়ে চুক্তিবদ্ধ হচ্ছি।
                                            </Text>
                                            <Heading as='h6' size='sm' mt={2} p={2}>
                                                ব্যক্তিগত তথ্যঃ
                                            </Heading>
                                            <Flex w='100%' direction='column'>
                                                <Box>
                                                    <table>
                                                        {/* <Thead> */}
                                                        <tr>
                                                            <Th style={{ width: '12%' }}>
                                                                {' '}
                                                                <Heading as='h6' size='sm'>
                                                                    তথ্যঃ
                                                                </Heading>{' '}
                                                            </Th>
                                                            <Th style={{ width: '40%' }}>
                                                                {' '}
                                                                <Heading as='h6' size='sm'>
                                                                    ভাড়াটিয়া
                                                                </Heading>
                                                            </Th>
                                                            <Th style={{ width: '40%' }}>
                                                                {' '}
                                                                <Heading as='h6' size='sm'>
                                                                    বাড়িওয়ালা
                                                                </Heading>
                                                            </Th>
                                                        </tr>
                                                        <tr>
                                                            <Td>পুরো নামঃ</Td>
                                                            <Td>{data.tenantFullname}</Td>
                                                            <Td>{data.ownerFullname}</Td>
                                                        </tr>
                                                        <tr>
                                                            <Td>পিতার নামঃ</Td>
                                                            <Td>{data.tenantFathersName}</Td>
                                                            <Td>{data.ownerFathersName}</Td>
                                                        </tr>
                                                        <tr>
                                                            <Td>শিক্ষাগত যোগ্যতাঃ </Td>
                                                            <Td>{data.tenantEduQualification}</Td>
                                                            <Td>{data.ownerEduQualification}</Td>
                                                        </tr>
                                                        <tr>
                                                            <Td>পেশাঃ </Td>
                                                            <Td>{data.tenantOccupation}</Td>
                                                            <Td>{data.ownerOccupation}</Td>
                                                        </tr>
                                                        <tr>
                                                            <Td>বৈবাহিক অবস্থাঃ </Td>
                                                            <Td>{data.tenantMaritalStatus}</Td>
                                                            <Td>{data.ownerMaritalStatus}</Td>
                                                        </tr>
                                                        <tr>
                                                            <Td>পরিপূর্ণ ঠিকানাঃ </Td>
                                                            <Td>{data.tenantFullAddress}</Td>
                                                            <Td>{data.ownerFullAddress}</Td>
                                                        </tr>
                                                        <tr>
                                                            <Td>জাতীয় পরিচয়পত্র নংঃ </Td>
                                                            <Td>{data.tenantNID}</Td>
                                                            <Td>{data.ownerNID}</Td>
                                                        </tr>
                                                        <tr>
                                                            <Td>ফোন নংঃ </Td>
                                                            <Td>{data.tenantPhone}</Td>
                                                            <Td>{data.ownerPhone}</Td>
                                                        </tr>
                                                    </table>
                                                </Box>
                                            </Flex>
                                            <Box mt={3}>
                                                <Text fontSize='lg' p={2} fontWeight={600}>
                                                    পূর্ববর্তী বাড়ির তথ্যঃ{' '}
                                                </Text>
                                                <UnorderedList>
                                                    <ListItem>
                                                        <Text m={0} fontSize='lg' p={2}>
                                                            বাড়িওয়ালার নামঃ{data.prevOwner}
                                                        </Text>
                                                    </ListItem>
                                                    <ListItem>
                                                        <Text m={0} fontSize='lg' p={2}>
                                                            বাড়ির ঠিকানাঃ{data.prevAddress}
                                                        </Text>
                                                    </ListItem>
                                                    <ListItem>
                                                        <Text m={0} fontSize='lg' p={2}>
                                                            বাড়ি ছাড়ার কারনঃ{data.causeOfLeave}
                                                        </Text>
                                                    </ListItem>
                                                </UnorderedList>
                                            </Box>
                                            <Box mt={4}>
                                                <Text fontSize='lg' p={2} fontWeight={600}>
                                                    জরুরী নির্দেশনাঃ{' '}
                                                </Text>
                                                <UnorderedList>
                                                    <ListItem>
                                                        <Text m={0} fontSize='lg' p={2}>
                                                            ২ কপি পাসপোর্ট সাইজ ছবি
                                                        </Text>
                                                    </ListItem>
                                                    <ListItem>
                                                        <Text m={0} fontSize='lg' p={2}>
                                                            জন্মনিবন্ধন সার্টিফিকেট
                                                        </Text>
                                                    </ListItem>
                                                </UnorderedList>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Text fontSize='lg' p={2}>
                                                ***বাড়ি ভাড়া {data.formSubmitDate} নেয়ার দিনের ভিতর ভাড়াটিয়া
                                                পরিপূর্ণ তথ্যসহিত এই ফরমটি বাড়িওয়ালা কাছে জমা দিয়ে
                                                বাধিত থাকিবেন।
                                            </Text>
                                        </Box>
                                        <Box mt={4}>
                                            <Text fontSize='lg' p={2} fontWeight={600}>
                                                শর্তাবলীঃ{' '}
                                            </Text>
                                            <UnorderedList>
                                                <ListItem>
                                                    <Text m={0} fontSize='lg' p={2}>
                                                        মাসের প্রথম {data.billingDate} দিনের ভিতর বাসার সমস্ত
                                                        বিল এবং ভাড়া পরিশোধ করতে হবে।
                                                    </Text>
                                                </ListItem>
                                                <ListItem>
                                                    <Text m={0} fontSize='lg' p={2}>
                                                        বাড়িওয়ালা প্রদত্ত যেকোন সম্পত্তি নষ্ট হলে
                                                        ভাড়াটিয়া দায়ী হিসেবে গন্য হবে এবং ক্ষতিপূরণ
                                                        দিতে বাধিত থাকিবেন।
                                                    </Text>
                                                </ListItem>
                                                <ListItem>
                                                    <Text m={0} fontSize='lg' p={2}>
                                                        অনৈতিক বা অপরাধমূলক কোনো কর্মকাণ্ডের সাথে
                                                        জড়িত থাকলে ভাড়াটিয়াকে তাৎক্ষনিকভাবে
                                                        আইনানুযায়ী ব্যবস্থা নেয়া হবে।
                                                    </Text>
                                                </ListItem>
                                                <ListItem>
                                                    <Text m={0} fontSize='lg' p={2}>
                                                        বাসা ছাড়ার {data.noticeDate} কমপক্ষে দিন আগে
                                                        বাড়িওয়ালাকে বিষয়টি অবগত করতে হবে।
                                                    </Text>
                                                </ListItem>
                                            </UnorderedList>
                                        </Box>
                                        <Box my={5}>
                                            <Flex justifyContent='space-between' w='100%'>
                                                <Box>
                                                    <Text fontSize='lg' p={2} fontWeight={600}>
                                                        বাড়িওয়ালা
                                                    </Text>
                                                    <Text fontSize='lg' p={2}>
                                                        {data.ownerSignature}
                                                    </Text>
                                                    <Text fontSize='lg' p={2}>
                                                        স্বাক্ষর (নাম)
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text fontSize='lg' p={2} fontWeight={600}>
                                                        ভাড়াটিয়া
                                                    </Text>
                                                    <Text fontSize='lg' p={2}>
                                                        {data.tenantSignature}
                                                    </Text>
                                                    <Text fontSize='lg' p={2}>
                                                        স্বাক্ষর (নাম)
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </form>
                                </Box>
                            </Box>
                        </Box>
                    </PageFormContainer>
                </Modal>
            </ThemeProvider>
        </>
    )
}
