import BaseRequest from '../../../special/models/BaseRequest'

export default class CreatePreAuthorizationEntryRequest extends BaseRequest {
    authorizationListId = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
