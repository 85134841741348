import {
    Box,
    Heading,
    ThemeProvider,
    theme,
    Text,
    Input,
    Flex,
    Button
} from '@chakra-ui/react'
import { decode } from 'base-64'
// import { Button } from 'antd'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import FormsAction from '../../../stores/admin-management/forms/FormsAction'
import CreateNewTanentDepartureFormRequest from '../../../stores/admin-management/forms/requests/CreateNewTanentDepartureFormRequest'
import { PageFormContainer } from '../../pages/PageStyles'

export default function FormTenantDeparture() {

    const { encryptedData } = useParams()
    const dispatch = useDispatch();

    try {
        const decodedData = JSON.parse(decode(encryptedData))
        var { userId } = decodedData
    } catch (error) {
        // message.error('Wrong user id!')
    }


    const { handleSubmit, register } = useForm()

    const customTheme = {
        ...theme
    }

    const onSubmit = (data) => {
        const values = {
            userId: userId,
            forms: { ...data }
        }
        dispatch(FormsAction._requestCreateNewDepartureForm(new CreateNewTanentDepartureFormRequest(values)));
    }

    return (
        <ThemeProvider theme={customTheme}>
            {/* <CSSReset /> */}
            <PageFormContainer>
                <Box bg='white' color='black' py={4} px={7} my={4} mx={1}>
                    <Heading py={4} as='h2' size='md' textAlign='center'>
                        ভাড়াটিয়া ছাড়পত্র
                    </Heading>
                    <Box>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Flex justifyContent='space-between'>
                                <Box w='80%'>
                                    <Flex>
                                        <Text fontSize='lg' p={2}>
                                            তারিখঃ{' '}
                                        </Text>
                                        <Input
                                            isRequired
                                            type='date'
                                            w='20%'
                                            name='applicationDate'
                                            py={3}
                                            px={2}
                                            ref={register}
                                        />
                                    </Flex>
                                </Box>
                                <Button
                                    type='submit'
                                    colorScheme='blue'
                                    border='none'
                                    cursor='pointer'
                                >
                                    Save
                                </Button>
                            </Flex>
                            <Box>
                                <Flex>
                                    <Text fontSize='lg' p={2}>
                                        বাড়ির মালিকের নাম:
                                    </Text>
                                    <Input
                                        isRequired
                                        type='text'
                                        name='ownerName'
                                        w='25%'
                                        py={3}
                                        px={2}
                                        ref={register}
                                        placeholder='বাড়িওয়ালার নাম'
                                    />
                                </Flex>
                            </Box>
                            <Flex>
                                <Text fontSize='lg' p={2}>
                                    ঠিকানা:
                                </Text>
                                <Input
                                    isRequired
                                    type='text'
                                    name='ownerAddress'
                                    w='25%'
                                    py={3}
                                    px={2}
                                    ref={register}
                                    placeholder='ঠিকানা'
                                />
                            </Flex>
                            <Flex>
                                <Text fontSize='lg' p={2}>
                                    বিষয়ঃ
                                </Text>
                                <Input
                                    isRequired
                                    type='text'
                                    name='subject'
                                    w='25%'
                                    py={3}
                                    px={2}
                                    ref={register}
                                    placeholder='বিষয়'
                                />
                            </Flex>
                            <Text fontSize='lg' p={2} marginBlockEnd={1}>
                                জনাব,
                            </Text>
                            <Flex>
                                <Text fontSize='lg' p={2} marginBlockEnd={1}>
                                    এই মর্মে জানাচ্ছি যে, আমি
                                </Text>
                                <Input
                                    isRequired
                                    type='text'
                                    name='tenantName'
                                    w='22%'
                                    border='none'
                                    py={3}
                                    px={2}
                                    ref={register}
                                    placeholder='নাম'
                                />
                                <Text fontSize='lg' p={2} marginBlockEnd={1}>
                                    পিতা(নাম)
                                </Text>
                                <Input
                                    isRequired
                                    type='text'
                                    w='22%'
                                    name='fathersName'
                                    border='none'
                                    py={3}
                                    px={2}
                                    ref={register}
                                    placeholder='পিতার নাম'
                                />
                                <Text fontSize='lg' p={2} marginBlockEnd={1}>
                                    ঠিকানাঃ
                                </Text>
                                <Input
                                    isRequired
                                    type='text'
                                    name='ownerAddress'
                                    w='22%'
                                    border='none'
                                    py={3}
                                    px={2}
                                    ref={register}
                                    placeholder='বাড়ির ঠিকানা'
                                />
                                {/* <Text fontSize='lg' p={2}>
                                    তারিখ হতে জনাব
                                </Text>
                                <Input
                                    isRequired
                                    type='text'
                                    name='ownerName'
                                    w='25%'
                                    border='none'
                                    py={3}
                                    px={2}
                                    ref={register}
                                    placeholder='বাড়ির মালিকের নাম'
                                /> */}
                            </Flex>
                            <Flex my={1}>
                                {/* <Text fontSize='lg' p={2}>
                                    ঠিকানাঃ
                                </Text>
                                <Input
                                    isRequired
                                    type='text'
                                    name='ownerAddress'
                                    w='25%'
                                    border='none'
                                    py={3}
                                    px={2}
                                    ref={register}
                                    placeholder='বাড়ির ঠিকানা'
                                /> */}
                                <Text fontSize='lg' p={2} marginBlockEnd={1}>
                                    এই ঠিকানায় বসবাস করতেন গত
                                </Text>
                                <Input
                                    isRequired
                                    type='date'
                                    name='departureDate'
                                    w='12%'
                                    border='none'
                                    py={3}
                                    px={2}
                                    ref={register}
                                />
                                <Text fontSize='lg' p={2} marginBlockEnd={1}>
                                    আমার জানামতে, এতদিন এই ঠিকানায় অবস্থানরত থাকাকালে তিনি সমাজ বা
                                    রাষ্ট্রবিরোধী অথবা অনৈতিক কোন কাজের সাথে জড়িত ছিলেন না
                                </Text>
                            </Flex>
                            <Flex>
                                <Text fontSize='lg' p={2} marginBlockEnd={1}>
                                    এবং তার স্বভাব ও নৈতিকতা ছিল প্রশংসনীয়।
                                </Text>
                            </Flex>
                            <Flex>
                                <Text fontSize='lg' p={2} marginBlockEnd={1}>
                                    তার সাথে আমার পূর্বের কোন লেনদেন বাকি নেই। আমি তার ভবিষ্যৎ মঙ্গল
                                    কামনা করি।
                                </Text>
                            </Flex>
                            <Box my={6} marginBlockStart={10}>
                                <Input
                                    isRequired
                                    type='text'
                                    name='tenantSignature'
                                    borderBottom='1px solid black'
                                    w='10%'
                                    border='none'
                                    // py={3}
                                    // px={2}
                                    // m={2}
                                    ref={register}
                                />
                                <Text fontSize='lg' p={2}>
                                    স্বাক্ষর (নাম)
                                </Text>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </PageFormContainer>
        </ThemeProvider>
    )
}
