import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import EntranceAction from '../../../stores/security-management/entrance/EntranceAction'
import ParcelDetailsRequest from '../../../stores/security-management/entrance/requests/ParcelDetailsRequest'
import { makeSelectParcelsList } from '../../../stores/security-management/entrance/EntranceSelector'
import { EntranceStatusConstants } from '../../../assets/constants/GeneralConstants'
import { BlueTag, GreenTag } from '../../component/misc/Tags'
import ModalImage from '../../modals/ModalImage'

export default function TableParcel(props) {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectParcelsList)
    const isRequesting = useSelector((state) => selectRequesting(state, [EntranceAction.REQUEST_GET_PARCELS]))

    const columns = [
        {
            title: 'Image',
            dataIndex: 'thumbImage',
            render: (text, record) => <ModalImage imageUrl={record.thumbImage} imageTitle={'Parcel Image'} />
        },
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Company',
            dataIndex: 'company'
        },
        {
            title: 'Status',
            dataIndex: 'status'
        },
        {
            align: 'start',
            render: (text, record) => {
                if (record.status === EntranceStatusConstants.PARCEL_RECEIVED) return <GreenTag text={'Received'} />
                else return <BlueTag text={'Delivered'} />
            }
        },
        {
            title: 'Receive Date',
            render: (text, record) => <div> {record.inTime && new Date(record.inTime).toLocaleDateString()} </div>
        },
        {
            title: 'Receive Time',
            render: (text, record) => <div> {record.inTime && new Date(record.inTime).toLocaleTimeString()} </div>
        },
        {
            title: 'Delivery Date',
            render: (text, record) => <div> {record.exitTime && new Date(record.exitTime).toLocaleDateString()} </div>
        },
        {
            title: 'Delivery Time',
            render: (text, record) => <div> {record.exitTime && new Date(record.exitTime).toLocaleTimeString()} </div>
        },
        {
            title: '',
            align: 'center',
            render: (text, record) => (
                <ModalDeleteConfirmation
                    confirmationText={'Are you sure this parcel is delivered ?'}
                    onConfirm={() =>
                        dispatch(EntranceAction._requestMarkParcelAsReceived(new ParcelDetailsRequest(record)))
                    }
                />
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
