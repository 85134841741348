import BaseRequest from '../../../special/models/BaseRequest'

export default class CreateCommunityRequest extends BaseRequest {
    name = ''
    address = ''
    contactInfo = ''
    contactPerson = ''
    email = ''
    password = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
