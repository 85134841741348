import React from 'react'
// import { AppRoutes } from '../../../assets/constants/routes'
import FormCreateNewPackage from '../../forms/rokkhiAdmin/FormCreateNewPackage'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'
import TableNewPackage from '../../tables/admin/TableNewPackage'
import DynamicHelmet from '../../misc/DynamicHelmet'

export default function PageRokkhiAdminManagement(props) {
    return (
        <PageBasicContainer>
            <DynamicHelmet title={'Rokkhi | Super Admin'} description={'Rokkhi super admin'} />
            <FormCreateNewPackage />
            <PageMainBarContainer>
                <h2> Packages </h2>
                <TableNewPackage />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
