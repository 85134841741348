import { ApiEndpoint } from '../../assets/constants/ApiEndpoint'
import EffectUtility from '../../utils/EffectUtility'
import ServerResponse from '../special/models/ServerResponse'
import BaseRequest from '../special/models/BaseRequest'

export default class ReportEffect {
    static async _requestGetLedgerReport(data) {
        const endPoint = ApiEndpoint.report.getLedgerReport
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetIncomeReport(data) {
        const endPoint = ApiEndpoint.report.getIncomeReport
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetExpenseReport(data) {
        const endPoint = ApiEndpoint.report.getExpenseReport
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetCashFlowReport(data) {
        const endPoint = ApiEndpoint.report.getCashFlowReport
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetIncomeStatement(data) {
        const endPoint = ApiEndpoint.report.getIncomeStatement
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
