import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import EntranceAction from './EntranceAction'

const EntrancePostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case EntranceAction.REQUEST_MARK_VISITOR_EXIT_FINISHED:
                next(MiscAction._showModalSuccess('Visitors Exit is Saved'))
                break
            case EntranceAction.REQUEST_MARK_VEHICLE_EXIT_FINISHED:
                next(MiscAction._showModalSuccess('Vehicle Exit record is Saved'))
                break
            case EntranceAction.REQUEST_MARK_PARCEL_DELIVERED_FINISHED:
                next(MiscAction._showModalSuccess('Parcel Marked as Delivered'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default EntrancePostEffect
