import BaseReducer from '../../../utils/BaseReducer'
import BillAction from './BillAction'

export default class BillReducer extends BaseReducer {
    initialState = {
        billItems: null,
        billTypes: null,
        billPackages: null,
        decideBill: null,
        dueBillItems: null,
        requestGenerateBillReport: null,
        getMultipleBillInvoiceReport: null
    };

    [BillAction.REQUEST_GET_BILL_ITEMS_FINISHED](state, action) {
        return {
            ...state,
            billItems: action.payload
        }
    }

    [BillAction.REQUEST_GET_BILL_TYPES_FINISHED](state, action) {
        return {
            ...state,
            billTypes: action.payload
        }
    }

    [BillAction.REQUEST_GET_BILL_PACKAGES_FINISHED](state, action) {
        return {
            ...state,
            billPackages: action.payload
        }
    }

    [BillAction.REQUEST_GET_DECIDE_BILL_FINISHED](state, action) {
        return {
            ...state,
            decideBill: action.payload
        }
    }

    [BillAction.REQUEST_DUE_BILL_ITEM_FINISHED](state, action) {
        return {
            ...state,
            dueBillItems: action.payload
        }
    }
    [BillAction.REQUEST_REQUESTED_BILL_GENERATION_FINISHED] (state, action) {
        return {
            ...state,
            requestGenerateBillReport: action.payload
        }
    }
    [BillAction.REQUEST_GET_MULTIPLE_BILL_INVOICE_REPORT_FINISHED] (state, action) {
        return {
            ...state,
            getMultipleBillInvoiceReport: action.payload
        }
    }
}
