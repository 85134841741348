import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import FormCreateFund from '../../forms/fund/FormCreateFund'
import FundAction from '../../../stores/money-management/fund/FundAction'
import TableFund from '../../tables/fund/TableFund'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'
import { GetFundsRequest } from '../../../stores/money-management/fund/requests/GetFundsRequest'
import { useTranslation } from 'react-i18next'
import TableDataFilter from '../../tables/TableDataFilter'
import { GetBalanceSheet } from '../../../stores/money-management/fund/requests/GetBalanceSheet'
import { makeSelectExpenseData } from '../../../stores/money-management/fund/FundSelector'
import ExpenseAction from '../../../stores/money-management/expense/ExpenseAction'
import { GetExpenseItemsRequest } from '../../../stores/money-management/expense/requests/GetExpenseItemsRequest'
import { makeSelectExpenseItemsList } from '../../../stores/money-management/expense/ExpenseSelector'
import ReportAction from '../../../stores/reporting/ReportAction'
import LedgerReportRequest from '../../../stores/reporting/requests/LedgerReportRequest'
import { makeSelectLedgerReportsList } from '../../../stores/reporting/ReportSelector'
import { Table, Tag, Space, Icon } from 'antd'
import './balanceSheet.css'
import TableIncome from '../../tables/fund/TableIncome'
import TableExpense from '../../tables/fund/TableExpense'
import { MoneyIcon, TotalCreditBlackIcon, TotalDebitBlackIcon } from '../../misc/IconsProvider'

export default function PageBalanceSheet() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [filterValues, setFilterValues] = useState({})
    const [totalIncome, setTotalIncome] = useState(0)
    const [totalExpense, setTotalExpense] = useState(0)

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    const dataList = useSelector(makeSelectLedgerReportsList)

    console.log('dataList', dataList)

    useEffect(() => {
        // console.log(filterValues)
        if (filterValues.buildingId) {
            dispatch(ReportAction._requestGetLedgerReport(new LedgerReportRequest(filterValues)))
        }
    }, [filterValues])

    useEffect(() => {
        if (dataList && dataList.list) {
            const totalIncome = dataList.list
                .filter((item) => item.entryType == 'debit')
                .reduce((sum, item) => sum + item.amount, 0)

            setTotalIncome(totalIncome)

            const totalExpense = dataList.list
                .filter((item) => item.entryType == 'credit')
                .reduce((sum, item) => sum + item.amount, 0)

            setTotalExpense(totalExpense)
        }
    }, [dataList])

    return (
        <PageBasicContainer>
            {/* <FormCreateFund /> */}

            <PageMainBarContainer>
                <div style={{ marginBottom: '16px' }}>
                    <div
                        style={{
                            display: 'inline-block',
                            width: '44px',
                            height: '44px',
                            borderRadius: '50%',
                            backgroundColor: 'green',
                            textAlign: 'center'
                        }}
                    >
                        <Icon
                            style={{ margin: '10px auto', fontSize: '18px', color: '#fff' }}
                            type='apartment'
                        />
                    </div>
                    <h1
                        style={{
                            display: 'inline-block',
                            marginLeft: '12px',
                            marginBottom: '0px',
                            fontSize: '28px'
                        }}
                    >
                        Balance Sheet
                    </h1>
                </div>
                <TableDataFilter
                    showBuildingFilter={true}
                    showDateRangeFilter={true}
                    sendDataToParent={receiveFilterData}
                />

                {/* <TableFund /> */}
            </PageMainBarContainer>
            <div className='balance-sheet-container'>
                <div>
                    {/* <Table dataSource={data} columns={columns} />; */}
                    <div style={{ marginBottom: '12px', fontSize: '20px' }}>
                        <TotalDebitBlackIcon style={{ display: 'inline-block' }} />
                        <h3
                            style={{
                                display: 'inline-block',
                                marginBottom: '0',
                                marginLeft: '8px'
                            }}
                        >
                            Incomes
                        </h3>
                    </div>
                    <TableIncome />
                    <div>
                        <table width='100%' style={{ marginBottom: '30px' }}>
                            <tr>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        width: '70%'
                                    }}
                                >
                                    Total Income =
                                </td>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        width: '30%',
                                        padding: '0 8px',
                                        fontSize: '18px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {totalIncome}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div>
                        <table width='100%'>
                            <tr>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        width: '70%'
                                    }}
                                >
                                    Opening Balance =
                                </td>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        width: '30%',
                                        padding: '0 8px',
                                        fontSize: '18px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {dataList.startBalance || 0}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        width: '70%'
                                    }}
                                >
                                    (+)Total Income =
                                </td>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        width: '30%',
                                        padding: '0 8px',
                                        fontSize: '18px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {totalIncome}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        width: '70%'
                                    }}
                                >
                                    (=)Gross Total Income =
                                </td>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        width: '30%',
                                        padding: '0 8px',
                                        fontSize: '18px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {dataList.startBalance && totalIncome
                                        ? dataList.startBalance + totalIncome
                                        : 0}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        width: '70%'
                                    }}
                                >
                                    (-)Total Expense =
                                </td>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        width: '30%',
                                        padding: '0 8px',
                                        fontSize: '18px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {totalExpense}
                                </td>
                            </tr>
                            <tr style={{ backgroundColor: 'green', color: '#fff' }}>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        width: '70%'
                                    }}
                                >
                                    (=)End Balance=
                                </td>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        width: '30%',
                                        padding: '0 8px',
                                        fontSize: '18px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {dataList.startBalance && totalIncome && totalExpense
                                        ? dataList.startBalance + totalIncome - totalExpense
                                        : 0}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div>
                        <div
                            style={{
                                marginTop: '50px',
                                marginBottom: '30px',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <MoneyIcon />
                            <h2
                                style={{
                                    display: 'inline-block',
                                    marginLeft: '8px',
                                    marginBottom: '0px'
                                }}
                            >
                                Cash Balance
                            </h2>
                        </div>
                        <div>
                            <table width='100%'>
                                <tbody>
                                    <tr style={{ backgroundColor: 'green', color: '#fff' }}>
                                        <td
                                            style={{
                                                textAlign: 'right',
                                                fontWeight: 'bold',
                                                fontSize: '18px',
                                                width: '70%'
                                            }}
                                        >
                                            Current Balance=
                                        </td>
                                        <td
                                            style={{
                                                textAlign: 'right',
                                                width: '30%',
                                                padding: '0 8px',
                                                fontSize: '18px',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {dataList.currentBalance || 0}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ marginBottom: '12px', fontSize: '20px' }}>
                        <TotalCreditBlackIcon style={{ display: 'inline-block' }} />
                        <h3
                            style={{
                                display: 'inline-block',
                                marginBottom: '0',
                                marginLeft: '8px'
                            }}
                        >
                            Expenses
                        </h3>
                    </div>
                    <TableExpense />
                    <div>
                        <table width='100%'>
                            <tbody>
                                <tr style={{ backgroundColor: '#FFE4E4' }}>
                                    <td
                                        style={{
                                            textAlign: 'right',
                                            fontWeight: 'bold',
                                            fontSize: '18px',
                                            width: '70%'
                                        }}
                                    >
                                        Total Expense=
                                    </td>
                                    <td
                                        style={{
                                            textAlign: 'right',
                                            width: '30%',
                                            padding: '0 8px',
                                            fontSize: '18px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {totalExpense}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </PageBasicContainer>
    )
}
