import { message, Modal } from 'antd'
import React from 'react'
import { ButtonFillBlue } from '../component/buttons/CustomButtons'
import { FormInputText } from '../component/form-input/FormInputText'
import ButtonFormSubmitIcon from '../forms/ButtonFormSubmitIcon'
import firebase from '../../utils/firebase'
import * as Yup from 'yup'
import { useState } from 'react'
import useFormInputValidation from '../component/form-input/useFormInputValidation'
import DataValidationConstants from '../forms/FormDataValidationConstants'

const INITIAL_STATE = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
}
const VALIDATION_SCHEMA = Yup.object({
    currentPassword: Yup.string().required('Current Password is Required'),
    newPassword: DataValidationConstants.REQUIRED_VALID_PASSWORD,
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
})
function ModalChangePassword(props) {
    const [visibility, setVisibility] = useState(false)
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const [loading, setLoading] = useState(false)
    const onSubmit = () => {
        setLoading(true)
        const credential = firebase.auth.EmailAuthProvider.credential(
            props.currentUser.data.email,
            values.currentPassword
        )
        firebase
            .auth()
            .currentUser.reauthenticateWithCredential(credential)
            .then(() => changePass())
            .catch((error) => {
                message.error(error.message)
                setLoading(false)
            })
    }
    const changePass = async () => {
        await firebase
            .auth()
            .currentUser.updatePassword(values.newPassword)
            .then(() => {
                message.success('Password Updated Successful')
                setLoading(false)
                control.resetData()
            })
            .catch((error) => {
                message.error(error.message)
                setLoading(false)
            })
    }
    return (
        <div>
            <ButtonFillBlue
                icon={''}
                text={'Change Password'}
                onClick={() => {
                    setVisibility(true)
                }}
            />
            <Modal
                title={`Change Password`}
                centered
                // okText={'Add'}
                visible={visibility}
                key='billTypeModal'
                // confirmLoading={isRequesting}
                onCancel={() => setVisibility(false)}
                cancelText={'Cancel'}
                // onOk={''}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <FormInputText
                    type={'password'}
                    label={'Current Password'}
                    control={control}
                    name={'currentPassword'}
                />
                <FormInputText
                    type={'password'}
                    label={'New Password'}
                    control={control}
                    name={'newPassword'}
                />
                <FormInputText
                    type={'password'}
                    label={'Confirm New Password'}
                    control={control}
                    name={'confirmPassword'}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ButtonFormSubmitIcon
                        isRequesting={loading}
                        title={'Update'}
                        control={control}
                        onSubmit={onSubmit}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default ModalChangePassword
