import React, { useEffect, useState } from 'react'
import TableParcel from '../../tables/security/TableParcel'
import TableDataFilter from '../../tables/TableDataFilter'
import EntranceAction from '../../../stores/security-management/entrance/EntranceAction'
import { useDispatch, useSelector } from 'react-redux'
import GetParcelsRequest from '../../../stores/security-management/entrance/requests/GetParcelsRequest'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'
export default function PageParcels(props) {
    const dispatch = useDispatch()
    const [filterValues, setFilterValues] = useState({})
    const isFinished = useSelector((state) => selectFinished(state, EntranceAction.REQUEST_MARK_PARCEL_DELIVERED))

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        dispatch(EntranceAction._requestGetParcels(new GetParcelsRequest(filterValues)))
    }, [filterValues, isFinished])

    return (
        <PageBasicContainer>
            <TableDataFilter
                showStyledBuildingFilter={true}
                showStyledDateRangeFilter={true}
                showStyledFlatFilter={true}
                sendDataToParent={receiveFilterData}
            />

            <PageMainBarContainer>
                <h2> Parcels </h2>
                <TableParcel />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
