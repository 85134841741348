import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import FormInputDropDown from '../../component/form-input/FormInputDropDown'
import {
    ColorConstants,
    DropDownConstants,
    RadioConstants
} from '../../../assets/constants/GeneralConstants'
import FlatAction from '../../../stores/property-management/flat/FlatAction'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { FlatIconWhite } from '../../misc/IconsProvider'
import { FormButtonContainer, FormIconContainer, FormItemContainer } from '../FormStyles'
import FormInputRadio from '../../component/form-input/FormInputRadio'
import { useFlatGenerator } from './useFlatGenerator'
import { Tag } from 'antd'
import CreateMultipleFlatRequest from '../../../stores/property-management/flat/request/CreateMultipleFlatRequest'
import SingleFlatItem from '../../../stores/property-management/flat/request/SingleFlatItem'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../../../assets/constants/routes'
import { ButtonFillBlack } from '../../component/buttons/CustomButtons'

const INITIAL_STATE = {
    buildingId: '',
    floorCount: '',
    flatsPerFloor: '',
    flatFormat: ''
}
const VALIDATION_SCHEMA = Yup.object({
    buildingId: Yup.string().required('Please Select a Building'),
    floorCount: Yup.number()
        .typeError('Please Provide Digit')
        .required('Number of Floor is Required')
        .min(1, 'Minimum Digit 1')
        .max(30, 'Maximum Digit 30')
        .integer('Please Provide Integer'),
    flatsPerFloor: Yup.number()
        .typeError('Please Provide Digit')
        .required('Flats Per Floor is Required')
        .min(0, 'Minimum Digit 0')
        .max(30, 'Maximum Digit 30')
        .integer('Please Provide Integer'),
    flatFormat: Yup.string().required('Please Select Flat Format')
})

export default function FormCreateMultipleFlat(props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [FlatAction.REQUEST_CREATE_MULTIPLE_FLAT])
    )
    const generatedFlats = useFlatGenerator(values)
    const isFinished = useSelector((state) =>
        selectFinished(state, FlatAction.REQUEST_CREATE_MULTIPLE_FLAT)
    )
    useEffect(() => control.resetData(), [isFinished])
    const onSubmit = () => {
        const request = new CreateMultipleFlatRequest(values)
        request.flats = generatedFlats.map((flatNumber) => new SingleFlatItem(flatNumber))
        dispatch(FlatAction._requestCreateMultipleFlat(request))
    }

    return (
        <>
         <FormButtonContainer>
                <Link to={AppRoutes.FLATS}>
                    <ButtonFillBlack icon={'backward'} text={'Flats'}/>
                </Link>
            </FormButtonContainer>
        <FormIconContainer>
            <FormTitleWithIcon
                icon={<FlatIconWhite />}
                color={ColorConstants.GREEN1}
                formTitle={t('flat.createMultipleFlat.title')}
            />

            <FormItemContainer>
                <FormInputDropDown
                    label={t('flat.createMultipleFlat.selectBuilding')}
                    control={control}
                    name={'buildingId'}
                    dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                />
                <FormInputText
                    label={t('flat.createMultipleFlat.floorCount')}
                    control={control}
                    name={'floorCount'}
                />
                <FormInputText
                    label={t('flat.createMultipleFlat.flatsPerFloor')}
                    control={control}
                    name={'flatsPerFloor'}
                />
                <FormInputRadio
                    label={t('flat.createMultipleFlat.flatFormat')}
                    control={control}
                    name={'flatFormat'}
                    radioFor={RadioConstants.TYPE_FLAT_NUMBER_FORMAT}
                />
            </FormItemContainer>
            {generatedFlats.map((item) => (
                <Tag color={'blue'}> {item} </Tag>
            ))}
            <ButtonFormSubmit
                title={t('flat.createMultipleFlat.submitButton')}
                isRequesting={isRequesting}
                control={control}
                onSubmit={onSubmit}
            />
        </FormIconContainer>
        </>
    )
}
