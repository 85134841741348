import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'antd'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import BillAction from '../../../stores/money-management/bill/BillAction'
import { FormBillItemContainer } from '../FormStyles'
import { FormInputText } from '../../component/form-input/FormInputText'

const INITIAL_STATE = {
    name: '',
    description: '',
    amount: '',
    typeId: ''
}

const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string()
        .required("Name can't be empty")
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Maximum 40 Characters'),
    description: Yup.string().max(150, 'Max 150 Characters'),
    amount: Yup.number()
        .typeError('Amount must be Number')
        .required('Amount is required.')
        .min(1, 'Amount must be greater than 0')
        .max(100000000, 'Amount must be smaller than 100000000'),
    typeId: Yup.string().required("TypeId can't be empty")
})

export default function SingleBillInvoiceItem({ billItem, sendDataToParent, removeItem, index }) {
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isFinished = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_CREATE_BILL_PACKAGE)
    )

    useEffect(() => control.resetData(), [isFinished])

    useEffect(() => {
        if (billItem) control.setAllValues(billItem)
    }, [billItem])

    useEffect(() => {
        if (control.values.id) sendDataToParent(control.values, index)
    }, [control.values])

    return (
        <FormBillItemContainer>
            <FormInputText
                fontWeight='bold'
                backgroundColor={'#EBF5FB'}
                color={'#000'}
                disabled={true}
                control={control}
                name={'name'}
            />
            <FormInputText control={control} name={'amount'} prefix={'Amount'} />
            <FormInputText control={control} name={'description'} prefix={'Description'} />

            <Button
                style={{ marginBottom: '9px' }}
                icon='close'
                type={'danger'}
                onClick={() => removeItem(index)}
            />
        </FormBillItemContainer>
    )
}
