import React from 'react'
import { Modal } from 'antd'
import { Tooltip } from 'antd'
import { ButtonMildRed } from '../component/buttons/CustomButtons'

export default function ModalDeleteConfirmation(props) {
    const showConfirmDeleteCalculationData = () => {
        Modal.confirm({
            title: `${props.confirmationText}`,
            width: 450,
            okText: props.okText ? props.okText : 'Yes I am Sure',
            okType: 'danger',
            cancelText: props.cancelText ? props.cancelText : 'No Cancel this',
            onOk() {
                props.onConfirm()
            },
            onCancel() {}
        })
    }
    const text = <span>{props.tooltip}</span>
    return (
        <Tooltip placement={'top'} title={props.tooltip}>
            <span>
                <ButtonMildRed
                    icon={props.icon ? props.icon : 'delete'}
                    text={''}
                    onClick={() => showConfirmDeleteCalculationData()}
                />
            </span>
        </Tooltip>
    )
}
