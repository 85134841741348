import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import BillAction from '../../../stores/money-management/bill/BillAction'
import { GetBillTypeRequest } from '../../../stores/money-management/bill/requests/GetBillTypeRequest'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'
import FormCreateBillPackage from '../../forms/bill/FormCreateBillPackage'
import TableBillPackages from '../../tables/bill/TableBillPackages'
import ModalCreateBillPackage from '../../modals/ModalCreateBillPackage'
import BillDetails from '../../../assets/images/bill-details-small.png'
import { ColorConstants } from '../../../assets/constants/GeneralConstants'
import { FormTitleWithSvgIcon } from '../../component/decoration/CardSingleInfo'

export default function PageBillPackages(props) {
    const dispatch = useDispatch()
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_CREATE_BILL_PACKAGE)
    )

    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_CREATE_BILL_PACKAGE)
    )

    useEffect(() => {
        dispatch(BillAction._requestGetBillTypes(new GetBillTypeRequest()))
    }, [isFinishedDeletion, isFinishedCreation])

    return (
        <PageBasicContainer>
            {/* <FormCreateBillPackage /> */}
            <PageMainBarContainer>
                <FormTitleWithSvgIcon
                    icon={BillDetails}
                    backgroundColor={ColorConstants.WOOD1}
                    title={'Bill Packages'}
                    child={<ModalCreateBillPackage />}
                />

                <TableBillPackages />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
