import ActionUtility from '../../../utils/ActionUtility'
import DirectPaymentEffect from './DirectPaymentEffect'

export default class DirectPaymentAction {
    static REQUEST_DYNAMIC_PAYMENT = 'REQUEST_DYNAMIC_PAYMENT'
    static REQUEST_DYNAMIC_PAYMENT_FINISHED = 'REQUEST_DYNAMIC_PAYMENT_FINISHED'

    static _requestCreateDynamicPayment(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                DirectPaymentAction.REQUEST_DYNAMIC_PAYMENT,
                DirectPaymentEffect._requestDirectPayment,
                data
            )
        }
    }
}
