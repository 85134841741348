import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import ErrorReducer from './special/error/ErrorReducer'
import RequestingReducer from './special/requesting/RequestingReducer'
import ToastsReducer from './special/toasts/ToastsReducer'
import MiscReducer from './misc/MiscReducer'
import FinishedReducer from './special/finished/FinishedReducer'
import CommunityReducer from './property-management/community/CommunityReducer'
import BuildingReducer from './property-management/building/BuildingReducer'
import FlatReducer from './property-management/flat/FlatReducer'
import UserReducer from './user-management/user/UserReducer'
import PrivilegeReducer from './user-management/privilege/PrivilegeReducer'
import NoticeReducer from './admin-management/notice/NoticeReducer'
import MeetingReducer from './admin-management/meeting/MeetingReducer'
import ComplainReducer from './admin-management/complain/ComplainReducer'
import EntranceReducer from './security-management/entrance/EntranceReducer'
import PreAuthorizationReducer from './security-management/pre-authorization/PreAuthorizationReducer'
import ExpenseReducer from './money-management/expense/ExpenseReducer'
import BillReducer from './money-management/bill/BillReducer'
import FundReducer from './money-management/fund/FundReducer'
import VehicleReducer from './security-management/vehicle/VehicleReducer'
import FingerPrintReducer from './device-management/fingerprint-device/FingerPrintReducer'
import ReportReducer from './reporting/ReportReducer'
import NewPackageReducer from './rokkhi-admin/subscription/NewPackageReducer'
import PackagePaymentReducer from './money-management/package-payment/PackagePaymentReducer'
import DirectPaymentReducer from './money-management/direct-payment/DirectPaymentReducer'
import FormsReducer from './admin-management/forms/FormsReducer'
import storage from 'redux-persist/lib/storage'
import ResetAction from './special/reset/ResetAction'
import ResetReducer from './special/reset/ResetReducer'
import CartReducer from './money-management/cart/CartReducer'

export default (history) => {
    const reducerMap = {
        error: ErrorReducer.reducer,
        requesting: RequestingReducer.reducer,
        finished: FinishedReducer.reducer,
        router: connectRouter(history),
        toasts: new ToastsReducer().reducer,
        misc: new MiscReducer().reducer,
        reset: new ResetReducer().reducer,

        community: new CommunityReducer().reducer,
        building: new BuildingReducer().reducer,
        flat: new FlatReducer().reducer,
        user: new UserReducer().reducer,
        privilege: new PrivilegeReducer().reducer,

        notice: new NoticeReducer().reducer,
        meeting: new MeetingReducer().reducer,
        complain: new ComplainReducer().reducer,
        entrance: new EntranceReducer().reducer,
        preAuthorization: new PreAuthorizationReducer().reducer,

        expense: new ExpenseReducer().reducer,
        bill: new BillReducer().reducer,
        fund: new FundReducer().reducer,
        vehicle: new VehicleReducer().reducer,
        fingerPrint: new FingerPrintReducer().reducer,
        report: new ReportReducer().reducer,
        forms: new FormsReducer().reducer,
        packages: new NewPackageReducer().reducer,
        orderInfo: new PackagePaymentReducer().reducer,
        cartInfo: new CartReducer().reducer,
        directPaymentInfo: new DirectPaymentReducer().reducer,
    }

    const appReducer =  combineReducers(reducerMap)
    return (state, action) => {
        if (action.type === ResetAction.RESET_STORAGE) {
            storage.removeItem('persist:rokkhi-home-web')
            state = undefined
          }
        return appReducer(state, action)
    }
}
