import React, { useState } from 'react'
import LogIn from './signin'
import ResetPassword from './passwordReset'
import { AppMainBarContainer } from '../Styles'

const Controller = (props) => {
    const [forgot, setForgot] = useState(false)
    const forgotPassword = () => {
        setForgot(!forgot)
    }
    return forgot ? (
        <AppMainBarContainer>
            <ResetPassword forgotPassword={forgotPassword}/>
        </AppMainBarContainer>
    ) : (
        <AppMainBarContainer>
            <LogIn userDetails={props.userDetails} forgotPassword={forgotPassword} />
        </AppMainBarContainer>
    )
}
export default Controller
