import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import FundAction from '../../../stores/money-management/fund/FundAction'
import { makeSelectFundsList } from '../../../stores/money-management/fund/FundSelector'
import { BlueTag } from '../../component/misc/Tags'
import { useTranslation } from 'react-i18next'

export default function TableFund(props) {
    const { t } = useTranslation();
    const dataList = useSelector(makeSelectFundsList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [FundAction.REQUEST_GET_FUNDS])
    )
    const columns = [
        {
            title: t('fund.tableHeader.code'),
            dataIndex: 'code',
            width: '10%'
        },

        {
            title: t('fund.tableHeader.name'),
            dataIndex: 'name',
            width: '20%'
        },

        {
            title: t('fund.tableHeader.description'),
            dataIndex: 'description',
            width: '35%'
        },
        {
            title: t('fund.tableHeader.type'),
            dataIndex: 'type',
            render: (text, record) => <BlueTag text={record.type} />,
            width: '15%'
        },
        {
            title: t('fund.tableHeader.date'),
            render: (text, record) => (
                <div>
                    {' '}
                    {record.createdDate && new Date(record.createdDate).toLocaleDateString()}{' '}
                </div>
            ),
            width: '20%'
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
