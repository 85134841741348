import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import ComponentTable from '../ComponentTable'
import { makeSelectMeetingList } from '../../../stores/admin-management/meeting/MeetingSelector'
import MeetingAction from '../../../stores/admin-management/meeting/MeetingAction'
import GetMeetingsRequest from '../../../stores/admin-management/meeting/requests/GetMeetingsRequest'
import MeetingDetailsRequest from '../../../stores/admin-management/meeting/requests/MeetingDetailsRequest'
import { MeetingStatusConstants } from '../../../assets/constants/GeneralConstants'
import { BlueTag, GreenTag, RedTag } from '../../component/misc/Tags'
import { ButtonMildRed } from '../../component/buttons/CustomButtons'

export default function TableMeeting(props) {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectMeetingList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [MeetingAction.REQUEST_GET_MEETINGS])
    )
    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, MeetingAction.REQUEST_DELETE_MEETING)
    )
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, MeetingAction.REQUEST_CREATE_FLAT)
    )

    useEffect(() => {
        dispatch(MeetingAction._requestGetMeetingList(new GetMeetingsRequest()))
    }, [isFinishedDeletion, isFinishedCreation])

    const columns = [
        {
            title: 'Meeting title',
            dataIndex: 'title'
        },
        {
            title: 'Agenda',
            dataIndex: 'agenda'
        },
        {
            title: 'Date',
            render: (text, record) => (
                <div> {record.date && new Date(record.date).toLocaleDateString()} </div>
            )
        },
        {
            align: 'center',
            title: 'Status',
            render: (text, record) => {
                switch (record.status) {
                    case MeetingStatusConstants.SCHEDULED:
                        return <BlueTag text={'Scheduled'} />
                    case MeetingStatusConstants.RUNNING:
                        return <BlueTag text={'Running'} />
                    case MeetingStatusConstants.CANCELLED:
                        return <RedTag text={'Cancelled'} />
                    case MeetingStatusConstants.FINISHED:
                        return <GreenTag text={'Finished'} />
                    default:
                        return <div></div>
                }
            }
        },
        {
            title: 'Cancel',
            align: 'center',
            render: (text, record) => {
                switch (record.status) {
                    case MeetingStatusConstants.SCHEDULED:
                        return (
                            <ModalDeleteConfirmation
                                tooltip='Cancel Meeting'
                                icon={'issues-close'}
                                confirmationText={'Are you sure you want to cancel this meeting ?'}
                                onConfirm={() =>
                                    dispatch(
                                        MeetingAction._requestCancelMeeting(
                                            new MeetingDetailsRequest(record)
                                        )
                                    )
                                }
                            />
                        )
                    case MeetingStatusConstants.RUNNING:
                        return <ButtonMildRed icon={'issues-close'} disabled={true} />
                    case MeetingStatusConstants.CANCELLED:
                        return <ButtonMildRed icon={'issues-close'} disabled={true} />

                    case MeetingStatusConstants.FINISHED:
                        return <ButtonMildRed icon={'issues-close'} disabled={true} />
                    default:
                        return <div></div>
                }
            }
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
