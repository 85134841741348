import ActionUtility from '../../../utils/ActionUtility'
import VehicleEffect from './VehicleEffect'

export default class VehicleAction {
    static REQUEST_ADD_VEHICLE = 'REQUEST_ADD_VEHICLE'
    static REQUEST_ADD_VEHICLE_FINISHED = 'REQUEST_ADD_VEHICLE_FINISHED'

    static REQUEST_GET_VEHICLES = 'REQUEST_GET_VEHICLES'
    static REQUEST_GET_VEHICLES_FINISHED = 'REQUEST_GET_VEHICLES_FINISHED'

    static _requestAddVehicle(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                VehicleAction.REQUEST_ADD_VEHICLE,
                VehicleEffect._requestAddVehicle,
                data
            )
        }
    }

    static _requestGetVehicles(data) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(
                dispatch,
                VehicleAction.REQUEST_GET_VEHICLES,
                VehicleEffect._requestGetVehicles,
                data
            )
        }
    }
}
