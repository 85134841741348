import BaseRequest from '../../../special/models/BaseRequest'
import { FundTypeConstants } from '../../../../assets/constants/GeneralConstants'

export class GetFundsRequest extends BaseRequest {
    fundType = FundTypeConstants.ASSET

    constructor(data) {
        super()
        this.update(data)
    }
}
