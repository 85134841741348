import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormExpenseItemContainer } from '../FormStyles'
import { FormInputText } from '../../component/form-input/FormInputText'
import FormInputDatePicker from '../../component/form-input/FormInputDatePicker'
import ExpenseAction from '../../../stores/money-management/expense/ExpenseAction'
import { Button } from 'antd'

const INITIAL_STATE = {
    name: '',
    description: '',
    amount: '',
    typeId: '',
    date: ''
}

const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string()
        .required("Name can't be empty")
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Max 40 Characters'),
    description: Yup.string().max(150, 'Max 150 Characters'),
    amount: Yup.number()
        .typeError('Amount must be Digit')
        .required('Amount is required.')
        .min(1, 'Minimum amount 1')
        .max(100000000, 'Maximum amount 100000000')
})

export default function SingleExpenseVoucherItem({
    expenseType,
    sendDataToParent,
    removeItem,
    index
}) {
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isFinished = useSelector((state) =>
        selectFinished(state, ExpenseAction.REQUEST_GENERATE_EXPENSE_VOUCHER)
    )

    useEffect(() => control.resetData(), [isFinished])

    useEffect(() => {
        if (expenseType) control.setAllValues(expenseType)
    }, [expenseType])

    useEffect(() => {
        if (control.values.typeId) sendDataToParent(control.values, index)
    }, [control.values])

    return (
        <FormExpenseItemContainer>
            <FormInputText
                fontWeight='bold'
                backgroundColor={'#EBF5FB'}
                color={'#000'}
                disabled={true}
                label={'Expense '}
                control={control}
                name={'name'}
            />
            <FormInputText label={'Amount'} control={control} name={'amount'} />
            <FormInputDatePicker label={'Date'} control={control} name={'date'} />
            <FormInputText label={'Description'} control={control} name={'description'} />
            <Button
                style={{ marginBottom: '9px' }}
                icon='close'
                type={'danger'}
                onClick={() => removeItem(index)}
            />
        </FormExpenseItemContainer>
    )
}
