import { createSelector } from 'reselect'

export class PrivilegeSelector {
    static getUserRolesList = (state) => state.privilege.userRolesList

    static getUserFunctionsList = (state) => state.privilege.userFunctionsList

    static getFunctionsOfRoleRequest = (state) => state.privilege.userFunctionsOfRoleList
}

export const makeSelectUserRolesList = createSelector(PrivilegeSelector.getUserRolesList, (user) =>
    user ? user.data : []
)

export const makeSelectUserFunctionsList = createSelector(
    PrivilegeSelector.getUserFunctionsList,
    (user) => (user ? user.data : [])
)

export const makeSelectFunctionsOfRoleList = createSelector(
    PrivilegeSelector.getFunctionsOfRoleRequest,
    (user) =>
        user
            ? user.data.map((item) => {
                  return { ...item, key: item.code, title: item.name }
              })
            : []
)
