import BaseRequest from '../../../../special/models/BaseRequest'

export class GetExpenseTypeRequest extends BaseRequest {
    buildingId = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
