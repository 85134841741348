import React from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts'
import { getMonthName } from '../../../utils/functions/functions'

export default function MonthlyBillBarChart({ monthlyData }) {
    const data = getChartData(monthlyData)
    return (
        <ResponsiveContainer minWidth='40%' width='95%' height={330}>
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    bottom: 5
                }}
            >
                <CartesianGrid strokeDasharray='3' />
                <XAxis dataKey='name' />
                <YAxis />
                <Tooltip />
                <Legend margin={{ top: 10 }} />
                <Bar dataKey='income' fill='#1D52FF' />
                <Bar dataKey='expense' fill='#1B2A5D' />
            </BarChart>
        </ResponsiveContainer>
    )
}

const getChartData = (monthlyData) => {
    let data = []
    let months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'June',
        'July',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ]

    if (monthlyData && monthlyData.length) {
        for (let i = 0; i < monthlyData.length; i++) {
            data[parseInt(monthlyData[i].period.split('/')[0] - 1)] = {
                income: monthlyData[i].income,
                expense: monthlyData[i].expense,
                name: getMonthName(monthlyData[i].period)
            }
        }

        for (let i = 0; i < 12; i++) {
            if (!data[i]) {
                data[i] = {
                    income: 0,
                    expense: 0,
                    name: months[i]
                }
            }
        }
    } else {
        for (let i = 0; i < 12; i++) {
            data[i] = { income: 0, expense: 0, name: months[i] }
        }
    }

    return data
}
