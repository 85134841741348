import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import FingerPrintAction from './FingerPrintAction'

const FingerPrintPostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case FingerPrintAction.REQUEST_REGISTER_FINGERPRINT_DEVICE_FINISHED:
                next(MiscAction._showModalSuccess('FingerPrint Device Registered Successfully'))
                break
            case FingerPrintAction.REQUEST_DELETE_FINGERPRINT_DEVICE_FINISHED:
                next(MiscAction._showModalSuccess('FingerPrint Device Deleted Successfully'))
                break
            case FingerPrintAction.REQUEST_ASSIGN_FINGERPRINT_TO_USER_FINISHED:
                next(MiscAction._showModalSuccess('FingerPrint assigned to user successfully'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default FingerPrintPostEffect
