import React from 'react'
import TableFingerprint from '../../tables/device/TableFingerprint'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'

export default function PageFingerprint() {
    return (
        <PageBasicContainer>
            <PageMainBarContainer>
                <h2>Assign Fingerprints</h2>
            <TableFingerprint />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
