import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { ButtonFillPurple } from '../component/buttons/CustomButtons'
import * as Yup from 'yup'
import useFormInputValidation from '../component/form-input/useFormInputValidation'
import BillAction from '../../stores/money-management/bill/BillAction'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../stores/special/finished/FinishedSelector'
import ButtonFormSubmitIcon from '../forms/ButtonFormSubmitIcon'
import { CreateBillTypeRequest } from '../../stores/money-management/bill/requests/CreateBillTypeRequest'
import { FormInputText } from '../component/form-input/FormInputText'
import TotalBillIcon from '../../assets/images/bill-details-small.png'
import { ColorConstants } from '../../assets/constants/GeneralConstants'

const INITIAL_STATE = {
    name: '',
    description: '',
    defaultAmount: ''
}

const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string()
        .required('Name is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Max 40 Characters'),
    description: Yup.string().max(150, 'Maximum 150 Characters'),
    defaultAmount: Yup.number()
        .typeError('Amount must be Digit')
        .required('Amount is required.')
        .min(0, 'Minimum amount 0')
        .max(100000000, 'Maximum amount 100000000')
})

export default function ModalCreateBillTypes() {
    const dispatch = useDispatch()
    const [visibility, setVisibility] = useState(false)
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [BillAction.REQUEST_CREATE_BILL_TYPE])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_CREATE_BILL_TYPE)
    )

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        dispatch(BillAction._requestCreateBillType(new CreateBillTypeRequest(values)))
    }

    return (
        <>
            <ButtonFillPurple
                icon={'plus'}
                size={'large'}
                text={'Create Bill Types'}
                onClick={() => setVisibility(true)}
            />
            <Modal
                centered
                visible={visibility}
                onCancel={() => setVisibility(false)}
                onOk={() => setVisibility(false)}
                okText={'Close'}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            display: 'grid',
                            alignItems: 'center',
                            margin: '4px',
                            width: '40px',
                            height: '40px',
                            backgroundColor: ColorConstants.GREEN6,
                            justifyItems: 'center',
                            padding: '10px',
                            borderRadius: '50%/50%'
                        }}
                    >
                        <img src={TotalBillIcon} alt='icon' />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <h2 style={{ margin: 0, textAlign: 'center' }}>Create Bill Types</h2>
                    </div>
                </div>

                <FormInputText label={'Name'} control={control} name={'name'} />
                <FormInputText label={'Default Amount'} control={control} name={'defaultAmount'} />
                <FormInputText label={'Description'} control={control} name={'description'} />

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ButtonFormSubmitIcon
                        title={'Save'}
                        isRequesting={isRequesting}
                        control={control}
                        onSubmit={onSubmit}
                    />
                </div>
            </Modal>
        </>
    )
}
