import BaseRequest from '../../../special/models/BaseRequest'

export default class CreateNewPoliceFormRequest extends BaseRequest {
    userId = ''
    forms = {
        nidNumber: '',
        maid_name: '',
        prevLandlord_address: '',
        familyMamTwo_occu: '',
        emergency_con_relation: '',
        floor: '',
        familyMamOne_occu: '',
        emergency_con_name: '',
        passportImage: '',
        currentHomeStartdate: '',
        religion: '',
        emergency_con_number: '',
        driver_address: '',
        permanentAddress: '',
        division: '',
        fullName: '',
        createdAt: '',
        email: '',
        familyMamOne_name: '',
        road: '',
        familyMamOne_age: '',
        maid_nid: '',
        currentLandlord_number: '',
        birthDate: '',
        area: '',
        verificationId: '',
        driver_nid: '',
        house: '',
        familyMamTwo_name: '',
        thana: '',
        workAddress: '',
        fatherName: '',
        educationStatus: '',
        maid_address: '',
        prevLandlord_number: '',
        mobileNumber: '',
        familyMamThree_occu: '',
        prevLandlord_name: '',
        familyMamThree_name: '',
        currentLandlord_name: '',
        familyMamThree_num: '',
        maritalStatus: '',
        maid_number: '',
        driver_number: '',
        familyMamThree_age: '',
        familyMamTwo_age: '',
        reasonToLeavePrevHouse: '',
        familyMamOne_num: '',
        familyMamTwo_num: '',
        passportNumber: '',
        emergency_con_address: '',
        nidVerified: '',
        driver_name: '',
        postCode: '',
    }

    constructor(data) {
        super()
        this.update(data)
    }
}