import BaseRequest from '../../../special/models/BaseRequest'
import { EntranceStatusConstants } from '../../../../assets/constants/GeneralConstants'

export default class VisitorDetailsRequest extends BaseRequest {
    visitorId = ''
    newStatus = EntranceStatusConstants.OUTSIDE_COMPOUND

    constructor(data) {
        super()
        this.update(data)
        this.visitorId = data.id
    }
}
