import { createSelector } from 'reselect'

export class EntranceSelector {
    static getVisitorsList = (state) => state.entrance.visitorList
    static getParcelsList = (state) => state.entrance.parcelList
    static getVehiclesList = (state) => state.entrance.vehicleList
}

export const makeSelectVisitorsList = createSelector(EntranceSelector.getVisitorsList, (entrance) =>
    entrance ? entrance.data : []
)

export const makeSelectParcelsList = createSelector(EntranceSelector.getParcelsList, (entrance) =>
    entrance ? entrance.data : []
)

export const makeSelectVehiclesList = createSelector(EntranceSelector.getVehiclesList, (entrance) => {
    if (entrance) {
        return entrance.data
    }
    return []
})
