import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { makeSelectUserFunctionsList } from '../../../stores/user-management/privilege/PrivilegeSelector'
import PrivilegeAction from '../../../stores/user-management/privilege/PrivilegeAction'
import BaseRequest from '../../../stores/special/models/BaseRequest'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import ComponentTable from '../ComponentTable'
import FunctionDetailsRequest from '../../../stores/user-management/privilege/models/FunctionDetailsRequest'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'

export default function TableUserRole(props) {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectUserFunctionsList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [PrivilegeAction.REQUEST_GET_USER_FUNCTIONS])
    )
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, PrivilegeAction.REQUEST_CREATE_USER_FUNCTION)
    )
    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, PrivilegeAction.REQUEST_DELETE_USER_FUNCTION)
    )

    useEffect(() => {
        dispatch(PrivilegeAction._requestGetUserFunctions(new BaseRequest()))
    }, [isFinishedCreation, isFinishedDeletion])

    const columns = [
        {
            title: 'Function Name',
            dataIndex: 'name'
        },
        {
            title: 'Function Code',
            dataIndex: 'code'
        },
        {
            title: 'Description ',
            dataIndex: 'description'
        },
        {
            title: '',
            align: 'center',
            render: (text, record) => (
                <ModalDeleteConfirmation
                    confirmationText={'Are you sure you want to delete this function ?'}
                    onConfirm={() =>
                        dispatch(
                            PrivilegeAction._requestDeleteUserFunction(
                                new FunctionDetailsRequest(record.id)
                            )
                        )
                    }
                />
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
