import React from 'react'
import { withRouter } from 'react-router-dom'
import AccessDenied from '../../assets/images/access_denied.png'
import ResultTypePage from './ResultTypePage'

const RestrictedPage = (props) => {
    console.log(props)
    return (
        <ResultTypePage
            status='403'
            title="Access Denied! You Do not Have Permission to Access This Page."
            subTitle='To Unlock This Feature Please Contact Rokkhi Team: '
            image={AccessDenied}
        />
    )
}

export default withRouter(RestrictedPage)
