import { message, Spin, Tooltip } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CartAction from '../../stores/money-management/cart/CartAction'
import {
    makeSelectCartInfo,
    makeSelectCartItems
} from '../../stores/money-management/cart/CartSelector'
import { AddToCartRequest } from '../../stores/money-management/cart/requests/AddToCartRequest'
import { CartItemDeleteRequest } from '../../stores/money-management/cart/requests/CartItemDeleteRequest'
import { GetAllCartItemsRequest } from '../../stores/money-management/cart/requests/GetAllCartItemsRequest'
import PackagePaymentAction from '../../stores/money-management/package-payment/PackagePaymentAction'
import { makeSelectOrderInfo } from '../../stores/money-management/package-payment/PackagePaymentSelector'
import { OrderPackagePaymentRequest } from '../../stores/money-management/package-payment/requests/OrderPackagePaymentRequest'
import NewPackageAction from '../../stores/rokkhi-admin/subscription/NewPackageAction'
import { makeSelectDefaultPackage } from '../../stores/rokkhi-admin/subscription/NewPackageSelector'
import GetNewPackageRequest from '../../stores/rokkhi-admin/subscription/request/GetNewPackageRequest'
import { selectFinished } from '../../stores/special/finished/FinishedSelector'
import { selectRequesting } from '../../stores/special/requesting/RequestingSelector'
import GetUserDashboardRequest from '../../stores/user-management/user/requests/GetUserDashboardRequest'
import UserAction from '../../stores/user-management/user/UserAction'
import { makeSelectDahboardOfUser } from '../../stores/user-management/user/UserSelector'
import { ButtonFillGreen, ButtonMildRed } from '../component/buttons/CustomButtons'
import { FormInputText } from '../component/form-input/FormInputText'
import useFormInputValidation from '../component/form-input/useFormInputValidation'
import { CheckCircleGreenIcon, CloseCircleRedIcon } from '../misc/IconsProvider'
import * as Yup from 'yup'

const INITIAL_STATE = {
    promoCode: ''
}

const VALIDATION_SCHEMA = Yup.object({
    promoCode: Yup.string().max(30, 'Maximum 30 Characters')
})

function PagePricingPlanTable() {
    const dispatch = useDispatch()
    const [totalFlat, setTotalFlat] = useState(0)

    const isFinishedCartCreation = useSelector((state) =>
        selectFinished(state, CartAction.REQUEST_ADD_TO_CART)
    )
    const isRequestingAddToCart = useSelector((state) =>
        selectRequesting(state, [CartAction.REQUEST_ADD_TO_CART])
    )
    const isRequestingOrderCreation = useSelector((state) =>
        selectRequesting(state, [PackagePaymentAction.REQUEST_CREATE_ORDER])
    )
    const isFinishedGetPackage = useSelector((state) =>
        selectFinished(state, NewPackageAction.REQUEST_GET_ALL_PACKAGE)
    )
    const isFinishedGetCart = useSelector((state) =>
        selectFinished(state, CartAction.REQUEST_GET_ALL_CART)
    )
    const isFinishedCartDeletion = useSelector((state) =>
        selectFinished(state, CartAction.REQUEST_DELETE_CART_ITEM)
    )
    const isRequestingDeleteCart = useSelector((state) =>
        selectRequesting(state, [CartAction.REQUEST_DELETE_CART_ITEM])
    )

    const handleDeleteCart = (cartId) => {
        if (cartId) {
            dispatch(
                CartAction._requestDeleteCartItem(new CartItemDeleteRequest({ cartId: cartId }))
            )
        } else {
            message.error({
                key: 'carTDelete',
                content: 'Something went wrong please try again later'
            })
        }
    }
    useEffect(() => {
        dispatch(CartAction._requestGetAllCart(new GetAllCartItemsRequest()))
        dispatch(UserAction._requestGetDashboardOfUser(new GetUserDashboardRequest()))
    }, [isFinishedCartCreation, isFinishedCartDeletion])

    useEffect(() => {
        if (!cartItems) {
            dispatch(NewPackageAction._requestGetPackageList(new GetNewPackageRequest()))
        }
    }, [cartItems, isFinishedCartDeletion, isFinishedCartCreation])

    const userDashboard = useSelector((state) => makeSelectDahboardOfUser(state))
    const packageList = useSelector((state) => makeSelectDefaultPackage(state))
    const cartItems = useSelector((state) => makeSelectCartItems(state))

    useEffect(() => {
        if (userDashboard && userDashboard.totalFlats) {
            setTotalFlat(userDashboard.totalFlats)
        }
    }, [userDashboard])

    console.log('cartItems', cartItems)

    const orderInfo = useSelector(makeSelectOrderInfo)

    useEffect(() => {
        if (orderInfo) {
            window.location.replace(orderInfo.paymentUrl.GatewayPageURL)
        }
    }, [orderInfo])

    const handleOrder = (e, packageId) => {
        if (packageId !== null) {
            dispatch(CartAction._requestAddToCart(new AddToCartRequest({ packageId: packageId })))
        } else {
            message.error('Something went wrong!')
        }
        e.preventDefault()
    }

    const handleCreatePurchase = (e, cartId, promoCode, planName, planId) => {
        if (cartId) {
            dispatch(
                PackagePaymentAction._requestCreateOrder(
                    new OrderPackagePaymentRequest({
                        cartId: cartId,
                        promoCode: promoCode,
                        description: [{ id: planId, quantity: 1 }]
                    })
                )
            )
        } else {
            message.error({
                content: 'Something went wrong please try again later',
                key: 'errorPurchase'
            })
        }

        e.preventDefault()
    }

    return (
        <>
            {cartItems && cartItems.length > 0 ? (
                <Cart
                    dispatch={dispatch}
                    cartItems={cartItems}
                    isRequestingDeleteCart={isRequestingDeleteCart}
                    handleDeleteCart={handleDeleteCart}
                    handleCreatePurchase={handleCreatePurchase}
                    isRequestingOrderCreation={isRequestingOrderCreation}
                />
            ) : (
                isFinishedGetPackage &&
                cartItems &&
                !cartItems.length && (
                    <PackagePlans packageList={packageList} handleOrder={handleOrder} />
                )
            )}
        </>
    )
}

const Cart = ({
    cartItems,
    isRequestingDeleteCart,
    handleDeleteCart,
    handleCreatePurchase,
    isRequestingOrderCreation
}) => {
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    return (
        <>
            {cartItems && (
                <div className={'cart-container'}>
                    <div className='cart-card'>
                        <h1>You have already selected a package</h1>
                        <div className='cart-item-card'>
                            <div>
                                <h1>
                                    {cartItems && cartItems[0].packagePlan
                                        ? cartItems[0].packagePlan[0].plan
                                        : ''}
                                </h1>
                                {/* <h1>Price</h1> */}
                                <h1>
                                    ৳{' '}
                                    {cartItems &&
                                    cartItems[0].packagePlan &&
                                    cartItems[0].totalFlats
                                        ? cartItems[0].packagePlan[0].price *
                                          cartItems[0].totalFlats
                                        : 0}{' '}
                                    <span style={{ fontSize: '12px', color: '#ff3300' }}>
                                        / monthly for {cartItems ? cartItems[0].totalFlats : 0}{' '}
                                        flats
                                    </span>{' '}
                                </h1>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ margin: '20px auto' }}>
                                <ButtonMildRed
                                    loading={isRequestingDeleteCart}
                                    onClick={() => handleDeleteCart(cartItems[0].id)}
                                    text={'Select Another Plan'}
                                />
                            </div>
                            <div className='separator'>Or</div>
                            <FormInputText
                                placeholder={'Promo code'}
                                name={'promoCode'}
                                control={control}
                            />
                            <div style={{ margin: '5px auto' }}>
                                <ButtonFillGreen
                                    isRequesting={isRequestingOrderCreation}
                                    onClick={(e) =>
                                        handleCreatePurchase(
                                            e,
                                            cartItems[0].id,
                                            control.promoCode ? control.promoCode : '',
                                            cartItems[0].packagePlan[0].plan,
                                            cartItems[0].packagePlan[0].id
                                        )
                                    }
                                    text={'Proceed to Payment'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

const PackagePlans = ({ packageList, handleOrder }) => (
    <div className='pricing-plan-container'>
        <h1
            style={{
                fontSize: '44px',
                textShadow: '2px 2px 1px gray',
                color: '#000',
                marginBottom: '40px',
                textAlign: 'center'
            }}
        >
            Choose The Best Plan For You
        </h1>
        <div className='pricing-plan-card-container'>
            <div className='pricing-plan-card'>
                <h1 style={{ fontSize: '24px', marginBottom: '0' }}>Free</h1>
                <h1 style={{ fontSize: '36px', color: '#ff3300', marginBottom: '0' }}>
                    0<span style={{ fontSize: '16px', color: '#000' }}>/month</span>
                </h1>
                <div>
                    <ul className='pricing-plan-card-items'>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Digital Notice Board
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Digital Complain Box
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Police Tenant Verification Form
                        </li>
                        <li>
                            {' '}
                            <CloseCircleRedIcon /> NID Verification
                        </li>
                        <li>
                            {' '}
                            <CloseCircleRedIcon /> Bill Management
                        </li>
                        <li>
                            {' '}
                            <CloseCircleRedIcon /> Expense Management
                        </li>
                        <li>
                            {' '}
                            <CloseCircleRedIcon /> Fund Management
                        </li>
                        <li>
                            {' '}
                            <CloseCircleRedIcon /> Visitor Management
                        </li>
                        <li>
                            {' '}
                            <CloseCircleRedIcon /> Service Worker Managemen
                        </li>
                        <li>
                            {' '}
                            <CloseCircleRedIcon /> Vehicle Management
                        </li>
                        <li>
                            {' '}
                            <CloseCircleRedIcon /> Parcel Management
                        </li>
                        <li>
                            {' '}
                            <CloseCircleRedIcon /> Mobile Intercom
                        </li>
                    </ul>
                </div>
                <button disabled={true} className='select-plan-btn'>
                    Select Plan
                </button>
            </div>
            <div className='pricing-plan-card active-card'>
                <h1 style={{ color: '#ff3300', fontSize: '24px', marginBottom: '0' }}>
                    Management
                </h1>
                <h1 style={{ fontSize: '36px', color: '#ff3300', marginBottom: '0' }}>
                    {packageList[1].price}
                    <span style={{ fontSize: '16px', color: '#000' }}>/month</span>
                </h1>
                <div>
                    <ul className='pricing-plan-card-items'>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Digital Notice Board
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Digital Complain Box
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Police Tenant Verification Form
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> NID Verification
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Bill Management
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Expense Management
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Fund Management
                        </li>
                        <li>
                            {' '}
                            <CloseCircleRedIcon /> Visitor Management
                        </li>
                        <li>
                            {' '}
                            <CloseCircleRedIcon /> Service Worker Managemen
                        </li>
                        <li>
                            {' '}
                            <CloseCircleRedIcon /> Vehicle Management
                        </li>
                        <li>
                            {' '}
                            <CloseCircleRedIcon /> Parcel Management
                        </li>
                        <li>
                            {' '}
                            <CloseCircleRedIcon /> Mobile Intercom
                        </li>
                    </ul>
                </div>
                <button
                    onClick={(e) => handleOrder(e, packageList[1].packageId)}
                    className='select-plan-active-btn'
                >
                    Select Plan
                </button>
            </div>
            <div className='pricing-plan-card'>
                <h1 style={{ fontSize: '24px', marginBottom: '0' }}>Management & Security</h1>
                <h1 style={{ fontSize: '36px', color: '#ff3300', marginBottom: '0' }}>
                    {packageList[2].price}
                    <span style={{ fontSize: '16px', color: '#000' }}>/month</span>
                </h1>
                <div>
                    <ul className='pricing-plan-card-items'>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Digital Notice Board
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Digital Complain Box
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Police Tenant Verification Form
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> NID Verification
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Bill Management
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Expense Management
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Fund Management
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Visitor Management
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Service Worker Managemen
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Vehicle Management
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Parcel Management
                        </li>
                        <li>
                            {' '}
                            <CheckCircleGreenIcon /> Mobile Intercom
                        </li>
                    </ul>
                </div>
                <button
                    onClick={(e) => handleOrder(e, packageList[2].packageId)}
                    className='select-plan-btn'
                >
                    Select Plan
                </button>
            </div>
        </div>
    </div>
)

export default PagePricingPlanTable
