import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import FormCreateFund from '../../forms/fund/FormCreateFund'
import FundAction from '../../../stores/money-management/fund/FundAction'
import TableFund from '../../tables/fund/TableFund'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'
import { GetFundsRequest } from '../../../stores/money-management/fund/requests/GetFundsRequest'
import { useTranslation } from 'react-i18next'

export default function PageFund() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, FundAction.REQUEST_CREATE_FUND)
    )
    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, FundAction.REQUEST_CREATE_FUND)
    )

    useEffect(() => {
        dispatch(FundAction._requestGetFunds(new GetFundsRequest()))
    }, [isFinishedDeletion, isFinishedCreation])

    return (
        <PageBasicContainer>
            <FormCreateFund />
            <PageMainBarContainer>
                <h2 style={{ textAlign: 'center', padding: '0 20px' }}>
                    {' '}
                    {t('fund.tableTitle.heading')}{' '}
                </h2>
                <TableFund />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
