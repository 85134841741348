import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import TableDataFilter from '../../tables/TableDataFilter'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'
import ReportAction from '../../../stores/reporting/ReportAction'
import LedgerReportRequest from '../../../stores/reporting/requests/LedgerReportRequest'
import TableItemizedReport from '../../tables/report/TableItemizedReport'
import { ItemTableTypeConstants } from '../../../assets/constants/GeneralConstants'

export default function PageIncomeStatement(props) {
    const dispatch = useDispatch()
    const [filterValues, setFilterValues] = useState({})

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        if (filterValues.buildingId) {
            dispatch(ReportAction._requestGetIncomeStatement(new LedgerReportRequest(filterValues)))
        }
    }, [filterValues])

    return (
        <PageBasicContainer>
            <PageMainBarContainer>
                <h2 style={{ justifySelf: 'center' }}>Statement</h2>
                <TableDataFilter
                    showBuildingFilter={true}
                    showDateRangeFilter={true}
                    sendDataToParent={receiveFilterData}
                />
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gridColumnGap: '10px'
                    }}
                >
                    <div>
                        <h2> Incomes </h2>
                        <TableItemizedReport itemType={ItemTableTypeConstants.INCOME} />
                    </div>
                    <div>
                        <h2> Expenses </h2>
                        <TableItemizedReport itemType={ItemTableTypeConstants.EXPENSE} />
                    </div>
                </div>
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
