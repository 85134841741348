import BaseRequest from '../../../special/models/BaseRequest'

export default class CreateFlatRequest extends BaseRequest {
    name = ''
    number = ''
    description = ''
    contactInfo = ''
    contactPerson = ''
    totalRoom = ''
    totalWashRoom = ''
    totalBalcony = ''
    size = ''
    buildingId = ''

    constructor(data) {
        super()
        this.update(data)
        this.name = data.number
    }
}
