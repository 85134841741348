import styled from 'styled-components'
import { Card } from '../forms/FormStyles'
import { isMobile } from 'react-device-detect'

export const PageDecorationContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    grid-template-columns: repeat(${(props) => props.item}, 1fr);
    // padding: ${(props) => props.paddingTop ? props.paddingTop : '20px'} ${(props) => (5 - props.item) * 5}% 0px ${(props) => (5 - props.item) * 5}% ;
    padding: ${(props) => props.paddingTop ? props.paddingTop : '20px'} 0% 0px 0% ;
    grid-column-gap: 10px;
    @media(max-width:768px){
        grid-column-gap: 5px;
        display: grid;
        padding: 10px 0px;
        grid-template-columns: repeat(2, 1fr);
    }
    @media(max-width:576px){
        grid-column-gap: 5px;
        display: grid;
        padding: 10px 0px;
        grid-template-columns: repeat(1, 1fr);
    }
`

export const PageBasicContainer = styled.div`
    margin: 20px 0px;
`
export const PageUserForm = styled.div`
    margin: 20px 5%;
    input{
        border: 1px solid #CBD5E0;
    }
`

export const PageFormContainer = styled.div`
    margin: 20px 5%;
    word-wrap: normal;
    input {
        border: none;
        border-bottom: 1px solid #cbd5e0;
        border-radius: 0;
        transition: 0.2s
    }
    input:hover,
    input:focus {
        border: 1px solid #cbd5e0;
        border-radius: 4px;
        transition: 0s;
    }
    table Td input {
        border: 1px solid #cbd5e0;
        border-radius: 4px;
    }
    table Td input:hover {
        border: 1px solid gray;
        border-radius: 4px;
    }
`

export const PageMainBarContainer = styled(Card)`
    margin-top: 30px;
    margin-bottom: 5px;
`
export const TableButtonContainer = styled.div`
    margin-top: 10px;
    margin-bottom: -20px;
    text-align: end;
`
