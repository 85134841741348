import React, { useState } from 'react'
import firebase from '../../../utils/firebase'
import { Link } from 'react-router-dom'
import * as ROUTES from '../../../assets/constants/routes'
import { Badge, Button, Icon, Popover } from 'antd'
import { TopBarTitle } from '../../Styles'
import avatar from '../../../assets/images/avatar.png'
import { useTranslation } from 'react-i18next'
import LanguageSwitcherButton from '../language-switcher/LanguageSwitcherButton'
import { useDispatch, useSelector } from 'react-redux'
import ResetAction from '../../../stores/special/reset/ResetAction'
import { ColorConstants } from '../../../assets/constants/GeneralConstants'
import { ButtonFillPurple } from '../buttons/CustomButtons'
import UserAction from '../../../stores/user-management/user/UserAction'
import { push } from 'connected-react-router'
import UserProfileIcon from '../../../assets/images/user-profile-new.png'
import CommunityIcon from '../../../assets/images/community.png'
import { makeSelectUserPerspective } from '../../../stores/user-management/user/UserSelector'

export default function TopBar(props) {
    const [popoverVisibility, setPopOverVisibility] = useState(false)
    const [notificationVisibility, setNotificationVisibility] = useState(false)
    const userPerspective = useSelector((state) => makeSelectUserPerspective(state))
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const logout = () => {
        dispatch(ResetAction.resetStorage())
        firebase.auth().signOut()
        localStorage.clear()
    }
    const selectPerspective = (selectedRole) => {
        if (JSON.stringify(props.userPerspective) !== JSON.stringify(selectedRole)) {
            localStorage.setItem('perspective', JSON.stringify(selectedRole))
            dispatch(UserAction._setPerspective(selectedRole))
            dispatch(push(ROUTES.AppRoutes.DEFAULT))
        }
    }
    const content = (
        <div>
            <div style={{ textAlign: 'center' }}>
                <img
                    style={{ height: '60px', width: '60px', borderRadius: '50%' }}
                    src={avatar}
                    alt=''
                />
                <h2 style={{ marginBottom: 0 }}>{props.userDetails.data.name}</h2>
                <h3 style={{ marginBottom: 0 }}>{props.userDetails.data.communityName}</h3>
            </div>
            <Link to={ROUTES.AppRoutes.PROFILE} onClick={() => setPopOverVisibility(false)}>
                <button className='btn-profile'>{t('topbar_profile')} </button>
            </Link>
            <Link to={ROUTES.AppRoutes.DEFAULT} onClick={() => setPopOverVisibility(false)}>
                <button className='btn-profile'>{t('topbar_dashboard')}</button>
            </Link>
            <Button className='btn-logout' onClick={logout}>
                {t('topbar_logout')}
            </Button>
        </div>
    )

    const notifications = (
        <div>
            <h3 style={{ fontWeight: 'bold' }}>Notifications</h3>
            <div
                style={{
                    border: '1px solid #ff3300',
                    padding: '6px 12px',
                    borderRadius: '4px',
                    margin: '5px auto',
                    background: '#FEF6F4'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '10px auto'
                    }}
                >
                    <h4 style={{ margin: '0', fontWeight: 'bold' }}>Billing Price</h4>
                    <div>
                        <span style={{ color: `${ColorConstants.RED}`, display: 'block' }}>
                            {new Date().toLocaleDateString()}
                        </span>
                        <span style={{ color: `${ColorConstants.RED}` }}>
                            {new Date().toLocaleTimeString()}
                        </span>
                    </div>
                </div>
                <p style={{ width: '350px' }}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley
                </p>
            </div>
            <div
                style={{
                    border: '1px solid gray',
                    padding: '6px 12px',
                    borderRadius: '4px',
                    backgroundColor: '#F6F6F6',
                    margin: '5px auto'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '10px auto'
                    }}
                >
                    <h4 style={{ margin: '0', fontWeight: 'bold' }}>Billing Price</h4>
                    <div>
                        <span style={{ color: `${ColorConstants.RED}`, display: 'block' }}>
                            {new Date().toLocaleDateString()}
                        </span>
                        <span style={{ color: `${ColorConstants.RED}` }}>
                            {new Date().toLocaleTimeString()}
                        </span>
                    </div>
                </div>
                <p style={{ width: '350px' }}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley
                </p>
            </div>
        </div>
    )

    console.log('role', props.userDetails.data.userRoles)

    const popOverContent = (
        <div style={{ border: '2px solid gray', borderRadius: '4px' }}>
            {props.userDetails.data.userRoles.map((role, index) =>
                !role.communityName && !role.communityId ? null : (
                    <div
                        key={index}
                        className={
                            JSON.stringify(role) === JSON.stringify(userPerspective)
                                ? 'perspective-small-card active-perspective-card'
                                : 'perspective-small-card'
                        }
                        onClick={() => selectPerspective(role)}
                    >
                        <div className='header-container'>
                            <img src={CommunityIcon} />{' '}
                            <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {role.communityName}
                            </h3>
                        </div>
                        <div className='header-container'>
                            <img src={UserProfileIcon}></img>
                            <p>{role.userRoleName}</p>
                        </div>
                    </div>
                )
            )}
        </div>
    )

    return (
        <div className={'app-topbar'}>
            <div className='perspective-topbar'>
                <Popover content={popOverContent}>
                    <div className={'perspective-small-card show-perspective'}>
                        <div className='header-container'>
                            <img src={CommunityIcon}></img>
                            <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {userPerspective.communityName}
                            </h3>
                        </div>
                        <div className='header-container'>
                            <img src={UserProfileIcon}></img>
                            <p>{userPerspective.userRoleName}</p>
                        </div>
                    </div>
                </Popover>
            </div>

            <LanguageSwitcherButton />
            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Popover
                    placement={'bottomRight'}
                    content={notifications}
                    trigger='click'
                    visible={notificationVisibility}
                    onVisibleChange={() => setNotificationVisibility((current) => !current)}
                >
                    <Badge count={1}>
                        <Icon type='bell' style={{ fontSize: '28px', cursor: 'pointer' }}></Icon>
                    </Badge>
                </Popover>
            </div> */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Popover
                    placement='bottomRight'
                    content={content}
                    trigger='click'
                    visible={popoverVisibility}
                    onVisibleChange={() => setPopOverVisibility((current) => !current)}
                >
                    {/* <SettingOutlined  style={{color:'#ff3300', fontSize:'30px'}}/> */}

                    <img
                        style={{
                            height: '50px',
                            width: '50px',
                            borderRadius: '50%',
                            cursor: 'pointer'
                        }}
                        src={avatar}
                        alt=''
                    />
                    {/* <div>
                            <h3 style={{ marginBottom: 0, color: '#fff', width: '100%' }}>
                                {props.userDetails.data.name}
                            </h3>
                           
                        </div> */}
                    {/* </div> */}
                </Popover>
            </div>
        </div>
    )
}
