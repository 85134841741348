import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import BaseRequest from '../../special/models/BaseRequest'
import ServerResponse from '../../special/models/ServerResponse'

export default class NewPackageEffect {
    static async _requestGetPackages(data) {
        const endPoint = ApiEndpoint.subscription.getPackages
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestCreatePackage(data) {
        const endPoint = ApiEndpoint.subscription.createPackage
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data));
    }
}
