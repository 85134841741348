/* eslint-disable prettier/prettier */
import React, { Suspense, useEffect } from 'react'
import 'antd/dist/antd.css'
import { useDispatch, useSelector } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router-dom'
import Controller from './auth/controller'
import '../App.scss'
import '../ant-styles.scss'
import './component/buttons/buttons.scss'
import './modals/modals.scss'
import './tables/tables.scss'
import './component/navigation/layouts.scss'
import LoadingIndicator from './component/loading-indicator/LoadingIndicator'
import { AppRoutes } from '../assets/constants/routes'
import UserAction from '../stores/user-management/user/UserAction'
import SideNavBar from './component/navigation/SideNavBar'
import {
    FundMovementTypeConstants,
    UserFunctionConstants,
    UserRoleCodeConstants
} from '../assets/constants/GeneralConstants'
import { useFirebaseAuthProvider } from './auth/useFirebaseAuthProvider'
import { AppContainer, AppMainBarContainer } from './Styles'
import PageFingerprintDevice from './pages/device/PageFingerprintDevice'
import PageFingerprint from './pages/device/PageFingerprint'
import { Button, Layout } from 'antd'
import { isMobile } from 'react-device-detect'
import PageProfile from './pages/PageProfile'
import FormGenerateExpenseVoucher from './forms/expense/FormGenerateExpenseVoucher'
import PageLedgerReport from './pages/report/PageLedgerReport'
import PageIncomeReport from './pages/report/PageIncomeReport'
import PageExpenseReport from './pages/report/PageExpenseReport'
import PageIncomeStatement from './pages/report/PageIncomeStatement'
import PagePendingExpenses from './pages/expense/PagePendingExpense'
import PageBillPackages from './pages/bill/PageBillPackages'
import PublicRoute from './component/route/PublicRoute'
import Toasts from './component/toasts/Toasts'
import ModalHolder from './modals/ModalHolder'
import TopBar from './component/navigation/TopBar'
import ErrorBoundary from './misc/ErrorBoundary'
import NotFoundPage from './misc/NotFoundPage'
import PageFundMovement from './pages/fund/PageFundMovement'
import PageFundDashboard from './pages/fund/PageFundDashboard'
import FormAddVehicle from './forms/vehicle/FormAddVehicle'
import PageVehicleEntryExit from './pages/entrance/PageVehicleEntryExit'
import PageVehicles from './pages/vehicle/PageVehicles'
import PageVisitors from './pages/entrance/PageVisitors'
import PageParcels from './pages/entrance/PageParcels'
import PageProperty from './pages/property/PageProperty'
import PageBillDetails from './pages/bill/PageBillDetails'
import PageUsers from './pages/users/PageUsers'
import PagePrivilege from './pages/PagePrivilege'
import PageFlats from './pages/property/PageFlats'
import PageCommunities from './pages/property/PageCommunities'
import PagePreAuthorizedList from './pages/users/PagePreAuthorizedList'
import PageBuildings from './pages/property/PageBuildings'
import PageFund from './pages/fund/PageFund'
import FormAddExpenseEntry from './forms/expense/FormAddExpenseEntry'
import PageUserManagement from './pages/users/PageUserManagement'
import PageAdminManagement from './pages/admin/PageAdminManagement'
import PageExpenseDashBoard from './pages/expense/PageExpenseDashBoard'
import PageExpenseTypes from './pages/expense/PageExpenseTypes'
import PageBillTypes from './pages/bill/PageBillTypes'
import PageBillGeneration from './pages/bill/PageBillGeneration'
import PageBillGenerationBackup from './pages/bill/PageBillGenerationBackup'
import PageMeetings from './pages/admin/PageMeetings'
import PageComplains from './pages/admin/PageComplains'
import PageNotices from './pages/admin/PageNotices'
import PagePoliceForm from './pages/admin/PagePoliceForm'
import SideNavBarForFreeUser from './component/navigation/SideNavBarForFreeUser'
import PageFlatUsers from './pages/users/PageFlatUsers'
import firebase from '../utils/firebase/index'
import PageRokkhiAdminManagement from './pages/rokkhiAdmin/PageRokkhiAdminManagement'
import PageDirectPayment from './pages/rokkhiAdmin/PageDirectPayment'
import PaymentStatus from './misc/PaymentStatus'
import FormTenant from './forms/admin/FormTenant'
import ModalSubscriptionWarning from './modals/ModalSubscriptionWarning'
import FormTenantDeparture from './forms/admin/FormTenantDeparture'
import FormPoliceVerification from './forms/admin/FormPoliceVerification'
import PageAllForms from './pages/admin/PageAllForms'
import PageDashBoard from './pages/dashboard/PageDashBoard'
import {
    makeSelectFunctionsOfUser,
    makeSelectUserPerspective
} from '../stores/user-management/user/UserSelector'
import { useFirebaseNotificationProvider } from './auth/useFirebaseNotificationProvider'
import PagePricingPlanTable from './pages/PagePricingPlanTable'
import GetUserFuntionsRequest from '../stores/user-management/user/requests/GetUserFunctionsRequest'
import { useState } from 'react'
import PageBillGenerationReport from './pages/bill/PageBillGenerationReport'
import PageBalanceSheet from './pages/fund/PageBalanceSheet'
import ResetAction from '../stores/special/reset/ResetAction'

const { Content } = Layout

// const attemptsLeft = 5

// const PageFundMovement =
//    import('./pages/fund/PageFundMovement'), attemptsLeft)
// )

// const PageFundDashboard =
//    import('./pages/fund/PageFundDashboard'), attemptsLeft)
// )

// const FormAddVehicle =
//    import('./forms/vehicle/FormAddVehicle'), attemptsLeft)
// )
// const PageVehicleEntryExit =
//    import('./pages/entrance/PageVehicleEntryExit'), attemptsLeft)
// )
// const PageVehicles =
//    import('./pages/vehicle/PageVehicles'), attemptsLeft)
// )
// const PageVisitors =
//    import('./pages/entrance/PageVisitors'), attemptsLeft)
// )
// const PageParcels =
//    import('./pages/entrance/PageParcels'), attemptsLeft)
// )
// const PageProperty =
//    import('./pages/property/PageProperty'), attemptsLeft)
// )

// const PageBillDetails =
//    import('./pages/bill/PageBillDetails'), attemptsLeft)
// )
// const PageUsers = import('./pages/users/PageUsers'), attemptsLeft))
// const PagePrivilege =
//    import('./pages/PagePrivilege'), attemptsLeft)
// )

// const PageFlats =
//    import('./pages/property/PageFlats'), attemptsLeft)
// )
// const PageCommunities =
//    import('./pages/property/PageCommunities'), attemptsLeft)
// )
// const PagePreAuthorizedList =
//    import('./pages/users/PagePreAuthorizedList'), attemptsLeft)
// )
// const PageBuildings =
//    import('./pages/property/PageBuildings'), attemptsLeft)
// )

// const PageFund = import('./pages/fund/PageFund'), attemptsLeft))

// const FormAddExpenseEntry =
//    import('./forms/expense/FormAddExpenseEntry'), attemptsLeft)
// )

// const PageUserManagement =
//    import('./pages/users/PageUserManagement'), attemptsLeft)
// )

// const PageAdminManagement =
//    import('./pages/admin/PageAdminManagement'), attemptsLeft)
// )

// const PageExpenseDashBoard =
//    import('./pages/expense/PageExpenseDashBoard'), attemptsLeft)
// )

// const PageExpenseTypes =
//    import('./pages/expense/PageExpenseTypes'), attemptsLeft)
// )

// const PageBillTypes =
//    import('./pages/bill/PageBillTypes'), attemptsLeft)
// )

// const PageBillGeneration =
//    import('./pages/bill/PageBillGeneration'), attemptsLeft)
// )
// const PageMeetings =
//    import('./pages/admin/PageMeetings'), attemptsLeft)
// )
// const PageComplains =
//    import('./pages/admin/PageComplains'), attemptsLeft)
// )
// const PageNotices =
//    import('./pages/admin/PageNotices'), attemptsLeft)
// )

export default function App(props) {
    const dispatch = useDispatch()
    const isSignedIn = useFirebaseAuthProvider()
    // useFirebaseNotificationProvider()
    const analytics = firebase.analytics()
    const userFunctions = useSelector((state) => makeSelectFunctionsOfUser(state))
    const userDetails = useSelector((state) => state.user.userDetails)
    const userPerspective = useSelector((state) => makeSelectUserPerspective(state))

    useEffect(() => {
        if (userDetails) {
            localStorage.setItem('JWT_TOKEN', userDetails.data.jwtToken)
            analytics.logEvent('login', {
                userId: userDetails.data.userId
            })
            // dispatch(UserAction._requestGetFunctionsOfUser(new GetUserFuntionsRequest()))
        }
    }, [userDetails])
    useEffect(() => {
        if (Object.keys(userPerspective).length) {
            dispatch(UserAction._requestGetFunctionsOfUser(new GetUserFuntionsRequest()))
        }
    }, [userPerspective])
    useEffect(() => {
        if (!localStorage.getItem('perspective')) {
            dispatch(UserAction._setPerspective(null))
        }
    }, [localStorage.getItem('perspective')])
    if (isSignedIn !== null) {
        if (
            isSignedIn &&
            userPerspective &&
            Object.keys(userPerspective).length > 0 &&
            userFunctions &&
            userFunctions.length > 0 &&
            userDetails &&
            userDetails.data
        ) {
            return (
                <ConnectedRouter history={props.history}>
                    <Suspense fallback={<LoadingIndicator isActive={true} />}>
                        <ErrorBoundary>
                            <Layout>
                                <AppContainer>
                                    {userDetails.data.userRoles
                                        .map((item) => item.code)
                                        .includes(
                                            UserRoleCodeConstants.COMMUNITY_SUPER_ADMIN_FREE.toString()
                                        ) ? (
                                        <SideNavBarForFreeUser />
                                    ) : (
                                        <SideNavBar />
                                    )}
                                    <Layout style={{ background: '#ddd', overflowX: 'auto' }}>
                                        <Content
                                            style={{
                                                padding: !isMobile
                                                    ? '0px 16px 24px'
                                                    : '0px 5px 24px',
                                                overflow: 'initial'
                                            }}
                                        >
                                            <AppMainBarContainer>
                                                <ModalSubscriptionWarning
                                                    userDetails={userDetails}
                                                />
                                                <TopBar
                                                    userDetails={userDetails}
                                                    userPerspective={userPerspective}
                                                />
                                                {/* <ModalTenantOwnerForm/>
                                                <ModalTenantDeparture/> */}
                                                <AppMainBar />
                                            </AppMainBarContainer>
                                        </Content>
                                    </Layout>
                                </AppContainer>
                            </Layout>
                            <ModalHolder />
                            <Toasts />
                            <LoadingIndicator />
                        </ErrorBoundary>
                    </Suspense>
                </ConnectedRouter>
            )
        } else {
            return (
                <Suspense fallback={<LoadingIndicator isActive={true} />}>
                    <Controller userDetails={userDetails} />
                    <ModalHolder />
                    <Toasts />
                </Suspense>
            )
        }
    } else {
        return <LoadingIndicator isActive={true} />
    }
}

function AppMainBar() {
    return (
        <Switch>
            {/* <Route exact path={'/play'} render={() => <FlatInvoicePdfPage />} /> */}
            <Route exact path={AppRoutes.DEFAULT} render={() => <PageDashBoard />} />
            <Route exact path={AppRoutes.PROFILE} render={() => <PageProfile />} />
            {/* <Route exact path={AppRoutes.BALANCE_SHEET} render={() => <PageBalanceSheet />} /> */}

            {/* <Route exact path={AppRoutes.PRICING_PLANS} render={() => <PagePricingPlanTable />} /> */}
            {/* <Route exact path={AppRoutes.PERSPECTIVE} render={() => <PagePerspective />} /> */}
            {/* <Route exact path={AppRoutes.POLICE_FORM} render={() => <FormPoliceVerification />} /> */}

            <PublicRoute
                functionCode={UserFunctionConstants.VIEW_COMMUNITIES}
                component={PageCommunities}
                path={AppRoutes.COMMUNITIES}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.VIEW_BUILDINGS}
                component={PageBuildings}
                path={AppRoutes.BUILDINGS}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.VIEW_FLATS}
                component={PageFlats}
                path={AppRoutes.FLATS}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_COMMUNITY}
                component={PageProperty}
                route={AppRoutes.CREATE_COMMUNITY}
                path={AppRoutes.CREATE_COMMUNITY}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_BUILDING}
                component={PageProperty}
                route={AppRoutes.CREATE_BUILDING}
                path={AppRoutes.CREATE_BUILDING}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_FLAT}
                component={PageProperty}
                route={AppRoutes.CREATE_MULTIPLE_FLAT}
                path={AppRoutes.CREATE_MULTIPLE_FLAT}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_FLAT}
                component={PageProperty}
                route={AppRoutes.CREATE_FLAT}
                path={AppRoutes.CREATE_FLAT}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_FLAT}
                component={PageProperty}
                route={AppRoutes.DYNAMIC_ADD_FLAT_MEMBER}
                path={AppRoutes.DYNAMIC_ADD_FLAT_MEMBER}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.VIEW_FLATS}
                component={PageFlatUsers}
                route={AppRoutes.DYNAMIC_SEE_FLAT_MEMBER}
                path={AppRoutes.DYNAMIC_SEE_FLAT_MEMBER}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.VIEW_LEDGER_REPORT}
                component={PageLedgerReport}
                path={AppRoutes.LEDGER_REPORT}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.VIEW_INCOME_REPORT}
                component={PageIncomeReport}
                path={AppRoutes.INCOME_REPORT}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.VIEW_EXPENSE_REPORT}
                component={PageExpenseReport}
                path={AppRoutes.EXPENSE_REPORT}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.COLLECT_BILL_OF_FLAT}
                component={PageLedgerReport}
                path={AppRoutes.CASH_FLOW_REPORT}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.VIEW_INCOME_STATEMENT}
                component={PageIncomeStatement}
                path={AppRoutes.INCOME_STATEMENT}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.VIEW_USERS}
                component={PageUsers}
                path={AppRoutes.VIEW_USER}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.REGISTER_USER}
                component={PageUserManagement}
                route={AppRoutes.REGISTER_USER}
                path={AppRoutes.REGISTER_USER}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.ADD_TO_WHITE_LIST}
                component={PageUserManagement}
                route={AppRoutes.ADD_TO_WHITE_LIST}
                path={AppRoutes.ADD_TO_WHITE_LIST}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.ADD_TO_BLACK_LIST}
                component={PageUserManagement}
                route={AppRoutes.ADD_TO_BLACK_LIST}
                path={AppRoutes.ADD_TO_BLACK_LIST}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.VIEW_PRE_AUTHORIZED_LIST_OF_COMMUNITY}
                component={PagePreAuthorizedList}
                path={AppRoutes.VIEW_PRE_AUTHORIZED_LIST}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.ADD_NEW_DEVICE}
                component={PageFingerprintDevice}
                path={AppRoutes.FINGERPRINT_DEVICE}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.ASSIGN_FINGERPRINT_TO_SERVICE_WORKERS}
                component={PageFingerprint}
                path={AppRoutes.ASSIGN_FINGERPRINT}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.VIEW_ALL_NOTICE_OF_COMMUNITY}
                component={PageNotices}
                path={AppRoutes.NOTICES}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.VIEW_ALL_COMPLAIN_OF_COMMUNITY}
                component={PageComplains}
                path={AppRoutes.COMPLAINS}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.MEETINGS_DASHBOARD}
                component={PageMeetings}
                path={AppRoutes.MEETINGS}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_VIEW_ALL_FORMS}
                component={PageAllForms}
                path={AppRoutes.ALL_FORMS}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_NEW_MEETING}
                component={PageAdminManagement}
                route={AppRoutes.CREATE_MEETING}
                path={AppRoutes.CREATE_MEETING}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.SEND_NOTICE_FROM_ADMIN}
                component={PageAdminManagement}
                route={AppRoutes.CREATE_NOTICE}
                path={AppRoutes.CREATE_NOTICE}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.SUBMIT_COMPLAIN}
                component={PageAdminManagement}
                route={AppRoutes.CREATE_COMPLAIN}
                path={AppRoutes.CREATE_COMPLAIN}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.ADD_MODIFY_USER_ROLE}
                component={PagePrivilege}
                route={AppRoutes.USER_ROLE}
                path={AppRoutes.USER_ROLE}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.ASSIGN_FUNCTIONS_TO_ROLE}
                component={PagePrivilege}
                route={AppRoutes.USER_FUNCTION}
                path={AppRoutes.USER_FUNCTION}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.ADD_MODIFY_USER_FUNCTION}
                component={PagePrivilege}
                route={AppRoutes.ASSIGN_FUNCTION_TO_ROLE}
                path={AppRoutes.ASSIGN_FUNCTION_TO_ROLE}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.ASSIGN_USER_TO_ROLE}
                component={PagePrivilege}
                route={AppRoutes.ASSIGN_ROLE_TO_USER}
                path={AppRoutes.ASSIGN_ROLE_TO_USER}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_FUND}
                component={PageFund}
                path={AppRoutes.FUNDS}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.VIEW_BALANCE_SHEET}
                component={PageBalanceSheet}
                path={AppRoutes.BALANCE_SHEET}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.ADD_TO_FUND}
                component={PageFundMovement}
                path={AppRoutes.ADD_TO_FUND}
                fundMovementType={FundMovementTypeConstants.DEPOSIT}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.WITHDRAW_FROM_FUND}
                component={PageFundMovement}
                path={AppRoutes.WITHDRAW_FROM_FUND}
                fundMovementType={FundMovementTypeConstants.WITHDRAW}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.TRANSFER_FUND}
                component={PageFundMovement}
                path={AppRoutes.FUND_TRANSFER}
                fundMovementType={FundMovementTypeConstants.TRANSFER}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.TRANSACTION_HISTORY}
                component={PageFundDashboard}
                path={AppRoutes.FUND_DASHBOARD}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_AND_VIEW_EXPENSE_TYPE}
                component={PageExpenseTypes}
                path={AppRoutes.EXPENSE_TYPES}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.ADD_EXPENSE_ENTRY}
                component={FormAddExpenseEntry}
                path={AppRoutes.EXPENSE_RECORD}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.GENERATE_EXPENSE_VOUCHER}
                component={FormGenerateExpenseVoucher}
                path={AppRoutes.EXPENSE_VOUCHER_GENERATION}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.AUTHORIZE_EXPENSE_VOUCHER}
                component={PagePendingExpenses}
                path={AppRoutes.EXPENSE_VOUCHER_AUTHORIZATION}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.EXPENSE_DASHBOARD}
                component={PageExpenseDashBoard}
                path={AppRoutes.EXPENSE_DASHBOARD}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.VEHICLES_LIST}
                component={PageVehicles}
                path={AppRoutes.VEHICLE_LIST}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.ADD_VEHICLE}
                component={FormAddVehicle}
                path={AppRoutes.ADD_VEHICLE}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_AND_VIEW_BILL_TYPE}
                component={PageBillTypes}
                path={AppRoutes.BILL_TYPES}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.GENERATE_BILL_FOR_FLAT}
                component={PageBillGeneration}
                path={AppRoutes.BILL_GENERATION}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.GENERATE_BILL_FOR_FLAT}
                component={PageBillGenerationBackup}
                path={AppRoutes.BILL_GENERATION_BACKUP}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_BILL_PACKAGE}
                component={PageBillPackages}
                path={AppRoutes.BILL_PACKAGE}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.COLLECT_BILL_OF_FLAT}
                component={PageBillDetails}
                path={AppRoutes.BILL_DETAILS}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.COLLECT_BILL_OF_FLAT}
                component={PageBillGenerationReport}
                path={AppRoutes.REQUESTED_BILL_GENERATION}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.VISITOR_ENTRANCE_DASHBOARD}
                component={PageVisitors}
                path={AppRoutes.VISITORS}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.PARCEL_ENTRANCE_DASHBOARD}
                component={PageParcels}
                path={AppRoutes.PARCELS}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.VEHICLE_ENTRANCE_DASHBOARD}
                component={PageVehicleEntryExit}
                path={AppRoutes.VEHICLE_ENTRY_EXIT}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_PACKAGE_ADMIN}
                component={PageRokkhiAdminManagement}
                route={AppRoutes.CREATE_PACKAGE}
                path={AppRoutes.CREATE_PACKAGE}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.DIRECT_PAYMENT}
                component={PageDirectPayment}
                // route={AppRoutes.DIRECT_PAYMENT_DYNAMIC}
                path={AppRoutes.DIRECT_PAYMENT_DYNAMIC}
                exact
            />

            <PublicRoute
                functionCode={UserFunctionConstants.PAYMENT}
                component={PaymentStatus}
                // route={AppRoutes.DYNAMIC_PAYMENT}
                path={AppRoutes.DYNAMIC_PAYMENT}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_VIEW_ALL_FORMS}
                component={FormTenant}
                // route={AppRoutes.TENANT_FORM}
                path={AppRoutes.DYNAMIC_CREATE_TENANT_FORM}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_VIEW_ALL_FORMS}
                component={FormTenantDeparture}
                // route={AppRoutes.TENANT_DEPARTURE_FORM}
                path={AppRoutes.DYNAMIC_CREATE_TENANT_DEPARTURE_FORM}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.CREATE_VIEW_ALL_FORMS}
                component={FormPoliceVerification}
                // route={AppRoutes.TENANT_DEPARTURE_FORM}
                path={AppRoutes.DYNAMIC_CREATE_POLICE_FORM}
                exact
            />
            <PublicRoute
                functionCode={UserFunctionConstants.POLICE_FORM_ADMIN}
                component={PagePoliceForm}
                // route={AppRoutes.TENANT_DEPARTURE_FORM}
                path={AppRoutes.POLICE_FORMS}
                exact
            />
            {/* <PublicRoute
                functionCode={UserFunctionConstants.PAYMENT}
                component={PageDashBoard}
                path={AppRoutes.USER_DASHBOARD}
                exact
            /> */}
            {/* <PublicRoute
                functionCode={UserFunctionConstants.POLICE_FORM_ADMIN}
                component={PageNIDVerification}
                path={AppRoutes.ADMIN_NID_VERIFICATION}
                exact
            /> */}

            <Route component={NotFoundPage} />
        </Switch>
    )
}
