import BaseRequest from '../../../special/models/BaseRequest'

export class CreateFundRequest extends BaseRequest {
    fundName = ''
    description = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
