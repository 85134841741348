import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
const InvoiceBody = (props) => {
    return (
        <>
            {props.invoiceForFlat && (
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.flatBillName, styles.tableHeader]}>
                            <Text style={styles.tableHeaderCell}>Item Name</Text>
                        </View>
                        <View style={[styles.tableCol, styles.flatBillAmount, styles.tableHeader]}>
                            <Text style={styles.tableHeaderCell}>Amount</Text>
                        </View>
                    </View>
                    {props.data.items.map((item, index) => (
                        <View key={item.id} style={styles.tableRow}>
                            <View style={[styles.tableCol, styles.flatBillName]}>
                                <Text style={styles.tableCell}>{item.name}</Text>
                            </View>
                            <View style={[styles.tableCol, styles.flatBillAmount]}>
                                <Text style={styles.tableCell}>{item.amount}</Text>
                            </View>
                        </View>
                    ))}
                    <View style={[styles.tableRow, styles.tableFooter]}>
                        <View style={[styles.tableCol, styles.flatBillName, styles.tableHeader]}>
                            <Text style={styles.tableHeaderCell}> Total Amount </Text>
                        </View>
                        <View style={[styles.tableCol, styles.flatBillAmount, styles.tableHeader]}>
                            <Text style={styles.tableHeaderCell}>{props.data.totalAmount}</Text>
                        </View>
                    </View>
                </View>
            )}
            {props.invoiceForExpense && (
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.expenseBillName, styles.tableHeader]}>
                            <Text style={styles.tableHeaderCell}>Item Name</Text>
                        </View>
                        <View
                            style={[
                                styles.tableCol,
                                styles.expenseBillDescription,
                                styles.tableHeader
                            ]}
                        >
                            <Text style={styles.tableHeaderCell}>Description</Text>
                        </View>
                        <View style={[styles.tableCol, styles.expenseBillDate, styles.tableHeader]}>
                            <Text style={styles.tableHeaderCell}>Date</Text>
                        </View>
                        <View
                            style={[styles.tableCol, styles.expenseBillAmount, styles.tableHeader]}
                        >
                            <Text style={styles.tableHeaderCell}>Amount</Text>
                        </View>
                    </View>
                    {props.data.items.map((item, index) => (
                        <View key={item.id} style={styles.tableRow}>
                            <View style={[styles.tableCol, styles.expenseBillName]}>
                                <Text style={styles.tableCell}>{item.name}</Text>
                            </View>
                            <View style={[styles.tableCol, styles.expenseBillDescription]}>
                                <Text style={styles.tableCell}>{item.description}</Text>
                            </View>
                            <View style={[styles.tableCol, styles.expenseBillDate]}>
                                <Text style={styles.tableCell}>{item.date}</Text>
                            </View>
                            <View style={[styles.tableCol, styles.expenseBillAmount]}>
                                <Text style={styles.tableCell}>{item.amount}</Text>
                            </View>
                        </View>
                    ))}
                    <View style={[styles.tableRow, styles.tableFooter]}>
                        <View style={[styles.tableCol, styles.expenseBillName, styles.tableHeader]}>
                            <Text style={styles.tableHeaderCell}> Total Amount </Text>
                        </View>
                        <View
                            style={[
                                styles.tableCol,
                                styles.expenseBillDescription,
                                styles.tableHeader
                            ]}
                        >
                            <Text style={styles.tableHeaderCell}></Text>
                        </View>
                        <View style={[styles.tableCol, styles.expenseBillDate, styles.tableHeader]}>
                            <Text style={styles.tableHeaderCell}></Text>
                        </View>
                        <View
                            style={[styles.tableCol, styles.expenseBillAmount, styles.tableHeader]}
                        >
                            <Text style={styles.tableHeaderCell}>{props.data.totalAmount}</Text>
                        </View>
                    </View>
                </View>
            )}
        </>
    )
}

export default InvoiceBody

const styles = StyleSheet.create({
    table: {
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'left',
        borderStyle: 'solid',
        borderWidth: 1
    },
    flatBillName: {
        width: `70%`,
        borderStyle: 'solid',
        borderRightWidth: 1
    },
    flatBillAmount: {
        width: `30%`
    },
    expenseBillName: {
        width: `25%`,
        borderStyle: 'solid',
        borderRightWidth: 1,
        height: 'auto'
    },
    expenseBillDescription: {
        width: `35%`,
        borderStyle: 'solid',
        borderRightWidth: 1,
        height: 'auto'
    },
    expenseBillDate: {
        width: `20%`,
        borderStyle: 'solid',
        borderRightWidth: 1,
        height: 'auto'
    },
    expenseBillAmount: {
        width: `20%`,
        height: 'auto'
    },
    tableHeader: {
        backgroundColor: '#C7D9F2'
    },
    tableData: {},
    tableCol: {
        height: 25,
        borderStyle: 'solid',
        borderBottomWidth: 1
    },
    tableRow: {
        flexDirection: 'row'
    },
    tableHeaderCell: {
        fontSize: 12,
        fontWeight: 'bold',
        margin: 5,
        
    },
    tableCell: {
        margin: 5,
        fontSize: 10
    },

    body: {
        fontFamily: 'Times-Roman',
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 25
    },
    title: {
        fontSize: 24,
        textAlign: 'center'
    },
    billHeader: {
        backgroundColor: '#000000',
        color: '#ffffff',
        padding: 10,
        borderRadius: 4,
        marginBottom: 10,
        fontSize: 24,
        textAlign: 'center'
    },
    address: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 4
    },
    billingMonth: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 20
    },
    tableFooter: {
        marginTop: 'auto',
        borderStyle: 'solid',
        fontWeight: 'bold',
        borderTopWidth: 1
    },
    flatOwnerName: {
        fontSize: 13,
        marginTop: 10
    }
})
