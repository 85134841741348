import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash/fp'
import '../Form.scss'
import {
    FormLabel,
    FormControl,
    Input,
    Button,
    Box,
    Grid,
    Heading,
    Flex,
    Image,
    Text,
    InputGroup,
    InputLeftAddon
} from '@chakra-ui/react'
import { ThemeProvider, theme } from '@chakra-ui/react'
import logo from '../../../assets/images/dhaka_police_logo.png'
import { Th, Td } from '../../component/table/Table'
import { PageUserForm } from '../../pages/PageStyles'
import { WarningOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { decode } from 'base-64'
import { _getSelectedImageFile, _getSelectedImageUrl } from '../../../selectors/ImageSelector'
import { ImageConstants } from '../../../assets/constants/GeneralConstants'
import UploadImageButton from '../../component/upload/UploadImageButton'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import MiscAction from '../../../stores/misc/MiscAction'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import FormsAction from '../../../stores/admin-management/forms/FormsAction'
import ImageSelectModel from '../../../stores/misc/model/ImageSelectModel'
import { message } from 'antd'
import useImageUpload from '../../component/upload/useImageUpload'
import CreateNewPoliceFormRequest from '../../../stores/admin-management/forms/requests/CreateNewPoliceFormRequest'

export default function FormPoliceVerification() {
    const dispatch = useDispatch()

    const { control } = useFormInputValidation()

    const isFinished = useSelector((state) => {
        selectFinished(state, FormsAction.REQUEST_CREATE_POLICE_FORM_FINISHED)
    })

    useEffect(() => {
        dispatch(
            MiscAction._setImage(
                new ImageSelectModel(ImageConstants.FOR_ADD_POLICE_FORM, null, null)
            )
        )
    }, [isFinished])

    const customTheme = {
        ...theme,
        fonts: {
            heading: "'Raleway', sans-serif",
            body: "'Raleway', sans-serif;",
            mono: 'Menlo, monospace'
        }
    }

    const { handleSubmit, errors, register, watch, reset } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: true
    })
    const [image, setImage] = useState()
    const [loading, setLoading] = useState(false)

    const { encryptedData } = useParams()

    try {
        const decodedData = JSON.parse(decode(encryptedData))
        var { userId } = decodedData
    } catch (error) {
        // message.error('Wrong user id!')
    }

    const imageUrl = useSelector((state) =>
        _getSelectedImageUrl(state, ImageConstants.FOR_ADD_POLICE_FORM)
    )
    const imageFile = useSelector((state) =>
        _getSelectedImageFile(state, ImageConstants.FOR_ADD_POLICE_FORM)
    )

    async function onSubmit(values) {
        if (imageUrl) {
            const hideUploading = message.loading('Image Uploading...', 0)
            const { uploadImage } = useImageUpload()
            const formData = new FormData()
            formData.append('image', imageFile)
            formData.append('folderName', 'police')
            formData.append('subFolderName', userId)
            formData.append('fileName', Date.now())
            const postImage = await uploadImage(formData)

            if (postImage.success) {
                setTimeout(hideUploading, 0)
                message.success({
                    content: 'Image uploaded successfully!',
                    key: 'imagePoliceUploadSuccessfully'
                })

                const newValues = {
                    userId: userId,
                    forms: { ...values, passportImage: postImage.success }
                }

                dispatch(
                    FormsAction._requestCreateNewPoliceForm(
                        new CreateNewPoliceFormRequest(newValues)
                    )
                )
            } else if (postImage.error) {
                setTimeout(hideUploading, 0)
                message.error({
                    content: 'Image Upload Failed! Please Try Again.',
                    key: 'imagePoliceUploadFailed'
                })
                return false
            }
        } else {
            const newValues = {
                userId: userId,
                forms: { ...values, passportImage: ''}
            }
            dispatch(
                FormsAction._requestCreateNewPoliceForm(new CreateNewPoliceFormRequest(newValues))
            )
        }
        // postVerification({
        //   ...values,
        //   passportImage: image?.file,
        // });

        // const data = {
        //     userId: userId,
        //     forms: {...values, passportImage: image?.url}
        // }
    }

    function onUpload(e) {
        document.getElementById('passportImage').click()
    }

    function onFileUploaded(e) {
        setImage({
            file: e.target.files,
            url: URL.createObjectURL(e.target.files[0])
        })
    }

    return (
        <PageUserForm>
            <ThemeProvider theme={customTheme}>
                {/* <CSSReset /> */}
                <Box className='police-form-container' borderRadius={6} py={6} bg='white'>
                    <Box>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid templateColumns='repeat(1, .5fr 1.5fr 1fr)' gap={8} w='100%'>
                                <Flex w='100%' flexDir='column'>
                                    <Box
                                        my={3}
                                        ml={4}
                                        maxW='xs'
                                        d='flex'
                                        justifyContent='center'
                                        alignItems='center'
                                    >
                                        <UploadImageButton
                                            label={'ভাড়াটিয়ার এক কপি ছবি'}
                                            control={control}
                                            name={'image'}
                                            imageFor={ImageConstants.FOR_ADD_POLICE_FORM}
                                            imageUrl={imageUrl}
                                        />
                                    </Box>
                                    {/* <Box
                                        border='2px solid #CBD5E0'
                                        rounded='lg'
                                        height='200px'
                                        maxW='xs'
                                        d='flex'
                                        justifyContent='center'
                                    >
                                        {image && image.url ? (
                                            <Image
                                                width='100%'
                                                height='100%'
                                                src={image.url}
                                                alt='image'
                                            />
                                        ) : (
                                            <Text
                                                fontSize='lg'
                                                fontWeight={400}
                                                textAlign='center'
                                                style={{ margin: 'auto' }}
                                            >
                                                ভাড়াটিয়ার এক কপি পাসপোর্ট সাইজ ছবি
                                            </Text>
                                        )}
                                    </Box>
                                    <Input
                                        type='file'
                                        name='passportImage'
                                        id='passportImage'
                                        hidden
                                        onChange={onFileUploaded}
                                        accept='image/png,image/jpg,image/jpeg'
                                    />
                                    <Button
                                        leftIcon={<PlusOutlined />}
                                        colorScheme='red'
                                        variant='outline'
                                        my={3}
                                        mx='auto'
                                        onClick={onUpload}
                                    >
                                        UPLOAD
                                    </Button> */}
                                </Flex>
                                <Box w='100%'>
                                    <Flex my={6} alignItems='center' justifyContent='center'>
                                        <Image src={logo} alt='police logo' height='58px' mr={4} />
                                        <Heading as='h2' size='xl'>
                                            ঢাকা মেট্রোপলিটন পুলিশ
                                        </Heading>
                                    </Flex>
                                    <Box px={[0, 6, 12]}>
                                        <FormControl
                                            isRequired
                                            w='100%'
                                            d='flex'
                                            alignItems='center'
                                            my={3}
                                        >
                                            <FormLabel htmlFor='division' w='15%'>
                                                বিভাগ :
                                            </FormLabel>
                                            <Input name='division' ref={register} w='85%' />
                                        </FormControl>
                                        <FormControl
                                            isRequired
                                            w='100%'
                                            d='flex'
                                            alignItems='center'
                                            my={3}
                                        >
                                            <FormLabel htmlFor='thana' w='15%'>
                                                থানা :
                                            </FormLabel>
                                            <Input name='thana' ref={register} w='85%' />
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box w='100%'>
                                    <FormControl
                                        isRequired
                                        w='100%'
                                        d='flex'
                                        alignItems='center'
                                        my={3}
                                    >
                                        <FormLabel htmlFor='floor' w='32%'>
                                            ফ্লাট/তলা :
                                        </FormLabel>
                                        <Input name='floor' ref={register} w='68%' />
                                    </FormControl>
                                    <FormControl
                                        isRequired
                                        w='100%'
                                        d='flex'
                                        alignItems='center'
                                        my={3}
                                    >
                                        <FormLabel htmlFor='house' w='32%'>
                                            বাড়ী/হোল্ডিং :
                                        </FormLabel>
                                        <Input name='house' ref={register} w='68%' />
                                    </FormControl>
                                    <FormControl
                                        isRequired
                                        w='100%'
                                        d='flex'
                                        alignItems='center'
                                        my={3}
                                    >
                                        <FormLabel htmlFor='road' w='32%'>
                                            রাস্তা :
                                        </FormLabel>
                                        <Input name='road' ref={register} w='68%' />
                                    </FormControl>
                                    <FormControl
                                        isRequired
                                        w='100%'
                                        d='flex'
                                        alignItems='center'
                                        my={3}
                                        position='relative'
                                    >
                                        <FormLabel htmlFor='area' w='32%'>
                                            এলাকা :
                                        </FormLabel>

                                        <Input name='area' ref={register} w='68%' />
                                    </FormControl>
                                    <FormControl
                                        isRequired
                                        w='100%'
                                        d='flex'
                                        alignItems='center'
                                        my={3}
                                        type='number'
                                    >
                                        <FormLabel htmlFor='postCode' w='32%'>
                                            পোস্ট কোড :
                                        </FormLabel>
                                        <Input
                                            name='postCode'
                                            ref={register}
                                            w='68%'
                                            type='number'
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Flex justify='center' mt={8} mb={12}>
                                <Heading as='h2' fontSize='24px' textAlign='center' as='u'>
                                    ভাড়াটিয়া নিবন্ধন ফরম
                                </Heading>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    1.
                                </Box>
                                <FormControl isRequired w='100%'>
                                    <FormLabel htmlFor='fullName'>
                                        ভাড়াটিয়া/বাড়িওয়ালা নাম :
                                    </FormLabel>
                                    <Input
                                        name='fullName'
                                        ref={register}
                                        variant='outline'
                                        border='none'
                                    />
                                </FormControl>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    2.
                                </Box>
                                <FormControl isRequired w='100%'>
                                    <FormLabel htmlFor='fatherName'>পিতার নাম :</FormLabel>
                                    <Input name='fatherName' type='text' ref={register} />
                                </FormControl>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    3.
                                </Box>
                                <Grid templateColumns='repeat(2, 1fr)' gap={6} w='100%'>
                                    <FormControl isRequired w='100%'>
                                        <FormLabel htmlFor='birthDate'>জন্ম তারিখ :</FormLabel>
                                        <Input name='birthDate' type='date' ref={register} />
                                    </FormControl>
                                    <FormControl isRequired w='100%'>
                                        <FormLabel htmlFor='maritalStatus'>
                                            বৈবাহিক অবস্থা :
                                        </FormLabel>
                                        <Input name='maritalStatus' ref={register} />
                                    </FormControl>
                                </Grid>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    4.
                                </Box>
                                <FormControl isRequired w='100%'>
                                    <FormLabel htmlFor='permanentAddress'>
                                        স্থায়ী ঠিকানা :
                                    </FormLabel>
                                    <Input name='permanentAddress' type='text' ref={register} />
                                </FormControl>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    5.
                                </Box>
                                <FormControl isRequired w='100%'>
                                    <FormLabel htmlFor='workAddress'>
                                        পেশা ও প্রতিষ্টান / কর্মস্থল ঠিকানা :
                                    </FormLabel>
                                    <Input name='workAddress' type='text' ref={register} />
                                </FormControl>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    6.
                                </Box>
                                <Grid templateColumns='repeat(2, 1fr)' gap={6} w='100%'>
                                    <FormControl isRequired w='100%'>
                                        <FormLabel htmlFor='religion'>ধর্ম :</FormLabel>
                                        <Input name='religion' ref={register} />
                                    </FormControl>
                                    <FormControl isRequired w='100%'>
                                        <FormLabel htmlFor='educationStatus'>
                                            শিক্ষাগত যোগ্যতা :
                                        </FormLabel>
                                        <Input name='educationStatus' ref={register} />
                                    </FormControl>
                                </Grid>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    7.
                                </Box>
                                <Grid templateColumns='repeat(2, 1fr)' gap={6} w='100%'>
                                    <FormControl w='100%' isRequired type='number'>
                                        <FormLabel htmlFor='mobileNumber'>
                                            মোবাইল নম্বর 1:
                                        </FormLabel>
                                        <InputGroup>
                                            <InputLeftAddon children='+88' borderColor='#CBD5E0' />
                                            <Input
                                                name='mobileNumber'
                                                type='number'
                                                roundedLeft='0'
                                                placeholder='01XXXXXXXXX'
                                                ref={register({
                                                    pattern: /(^(01){1}[3456789]{1}(\d){8})$/
                                                })}
                                            />
                                        </InputGroup>
                                        {errors.mobileNumber &&
                                            errors.mobileNumber.type === 'pattern' && (
                                                <Text
                                                    color='red.500'
                                                    fontWeight='500'
                                                    fontSize='md'
                                                    mb='0'
                                                    mt='2'
                                                >
                                                    <WarningOutlined /> Please Provide a Valid Phone
                                                    Number !
                                                </Text>
                                            )}
                                    </FormControl>
                                    <FormControl isRequired w='100%'>
                                        <FormLabel htmlFor='email'>ই-মেইল আইডি :</FormLabel>
                                        <Input name='email' type='email' ref={register} />
                                    </FormControl>
                                </Grid>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    8.
                                </Box>
                                <FormControl isRequired w='100%' type='number'>
                                    <FormLabel htmlFor='nidNumber' type='number'>
                                        জাতীয় পরিচয়পত্র নম্বর :
                                    </FormLabel>
                                    <Input name='nidNumber' type='number' ref={register} />
                                </FormControl>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    9.
                                </Box>
                                <FormControl w='100%'>
                                    <FormLabel htmlFor='passportNumber'>
                                        পাসপোর্ট নম্বর (যদি থাকে) :
                                    </FormLabel>
                                    <Input name='passportNumber' type='text' ref={register} />
                                </FormControl>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    10.
                                </Box>
                                <Flex direction='column' w='100%'>
                                    <Box mb={2}>জরুরি যোগাযোগ</Box>
                                    <Grid templateColumns='repeat(4, 1fr)' gap={6}>
                                        <FormControl isRequired w='100%'>
                                            <FormLabel htmlFor='emergency_con_name'>
                                                (ক) নাম :
                                            </FormLabel>
                                            <Input
                                                name='emergency_con_name'
                                                type='text'
                                                ref={register}
                                            />
                                        </FormControl>
                                        <FormControl isRequired w='100%'>
                                            <FormLabel htmlFor='emergency_con_relation'>
                                                (খ) সম্পর্ক :
                                            </FormLabel>
                                            <Input name='emergency_con_relation' ref={register} />
                                        </FormControl>
                                        <FormControl isRequired w='100%'>
                                            <FormLabel htmlFor='emergency_con_address'>
                                                (গ) ঠিকানা :
                                            </FormLabel>
                                            <Input name='emergency_con_address' ref={register} />
                                        </FormControl>

                                        <FormControl isRequired w='100%'>
                                            <FormLabel htmlFor='emergency_con_number'>
                                                (ঘ) মোবাইল নম্বর :
                                            </FormLabel>
                                            <InputGroup>
                                                <InputLeftAddon
                                                    children='+88'
                                                    borderColor='#CBD5E0'
                                                />
                                                <Input
                                                    name='emergency_con_number'
                                                    type='number'
                                                    roundedLeft='0'
                                                    placeholder='01XXXXXXXXX'
                                                    ref={register({
                                                        pattern: /(^(01){1}[3456789]{1}(\d){8})$/
                                                    })}
                                                />
                                            </InputGroup>
                                            {errors.emergency_con_number &&
                                                errors.emergency_con_number.type === 'pattern' && (
                                                    <Text
                                                        color='red.500'
                                                        fontWeight='500'
                                                        fontSize='md'
                                                        mb='0'
                                                        mt='2'
                                                    >
                                                        <WarningOutlined /> Please Provide a Valid
                                                        Phone Number !
                                                    </Text>
                                                )}
                                        </FormControl>
                                    </Grid>
                                </Flex>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    11.
                                </Box>
                                <Flex direction='column' w='100%'>
                                    <Box mb={2}>পরিবার / মেসের সঙ্গীর সংদস্যদের বিবরণ </Box>
                                    <table>
                                        <tr>
                                            <Th style={{ width: '7%' }}>ক্রম নং</Th>
                                            <Th style={{ width: '35%' }}>নাম</Th>
                                            <Th style={{ width: '10%' }}>বয়স</Th>
                                            <Th style={{ width: '18%' }}>পেশা</Th>
                                            <Th style={{ width: '24%' }}>মোবাইল নম্বর</Th>
                                        </tr>
                                        <tr>
                                            <Td>1.</Td>
                                            <Td>
                                                <Input
                                                    name='familyMamOne_name'
                                                    type='text'
                                                    ref={register}
                                                    border='none'
                                                />
                                            </Td>
                                            <Td>
                                                <Input
                                                    name='familyMamOne_age'
                                                    type='number'
                                                    ref={register}
                                                    border='none'
                                                />
                                            </Td>
                                            <Td>
                                                <Input
                                                    name='familyMamOne_occu'
                                                    type='text'
                                                    ref={register}
                                                    border='none'
                                                />
                                            </Td>
                                            <Td>
                                                <Input
                                                    name='familyMamOne_num'
                                                    type='text'
                                                    ref={register}
                                                    border='none'
                                                />
                                            </Td>
                                        </tr>
                                        <tr>
                                            <Td>2.</Td>
                                            <Td>
                                                <Input
                                                    name='familyMamTwo_name'
                                                    type='text'
                                                    ref={register}
                                                    border='none'
                                                />
                                            </Td>
                                            <Td>
                                                <Input
                                                    name='familyMamTwo_age'
                                                    type='number'
                                                    ref={register}
                                                    border='none'
                                                />
                                            </Td>
                                            <Td>
                                                <Input
                                                    name='familyMamTwo_occu'
                                                    type='text'
                                                    ref={register}
                                                    border='none'
                                                />
                                            </Td>
                                            <Td>
                                                <Input
                                                    name='familyMamTwo_num'
                                                    type='text'
                                                    ref={register}
                                                    border='none'
                                                />
                                            </Td>
                                        </tr>
                                        <tr>
                                            <Td>3.</Td>
                                            <Td>
                                                <Input
                                                    name='familyMamThree_name'
                                                    type='text'
                                                    ref={register}
                                                    border='none'
                                                />
                                            </Td>
                                            <Td>
                                                <Input
                                                    name='familyMamThree_age'
                                                    type='number'
                                                    ref={register}
                                                    border='none'
                                                />
                                            </Td>
                                            <Td>
                                                <Input
                                                    name='familyMamThree_occu'
                                                    type='text'
                                                    ref={register}
                                                    border='none'
                                                />
                                            </Td>
                                            <Td>
                                                <Input
                                                    name='familyMamThree_num'
                                                    type='text'
                                                    ref={register}
                                                    border='none'
                                                />
                                            </Td>
                                        </tr>
                                    </table>
                                </Flex>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    12.
                                </Box>
                                <Grid templateColumns='repeat(4, 1fr)' gap={6} w='100%'>
                                    <FormControl w='100%'>
                                        <FormLabel htmlFor='maid_name'>গৃহকর্মীর নাম :</FormLabel>
                                        <Input name='maid_name' type='text' ref={register} />
                                    </FormControl>
                                    <FormControl w='100%' type='number'>
                                        <FormLabel htmlFor='maid_nid'>
                                            জাতীয় পরিচয়পত্র নং :
                                        </FormLabel>
                                        <Input name='maid_nid' ref={register} />
                                    </FormControl>

                                    <FormControl w='100%'>
                                        <FormLabel htmlFor='maid_number'>মোবাইল নম্বর :</FormLabel>
                                        <InputGroup>
                                            <InputLeftAddon children='+88' borderColor='#CBD5E0' />
                                            <Input
                                                name='maid_number'
                                                type='number'
                                                roundedLeft='0'
                                                placeholder='01XXXXXXXXX'
                                                ref={register({
                                                    pattern: /(^(01){1}[3456789]{1}(\d){8})$/
                                                })}
                                            />
                                        </InputGroup>
                                        {errors.maid_number &&
                                            errors.maid_number.type === 'pattern' && (
                                                <Text
                                                    color='red.500'
                                                    fontWeight='500'
                                                    fontSize='md'
                                                    mb='0'
                                                    mt='2'
                                                >
                                                    <WarningOutlined /> Wrong Phone Number !
                                                </Text>
                                            )}
                                    </FormControl>
                                    <FormControl w='100%'>
                                        <FormLabel htmlFor='maid_address'>
                                            স্থায়ী ঠিকানা :
                                        </FormLabel>
                                        <Input name='maid_address' ref={register} />
                                    </FormControl>
                                </Grid>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    13.
                                </Box>
                                <Grid templateColumns='repeat(4, 1fr)' gap={6} w='100%'>
                                    <FormControl w='100%'>
                                        <FormLabel htmlFor='driver_name'>
                                            ড্রাইভারের নাম :
                                        </FormLabel>
                                        <Input name='driver_name' type='text' ref={register} />
                                    </FormControl>
                                    <FormControl w='100%' type='number'>
                                        <FormLabel htmlFor='driver_nid'>
                                            জাতীয় পরিচয়পত্র নং :
                                        </FormLabel>
                                        <Input name='driver_nid' ref={register} />
                                    </FormControl>

                                    <FormControl w='100%'>
                                        <FormLabel htmlFor='driver_number'>
                                            মোবাইল নম্বর :
                                        </FormLabel>
                                        <InputGroup>
                                            <InputLeftAddon children='+88' borderColor='#CBD5E0' />
                                            <Input
                                                name='driver_number'
                                                type='number'
                                                roundedLeft='0'
                                                placeholder='01XXXXXXXXX'
                                                ref={register({
                                                    pattern: /(^(01){1}[3456789]{1}(\d){8})$/
                                                })}
                                            />
                                        </InputGroup>
                                        {errors.driver_number &&
                                            errors.driver_number.type === 'pattern' && (
                                                <Text
                                                    color='red.500'
                                                    fontWeight='500'
                                                    fontSize='md'
                                                    mb='0'
                                                    mt='2'
                                                >
                                                    <WarningOutlined /> Please Provide a Valid Phone
                                                    Number !
                                                </Text>
                                            )}
                                    </FormControl>

                                    <FormControl w='100%'>
                                        <FormLabel htmlFor='driver_address'>
                                            স্থায়ী ঠিকানা :
                                        </FormLabel>
                                        <Input name='driver_address' ref={register} />
                                    </FormControl>
                                </Grid>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    14.
                                </Box>
                                <Grid templateColumns='repeat(3, 1fr)' gap={6} w='100%'>
                                    <FormControl w='100%'>
                                        <FormLabel htmlFor='prevLandlord_name'>
                                            পূর্ববর্তী বাড়িওয়ালার নাম :
                                        </FormLabel>
                                        <Input
                                            name='prevLandlord_name'
                                            type='text'
                                            ref={register}
                                        />
                                    </FormControl>

                                    <FormControl w='100%'>
                                        <FormLabel htmlFor='prevLandlord_number'>
                                            মোবাইল নম্বর :
                                        </FormLabel>

                                        <InputGroup>
                                            <InputLeftAddon children='+88' borderColor='#CBD5E0' />
                                            <Input
                                                name='prevLandlord_number'
                                                type='number'
                                                roundedLeft='0'
                                                placeholder='01XXXXXXXXX'
                                                ref={register({
                                                    pattern: /(^(01){1}[3456789]{1}(\d){8})$/
                                                })}
                                            />
                                        </InputGroup>
                                        {errors.prevLandlord_number &&
                                            errors.prevLandlord_number.type === 'pattern' && (
                                                <Text
                                                    color='red.500'
                                                    fontWeight='500'
                                                    fontSize='md'
                                                    mb='0'
                                                    mt='2'
                                                >
                                                    <WarningOutlined /> Please Provide a Valid Phone
                                                    Number !
                                                </Text>
                                            )}
                                    </FormControl>

                                    <FormControl w='100%'>
                                        <FormLabel htmlFor='prevLandlord_address'>
                                            ঠিকানা :
                                        </FormLabel>
                                        <Input
                                            name='prevLandlord_address'
                                            type='text'
                                            ref={register}
                                        />
                                    </FormControl>
                                </Grid>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    15.
                                </Box>
                                <FormControl isRequired w='100%'>
                                    <FormLabel htmlFor='reasonToLeavePrevHouse'>
                                        পূর্ববর্তী বাসা ছাড়ার কারণ :
                                    </FormLabel>
                                    <Input
                                        name='reasonToLeavePrevHouse'
                                        type='text'
                                        ref={register}
                                    />
                                </FormControl>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    16.
                                </Box>
                                <Grid templateColumns='repeat(2, 1fr)' gap={6} w='100%'>
                                    <FormControl w='100%'>
                                        <FormLabel htmlFor='currentLandlord_name'>
                                            বর্তমান বাড়িওয়ালার নাম :
                                        </FormLabel>
                                        <Input
                                            name='currentLandlord_name'
                                            type='text'
                                            ref={register}
                                        />
                                    </FormControl>

                                    <FormControl w='100%'>
                                        <FormLabel htmlFor='currentLandlord_number'>
                                            মোবাইল নম্বর :
                                        </FormLabel>

                                        <InputGroup>
                                            <InputLeftAddon children='+88' borderColor='#CBD5E0' />
                                            <Input
                                                name='currentLandlord_number'
                                                type='number'
                                                roundedLeft='0'
                                                placeholder='01XXXXXXXXX'
                                                ref={register({
                                                    pattern: /(^(01){1}[3456789]{1}(\d){8})$/
                                                })}
                                            />
                                        </InputGroup>
                                        {errors.currentLandlord_number &&
                                            errors.currentLandlord_number.type === 'pattern' && (
                                                <Text
                                                    color='red.500'
                                                    fontWeight='500'
                                                    fontSize='md'
                                                    mb='0'
                                                    mt='2'
                                                >
                                                    <WarningOutlined /> Please Provide a Valid Phone
                                                    Number !
                                                </Text>
                                            )}
                                    </FormControl>
                                </Grid>
                            </Flex>
                            <Flex my={6}>
                                <Box w={10} px={2}>
                                    17.
                                </Box>
                                <FormControl isRequired w='100%'>
                                    <FormLabel htmlFor='currentHomeStartdate'>
                                        বর্তমান বাড়িতে কোন তারিখ থেকে বসবাস :
                                    </FormLabel>
                                    <Input name='currentHomeStartdate' type='date' ref={register} />
                                </FormControl>
                            </Flex>

                            <Box>
                                <Flex justifyContent='flex-end'>
                                    <Button
                                        mt={4}
                                        colorScheme='red'
                                        mx={2}
                                        isLoading={loading}
                                        size='lg'
                                        border='none'
                                        cursor='pointer'
                                        type='submit'
                                        loadingText='Submitting'
                                    >
                                        SUBMIT
                                    </Button>
                                </Flex>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </ThemeProvider>
        </PageUserForm>
    )
}
