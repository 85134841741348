import React from 'react'
import { useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import ReportAction from '../../../stores/reporting/ReportAction'
import { makeSelectIncomeReportsList } from '../../../stores/reporting/ReportSelector'

export default function TableIncomeReport() {
    const dataList = useSelector(makeSelectIncomeReportsList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [ReportAction.REQUEST_GET_INCOME_REPORT])
    )

    const columns = [
        {
            title: 'Date',
            render: (text, record) => <b> {new Date(record.date).toLocaleDateString()} </b>
        },
        {
            title: 'Description',
            dataIndex: 'description'
        },
        {
            title: 'Amount',
            dataIndex: 'amount'
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
