import React, { useState } from 'react'
import { FormInputText } from '../component/form-input/FormInputText'
import * as Yup from 'yup'
import useFormInputValidation from '../component/form-input/useFormInputValidation'
import ButtonFormSubmit from './ButtonFormSubmit'
import firebase from '../../utils/firebase'
import { message } from 'antd'
import DataValidationConstants from './FormDataValidationConstants'

const INITIAL_STATE = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
}
const VALIDATION_SCHEMA = Yup.object({
    currentPassword: Yup.string().required('Current Password is Required'),
    newPassword: DataValidationConstants.REQUIRED_VALID_PASSWORD,
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
})
const FormChangePassword = (props) => {
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const [loading, setLoading] = useState(false)
    const onSubmit = () => {
        setLoading(true)
        const credential = firebase.auth.EmailAuthProvider.credential(
            props.currentUser.data.email,
            values.currentPassword
        )
        firebase
            .auth()
            .currentUser.reauthenticateWithCredential(credential)
            .then(() => changePass())
            .catch((error) => {
                message.error(error.message)
                setLoading(false)
            })
    }
    const changePass = async () => {
        await firebase
            .auth()
            .currentUser.updatePassword(values.newPassword)
            .then(() => {
                message.success('Password Updated Successful')
                setLoading(false)
                control.resetData()
            })
            .catch((error) => {
                message.error(error.message)
                setLoading(false)
            })
    }
    return (
        <div>
            <FormInputText type={'password'} label={'Current Password'} control={control} name={'currentPassword'} />
            <FormInputText type={'password'} label={'New Password'} control={control} name={'newPassword'} />
            <FormInputText
                type={'password'}
                label={'Confirm New Password'}
                control={control}
                name={'confirmPassword'}
            />
            <ButtonFormSubmit isRequesting={loading} title={'Update Password'} control={control} onSubmit={onSubmit} />
        </div>
    )
}

export default FormChangePassword
