import { createSelector } from 'reselect'
import { ImageConstants } from '../assets/constants/GeneralConstants'

export class ImageSelector {
    static _getImageObjectFromStore(state) {
        return state.misc.imageObject
    }
}

export const _makeSelectImageFileAddCalc = () =>
    _createImageFileSelectorUtility(ImageConstants.FOR_ADD_CALCULATION_MEMO)
export const _makeSelectImageUrlAddCalc = () =>
    _createImageUrlSelectorUtility(ImageConstants.FOR_ADD_CALCULATION_MEMO)

export const _getSelectedImageUrl = createSelector(
    ImageSelector._getImageObjectFromStore,
    (state, actionType) => actionType,
    (imageObject, imageFor) =>
        imageObject && imageObject.imageFor === imageFor ? imageObject.imageUrl : null
)

export const _getSelectedImageFile = createSelector(
    ImageSelector._getImageObjectFromStore,
    (state, actionType) => actionType,
    (imageObject, imageFor) =>
        imageObject && imageObject.imageFor === imageFor ? imageObject.imageFile : null
)

const _createImageFileSelectorUtility = (type) => {
    return createSelector(ImageSelector._getImageObjectFromStore, (imageObject) =>
        imageObject && imageObject.imageFor === type ? imageObject.imageFile : null
    )
}

const _createImageUrlSelectorUtility = (type) => {
    return createSelector(ImageSelector._getImageObjectFromStore, (imageObject) =>
        imageObject && imageObject.imageFor === type ? imageObject.imageUrl : null
    )
}
