import ActionUtility from '../../utils/ActionUtility'
import ModalStateModel from './model/ModalStateModel'
import { ModalTypeConstants } from '../../assets/constants/GeneralConstants'

export default class MiscAction {
    static SET_PAGE_TYPE = 'SET_PAGE_TYPE'
    static SET_ADMIN_TYPE = 'SET_ADMIN_TYPE'
    static SET_COMMUNITY_ID = 'SET_COMMUNITY_ID'
    static SET_BUILDING_ID = 'SET_BUILDING_ID'
    static SET_FLAT_ID = 'SET_FLAT_ID'
    static SET_USER_ID = 'SET_USER_ID'
    static SET_USER_EMAIL = 'SET_USER_EMAIL'
    static SET_DATE_RANGE = 'MiscAction.SET_DATE_RANGE'
    static SET_DATE = 'MiscAction.SET_DATE'
    static SET_MONTH = 'MiscAction.SET_MONTH'
    static SET_IMAGE = 'MiscAction.SET_IMAGE'
    static SET_XL_DATA = 'MiscAction.SET_XL_DATA'
    static SELECT_DROPDOWN_ITEM = 'MiscAction.SELECT_DROPDOWN_ITEM'
    static SET_MODAL_STATUS = 'MiscAction.SET_MODAL_STATUS'
    static SELECT_RADIO_ITEM = 'SELECT_RADIO_ITEM'

    static _setPageType(pageType) {
        return ActionUtility._createAction(MiscAction.SET_PAGE_TYPE, pageType)
    }

    static _setAdminType(adminType) {
        return ActionUtility._createAction(MiscAction.SET_ADMIN_TYPE, adminType)
    }

    static _setCommunityId(communityId) {
        return ActionUtility._createAction(MiscAction.SET_COMMUNITY_ID, communityId)
    }
    static _setBuildingId(buildingId) {
        return ActionUtility._createAction(MiscAction.SET_BUILDING_ID, buildingId)
    }

    static _setFlatId(flatId) {
        return ActionUtility._createAction(MiscAction.SET_FLAT_ID, flatId)
    }
    static _setUserId(userId) {
        return ActionUtility._createAction(MiscAction.SET_USER_ID, userId)
    }

    static _setUserEmail(userEmail) {
        return ActionUtility._createAction(MiscAction.SET_USER_EMAIL, userEmail)
    }

    static _selectRadioItem(dropdownObject) {
        return ActionUtility._createAction(MiscAction.SELECT_RADIO_ITEM, dropdownObject)
    }

    static _setDateRange(dateRangeObject) {
        return ActionUtility._createAction(MiscAction.SET_DATE_RANGE, dateRangeObject)
    }

    static _clearDateRange() {
        return ActionUtility._createAction(MiscAction.SET_DATE_RANGE, null)
    }

    static _setDate(dateObject) {
        return ActionUtility._createAction(MiscAction.SET_DATE, dateObject)
    }

    static _setMonth(monthObject) {
        return ActionUtility._createAction(MiscAction.SET_MONTH, monthObject)
    }

    static _setImage(imageObject) {
        return ActionUtility._createAction(MiscAction.SET_IMAGE, imageObject)
    }

    static _setXLData(XLDateObject) {
        return ActionUtility._createAction(MiscAction.SET_XL_DATA, XLDateObject)
    }

    static _selectDropdownItem(dropdownObject) {
        return ActionUtility._createAction(MiscAction.SELECT_DROPDOWN_ITEM, dropdownObject)
    }

    static _showModalSuccess(message) {
        return MiscAction._setModalStatus(
            new ModalStateModel(ModalTypeConstants.TYPE_SUCCESS, true, { message })
        )
    }
    static _showModalFailure(message) {
        return MiscAction._setModalStatus(
            new ModalStateModel(ModalTypeConstants.TYPE_ERROR, true, { message })
        )
    }
    static _showModalInfo(message) {
        return MiscAction._setModalStatus(
            new ModalStateModel(ModalTypeConstants.TYPE_INFO, true, { message })
        )
    }
    static _showModalWarning(message) {
        return MiscAction._setModalStatus(
            new ModalStateModel(ModalTypeConstants.TYPE_WARNING, true, { message })
        )
    }

    static _setModalStatus(modalData) {
        return ActionUtility._createAction(MiscAction.SET_MODAL_STATUS, modalData)
    }
}
