import React from 'react'
import CommunityAction from '../../../stores/property-management/community/CommunityAction'
import { useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { makeSelectCommunityList } from '../../../stores/property-management/community/CommunitySelector'
import ComponentTable from '../ComponentTable'
import { GreenTag, RedTag } from '../../component/misc/Tags'
import { useTranslation } from 'react-i18next'
import { ButtonMildBlue } from '../../component/buttons/CustomButtons'
import { AppRoutes } from '../../../assets/constants/routes'
import { Link } from 'react-router-dom'
import { encode } from 'base-64'

export default function TableCommunity(props) {
    const { t } = useTranslation()
    const dataList = useSelector(makeSelectCommunityList)

    const isRequesting = useSelector((state) =>
        selectRequesting(state, [CommunityAction.REQUEST_GET_COMMUNITY_LIST])
    )

    const columns = [
        {
            title: t('community.tableHeader.name'),
            dataIndex: 'name'
        },
        {
            title: t('community.tableHeader.address'),
            dataIndex: 'address'
        },
        {
            title: t('community.tableHeader.email'),
            dataIndex: 'email'
        },

        {
            title: t('community.tableHeader.contactPerson'),
            dataIndex: 'contactPerson'
        },
        {
            title: t('community.tableHeader.contactInfo'),
            dataIndex: 'contactInfo'
        },
        {
            align: 'center',
            title: t('community.tableHeader.status'),
            render: (text, record) => {
                switch (record.isActive) {
                    case true:
                        return <GreenTag text={'ACTIVE'} />
                    case false:
                        return <RedTag text={'INACTIVE'} />
                }
            }
        },
        {
            title: 'Payment',
            align: 'center',
            render: (text, record) => (
                <Link
                    to={`${AppRoutes.DIRECT_PAYMENT}/${encode(
                        JSON.stringify({ communityId: record.id })
                    ).toString()}`}
                >
                    <ButtonMildBlue
                        key={record.id}
                        text={'Make Payment'}
                        // onClick={() => AppRoutes.DIRECT_PAYMENT}
                    />
                </Link>
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
