import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class PreAuthorizationEffect {
    static async _requestGetPreAuthorizationList(data) {
        const endPoint = ApiEndpoint.preAuthorization.getPreAuthorizedList
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestAddToPreAuthorizedList(data) {
        const endPoint = ApiEndpoint.preAuthorization.createPreAuthorizationEntry
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestDeleteFromPreAuthorizationList(data) {
        const endPoint = ApiEndpoint.preAuthorization.removeFromList
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
