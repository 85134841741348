import React, { useEffect, useState } from 'react'
import { DataBuilderUtility } from '../../../utils/DataBuilderUtility'
import { CardColoredIconBottomInfo } from '../../component/decoration/CardSingleInfo'
import { useDispatch, useSelector } from 'react-redux'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import BuildingAction from '../../../stores/property-management/building/BuildingAction'
import GetBuildingsRequest from '../../../stores/property-management/building/request/GetBuildingsRequest'
import TableBuildings from '../../tables/property/TableBuilding'
import { makeSelectBuildingsList } from '../../../stores/property-management/building/BuildingSelector'
import { PageBasicContainer, PageDecorationContainer, PageMainBarContainer, TableButtonContainer } from '../PageStyles'
import { useTranslation } from 'react-i18next'
import { AppRoutes } from '../../../assets/constants/routes'
import { Link } from 'react-router-dom'
import { ButtonFillBlack } from '../../component/buttons/CustomButtons'

export default function PageBuildings(props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [activeStatus, setActiveStatus] = useState(-1)

    const dataList = useSelector(makeSelectBuildingsList)
    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, BuildingAction.REQUEST_CREATE_BUILDING)
    )
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, BuildingAction.REQUEST_CREATE_BUILDING)
    )

    useEffect(() => {
        dispatch(BuildingAction._requestGetBuildingList(new GetBuildingsRequest(activeStatus)))
    }, [isFinishedDeletion, isFinishedCreation, activeStatus])

    const decorationArr = DataBuilderUtility._getBuildingsDataOverview(
        dataList,
        t('building.cardTitle', { returnObjects: true })
    )

    // const changeActiveStatus = (status) => setActiveStatus(status)
    const decorItems = decorationArr.map((item) => (
        <CardColoredIconBottomInfo key={item.dataTitle} data={item} />
    ))
    return (
        <PageBasicContainer>
            <PageDecorationContainer paddingTop={'0px'} item={4}>
                {decorItems}
            </PageDecorationContainer>
            <TableButtonContainer>
                <Link to={AppRoutes.CREATE_BUILDING}>
                    <ButtonFillBlack icon={'plus'} text={'Add Building'} />
                </Link>
            </TableButtonContainer>
            <PageMainBarContainer>
                <h2>Buildings</h2>
                <TableBuildings />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
