import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import FormInputDropDown from '../../component/form-input/FormInputDropDown'
import { ColorConstants, DropDownConstants, ImageConstants } from '../../../assets/constants/GeneralConstants'
import FormInputDatePicker from '../../component/form-input/FormInputDatePicker'
import ExpenseAction from '../../../stores/money-management/expense/ExpenseAction'
import { RecordExpenseRequest } from '../../../stores/money-management/expense/requests/direct-expense/RecordExpenseRequest'
import { makeSelectExpenseTypesList } from '../../../stores/money-management/expense/ExpenseSelector'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { ExpenseIcon } from '../../misc/IconsProvider'
import { FormIconContainer, FormItemContainer, FormImageWithInfoContainer } from '../FormStyles'
import FormInputMonthPicker from '../../component/form-input/FormInputMonthPicker'
import { getCurrentMonthWithYear } from '../../../utils/functions/functions'
import { isMobile } from 'react-device-detect'
import UploadImageButton from '../../component/upload/UploadImageButton'
import { message } from 'antd'
import useImageUpload from '../../component/upload/useImageUpload'
import { _getSelectedImageFile, _getSelectedImageUrl } from '../../../selectors/ImageSelector'
import MiscAction from '../../../stores/misc/MiscAction'
import ImageSelectModel from '../../../stores/misc/model/ImageSelectModel'

const INITIAL_STATE = {
    name: '',
    image: '',
    description: '',
    amount: '',
    date: '',
    typeId: '',
    buildingId: '',
    month: getCurrentMonthWithYear()
}

const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string()
        .required('Name is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Maximum 40 Characters'),
    amount: Yup.number()
        .typeError('Invalid Amount')
        .required('Amount is Required')
        .min(1, 'Minimum Amount 1')
        .max(100000000, 'Maximum Amount 100000000'),
    description: Yup.string()
        .required('Description is Required')
        .max(150, 'Maximum 150 Characters'),
    date: Yup.string().required('Date is Required'),
    month: Yup.string().required('Month is Required'),
    typeId: Yup.string().required('Type is Required'),
    buildingId: Yup.string().required('Building is Required')
})

export default function FormAddExpenseEntry(props) {
    const dispatch = useDispatch()
    const imageUrl= useSelector( state=>_getSelectedImageUrl(state, ImageConstants.FOR_EXPENSE_ENTRY_MEMO))
    const imageFile= useSelector(state=>_getSelectedImageFile(state, ImageConstants.FOR_EXPENSE_ENTRY_MEMO))
    const userDetails = useSelector((state) => state.user.userDetails)
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [ExpenseAction.REQUEST_RECORD_DIRECT_EXPENSE])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, ExpenseAction.REQUEST_RECORD_DIRECT_EXPENSE)
    )
    const expenseTypes = useSelector(makeSelectExpenseTypesList)
    useEffect(() => {
        control.resetData()
        dispatch( MiscAction._setImage(new ImageSelectModel(ImageConstants.FOR_EXPENSE_ENTRY_MEMO, null, null)))
    }, [isFinished])

    const onSubmit = async () => {
     if(imageUrl){
        const hideUploading = message.loading('Image Uploading...', 0);
        const { uploadImage } = useImageUpload()
        const formData = new FormData()
        formData.append('image', imageFile)
        formData.append('folderName', 'fund')
        formData.append('subFolderName', userDetails.data.userId)
        formData.append('fileName', Date.now())
        const postImage = await uploadImage(formData)
        console.log("postImage",postImage);
        if (postImage.success) {
            setTimeout(hideUploading, 0);
            message.success(`Image upload successful`)
            const newValues = {
                ...control.values,
                image: postImage.success,
            }
            dispatch(ExpenseAction._requestCreateDirectExpense(new RecordExpenseRequest(newValues)))
        } else if (postImage.error) {
            setTimeout(hideUploading, 0);
            message.error('Image Upload Failed! Please Try Again')
            return false;
        }
     }
     else{
        dispatch(ExpenseAction._requestCreateDirectExpense(new RecordExpenseRequest(values)))
     }
    }

    useEffect(() => {
        if (values.typeId) {
            const expenseType = expenseTypes.find((item) => item.id === values.typeId)
            if (expenseType) {
                control.setValues({
                    ...values,
                    name: expenseType.name,
                    description: expenseType.description,
                    amount: expenseType.defaultAmount
                })
            }
        }
    }, [values.typeId])
    return (
        <FormIconContainer>
            <FormTitleWithIcon
                icon={<ExpenseIcon />}
                color={ColorConstants.GREEN1}
                formTitle={'Add Expense Entry'}
            />
              <FormImageWithInfoContainer>
              <div className={'form-image'} style={{  justifySelf:isMobile?"center":""}}>
                        <UploadImageButton
                            label={'Memo Image'}
                            control={control}
                            name={'image'}
                            imageFor={ImageConstants.FOR_EXPENSE_ENTRY_MEMO}
                            imageUrl={imageUrl}
                        />
                </div>

            <FormItemContainer >
                <FormInputDropDown
                    label={'Select Building'}
                    control={control}
                    name={'buildingId'}
                    dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                />
                <FormInputMonthPicker label={'Select Month'} control={control} name={'month'} />
                <FormInputDropDown
                    label={'Select Type'}
                    control={control}
                    name={'typeId'}
                    dropDownFor={DropDownConstants.TYPE_EXPENSE_TYPES}
                />
                <FormInputText label={'Name'} control={control} name={'name'} />
                <FormInputText label={'Description'} control={control} name={'description'} />
                <FormInputText label={'Amount'} control={control} name={'amount'} />
                <FormInputDatePicker label={'Date of Expense'} control={control} name={'date'} />
            </FormItemContainer>
            </FormImageWithInfoContainer>
            <ButtonFormSubmit isRequesting={isRequesting} control={control} onSubmit={onSubmit} />
        </FormIconContainer>
    )
}
