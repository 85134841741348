import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import ComponentTable from '../ComponentTable'
import { Tag } from 'antd'
import FingerPrintAction from '../../../stores/device-management/fingerprint-device/FingerPrintAction'
import GetFingerPrintDevicesRequest from '../../../stores/device-management/fingerprint-device/request/GetFingerPrintDevicesRequest'
import DeleteFingerPrintDevicesRequest from '../../../stores/device-management/fingerprint-device/request/DeleteFingerPrintDevicesRequest'
import { makeSelectFingerPrintDevicesList } from '../../../stores/device-management/fingerprint-device/FingerPrintSelector'

export default function TableFingerprintDevice(props) {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectFingerPrintDevicesList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [FingerPrintAction.REQUEST_GET_FINGERPRINT_DEVICES])
    )
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, FingerPrintAction.REQUEST_REGISTER_FINGERPRINT_DEVICE)
    )
    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, FingerPrintAction.REQUEST_DELETE_FINGERPRINT_DEVICE)
    )

    useEffect(() => {
        dispatch(FingerPrintAction._requestGetFingerPrintDevices(new GetFingerPrintDevicesRequest()))
    }, [isFinishedDeletion, isFinishedCreation])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Name',
            dataIndex: 'company'
        },
        {
            title: 'Description',
            dataIndex: 'purpose'
        },
        {
            title: 'MAC Address',
            dataIndex: 'macAddress'
        },
        {
            title: 'Registered',
            render: (text, record) => (
                <Tag color={'blue'}> {record.createdDate && new Date(record.createdDate).toLocaleDateString()} </Tag>
            )
        },
        {
            title: '',
            align: 'center',
            render: (text, record) => (
                <ModalDeleteConfirmation
                    confirmationText={'Are you sure you want to delete this device ?'}
                    onConfirm={() =>
                        dispatch(
                            FingerPrintAction._requestDeleteFingerPrintDevice(
                                new DeleteFingerPrintDevicesRequest(record.id)
                            )
                        )
                    }
                />
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
