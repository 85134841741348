import React, { useEffect, useState } from 'react'
import i18next from 'i18next'
import { LanguageConstants } from '../../../assets/constants/GeneralConstants'
import { getCookie, setCookie } from '../../../utils/functions/functions';
import './style.scss'
const LanguageSwitcherButton = () => {
    const [englishSelected, setEnglishSelected] = useState(getCookie('ln')?JSON.parse(getCookie('ln')):false)
    useEffect(()=>{
        if (englishSelected) i18next.changeLanguage(LanguageConstants.EN)
        else i18next.changeLanguage(LanguageConstants.BN)
    },[])
    const toggleSelected = () => {
        if (englishSelected) i18next.changeLanguage(LanguageConstants.BN)
        else i18next.changeLanguage(LanguageConstants.EN)
        setEnglishSelected(!englishSelected)
    }
    useEffect(()=>{
        setCookie('ln', englishSelected)
      },[englishSelected])

    return (
        <div className={` ${englishSelected ? 'toggle-container': 'toggle-container-hide'}`} onClick={toggleSelected}>
            <div className={`dialog-button ${englishSelected ? '' : 'disabled'}`}>
                {englishSelected ? 'English' : 'বাংলা'}
            </div>
        </div>
    )
}

export default LanguageSwitcherButton
