import BaseRequest from '../../../special/models/BaseRequest'

export default class RegisterUserRequest extends BaseRequest {
    name = ''
    userRoleCode = ''
    address = ''
    email = ''
    phone = ''
    gender = ''
    organization = ''
    nid = ''
    password = ''
    age = ''
    image = ''
    thumbImage = ''
    flatId = ''
    buildingId = ''

    constructor(data) {
        super()
        this.update(data)
        if (data.phone) this.phone = '+88'.concat(data.phone)
    }
}
