import BaseRequest from '../../../special/models/BaseRequest'

export class GetMultipleBillInvoiceReportRequest extends BaseRequest {
    flatId = 0
    success = ''
    requestId = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
