import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Transfer } from 'antd'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import PrivilegeAction from '../../../stores/user-management/privilege/PrivilegeAction'
import FormInputDropDown from '../../component/form-input/FormInputDropDown'
import { DropDownConstants } from '../../../assets/constants/GeneralConstants'
import GetFunctionsOfRoleRequest from '../../../stores/user-management/privilege/models/GetFunctionsOfRoleRequest'
import BaseRequest from '../../../stores/special/models/BaseRequest'
import {
    makeSelectFunctionsOfRoleList,
    makeSelectUserFunctionsList
} from '../../../stores/user-management/privilege/PrivilegeSelector'
import AssignFunctionsToRoleRequest from '../../../stores/user-management/privilege/models/AssignFunctionsToRoleRequest'
import { PageBasicContainer } from '../../pages/PageStyles'

const INITIAL_STATE = {
    roleCode: ''
}

const VALIDATION_SCHEMA = Yup.object({})

export default function FormAssignFunctionToRole(props) {
    const dispatch = useDispatch()

    const [targetKeys, setTargetKeys] = useState([])
    const [sourceItems, setSourceItemsWithKey] = useState([])

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    // const isRequesting = useSelector((state) =>
    //     selectRequesting(state, [
    //         PrivilegeAction.REQUEST_GET_USER_FUNCTIONS,
    //         PrivilegeAction.REQUEST_ASSIGN_FUNCTION_TO_ROLE,
    //         PrivilegeAction.REQUEST_GET_USER_FUNCTIONS_OF_ROLE
    //     ])
    // )

    const isFinished = useSelector((state) => selectFinished(state, PrivilegeAction.REQUEST_ASSIGN_FUNCTION_TO_ROLE))
    const allUserFunctions = useSelector(makeSelectUserFunctionsList)
    const onlyFunctionsOfRole = useSelector(makeSelectFunctionsOfRoleList)
    useEffect(() => {
        dispatch(PrivilegeAction._requestGetUserFunctions(new BaseRequest(values)))
    }, [])

    useEffect(() => {
        if (values.roleCode)
            dispatch(PrivilegeAction._requestGetUserFunctionsOfRole(new GetFunctionsOfRoleRequest(values)))
    }, [isFinished])

    useEffect(() => {
        if (values.roleCode)
            dispatch(PrivilegeAction._requestGetUserFunctionsOfRole(new GetFunctionsOfRoleRequest(values)))
    }, [values.roleCode])

    useEffect(() => {
        setSourceItemsWithKey(
            allUserFunctions.map((item) => {
                return { ...item, key: item.code, title: item.name }
            })
        )
    }, [allUserFunctions])

    useEffect(() => {
        setTargetKeys(onlyFunctionsOfRole.map((item) => item.code))
    }, [onlyFunctionsOfRole])

    const filterOption = (inputValue, option) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1

    const handleChange = (targetKeys) =>
        dispatch(
            PrivilegeAction._requestAssignFunctionToRole(new AssignFunctionsToRoleRequest(values.roleCode, targetKeys))
        )

    return (
        <PageBasicContainer className='card'>
            <h2>Assign Functions to Role </h2>

            <FormInputDropDown
                name={'roleCode'}
                label={'Select Role'}
                control={control}
                dropDownFor={DropDownConstants.TYPE_USER_ROLE}
            />

            <div style={{ margin: '20px' }}>
                <Transfer
                    // disabled={!values.roleCode}
                    dataSource={sourceItems}
                    showSearch
                    filterOption={filterOption}
                    targetKeys={targetKeys}
                    onChange={handleChange}
                    render={(item) => item.name}
                />
            </div>
        </PageBasicContainer>
    )
}
