import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const InvoiceFooter = (props) => {
    return (
        <View style={styles.signatureGroup}>
            <SignatureItem item={props.item}/>
        </View>
    );
};

export default InvoiceFooter;

function SignatureItem (props){
    const itemArr = [];
    props.item.map((text,index)=>
        itemArr.push(
            <Text key={index} style={styles.signature}> {text} Signature: </Text>
           )
    ) 
    return itemArr;
}

const styles = StyleSheet.create({
    signatureGroup: {
      marginTop: 'auto',
      flexDirection: 'row',
      justifyContent: "space-between"
    },
    signature: {
      borderStyle: 'dashed',
      borderTopWidth: 1,
      paddingTop: 5,
      marginTop: 20,
      fontSize: 12,
    },
  })