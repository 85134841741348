import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class NoticeEffect {
    static async _requestCreateNotice(data) {
        const endPoint = ApiEndpoint.notice.createNotice
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestDeleteNotice(data) {
        const endPoint = ApiEndpoint.notice.deleteNotice
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetNoticeList(data) {
        const endPoint = ApiEndpoint.notice.getNotices
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
