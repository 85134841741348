import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import TableExpenseType from '../../tables/expense/TableExpenseType'
import ExpenseAction from '../../../stores/money-management/expense/ExpenseAction'
import { GetExpenseTypeRequest } from '../../../stores/money-management/expense/requests/type/GetExpenseTypeRequest'
import FormCreateExpenseType from '../../forms/expense/FormCreateExpenseType'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'

export default function PageExpenseTypes(props) {
    const dispatch = useDispatch()
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, ExpenseAction.REQUEST_CREATE_EXPENSE_TYPE)
    )
    const isFinishedDeletion = useSelector((state) =>
        selectFinished(state, ExpenseAction.REQUEST_DELETE_EXPENSE_TYPE)
    )

    useEffect(() => {
        dispatch(ExpenseAction._requestGetExpenseTypes(new GetExpenseTypeRequest()))
    }, [isFinishedDeletion, isFinishedCreation])

    return (
        <PageBasicContainer>
            <FormCreateExpenseType />
            <PageMainBarContainer>
                <h2> Expense Types </h2>
                <TableExpenseType />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
