import { createSelector } from 'reselect'

export class BuildingSelector {
    static getBuildingsList = (state) => state.building.buildingList
}
export class LastBuildingCreationSelector {
    static getLastBuildingCreation = (state) => state.building.lastBuildingCreation
}

export const makeSelectBuildingsList = createSelector(
    BuildingSelector.getBuildingsList,
    (building) => (building ? building.data : [])
)
export const makeSelectLastCreationBuilding = createSelector(
    LastBuildingCreationSelector.getLastBuildingCreation,
    (building) => (building ? building.data : [])
)
