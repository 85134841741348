import { NoticeForConstants } from '../../../../assets/constants/GeneralConstants'
import BaseRequest from '../../../special/models/BaseRequest'

export default class GetNoticesRequest extends BaseRequest {
    noticeFor = ''
    buildingId = ''
    fromDate = null
    toDate = null

    constructor(data, noticeFor) {
        super()
        this.update(data)
        if (data && data.dateRange && data.dateRange.length > 0) {
            this.fromDate = new Date(data.dateRange[0] + ' 00:00:00')
            this.toDate = new Date(data.dateRange[1] + ' 23:59:59')
        }
        if(noticeFor!==NoticeForConstants.ALL){
            this.noticeFor = noticeFor
        }
    }
}
