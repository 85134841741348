import BaseRequest from '../../../special/models/BaseRequest'
import { EntranceStatusConstants } from '../../../../assets/constants/GeneralConstants'

export default class VehicleDetailsRequest extends BaseRequest {
    vehicleId = ''
    newStatus = EntranceStatusConstants.OUTSIDE_COMPOUND

    constructor(data) {
        super()
        this.update(data)
        this.vehicleId = data.id
    }
}
