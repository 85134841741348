import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import DataValidationConstants from '../FormDataValidationConstants'
import {
    ColorConstants,
    DropDownConstants,
    ImageConstants,
    RadioConstants,
    UserRoleCodeConstants
} from '../../../assets/constants/GeneralConstants'
import FormInputDropDown from '../../component/form-input/FormInputDropDown'
import UserAction from '../../../stores/user-management/user/UserAction'
import RegisterUserRequest from '../../../stores/user-management/user/requests/RegisterUserRequest'
import FormInputRadio from '../../component/form-input/FormInputRadio'
import FlatAction from '../../../stores/property-management/flat/FlatAction'
import GetFlatsRequest from '../../../stores/property-management/flat/request/GetFlatsRequest'
import { FormIconContainer, FormImageWithInfoContainer, FormItemContainer } from '../FormStyles'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { VehicleIcon } from '../../misc/IconsProvider'
import { Link, useParams } from 'react-router-dom'
import { decode } from 'base-64'
import DynamicHelmet from '../../misc/DynamicHelmet'
import { isMobile } from 'react-device-detect'
import UploadImageButton from '../../component/upload/UploadImageButton'
import { _getSelectedImageFile, _getSelectedImageUrl } from '../../../selectors/ImageSelector'
import useImageUpload from '../../component/upload/useImageUpload'
import { message } from 'antd'

export default function FormRegisterFlatMember(props) {
    const { encryptData } = useParams()
    const decodeData = JSON.parse(decode(encryptData))
    const { buildingId, flatId } = decodeData
    const dispatch = useDispatch()
    const imageUrl = useSelector((state) =>
        _getSelectedImageUrl(state, ImageConstants.FOR_ADD_FLAT_MEMBER)
    )
    const imageFile = useSelector((state) =>
        _getSelectedImageFile(state, ImageConstants.FOR_ADD_FLAT_MEMBER)
    )
    const userDetails = useSelector((state) => state.user.userDetails)
    const [resetFlat, setResetFlat] = useState(false)
    const INITIAL_STATE = {
        userRoleCode: UserRoleCodeConstants.GENERAL_REGISTERED_USER_NON_APP,
        name: '',
        address: '',
        email: '',
        phone: '',
        contactPersonName: '',
        contactPersonPhone: '',
        gender: '',
        organization: '',
        nid: '',
        age: '',
        image: '',
        thumbImage: '',
        flatId: flatId,
        buildingId: buildingId
    }
    const VALIDATION_SCHEMA = Yup.object({
        userRoleCode: Yup.string().required('Please Select User Type'),
        name: Yup.string()
            .required('Name is Required')
            .min(3, 'Minimum 3 Characters')
            .max(40, 'Max 40 Characters'),
        address: Yup.string()
            .required('Address is Required')
            .min(5, 'Minimum 5 Characters')
            .max(150, 'Maximum 150 Characters'),
        email: DataValidationConstants.VALID_EMAIL,
        phone: DataValidationConstants.REQUIRED_VALID_MOBILE_NUMBER,
        contactPersonName: Yup.string().max(40, 'Maximum 40 characters'),
        contactPersonPhone: DataValidationConstants.VALID_MOBILE_NUMBER,
        gender: Yup.string().required('Gender is Required'),
        organization: Yup.string().max(40, 'Maximum 40 characters'),
        nid: DataValidationConstants.VALID_NID,
        age: DataValidationConstants.VALID_AGE,
        flatId: Yup.string().required('Flat is Required'),
        buildingId: Yup.string().required('Building is Required')
    })

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [UserAction.REQUEST_REGISTER_USER])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, UserAction.REQUEST_REGISTER_USER)
    )
    useEffect(() => control.resetData(), [isFinished])
    const onSubmit = async () => {
        if (imageUrl) {
            const hideUploading = message.loading('Image Uploading...', 0)
            const { uploadImage } = useImageUpload()
            const formData = new FormData()
            formData.append('image', imageFile)
            formData.append('folderName', 'flat-user-non-app')
            formData.append('subFolderName', userDetails.data.userId)
            formData.append('fileName', Date.now())
            const postImage = await uploadImage(formData)
            console.log('postImage', postImage)
            if (postImage.success) {
                setTimeout(hideUploading, 0)
                message.success(`Image upload successful`)
                const newValues = {
                    ...control.values,
                    image: postImage.success,
                    thumbImage: postImage.success
                }
                dispatch(UserAction._requestRegisterUser(new RegisterUserRequest(newValues)))
            } else if (postImage.error) {
                setTimeout(hideUploading, 0)
                message.error('Image Upload Failed! Please Try Again')
                return false
            }
        } else {
            dispatch(UserAction._requestRegisterUser(new RegisterUserRequest(values)))
        }
    }

    return (
        <FormIconContainer>
            <DynamicHelmet
                title={'Rokkhi | Register Flat Member'}
                description={'Rokkhi register flat member.'}
            />
            <FormTitleWithIcon
                icon={<VehicleIcon />}
                color={ColorConstants.GREEN1}
                formTitle={'Add Member To Flat'}
            />
            <FormImageWithInfoContainer>
                <div className={'form-image'} style={{ justifySelf: isMobile ? 'center' : '' }}>
                    <UploadImageButton
                        label={'User Image'}
                        control={control}
                        name={'image'}
                        imageFor={ImageConstants.FOR_ADD_FLAT_MEMBER}
                        imageUrl={imageUrl}
                    />
                </div>
                <FormItemContainer>
                    <FormInputText label={'Name'} control={control} name={'name'} />
                    <FormInputText label={'Address'} control={control} name={'address'} />
                    <FormInputText label={'Email'} control={control} name={'email'} />
                    <FormInputText
                        prefix={'+88'}
                        label={'Phone Number'}
                        control={control}
                        name={'phone'}
                    />
                    <FormInputText
                        label={'Contact Person Name'}
                        control={control}
                        name={'contactPersonName'}
                    />
                    <FormInputText
                        label={'Contact Person Phone'}
                        control={control}
                        name={'contactPersonPhone'}
                    />
                    <FormInputRadio
                        label={'Select Gender'}
                        control={control}
                        name={'gender'}
                        radioFor={RadioConstants.TYPE_GENDER}
                    />
                    <FormInputText label={'Organization'} control={control} name={'organization'} />
                    <FormInputText label={'National ID'} control={control} name={'nid'} />
                    <FormInputText label={'Age'} control={control} name={'age'} />

                    {/*<FormInputText label={"Select Flat"} control ={control} name={"flatId"} />*/}
                </FormItemContainer>
            </FormImageWithInfoContainer>
            <ButtonFormSubmit isRequesting={isRequesting} control={control} onSubmit={onSubmit} />
        </FormIconContainer>
    )
}
