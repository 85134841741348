export class ApiEndpoint {
    static BASE_ENDPOINT = process.env.REACT_APP_BASE_API_ENDPOINT
    static image = {
        uploadSingle: `${ApiEndpoint.BASE_ENDPOINT}/image/uploadSingle`
    }
    static community = {
        selfRegisterCommunity: `${ApiEndpoint.BASE_ENDPOINT}/community/selfRegisterCommunity`,
        createNew: `${ApiEndpoint.BASE_ENDPOINT}/community/createNew`,
        getAll: `${ApiEndpoint.BASE_ENDPOINT}/community/getAll`,
        deleteOne: `${ApiEndpoint.BASE_ENDPOINT}/community/deleteOne`
    }

    static report = {
        getLedgerReport: `${ApiEndpoint.BASE_ENDPOINT}/reporting/getLedgerReport`,
        getIncomeReport: `${ApiEndpoint.BASE_ENDPOINT}/reporting/getIncomeReport`,
        getExpenseReport: `${ApiEndpoint.BASE_ENDPOINT}/reporting/getExpenseReport`,
        getIncomeStatement: `${ApiEndpoint.BASE_ENDPOINT}/reporting/getIncomeExpenseReport`,
        getCashFlowReport: `${ApiEndpoint.BASE_ENDPOINT}/reporting/getBuildings`
    }
    static building = {
        createNew: `${ApiEndpoint.BASE_ENDPOINT}/building/createNew`,
        deleteBuilding: `${ApiEndpoint.BASE_ENDPOINT}/building/deleteBuilding`,
        getBuildings: `${ApiEndpoint.BASE_ENDPOINT}/building/getBuildings`
    }

    static fingerPrint = {
        registerFingerPrintDevice: `${ApiEndpoint.BASE_ENDPOINT}/fingerprint-device/registerFingerPrintDevice`,
        deleteFingerPrintDevice: `${ApiEndpoint.BASE_ENDPOINT}/fingerprint-device/deleteFingerPrintDevice`,
        getFingerPrintDevices: `${ApiEndpoint.BASE_ENDPOINT}/fingerprint-device/getFingerPrintDevices`,
        registerFingerPrint: `${ApiEndpoint.BASE_ENDPOINT}/fingerprint-device/registerFingerPrint`,
        getRegisteredFingerPrints: `${ApiEndpoint.BASE_ENDPOINT}/fingerprint-device/getRegisteredFingerPrints`,
        assignFingerPrintToUser: `${ApiEndpoint.BASE_ENDPOINT}/fingerprint-device/assignFingerPrintToUser`
    }
    static policeform = {
        getAll: `${ApiEndpoint.BASE_ENDPOINT}/policeform/getAll`
    }
    static tenantOwner = {
        getAll: `${ApiEndpoint.BASE_ENDPOINT}/policeform/getAll`
    }
    static tenantTransfer = {
        getAll: `${ApiEndpoint.BASE_ENDPOINT}/policeform/getAll`
    }
    static forms = {
        getAll: `${ApiEndpoint.BASE_ENDPOINT}/forms/getUserForm`,
        createNewTanentOwnerForm: `${ApiEndpoint.BASE_ENDPOINT}/forms/createNewTanentOwnerForm`,
        createNewTanentDepartureForm: `${ApiEndpoint.BASE_ENDPOINT}/forms/createNewTanentDepartureForm`,
        createNewPoliceForm: `${ApiEndpoint.BASE_ENDPOINT}/forms/createNewPoliceForm`,
        getPoliceForm: `${ApiEndpoint.BASE_ENDPOINT}/rokkhi-system/searchPoliceForm`,
        getVerifyUserNID: `${ApiEndpoint.BASE_ENDPOINT}/rokkhi-system/nidVerificationInPoliceForm`
    }

    static flat = {
        createNew: `${ApiEndpoint.BASE_ENDPOINT}/flat/createNew`,
        createMultipleFlat: `${ApiEndpoint.BASE_ENDPOINT}/flat/createMultipleFlat`,
        getFlats: `${ApiEndpoint.BASE_ENDPOINT}/flat/getFlats`,
        getFlatsWithQr: `${ApiEndpoint.BASE_ENDPOINT}/flat/getFlatsWithQr`,
        getBillPackage: `${ApiEndpoint.BASE_ENDPOINT}/flat/getBillPackage`,
        deleteFlat: `${ApiEndpoint.BASE_ENDPOINT}/flat/deleteFlat`,
        getQrCode: `${ApiEndpoint.BASE_ENDPOINT}/flat/getFlatQr`,
        sendQrCode: `${ApiEndpoint.BASE_ENDPOINT}/notification/sendEmail`
    }

    static visitor = {
        getVisitors: `${ApiEndpoint.BASE_ENDPOINT}/entrance/getVisitors`,
        changeVisitorStatus: `${ApiEndpoint.BASE_ENDPOINT}/entrance/changeVisitorStatus`
    }

    static parcel = {
        getParcels: `${ApiEndpoint.BASE_ENDPOINT}/entrance/getParcels`,
        markParcelAsReceived: `${ApiEndpoint.BASE_ENDPOINT}/entrance/markParcelAsReceived`
    }

    static vehicle = {
        getVehicleEntryList: `${ApiEndpoint.BASE_ENDPOINT}/entrance/getVehicleEntryList`,
        addNewVehicle: `${ApiEndpoint.BASE_ENDPOINT}/vehicle/addNewVehicle`,
        getVehicles: `${ApiEndpoint.BASE_ENDPOINT}/vehicle/getVehicles`,
        changeVehicleStatus: `${ApiEndpoint.BASE_ENDPOINT}/entrance/changeVehicleStatus`
    }

    static entrance = {
        getChildExitReport: `${ApiEndpoint.BASE_ENDPOINT}/entrance/getChildExitReport`,
        getServiceWorkerAttendanceReport: `${ApiEndpoint.BASE_ENDPOINT}/entrance/getServiceWorkerAttendanceReport`
    }

    static expense = {
        generateVoucher: `${ApiEndpoint.BASE_ENDPOINT}/expense/generateVoucher`,
        getVouchers: `${ApiEndpoint.BASE_ENDPOINT}/expense/getVouchers`,
        deleteVoucher: `${ApiEndpoint.BASE_ENDPOINT}/expense/deleteExpense`,
        authorizeVoucher: `${ApiEndpoint.BASE_ENDPOINT}/expense/authorizeVoucher`,
        makePaymentAgainstVoucher: `${ApiEndpoint.BASE_ENDPOINT}/expense/makePaymentAgainstVoucher`,
        createNewType: `${ApiEndpoint.BASE_ENDPOINT}/expense/createExpenseType`,
        creatNewEntry: `${ApiEndpoint.BASE_ENDPOINT}/expense/recordExpense`,
        getExpenseEntries: `${ApiEndpoint.BASE_ENDPOINT}/expense/getExpenses`,
        getTypes: `${ApiEndpoint.BASE_ENDPOINT}/expense/getExpenseTypes`,
        deleteType: `${ApiEndpoint.BASE_ENDPOINT}/expense/deleteExpenseType`,
        deleteItem: `${ApiEndpoint.BASE_ENDPOINT}/expense/deleteExpenseItem`
    }

    static bill = {
        createPackage: `${ApiEndpoint.BASE_ENDPOINT}/bill/createPackage`,
        assignPackage: `${ApiEndpoint.BASE_ENDPOINT}/bill/assignPackage`,
        getPackages: `${ApiEndpoint.BASE_ENDPOINT}/bill/getPackages`,
        createNewType: `${ApiEndpoint.BASE_ENDPOINT}/bill/createBillType`,
        deleteType: `${ApiEndpoint.BASE_ENDPOINT}/bill/deleteBillType`,
        generateBillForFlat: `${ApiEndpoint.BASE_ENDPOINT}/bill/generateBillInvoice`,
        getBillItems: `${ApiEndpoint.BASE_ENDPOINT}/bill/getBillInvoices`,
        getTypes: `${ApiEndpoint.BASE_ENDPOINT}/bill/getBillTypes`,
        collectBill: `${ApiEndpoint.BASE_ENDPOINT}/bill/collectBill`,
        decideBill: `${ApiEndpoint.BASE_ENDPOINT}/bill/getDecidedBill`,
        generateMultipleBill: `${ApiEndpoint.BASE_ENDPOINT}/bill/generateMultipleBillInvoice`,
        deleteBill: `${ApiEndpoint.BASE_ENDPOINT}/bill/deleteBill`,
        getMultipleBillInvoiceReport: `${ApiEndpoint.BASE_ENDPOINT}/bill/getMultipleBillInvoiceReport`,
        getRequestedBillGeneration: `${ApiEndpoint.BASE_ENDPOINT}/bill/getRequestedBillGeneration`
    }

    static fund = {
        createFund: `${ApiEndpoint.BASE_ENDPOINT}/fund/createFund`,
        getFunds: `${ApiEndpoint.BASE_ENDPOINT}/fund/getFunds`,
        getBalanceSheetData: `${ApiEndpoint.BASE_ENDPOINT}/fund/getBalanceSheetData`,
        addToFund: `${ApiEndpoint.BASE_ENDPOINT}/fund/addToFund`,
        withdrawFromFund: `${ApiEndpoint.BASE_ENDPOINT}/fund/withdrawFromFund`,
        transferFund: `${ApiEndpoint.BASE_ENDPOINT}/fund/transferFund`,
        getVouchers: `${ApiEndpoint.BASE_ENDPOINT}/fund/getFundMovementInvoices`,
        getTransactions: `${ApiEndpoint.BASE_ENDPOINT}/transaction/getTransactions`
    }

    static preAuthorization = {
        createPreAuthorizationEntry: `${ApiEndpoint.BASE_ENDPOINT}/authorization/addToPreAuthorizationList`,
        getPreAuthorizedList: `${ApiEndpoint.BASE_ENDPOINT}/authorization/getPreAuthorizedList`,
        removeFromList: `${ApiEndpoint.BASE_ENDPOINT}/authorization/removeFromAuthorizationList`
    }

    static user = {
        getUserDetails: `${ApiEndpoint.BASE_ENDPOINT}/user/getUserDetails`,
        register: `${ApiEndpoint.BASE_ENDPOINT}/user/registerOrUpdate`,
        delete: `${ApiEndpoint.BASE_ENDPOINT}/user/deleteProfile`,
        getUsersList: `${ApiEndpoint.BASE_ENDPOINT}/user/getUsersList`,
        getFunctionsOfUser: `${ApiEndpoint.BASE_ENDPOINT}/user/getFunctionsOfUser`,
        getRolesOfUser: `${ApiEndpoint.BASE_ENDPOINT}/user/getRolesOfUser`
    }

    static dashboard = {
        getDash: `${ApiEndpoint.BASE_ENDPOINT}/dashboard/getDash`
    }

    static complain = {
        submitComplain: `${ApiEndpoint.BASE_ENDPOINT}/complain/submitComplain`,
        getComplains: `${ApiEndpoint.BASE_ENDPOINT}/complain/getComplains`,
        register: `${ApiEndpoint.BASE_ENDPOINT}/complain/register`
    }

    static notice = {
        createNotice: `${ApiEndpoint.BASE_ENDPOINT}/notice/createNew`,
        deleteNotice: `${ApiEndpoint.BASE_ENDPOINT}/notice/deleteNotice`,
        getNotices: `${ApiEndpoint.BASE_ENDPOINT}/notice/getNotices`
    }

    static meeting = {
        createMeeting: `${ApiEndpoint.BASE_ENDPOINT}/meeting/createNew`,
        cancelMeeting: `${ApiEndpoint.BASE_ENDPOINT}/meeting/cancelMeeting`,
        getMeetings: `${ApiEndpoint.BASE_ENDPOINT}/meeting/getMeetings`
    }

    static privilege = {
        createNewRole: `${ApiEndpoint.BASE_ENDPOINT}/privilege/createNewRole`,
        deleteRole: `${ApiEndpoint.BASE_ENDPOINT}/privilege/deleteRole`,
        createNewFunction: `${ApiEndpoint.BASE_ENDPOINT}/privilege/createNewFunction`,
        deleteFunction: `${ApiEndpoint.BASE_ENDPOINT}/privilege/deleteFunction`,
        getAllUserRoles: `${ApiEndpoint.BASE_ENDPOINT}/privilege/getAllUserRoles`,
        getAllUserFunctions: `${ApiEndpoint.BASE_ENDPOINT}/privilege/getAllUserFunctions`,
        getUserFunctionsOfRole: `${ApiEndpoint.BASE_ENDPOINT}/privilege/getUserFunctionsOfRole`,
        assignFunctionToRole: `${ApiEndpoint.BASE_ENDPOINT}/privilege/assignFunctionToRole`,
        assignRoleToUser: `${ApiEndpoint.BASE_ENDPOINT}/privilege/assignRoleToUser`,
        assignRoleToUserBeta: `${ApiEndpoint.BASE_ENDPOINT}/privilege/assignRoleToUserBeta`
    }
    static subscription = {
        getPackages: `${ApiEndpoint.BASE_ENDPOINT}/subscriptions/getAllPackage`,
        createPackage: `${ApiEndpoint.BASE_ENDPOINT}/subscriptions/createPackage`,
        createOrder: `${ApiEndpoint.BASE_ENDPOINT}/subscriptions/createOrder`,
        directPayment: `${ApiEndpoint.BASE_ENDPOINT}/subscriptions/createDirectOrder`
    }
    static cart = {
        addToCart: `${ApiEndpoint.BASE_ENDPOINT}/cart/addToCart`,
        getAllCart: `${ApiEndpoint.BASE_ENDPOINT}/cart/getAll`,
        deleteCart: `${ApiEndpoint.BASE_ENDPOINT}/cart/deleteCart`
    }
}
