import React, { useState } from 'react'
import { Modal } from 'antd'
import { ButtonMildBlue } from '../component/buttons/CustomButtons'
import ComponentTable from '../tables/ComponentTable'
import TotalBillIcon from '../../assets/images/total-bill-small.png'
import { ColorConstants, PaymentStatusConstants } from '../../assets/constants/GeneralConstants'
import { getMonthNameAndYear } from '../../utils/functions/functions'
import { BlueTag, GreenTag, RedTag } from '../component/misc/Tags'

export default function ModalCollectBill(props) {
    const [visibility, setVisibility] = useState(false)
    const { data } = props
    return (
        <div>
            <ButtonMildBlue
                disabled={props.isDisabled}
                icon={'eye'}
                text={'Details'}
                onClick={() => setVisibility(true)}
            />
            <Modal
                // title={`Details`}
                centered
                // okText={'Collect'}
                visible={visibility}
                onCancel={() => setVisibility(false)}
                cancelText={'Cancel'}
                // onOk={onSubmit}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
                footer={null}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '30px',
                        marginTop: '20px'
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <div
                            style={{
                                display: 'grid',
                                alignItems: 'center',
                                margin: '4px',
                                width: '40px',
                                height: '40px',
                                backgroundColor: ColorConstants.GREEN3,
                                justifyItems: 'center',
                                padding: '10px',
                                borderRadius: '50%/50%'
                            }}
                        >
                            <img src={TotalBillIcon} alt='icon' />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <h2 style={{ margin: 0, textAlign: 'center' }}>Bill Details</h2>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: '10px',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: '0 8px 0 0' }}>Flat:</h3>{' '}
                        <span
                            style={{
                                backgroundColor: '#efefef',
                                padding: '4px 8px',
                                textAlign: 'center',
                                borderRadius: '4px',
                                fontWeight: 'bold'
                            }}
                        >
                            {props.data.flatName}
                        </span>
                    </div>
                    {data.paymentStatus === PaymentStatusConstants.PAID ? (
                        <GreenTag text={'paid'} />
                    ) : data.paymentStatus === PaymentStatusConstants.UNPAID ? (
                        <RedTag text={'unpaid'} />
                    ) : (
                        <BlueTag text={'partial'} />
                    )}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: '0 8px 0 0' }}>Month: </h3>
                        <span
                            style={{
                                backgroundColor: '#efefef',
                                padding: '4px 8px',
                                textAlign: 'center',
                                borderRadius: '4px',
                                color: '#ff3300',
                                fontWeight: 'bold'
                            }}
                        >
                            {getMonthNameAndYear(props.data.month)}
                        </span>
                    </div>
                </div>
                <ComponentTable
                    scroll={false}
                    pagination={false}
                    dataList={props.data.items}
                    columns={columns}
                />
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '2fr 1fr 3fr',
                        alignItems: 'center',
                        marginTop: '10px'
                    }}
                >
                    <h3 style={{ margin: '0' }}>Bills to be paid</h3>
                    <span style={{ fontSize: '18px' }}>
                        {' '}
                        <span style={{ fontSize: '12px', color: '#ff3300' }}>৳</span>{' '}
                        {data.totalAmount - data.paidAmount}
                    </span>
                </div>
            </Modal>
        </div>
    )
}
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        width: '30%'
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        width: '20%'
    },
    {
        title: 'Description',
        dataIndex: 'description',
        width: '50%'
    }
]
