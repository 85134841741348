import { Button } from 'antd'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AppRoutes } from '../../assets/constants/routes'
import './resultTypePage.scss'

export default function ResultTypePage({ status, title, subTitle, image }) {
    const history = useHistory()
    return (
        <div className='error-description-container'>
            <img src={image}></img>
            <h2>{title}</h2>
            <h3>
                {subTitle} <span>01313028647</span>{' '}
            </h3>
            {status === '403' ? (
                <Button type='primary' onClick={() => history.goBack()}>
                    Go Back
                </Button>
            ) : status === '404' ? (
                <Link to={AppRoutes.DEFAULT}>
                    <Button type='primary'>Back Home</Button>
                </Link>
            ) : status === '402' ? (
                <Link to={AppRoutes.PRICING_PLANS}>
                    <Button type='primary'>Purchase a Plan</Button>
                </Link>
            ) : (
                ''
            )}
        </div>
    )
}
