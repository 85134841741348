import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import { ButtonMildGreen } from '../buttons/CustomButtons'
import { FlatInvoicePdf } from './FlatInvoicePdf'

function FlatInvoicePdfPage({ data, userDetails, invoiceHeader }) {

    const componentRef = useRef()
    return (
        <div>
            <ReactToPrint
                trigger={() => <ButtonMildGreen text={'PDF'} icon={'printer'} />}
                content={() => componentRef.current}
            />
            <FlatInvoicePdf data={data} userDetails={userDetails} invoiceHeader={invoiceHeader} ref={componentRef} />
        </div>
    )
}

export default FlatInvoicePdfPage
