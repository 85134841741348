import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import BillAction from '../../../stores/money-management/bill/BillAction'
import { CreateBillTypeRequest } from '../../../stores/money-management/bill/requests/CreateBillTypeRequest'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { NoticeWhiteIcon } from '../../misc/IconsProvider'
import { ColorConstants } from '../../../assets/constants/GeneralConstants'
import { FormIconContainer, FormItemContainer } from '../FormStyles'

const INITIAL_STATE = {
    name: '',
    description: '',
    defaultAmount: ''
}

const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string()
        .required('Name is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Max 40 Characters'),
    description: Yup.string().max(150, 'Maximum 150 Characters'),
    defaultAmount: Yup.number()
        .typeError('Amount must be Digit')
        .required('Amount is required.')
        .min(0, 'Minimum amount 0')
        .max(100000000, 'Maximum amount 100000000')
})

export default function FormCreateBillType() {
    const dispatch = useDispatch()

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [BillAction.REQUEST_CREATE_BILL_TYPE])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_CREATE_BILL_TYPE)
    )

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        dispatch(BillAction._requestCreateBillType(new CreateBillTypeRequest(values)))
    }

    return (
        <FormIconContainer>
            <FormTitleWithIcon
                icon={<NoticeWhiteIcon />}
                color={ColorConstants.GREEN1}
                formTitle={'Create Bill Type'}
            />
            <FormItemContainer triple>
                <FormInputText label={'Name'} control={control} name={'name'} />
                <FormInputText label={'Default Amount'} control={control} name={'defaultAmount'} />
                <FormInputText label={'Description'} control={control} name={'description'} />
            </FormItemContainer>
            <ButtonFormSubmit isRequesting={isRequesting} control={control} onSubmit={onSubmit} />
        </FormIconContainer>
    )
}
