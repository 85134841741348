import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class MeetingEffect {
    static async _requestCreateMeeting(data) {
        const endPoint = ApiEndpoint.meeting.createMeeting
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestCancelMeeting(data) {
        const endPoint = ApiEndpoint.meeting.cancelMeeting
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetMeetingList(data) {
        const endPoint = ApiEndpoint.meeting.getMeetings
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
