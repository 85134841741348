import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import { FormSearchWithInfoContainer, FormItemContainer } from '../FormStyles'
import DataValidationConstants from '../FormDataValidationConstants'
import FormsAction from '../../../stores/admin-management/forms/FormsAction'
import GetAllPoliceFormRequest from '../../../stores/admin-management/forms/requests/GetAllPoliceFormRequest'
import { isMobile } from 'react-device-detect'

const INITIAL_STATE = {
    fullName: '',
    mobileNumber: '',
    nidNumber: '',
    email: '',
    division: '',
    thana: ''
}

const VALIDATION_SCHEMA = Yup.object({
    fullName: Yup.string().max(40, 'Max 40 Characters'),
    nidNumber: DataValidationConstants.VALID_NID,
    mobileNumber: DataValidationConstants.VALID_MOBILE_NUMBER,
    email: DataValidationConstants.VALID_EMAIL,
    division: Yup.string().max(40, 'Max 40 Characters'),
    thana: Yup.string().max(40, 'Max 40 Characters'),
})

export default function FormSearchPoliceForm() {
    const dispatch = useDispatch()
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const onSubmit = () => {
        dispatch(FormsAction._requestGetPoliceFormsList(new GetAllPoliceFormRequest(values)))
    }
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [FormsAction.REQUEST_GET_ALL_POLICE_FORM])
    )
    return (
        <FormSearchWithInfoContainer>
            <FormItemContainer triple>
                <FormInputText placeholder={'Name'} control={control} name={'fullName'} />
                <FormInputText placeholder={'Mobile'} control={control} name={'mobileNumber'} />
                <FormInputText placeholder={'NID Number'} control={control} name={'nidNumber'} />
                <FormInputText placeholder={'Email'} control={control} name={'email'} />
                <FormInputText placeholder={'Division'} control={control} name={'division'} />
                <FormInputText placeholder={'Thana'} control={control} name={'thana'} />
            </FormItemContainer>
            <div className={'form-image'} style={{ justifySelf: isMobile ? 'center' : '' }}>
                <ButtonFormSubmit
                    isRequesting={isRequesting}
                    control={control}
                    onSubmit={onSubmit}
                    title={'Search'}
                    icon='search'
                    marginTop='3px'
                />
            </div>
        </FormSearchWithInfoContainer>
    )
}
