import React, { useState } from 'react'
import { ButtonMildBlue } from '../component/buttons/CustomButtons'
import { Modal } from 'antd'
import './tenantOwnerForm.scss'
import './modals.scss'
import { Box, Flex, Heading, ThemeProvider, Text, theme, Button } from '@chakra-ui/react'
import { PageFormContainer } from '../pages/PageStyles'
import TenantDeparturePdf from '../component/pdf/TenantDeparturePdf'

export default function ModalTenantDeparture({ data }) {
    const [visibility, setVisibility] = useState(false)
    const customTheme = {
        ...theme,
        fonts: {
            heading: "'Raleway', sans-serif",
            body: "'Raleway', sans-serif;",
            mono: 'Menlo, monospace'
        }
    }

    const handlePrint = () => {
        window.print()
    }

    console.log(data)

    return (
        <>
            <ButtonMildBlue
                // disabled={props.isDisabled}
                icon={'eye'}
                text={'View'}
                onClick={() => setVisibility(true)}
            />
            <ThemeProvider theme={customTheme}>
                <Modal
                    className='tenant-modal-form'
                    bodyStyle={{ height: '75vh', overflowY: 'auto' }}
                    title={`Tenant Details`}
                    centered
                    visible={visibility}
                    onCancel={() => setVisibility(false)}
                    onOk={() => handlePrint()}
                    okText={'Ok'}
                    cancelText={'Cancel'}
                    // cancelButtonProps={{ style: { display: 'none' } }}
                    footer={[
                        <Button
                            type='danger'
                            key='close'
                            cursor='pointer'
                            onClick={() => setVisibility(false)}
                        >
                            Close Details
                        </Button>,
                        <Button
                            type='danger'
                            key='close'
                            cursor='pointer'
                            onClick={() => handlePrint()}
                        >
                            Print
                        </Button>
                    ]}
                >
                    <PageFormContainer>
                        <Box bg='white' borderRadius={6} py={5} px={7} color='black'>
                            <Box>
                                <Heading as='h3' size='md' my={3} textAlign='center'>
                                    ভাড়াটিয়া ছাড়পত্র
                                </Heading>
                                <Box>
                                    <form>
                                        <Flex justifyContent='space-between'>
                                            <Box w='80%'>
                                                <Text fontSize='lg' p={2}>
                                                    তারিখঃ {data.applicationDate}
                                                </Text>
                                            </Box>
                                            {/* <TenantDeparturePdf /> */}
                                        </Flex>
                                        <Box>
                                            <Text fontSize='lg' p={2}>
                                                বাড়ির মালিকের নাম: {data.ownerName}
                                            </Text>
                                        </Box>

                                        <Text fontSize='lg' p={2}>
                                            ঠিকানা: {data.ownerAddress}
                                        </Text>

                                        <Text fontSize='lg' p={2}>
                                            বিষয়ঃ {data.subject}
                                        </Text>

                                        <Box>
                                            <Text fontSize='lg' pt={2} pl={2} m={0}>
                                                জনাব,
                                            </Text>
                                            <Text
                                                fontWeight={300}
                                                letterSpacing={0.5}
                                                fontSize='lg'
                                                p={2}
                                            >
                                                এই মর্মে জানাচ্ছি যে, আমি {data.tenantName} পিতা:{' '}
                                                {data.fathersName} ঠিকানাঃ {data.ownerAddress} এই
                                                ঠিকানায় বসবাস করতেন গত {data.departureDate} তারিখ
                                                থেকে আমার জানামতে, এতদিন এই ঠিকানায় অবস্থানরত
                                                থাকাকালে তিনি সমাজ বা রাষ্ট্রবিরোধী অথবা অনৈতিক কোন
                                                কাজের সাথে জড়িত ছিলেন না এবং তার স্বভাব ও নৈতিকতা
                                                ছিল প্রশংসনীয়। তার সাথে আমার পূর্বের কোন লেনদেন বাকি
                                                নেই। আমি তার ভবিষ্যৎ মঙ্গল কামনা করি।
                                            </Text>
                                        </Box>
                                        <Box>
                                            <Text fontSize='lg' m={0} p={2} fontWeight={500}>
                                                বাড়িওয়ালা
                                            </Text>
                                            <Text fontSize='lg' m={0} p={2}>
                                                {data.tenantSignature}
                                            </Text>
                                            <Text fontSize='lg' p={2}>
                                                স্বাক্ষর (নাম)
                                            </Text>
                                        </Box>
                                    </form>
                                </Box>
                            </Box>
                        </Box>
                    </PageFormContainer>
                </Modal>
            </ThemeProvider>
        </>
    )
}
