import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import ComplainAction from './ComplainAction'

const ComplainPostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case ComplainAction.REQUEST_SUBMIT_COMPLAIN_FINISHED:
                next(MiscAction._showModalSuccess('Complain Submitted Successfully'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default ComplainPostEffect
