import React, { useState } from 'react'
import { Modal } from 'antd'
import { ButtonFillPurple } from '../component/buttons/CustomButtons'
import ComponentTable from '../tables/ComponentTable'
import TotalBillIcon from '../../assets/images/bill-details-small.png'
import { ColorConstants } from '../../assets/constants/GeneralConstants'

export default function ModalBillPackageItems(props) {
    const [visibility, setVisibility] = useState(false)
    const data = props.data
    const { items } = data
    return (
        <>
            <ButtonFillPurple
                disabled={props.isDisabled}
                icon={'eye'}
                size={'default'}
                text={'Details'}
                onClick={() => setVisibility(true)}
            />
            <Modal
                // title={`Details`}
                centered
                visible={visibility}
                closable={false}
                onCancel={() => setVisibility(false)}
                onOk={() => setVisibility(false)}
                okText={'Close'}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '30px',
                        marginTop: '20px'
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <div
                            style={{
                                display: 'grid',
                                alignItems: 'center',
                                margin: '4px',
                                width: '40px',
                                height: '40px',
                                backgroundColor: ColorConstants.PURPLE2,
                                justifyItems: 'center',
                                padding: '10px',
                                borderRadius: '50%/50%'
                            }}
                        >
                            <img src={TotalBillIcon} alt='icon' />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <h2 style={{ margin: 0, textAlign: 'center' }}>Package Details</h2>
                        </div>
                    </div>
                </div>
                <div style={{backgroundColor: '#efefef', padding: '12px', marginBottom: '20px', borderRadius: '4px'}}>
                    <h2 style={{margin: '0', fontSize: '14px', fontWeight: 'bolder'}}>{data.name.toUpperCase()}</h2>
                </div>
                <ComponentTable
                    scroll={false}
                    pagination={false}
                    dataList={items}
                    columns={columns}
                />
                <div style={{ marginTop: '10px', width: '100%', display: 'flex' }}>
                    {/* <div style={{ width: '30%' }}></div> */}
                    <div style={{ width: '30%', textAlign: 'left', fontWeight: '700' }}>
                        Total Amount:
                    </div>
                    <div
                        style={{
                            width: '30%',
                            textAlign: 'left',
                            fontWeight: '700',
                            paddingLeft: '8px'
                        }}
                    >
                        ৳ {data.totalAmount}
                    </div>
                </div>
            </Modal>
        </>
    )
}
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        width: '30%'
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        width: '20%'
    },
    {
        title: 'Description',
        dataIndex: 'description',
        width: '50%'
    }
]
