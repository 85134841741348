import BaseRequest from '../../../special/models/BaseRequest'

export default class CreateNewTanentDepartureFormRequest extends BaseRequest {
    userId = ''
    forms = {
        applicationDate: '',
        departureDate: '',
        fathersName: '',
        ownerAddress: '',
        ownerName: '',
        subject: '',
        tenantName: '',
        tenantSignature: ''
    }

    constructor(data) {
        super()
        this.update(data)
    }
}