import BaseReducer from '../../../utils/BaseReducer'
import PreAuthorizationAction from './PreAuthorizationAction'

export default class PreAuthorizationReducer extends BaseReducer {
    initialState = {
        preAuthorizationList: null
    };

    [PreAuthorizationAction.REQUEST_GET_PRE_AUTHORIZATION_LIST_FINISHED](state, action) {
        return {
            ...state,
            preAuthorizationList: action.payload
        }
    }
}
