import { Button } from 'antd'
import { TagColors } from '../../../assets/constants/GeneralConstants'
import React from 'react'
import './style.scss'

export function GreenTag(props) {
    return (
        <div
            style={{
                width: 'fit-content',
                padding: '4px 15px',
                borderRadius: '4px',
                color: TagColors.GREEN_FRONT,
                textAlign: 'center',
                minWidth: '100px',
                margin: '0 auto',
                background: TagColors.GREEN_BACK
            }}
        >
            {props.icon?props.icon:''}
            {props.text}
        </div>
    )
}

export function YellowTag(props) {
    return (
        <div
            style={{
                width: 'fit-content',
                padding: '4px 15px',
                borderRadius: '4px',
                color: TagColors.YELLOW_FRONT,
                textAlign: 'center',
                margin: '0 auto',
                minWidth: '100px',
                background: TagColors.YELLOW_BACK
            }}
        >
            {props.text}
        </div>
    )
}

export function PurpleTag(props) {
    return (
        <div
            style={{
                width: 'fit-content',
                padding: '4px 15px',
                borderRadius: '4px',
                color: TagColors.PURPLE_FRONT,
                textAlign: 'center',
                margin: '0 auto',
                minWidth: '100px',
                background: TagColors.PURPLE_BACK
            }}
        >
            {props.text}
        </div>
    )
}

export function RedTag(props) {
    return (
        <div
            style={{
                width: 'fit-content',
                padding: '4px 15px',
                borderRadius: '4px',
                color: TagColors.RED_FRONT,
                textAlign: 'center',
                margin: '0 auto',
                minWidth: '100px',
                background: TagColors.RED_BACK
            }}
        >
            {props.text}
        </div>
    )
}
export function BlueTag(props) {
    return (
        <div
            style={{
                width: 'fit-content',
                padding: '4px 15px',
                borderRadius: '4px',
                color: TagColors.GEEK_BLUE,
                textAlign: 'center',
                margin: '0 auto',
                minWidth: '100px',
                background: TagColors.BLUE_BACK
            }}
        >
            {props.text}
        </div>
    )
}

export function BlueBackgroundTag(props) {
    return (
        <div
            style={{
                width: 'fit-content',
                padding: '4px',
                borderRadius: '4px',
                color: '#fff',
                textAlign: 'center',
                minWidth: '40px',
                margin: '0 auto',
                background: TagColors.GEEK_BLUE
            }}
        >
            {props.text}
        </div>
    )
}

export function RedTagButton(props) {
    return (
        <button
            className='red-tag-button'
            onClick={props.onClick}
            style={{
                width: 'fit-content',
                padding: '4px 15px',
                borderRadius: '4px',
                color: TagColors.RED_FRONT,
                textAlign: 'center',
                minWidth: '220px',
                background: TagColors.RED_BACK,
                border: 'none',
                cursor: 'pointer',
                fontWeight: '600'
            }}
        >
            {props.text}
        </button>
    )
}

export function BlackTagButton(props) {
    return (
        <button
            className='red-tag-button'
            onClick={props.onClick}
            style={{
                width: 'fit-content',
                padding: '4px 15px',
                borderRadius: '4px',
                color: TagColors.BLUE_FRONT,
                textAlign: 'center',
                minWidth: '40px',
                background: TagColors.BLUE_BACK,
                border: 'none',
                cursor: 'pointer',
                fontWeight: '600'
            }}
        >
            {props.text}
        </button>
    )
}
