// import React, { Component } from "react";
import { Result, Button } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import firebase from '../../utils/firebase/index';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }
    static getDerivedStateFromError(error) {
        return { hasError: true }
    }
    analytics = firebase.analytics();
    componentDidCatch(error, info) {
        this.analytics.logEvent('exception',{
            'name': error.message,
            'path': this.props.history.location.pathname,
            'componentSpecified': info.componentStack.split(')')[0]
        })
    }
    render() {
        if (this.state.hasError) {
            return (
                <Result
                    status='500'
                    // title="500"

                    subTitle='Sorry, Something went Wrong'
                    extra={
                        <Button type='primary' onClick={() => this.props.history.push('/')}>
                            Back Home
                        </Button>
                    }
                />
            )
        }
        return this.props.children
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

function mapStateToProps({}) {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary))
