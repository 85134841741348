import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import PrivilegeAction from './PrivilegeAction'

const PrivilegePostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case PrivilegeAction.REQUEST_CREATE_USER_ROLE_FINISHED:
                next(MiscAction._showModalSuccess('User Role Created Successfully'))
                break
            case PrivilegeAction.REQUEST_CREATE_USER_FUNCTION_FINISHED:
                next(MiscAction._showModalSuccess('User Function  Created Successfully'))
                break
            case PrivilegeAction.REQUEST_ASSIGN_FUNCTION_TO_ROLE_FINISHED:
                next(MiscAction._showModalSuccess('Function Assigned to Role  Successfully'))
                break
            case PrivilegeAction.REQUEST_DELETE_USER_FUNCTION_FINISHED:
                next(MiscAction._showModalSuccess('Function Deleted Successfully'))
                break
            case PrivilegeAction.REQUEST_DELETE_USER_ROLE_FINISHED:
                next(MiscAction._showModalSuccess('Role deleted Successfully'))
                break
            case PrivilegeAction.REQUEST_ASSIGN_ROLE_TO_USER_FINISHED:
                next(MiscAction._showModalSuccess('Role assigned to User Successfully'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default PrivilegePostEffect
