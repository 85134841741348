import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class ComplainEffect {
    static async _requestSubmitComplain(data) {
        const endPoint = ApiEndpoint.complain.submitComplain
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetComplainList(data) {
        const endPoint = ApiEndpoint.complain.getComplains
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetComplainDetails(data) {
        const endPoint = ApiEndpoint.complain.getComplainDetails
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
