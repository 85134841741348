import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { makeSelectUserRolesList } from '../../../stores/user-management/privilege/PrivilegeSelector'
import PrivilegeAction from '../../../stores/user-management/privilege/PrivilegeAction'
import BaseRequest from '../../../stores/special/models/BaseRequest'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import ComponentTable from '../ComponentTable'
import RoleDetailsRequest from '../../../stores/user-management/privilege/models/RoleDetailsRequest'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'

export default function TableUserRole(props) {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectUserRolesList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [PrivilegeAction.REQUEST_GET_USER_ROLES])
    )
    const isFinishedCreate = useSelector((state) =>
        selectFinished(state, PrivilegeAction.REQUEST_CREATE_USER_ROLE)
    )
    const isFinishedDelete = useSelector((state) =>
        selectFinished(state, PrivilegeAction.REQUEST_DELETE_USER_ROLE)
    )

    useEffect(() => {
        dispatch(PrivilegeAction._requestGetUserRoles(new BaseRequest()))
    }, [isFinishedCreate, isFinishedDelete])

    const columns = [
        {
            title: 'Role Name',
            dataIndex: 'name'
        },
        {
            title: 'Role Code',
            dataIndex: 'code'
        },
        {
            title: 'Description ',
            dataIndex: 'description'
        },
        {
            title: '',
            align: 'center',
            render: (text, record) => (
                <ModalDeleteConfirmation
                    confirmationText={'Are you sure you want to delete this role ?'}
                    onConfirm={() =>
                        dispatch(
                            PrivilegeAction._requestDeleteUserRole(
                                new RoleDetailsRequest(record.id)
                            )
                        )
                    }
                />
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
