import firebase from 'firebase'

const firebaseConfig = {
    apiKey: 'AIzaSyATDIA8VFjpbLpz80AlPt0TWq8cjusQd9Y',
    authDomain: 'rokkhi-f7514.firebaseapp.com',
    databaseURL: 'https://rokkhi-f7514.firebaseio.com',
    projectId: 'rokkhi-f7514',
    storageBucket: 'rokkhi-f7514.appspot.com',
    messagingSenderId: '1098122328211',
    appId: '1:1098122328211:web:58bdc89ae68a8b94',
    measurementId: 'G-H430K0F121'
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()
export default firebase
