import React, { useState } from 'react'
import { Collapse, Modal, Spin } from 'antd'
import {
    ButtonFillGreen,
    ButtonFillPurple,
    ButtonMildBlue
} from '../component/buttons/CustomButtons'
import ComponentTable from '../tables/ComponentTable'
import TotalBillIcon from '../../assets/images/total-bill-small.png'
import {
    ColorConstants,
    PaymentMethodConstants,
    PaymentStatusConstants
} from '../../assets/constants/GeneralConstants'
import { makeSelectDueBillVouchersList } from '../../stores/money-management/bill/BillSelector'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import BillAction from '../../stores/money-management/bill/BillAction'
import { GetBillItemsRequest } from '../../stores/money-management/bill/requests/GetBillItemsRequest'
import CollecBillInvoiceItem from '../forms/bill/CollectBillInvoiceItem'
import { getCurrentMonthWithYear, getMonthNameAndYear } from '../../utils/functions/functions'
import { BlueTag, GreenTag, RedTag } from '../component/misc/Tags'
import CollectBillRequest from '../../stores/money-management/bill/requests/CollectBillRequest'
import { selectRequesting } from '../../stores/special/requesting/RequestingSelector'

const { Panel } = Collapse

export default function ModalBillItems(props) {
    const [visibility, setVisibility] = useState(false)
    const dispatch = useDispatch()
    const data = props.data
    const { flat, building } = data

    useEffect(() => {
        if (visibility === true && flat && building) {
            dispatch(
                BillAction._requestGetDueBillItems(
                    new GetBillItemsRequest({
                        flatId: flat.id,
                        buildingId: building.id,
                        paymentStatus: props.paymentStatus,
                        getPreviousDueAndPartial: true
                    })
                )
            )
        }
    }, [visibility, flat, building])

    const dueBillItems = useSelector((state) => makeSelectDueBillVouchersList(state))

    const isRequesting = useSelector((state) =>
        selectRequesting(state, [BillAction.REQUEST_COLLECT_BILL])
    )

    const handleCollectAll = () => {
        const multipleData = getData(dueBillItems, data)
        if (multipleData && multipleData.length > 0) {
            for (let i = 0; i < multipleData.length; i++) {
                dispatch(
                    BillAction._requestCollectBill(
                        new CollectBillRequest({
                            paymentAmount: multipleData[i].totalAmount - multipleData[i].paidAmount,
                            billInvoiceId: multipleData[i].id,
                            paymentMode: PaymentMethodConstants.CASH_EXCHANGE
                        })
                    )
                )
            }
        }
    }

    return (
        <>
            <ButtonMildBlue
                disabled={data.paymentStatus === PaymentStatusConstants.PAID ? true : false}
                icon={'check'}
                text={'Collect'}
                onClick={() => setVisibility(true)}
            />
            <Modal
                // title={`Details`}
                centered
                visible={visibility}
                closable={true}
                onCancel={() => setVisibility(false)}
                onOk={() => setVisibility(false)}
                okText={'Close'}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                footer={null}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '30px',
                        marginTop: '20px'
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <div
                            style={{
                                display: 'grid',
                                alignItems: 'center',
                                margin: '4px',
                                width: '40px',
                                height: '40px',
                                backgroundColor: 'red',
                                justifyItems: 'center',
                                padding: '10px',
                                borderRadius: '50%/50%'
                            }}
                        >
                            <img src={TotalBillIcon} alt='icon' />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <h2 style={{ margin: 0, textAlign: 'center' }}>Bill Items</h2>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: '10px',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: '0 8px 0 0' }}>Flat:</h3>{' '}
                        <span
                            style={{
                                backgroundColor: '#efefef',
                                padding: '4px 8px',
                                textAlign: 'center',
                                borderRadius: '4px',
                                fontWeight: 'bold'
                            }}
                        >
                            {data.flatName}
                        </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: '0 8px 0 0' }}>Current Month: </h3>
                        <span
                            style={{
                                backgroundColor: '#efefef',
                                padding: '4px 8px',
                                textAlign: 'center',
                                borderRadius: '4px',
                                color: '#ff3300',
                                fontWeight: 'bold'
                            }}
                        >
                            {getMonthNameAndYear(getCurrentMonthWithYear())}
                        </span>
                    </div>
                </div>

                <div
                    style={{
                        marginTop: '10px',
                        width: '100%',
                        display: 'flex',
                        color: `${ColorConstants.RED}`
                    }}
                ></div>
                {dueBillItems &&
                dueBillItems.length > 0 &&
                dueBillItems.map((item) => item.flatName).includes(data.flatName) ? (
                    <>
                        <Collapse defaultActiveKey={1}>
                            {dueBillItems && dueBillItems.length > 0
                                ? getData(dueBillItems, data).map((item, index) => {
                                      if (item) {
                                          return (
                                              <Panel
                                                  header={
                                                      <div
                                                          style={{
                                                              display: 'flex',
                                                              justifyContent: 'space-between'
                                                          }}
                                                      >
                                                          <span>
                                                              {getMonthNameAndYear(item.month)}
                                                          </span>{' '}
                                                          {item.paymentStatus ===
                                                          PaymentStatusConstants.PAID ? (
                                                              <GreenTag text={item.paymentStatus} />
                                                          ) : item.paymentStatus ===
                                                            PaymentStatusConstants.PARTIAL_PAID ? (
                                                              <BlueTag text={'PARTIALLY PAID'} />
                                                          ) : (
                                                              <RedTag text={item.paymentStatus} />
                                                          )}
                                                          <span>
                                                              Amount {'     '}{' '}
                                                              {item.totalAmount - item.paidAmount}
                                                          </span>
                                                      </div>
                                                  }
                                                  key={index + 1}
                                              >
                                                  <ComponentTable
                                                      scroll={false}
                                                      pagination={false}
                                                      dataList={item.items}
                                                      columns={columns}
                                                  />
                                                  <CollecBillInvoiceItem
                                                      key={item.id}
                                                      item={item}
                                                  />
                                              </Panel>
                                          )
                                      }
                                  })
                                : null}
                        </Collapse>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: '10px auto'
                            }}
                        >
                            <h3 style={{ margin: 0, color: 'red' }}>
                                Total Due Amount:{' '}
                                <span style={{ fontSize: '12px', color: 'black' }}>৳</span>{' '}
                                {dueBillItems
                                    ? dueBillItems
                                          .map((item) => item.totalAmount - item.paidAmount)
                                          .reduce((acc, val) => {
                                              return acc + val
                                          })
                                    : 0}
                            </h3>
                            <div>
                                <ButtonFillGreen
                                    disabled={isRequesting ? true : false}
                                    onClick={() => handleCollectAll()}
                                    text={'Collect All'}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <Spin size='large' />
                    </div>
                )}
            </Modal>
        </>
    )
}
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        width: '30%'
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        width: '20%'
    },
    {
        title: 'Description',
        dataIndex: 'description',
        width: '50%'
    }
]

const getData = (dueBillItems, data) => {
    if (dueBillItems && data) {
        const newData = dueBillItems.filter((item) => {
            return item.paymentStatus !== 'PAID'
        })
        const isDuplicate = newData
            .map((item) => {
                if (item && item.id) return item.id
            })
            .includes(data.id)
        if (isDuplicate) {
            return [...newData].reverse();
        } else {
            return [data, ...newData]
        }
    }
}
