import { createSelector } from 'reselect'

export class ComplainSelector {
    static getComplainList = (state) => state.complain.complainList

    static getComplainDetails = (state) => state.complain.complainDetails
}

export const makeSelectComplainsList = createSelector(
    ComplainSelector.getComplainList,
    (complain) => (complain ? complain.data : [])
)
