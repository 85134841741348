import BaseRequest from '../../../special/models/BaseRequest'

export default class CreateNewPackageRequest extends BaseRequest {
    name = ''
    info = ''
    type = ''
    price = 0
    plan = ''
    status = ''
    duration = ''
    month = 0
    constructor(data) {
        super()
        this.update(data)
        if(this.data) {
            this.price = parseInt(data.price);
            this.month = parseInt(data.month);
        }
        // console.log(data);
    }
}
