import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import FormInputDropDown from '../../component/form-input/FormInputDropDown'
import {
    ColorConstants,
    DropDownConstants,
    RadioConstants
} from '../../../assets/constants/GeneralConstants'
import VehicleAction from '../../../stores/security-management/vehicle/VehicleAction'
import AddVehicleRequest from '../../../stores/security-management/vehicle/requests/AddVehicleRequest'
import FlatAction from '../../../stores/property-management/flat/FlatAction'
import GetFlatsRequest from '../../../stores/property-management/flat/request/GetFlatsRequest'
import FormInputRadio from '../../component/form-input/FormInputRadio'
import { FormIconContainer, FormItemContainer } from '../FormStyles'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { VehicleIcon } from '../../misc/IconsProvider'

const INITIAL_STATE = {
    name: '',
    number: '',
    model: '',
    color: '',
    vehicleType: '',
    registrationNumber: '',
    taxTokenNumber: '',
    image: '',
    thumbImage: '',
    ownedBy: '',
    buildingId: '',
    flatId: ''
}

const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string()
        .required('Vehicle Name is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Maximum 40 Characters'),
    number: Yup.string().required('Vehicle Number is Required').max(150, 'Maximum 150 Characters'),
    registrationNumber: Yup.string().max(150, 'Maximum 150 Characters'),
    taxTokenNumber: Yup.string().max(150, 'Maximum 150 Characters'),
    color: Yup.string().max(150, 'Maximum 150 Characters'),
    model: Yup.string().max(150, 'Maximum 150 Characters'),
    flatId: Yup.string().required('Flat is Required'),
    buildingId: Yup.string().required('Building is Required'),
    vehicleType: Yup.string().required('Vehicle Type is Required')
})

export default function FormAddVehicle(props) {
    const dispatch = useDispatch()

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [VehicleAction.REQUEST_ADD_VEHICLE])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, VehicleAction.REQUEST_ADD_VEHICLE)
    )

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        dispatch(VehicleAction._requestAddVehicle(new AddVehicleRequest(values)))
    }

    useEffect(() => {
        if (control.values.buildingId) dispatch(FlatAction._requestGetFlatList(new GetFlatsRequest(control.values)))
        control.setValue('flatId', '')
    }, [control.values.buildingId])

    return (
        <FormIconContainer>
            <FormTitleWithIcon
                icon={<VehicleIcon />}
                color={ColorConstants.GREEN1}
                formTitle={'Add New Vehicle'}
            />

            <FormItemContainer>
                <FormInputDropDown
                    label={'Select Building'}
                    control={control}
                    name={'buildingId'}
                    dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                />
                <FormInputDropDown
                    label={'Select Flat'}
                    control={control}
                    name={'flatId'}
                    dropDownFor={DropDownConstants.TYPE_FLATS_WITHOUT_ALL}
                />

                <FormInputText label={'Vehicle Name'} control={control} name={'name'} />
                <FormInputText label={'Vehicle Number'} control={control} name={'number'} />
                <FormInputText label={'Model'} control={control} name={'model'} />
                <FormInputText label={'Color'} control={control} name={'color'} />
                <FormInputText
                    label={'Registration Number'}
                    control={control}
                    name={'registrationNumber'}
                />
                <FormInputText
                    label={'Tax Token Number'}
                    control={control}
                    name={'taxTokenNumber'}
                />
            </FormItemContainer>
            <FormInputRadio
                label={'Select Vehicle Type'}
                name={'vehicleType'}
                control={control}
                radioFor={RadioConstants.TYPE_VEHICLE}
            />

            <ButtonFormSubmit isRequesting={isRequesting} control={control} onSubmit={onSubmit} />
        </FormIconContainer>
    )
}
