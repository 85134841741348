import {
    Box,
    Flex,
    Heading,
    Input,
    ListItem,
    ThemeProvider,
    Text,
    theme,
    Button
} from '@chakra-ui/react'
import { UnorderedList } from '@chakra-ui/react'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Th, Td } from '../../component/table/Table'
import { PageFormContainer } from '../../pages/PageStyles'
import _ from 'lodash'
import { WarningOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import FormsAction from '../../../stores/admin-management/forms/FormsAction'
import CreateNewTanentOwnerFormRequest from '../../../stores/admin-management/forms/requests/CreateNewTanentOwnerFormRequest'
import { useParams } from 'react-router-dom'
import { decode } from 'base-64'
import { message } from 'antd'

export default function FormTenant(props) {
    const dispatch = useDispatch()

    const { encryptedData } = useParams()

    try {
        const decodedData = JSON.parse(decode(encryptedData))
        var { userId } = decodedData
    } catch (error) {
        message.error({content: 'Community id does not match!', key: 'wrongCommunityIdTenant'})
    }

    console.log(userId)

    const { handleSubmit, register, errors } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: 'firstError',
        shouldFocusError: true
    })

    const customTheme = {
        ...theme
    }

    const onSubmit = (data) => {
        // data.userId = userId;
        const values = {
            userId: userId,
            forms: { ...data }
        }
        // console.log(new CreateNewTanentOwnerFormRequest(values));
        dispatch(
            FormsAction._requestCreateNewTanentOwnerForm(
                new CreateNewTanentOwnerFormRequest(values)
            )
        )
    }

    return (
        <ThemeProvider theme={customTheme}>
            <PageFormContainer>
                <Box bg='white' borderRadius={6} py={5} px={7} color='black'>
                    <Box>
                        <Heading as='h3' size='md' my={3} textAlign='center'>
                            বাড়িওয়ালা-ভাড়াটিয়া চুক্তিপত্র
                        </Heading>
                        <Box>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Flex justifyContent='space-between'>
                                    <Box w='80%'>
                                        <Flex>
                                            <Text fontSize='lg' p={2}>
                                                তারিখঃ{' '}
                                            </Text>
                                            <Input
                                                isRequired
                                                type='date'
                                                w='20%'
                                                name='applicationDate'
                                                py={3}
                                                px={2}
                                                border='none'
                                                ref={register}
                                            />
                                        </Flex>
                                    </Box>
                                    <Button
                                        border='none'
                                        cursor='pointer'
                                        colorScheme='blue'
                                        type='submit'
                                    >
                                        Save
                                    </Button>
                                </Flex>
                                <Box>
                                    <Flex my={2}>
                                        <Text fontSize='lg' p={2}>
                                            বাড়ির মালিকের নাম:
                                        </Text>
                                        <Input
                                            isRequired
                                            type='text'
                                            name='ownerName'
                                            w='25%'
                                            // border='none'
                                            ref={register({ maxLength: 40 })}
                                            placeholder='বাড়িওয়ালার নাম'
                                        />
                                        {errors &&
                                            errors.ownerName &&
                                            errors.ownerName.type === 'maxLength' && (
                                                <span style={{ color: 'red' }}>
                                                    {' '}
                                                    <WarningOutlined /> Name can be only 40
                                                    characters long
                                                </span>
                                            )}
                                    </Flex>
                                </Box>
                                <Flex my={2}>
                                    <Text fontSize='lg' p={2}>
                                        ঠিকানা:
                                    </Text>
                                    <Input
                                        isRequired
                                        type='text'
                                        name='ownerAddress'
                                        w='25%'
                                        border='none'
                                        py={3}
                                        px={2}
                                        ref={register}
                                        placeholder='ঠিকানা'
                                    />
                                </Flex>
                                <Flex my={2}>
                                    <Text fontSize='lg' p={2}>
                                        বিষয়ঃ
                                    </Text>
                                    <Input
                                        isRequired
                                        type='text'
                                        name='subject'
                                        w='25%'
                                        border='none'
                                        py={3}
                                        px={2}
                                        ref={register}
                                        placeholder='বিষয়'
                                    />
                                </Flex>
                                <Box>
                                    <Text fontSize='lg' p={2}>
                                        জনাব,
                                    </Text>
                                    <Flex>
                                        <Text fontSize='lg' p={2}>
                                            সম্মানপূর্বক জানাচ্ছি যে, আমি
                                        </Text>
                                        <Input
                                            isRequired
                                            type='text'
                                            name='tenantName'
                                            w='25%'
                                            border='none'
                                            py={3}
                                            px={2}
                                            ref={register({ maxLength: 40 })}
                                            placeholder='নাম'
                                        />
                                        {errors &&
                                            errors.tenantName &&
                                            errors.tenantName.type === 'maxLength' && (
                                                <span style={{ color: 'red' }}>
                                                    {' '}
                                                    <WarningOutlined /> Name can be only 40
                                                    characters long
                                                </span>
                                            )}
                                        <Text fontSize='lg' p={2}>
                                            আগামী
                                        </Text>
                                        <Input
                                            isRequired
                                            type='date'
                                            w='12%'
                                            name='arrivalDate'
                                            border='none'
                                            py={3}
                                            px={2}
                                            ref={register}
                                        />
                                        <Text fontSize='lg' p={2}>
                                            তারিখ হতে জনাব
                                        </Text>
                                        <Input
                                            isRequired
                                            type='text'
                                            name='ownerName'
                                            w='25%'
                                            border='none'
                                            py={3}
                                            px={2}
                                            ref={register({ maxLength: 40 })}
                                            placeholder='বাড়ির মালিকের নাম'
                                        />
                                        {errors &&
                                            errors.ownerName &&
                                            errors.ownerName.type === 'maxLength' && (
                                                <span style={{ color: 'red' }}>
                                                    {' '}
                                                    <WarningOutlined /> Name can be only 40
                                                    characters long
                                                </span>
                                            )}
                                    </Flex>
                                    <Flex my={1}>
                                        <Text fontSize='lg' p={2}>
                                            এর বাসা, ঠিকানাঃ
                                        </Text>
                                        <Input
                                            isRequired
                                            type='text'
                                            name='ownerAddress'
                                            w='25%'
                                            border='none'
                                            py={3}
                                            px={2}
                                            ref={register}
                                            placeholder='বাড়ির ঠিকানা'
                                        />
                                        <Text fontSize='lg' p={2}>
                                            তে
                                        </Text>
                                        <Input
                                            isRequired
                                            type='number'
                                            name='advanceAmount'
                                            w='15%'
                                            min='1'
                                            border='none'
                                            py={3}
                                            px={2}
                                            ref={register}
                                            placeholder='জামানতের পরিমাণ'
                                        />
                                        <Text fontSize='lg' p={2}>
                                            টাকা ফেরতযোগ্য অগ্রিম ভাড়া এবং
                                        </Text>
                                        <Input
                                            isRequired
                                            type='number'
                                            name='amount'
                                            w='15%'
                                            min='1'
                                            border='none'
                                            py={3}
                                            px={2}
                                            ref={register}
                                            placeholder='বাড়ি ভাড়ার পরিমাণ'
                                        />
                                        <Text fontSize='lg' p={2}>
                                            টাকা মাসিক ভাড়ায়
                                        </Text>
                                    </Flex>
                                    <Flex>
                                        <Text fontSize='lg' p={2}>
                                            বাড়ি ভাড়া নিয়ে চুক্তিবদ্ধ হচ্ছি।
                                        </Text>
                                    </Flex>
                                    <Heading as='h6' size='sm' mt={2} p={2}>
                                        ব্যক্তিগত তথ্যঃ
                                    </Heading>
                                    <Flex w='100%' direction='column'>
                                        <Box>
                                            <table

                                            // variant='striped'
                                            >
                                                {/* <Thead> */}
                                                <tr>
                                                    <Th style={{ width: '8%' }}>
                                                        {' '}
                                                        <Heading as='h6' size='sm'>
                                                            তথ্যঃ
                                                        </Heading>{' '}
                                                    </Th>
                                                    <Th style={{ width: '40%' }}>
                                                        {' '}
                                                        <Heading as='h6' size='sm'>
                                                            ভাড়াটিয়া
                                                        </Heading>
                                                    </Th>
                                                    <Th style={{ width: '40%' }}>
                                                        {' '}
                                                        <Heading as='h6' size='sm'>
                                                            বাড়িওয়ালা
                                                        </Heading>
                                                    </Th>
                                                </tr>
                                                <tr>
                                                    <Td>পুরো নামঃ</Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='tenantFullname'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register({ maxLength: 40 })}
                                                            placeholder='ভাড়াটিয়ার পুরো নাম'
                                                        />
                                                        {errors &&
                                                            errors.tenantFullname &&
                                                            errors.tenantFullname.type ===
                                                                'maxLength' && (
                                                                <span style={{ color: 'red' }}>
                                                                    {' '}
                                                                    <WarningOutlined /> Name can be
                                                                    only 40 characters long.
                                                                </span>
                                                            )}
                                                    </Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='ownerFullname'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register({ maxLength: 40 })}
                                                            placeholder='বাড়িওয়ালার পুরো নাম'
                                                        />
                                                        {errors &&
                                                            errors.ownerFullname &&
                                                            errors.ownerFullname.type ===
                                                                'maxLength' && (
                                                                <span style={{ color: 'red' }}>
                                                                    {' '}
                                                                    <WarningOutlined /> Name can be
                                                                    only 40 characters long
                                                                </span>
                                                            )}
                                                    </Td>
                                                </tr>
                                                <tr>
                                                    <Td>পিতার নামঃ</Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='tenantFathersName'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register({ maxLength: 40 })}
                                                            placeholder='ভাড়াটিয়ার পিতার নাম'
                                                        />
                                                        {errors &&
                                                            errors.tenantFathersName &&
                                                            errors.tenantFathersName.type ===
                                                                'maxLength' && (
                                                                <span style={{ color: 'red' }}>
                                                                    {' '}
                                                                    <WarningOutlined /> Name can be
                                                                    only 40 characters long
                                                                </span>
                                                            )}
                                                    </Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='ownerFathersName'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register({ maxLength: 40 })}
                                                            placeholder='বাড়িওয়ালার পিতার নাম'
                                                        />
                                                        {errors &&
                                                            errors.ownerFathersName &&
                                                            errors.ownerFathersName.type ===
                                                                'maxLength' && (
                                                                <span style={{ color: 'red' }}>
                                                                    {' '}
                                                                    <WarningOutlined /> Name can be
                                                                    only 40 characters long
                                                                </span>
                                                            )}
                                                    </Td>
                                                </tr>
                                                <tr>
                                                    <Td>শিক্ষাগত যোগ্যতাঃ </Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='tenantEduQualification'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register}
                                                            placeholder='শিক্ষাগত যোগ্যতা'
                                                        />
                                                    </Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='ownerEduQualification'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register}
                                                            placeholder='শিক্ষাগত যোগ্যতা'
                                                        />
                                                    </Td>
                                                </tr>
                                                <tr>
                                                    <Td>পেশাঃ </Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='tenantOccupation'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register}
                                                            placeholder='পেশা'
                                                        />
                                                    </Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='ownerOccupation'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register}
                                                            placeholder='পেশা'
                                                        />
                                                    </Td>
                                                </tr>
                                                <tr>
                                                    <Td>বৈবাহিক অবস্থাঃ </Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='tenantMaritalStatus'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register}
                                                            placeholder='বৈবাহিক অবস্থা'
                                                        />
                                                    </Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='ownerMaritalStatus'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register}
                                                            placeholder='বৈবাহিক অবস্থা'
                                                        />
                                                    </Td>
                                                </tr>
                                                <tr>
                                                    <Td>পরিপূর্ণ ঠিকানাঃ </Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='tenantFullAddress'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register}
                                                            placeholder='পূর্ণ ঠিকানা'
                                                        />
                                                    </Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='ownerFullAddress'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register}
                                                            placeholder='পূর্ণ ঠিকানা'
                                                        />
                                                    </Td>
                                                </tr>
                                                <tr>
                                                    <Td>জাতীয় পরিচয়পত্র নংঃ </Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='tenantNID'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register}
                                                            placeholder='জাতীয় পরিচয়পত্র নং'
                                                        />
                                                    </Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='ownerNID'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register}
                                                            placeholder='জাতীয় পরিচয়পত্র নং'
                                                        />
                                                    </Td>
                                                </tr>
                                                <tr>
                                                    <Td>ফোন নংঃ </Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='tenantPhone'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register({
                                                                pattern: /(^(01){1}[3456789]{1}(\d){8})$/
                                                            })}
                                                            placeholder='017XXXXXXXX'
                                                        />
                                                        {errors.tenantPhone && (
                                                            <span style={{ color: 'red' }}>
                                                                <WarningOutlined /> Please provide a
                                                                valid phone number
                                                            </span>
                                                        )}
                                                    </Td>
                                                    <Td>
                                                        <Input
                                                            isRequired
                                                            type='text'
                                                            name='ownerPhone'
                                                            w='100%'
                                                            border='none'
                                                            py={3}
                                                            px={2}
                                                            ref={register({
                                                                pattern: /(^(01){1}[3456789]{1}(\d){8})$/
                                                            })}
                                                            placeholder='017XXXXXXXX'
                                                        />
                                                        {errors.ownerPhone && (
                                                            <span style={{ color: 'red' }}>
                                                                <WarningOutlined /> Please provide a
                                                                valid phone number
                                                            </span>
                                                        )}
                                                    </Td>
                                                </tr>
                                            </table>
                                        </Box>
                                    </Flex>
                                    <Box mt={3}>
                                        <Text fontSize='lg' p={2} fontWeight={600}>
                                            পূর্ববর্তী বাড়ির তথ্যঃ{' '}
                                        </Text>
                                        <UnorderedList>
                                            <ListItem>
                                                <Flex w='100%'>
                                                    <Text fontSize='lg' p={2}>
                                                        বাড়িওয়ালার নামঃ{' '}
                                                    </Text>
                                                    <Input
                                                        isRequired
                                                        type='text'
                                                        name='prevOwner'
                                                        w='30%'
                                                        border='none'
                                                        py={3}
                                                        px={2}
                                                        ref={register}
                                                        placeholder='পূর্ববর্তী বাড়িওয়ালার নাম'
                                                    />
                                                </Flex>
                                            </ListItem>
                                            <ListItem>
                                                <Flex w='100%'>
                                                    <Text fontSize='lg' p={2}>
                                                        বাড়ির ঠিকানাঃ{' '}
                                                    </Text>
                                                    <Input
                                                        isRequired
                                                        type='text'
                                                        name='prevAddress'
                                                        w='30%'
                                                        border='none'
                                                        py={3}
                                                        px={2}
                                                        ref={register}
                                                        placeholder='পূর্ববর্তী বাড়ির ঠিকানা'
                                                    />
                                                </Flex>
                                            </ListItem>
                                            <ListItem>
                                                <Flex w='100%'>
                                                    <Text fontSize='lg' p={2}>
                                                        বাড়ি ছাড়ার কারনঃ{' '}
                                                    </Text>
                                                    <Input
                                                        isRequired
                                                        type='text'
                                                        name='causeOfLeave'
                                                        w='50%'
                                                        border='none'
                                                        py={3}
                                                        px={2}
                                                        ref={register}
                                                        placeholder='বাড়ি ছাড়ার কারন'
                                                    />
                                                </Flex>
                                            </ListItem>
                                        </UnorderedList>
                                    </Box>
                                    <Box mt={4}>
                                        <Text fontSize='lg' p={2} fontWeight={600}>
                                            জরুরী নির্দেশনাঃ{' '}
                                        </Text>
                                        <UnorderedList>
                                            <ListItem>
                                                <Text fontSize='lg' p={2}>
                                                    ২ কপি পাসপোর্ট সাইজ ছবি
                                                </Text>
                                            </ListItem>
                                            <ListItem>
                                                <Text fontSize='lg' p={2}>
                                                    জন্মনিবন্ধন সার্টিফিকেট
                                                </Text>
                                            </ListItem>
                                        </UnorderedList>
                                    </Box>
                                </Box>
                                <Box>
                                    <Flex w='100%'>
                                        <Text fontSize='lg' p={2}>
                                            ***বাড়ি ভাড়া নেয়ার
                                        </Text>
                                        <Input
                                            isRequired
                                            type='text'
                                            name='formSubmitDate'
                                            w='10%'
                                            border='none'
                                            py={3}
                                            px={2}
                                            ref={register}
                                            placeholder='00'
                                        />
                                        <Text fontSize='lg' p={2}>
                                            দিনের ভিতর ভাড়াটিয়া পরিপূর্ণ তথ্যসহিত এই ফরমটি বাড়িওয়ালা
                                            কাছে জমা দিয়ে বাধিত থাকিবেন।
                                        </Text>
                                    </Flex>
                                </Box>
                                <Box mt={4}>
                                    <Text fontSize='lg' p={2} fontWeight={600}>
                                        শর্তাবলীঃ{' '}
                                    </Text>
                                    <UnorderedList>
                                        <ListItem>
                                            <Flex w='100%'>
                                                <Text fontSize='lg' p={2}>
                                                    মাসের প্রথম
                                                </Text>
                                                <Input
                                                    isRequired
                                                    type='text'
                                                    name='billingDate'
                                                    w='5%'
                                                    border='none'
                                                    py={3}
                                                    px={2}
                                                    ref={register}
                                                    placeholder='00'
                                                />
                                                <Text fontSize='lg' p={2}>
                                                    দিনের ভিতর বাসার সমস্ত বিল এবং ভাড়া পরিশোধ করতে
                                                    হবে।
                                                </Text>
                                            </Flex>
                                        </ListItem>
                                        <ListItem>
                                            <Text fontSize='lg' p={2}>
                                                বাড়িওয়ালা প্রদত্ত যেকোন সম্পত্তি নষ্ট হলে ভাড়াটিয়া
                                                দায়ী হিসেবে গন্য হবে এবং ক্ষতিপূরণ দিতে বাধিত
                                                থাকিবেন।
                                            </Text>
                                        </ListItem>
                                        <ListItem>
                                            <Text fontSize='lg' p={2}>
                                                অনৈতিক বা অপরাধমূলক কোনো কর্মকাণ্ডের সাথে জড়িত থাকলে
                                                ভাড়াটিয়াকে তাৎক্ষনিকভাবে আইনানুযায়ী ব্যবস্থা নেয়া
                                                হবে।
                                            </Text>
                                        </ListItem>
                                        <ListItem>
                                            <Flex w='100%'>
                                                <Text fontSize='lg' p={2}>
                                                    বাসা ছাড়ার কমপক্ষে
                                                </Text>
                                                <Input
                                                    isRequired
                                                    type='text'
                                                    name='noticeDate'
                                                    w='5%'
                                                    border='none'
                                                    py={3}
                                                    px={2}
                                                    ref={register}
                                                    placeholder='00'
                                                />
                                                <Text fontSize='lg' p={2}>
                                                    দিন আগে বাড়িওয়ালাকে বিষয়টি অবগত করতে হবে।
                                                </Text>
                                            </Flex>
                                        </ListItem>
                                    </UnorderedList>
                                </Box>
                                <Box my={5}>
                                    <Flex justifyContent='space-between' w='100%'>
                                        <Box>
                                            <Text fontSize='lg' p={2} fontWeight={600}>
                                                বাড়িওয়ালা
                                            </Text>
                                            <Input
                                                isRequired
                                                type='text'
                                                name='ownerSignature'
                                                borderBottom='1px solid black'
                                                w='50%'
                                                border='none'
                                                // py={3}
                                                // px={2}
                                                // m={2}
                                                ref={register}
                                            />
                                            <Text fontSize='lg' p={2}>
                                                স্বাক্ষর (নাম)
                                            </Text>
                                        </Box>
                                        <Box>
                                            <Text fontSize='lg' p={2} fontWeight={600}>
                                                ভাড়াটিয়া
                                            </Text>
                                            <Input
                                                isRequired
                                                type='text'
                                                name='tenantSignature'
                                                borderBottom='1px solid black'
                                                w='50%'
                                                border='none'
                                                // py={3}
                                                // px={2}
                                                // m={2}
                                                ref={register}
                                            />
                                            <Text fontSize='lg' p={2}>
                                                স্বাক্ষর (নাম)
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </Box>
            </PageFormContainer>
        </ThemeProvider>
    )
}
