import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { getMonthNameAndYear } from '../../../utils/functions/functions'
const InvoiceHeader = (props) => {
    return (
        <>
            {props.invoiceForFlat && (
                <>
                    <Text style={styles.italicHeader}>{props.forWhom}</Text>
                    <Text style={styles.invoiceHeader}>{props.communityName}</Text>
                    <Text style={styles.invoiceHeaderSecond}>{props.invoiceHeader}</Text>
                    <Text style={styles.address}>{props.address}</Text>
                    <View style={styles.horizontalDivider}></View>
                    <View style={styles.flexItem}>
                        <Text style={styles.billingMonth}>
                            Month: {getMonthNameAndYear(props.data.month)}
                        </Text>
                        <Text style={styles.flatOwnerName}>
                            Flat Owner Name: {props.data.flat.contactPerson}
                        </Text>
                        <Text style={styles.flatOwnerName}>Flat No : {props.data.flatName}</Text>
                    </View>
                </>
            )}
            {props.invoiceForExpense && (
                <>
                    <Text style={styles.italicHeader}>{props.forWhom}</Text>
                    <Text style={styles.invoiceHeader}>{props.invoiceHeader}</Text>
                    <Text style={styles.address}>{props.data.building.address}</Text>
                    <Text style={styles.expenseBillMonth}>
                        Month: {getMonthNameAndYear(props.data.month)}
                    </Text>
                    <View style={styles.horizontalDivider}></View>
                    <Text style={styles.invoiceTitle}>Expense Bill Invoice</Text>
                </>
            )}
        </>
    )
}

export default InvoiceHeader

const styles = StyleSheet.create({
    flexItem: {
        marginTop: 'auto',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    italicHeader: {
        fontSize: 12,
        textAlign: 'center',
        fontStyle: 'oblique'
    },
    invoiceHeader: {
        fontSize: 14,
        textAlign: 'center'
    },
    invoiceHeaderSecond: {
        fontSize: 18,
        textAlign: 'center'
    },
    invoiceTitle: {
        marginTop: 5,
        fontSize: 16,
        textAlign: 'center',

    },
    address: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 4
    },
    billingMonth: {
        marginTop: 10,
        fontSize: 13,
        fontWeight: 'bold'
    },
    expenseBillMonth: {
        marginBottom: 10,
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    horizontalDivider: {
        width: '100%',
        borderBottomWidth: 1
    },
    flatOwnerName: {
        fontSize: 13,
        marginTop: 10
    }
})
