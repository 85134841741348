import { BaseModel } from 'sjs-base-model'
import moment from 'moment-timezone'

export default class BaseRequest extends BaseModel {
    data = {}
    region = moment.tz.guess()
    headers = {
        // authtoken: localStorage.getItem('AUTH_TOKEN'),
        jwtTokenHeader: localStorage.getItem('JWT_TOKEN'),
        'accept-language': 'en',
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }

    constructor(data) {
        super()
        if (data) this.data = data
        // console.log(JSON.parse(localStorage.getItem('perspective')).communityId)
        this.data['requesterCommunityId'] = localStorage.getItem('perspective')
            ? JSON.parse(localStorage.getItem('perspective')).communityId
                ? parseInt(JSON.parse(localStorage.getItem('perspective')).communityId)
                : 0
            : 0
        this.data['requesterBuildingId'] = localStorage.getItem('perspective')
            ? JSON.parse(localStorage.getItem('perspective')).buildingId
                ? parseInt(JSON.parse(localStorage.getItem('perspective')).buildingId)
                : 0
            : 0
        this.data['requesterFlatId'] = localStorage.getItem('perspective')
            ? JSON.parse(localStorage.getItem('perspective')).flatId
                ? parseInt(JSON.parse(localStorage.getItem('perspective')).flatId)
                : 0
            : 0
        this.data['requesterUserRole'] = localStorage.getItem('perspective')
            ? JSON.parse(localStorage.getItem('perspective')).userRole
                ? parseInt(JSON.parse(localStorage.getItem('perspective')).userRole)
                : 0
            : 0
        // this.data['requesterBuildingId'] = parseInt(JSON.parse(localStorage.getItem('perspective')).buildingId? (localStorage.getItem('perspective')).buildingId: 0)
        // this.data['requesterFlatId'] = parseInt(JSON.parse(localStorage.getItem('perspective')).flatId? (localStorage.getItem('perspective')).flatId: 0)
        // this.data['requesterUserRole'] = parseInt(JSON.parse(localStorage.getItem('perspective')).userRole? (localStorage.getItem('perspective')).userRole: 0)
    }
}
