import * as moment from 'moment'
export const splitData = (array) => {
    var data = []
    for (let index = 0; index < array.length; index++) {
        const splitName = array[index].toLowerCase()
        var a = splitName.split(' ')
        var b = ''
        for (let j = a.length - 1; j > -1; j--) {
            b = a[j] + ' ' + b
            for (let i = 0; i < b.length; i++) {
                data.push(b.slice(0, i + 1))
            }
        }
    }
    return data
}

export const splitAndPutData = (data, array) => {
    const splitName = array
    var a = splitName.split(' ')

    var b = ''
    for (let j = a.length - 1; j > -1; j--) {
        b = a[j] + ' ' + b
        for (let i = 0; i < b.length; i++) {
            data.push(b.slice(0, i + 1))
        }
    }
    return data
}

export const generateProfileData = (state) => {
    var data = {
        name: state.name,
        phone: state.phone,
        gender: state.gender,
        bday: state.selectedDate
    }

    return data
}

export const generateBuildingProfileData = (state) => {
    var data = {
        b_servicecharge: state.b_servicecharge
    }

    return data
}

export const generateGuardsData = (state) => {
    var data = {
        build_id: state.buildingId,
        comm_id: state.communityId,
        g_name: state.name,
        g_pass: '12345',
        g_nid: state.nid,
        g_org: state.org,
        g_phone: state.phone,
        g_address: state.address,
        g_array: splitData([state.name]).concat([state.phone])
    }
    if (state.join !== null && state.join !== undefined) {
        data.g_joining = state.join._d
    } else {
        data.g_joining = null
    }
    if (state.dob !== null && state.dob !== undefined) {
        data.g_bday = state.dob._d
    } else {
        data.g_bday = null
    }
    return data
}

export const generateCalculationData = (state) => {
    var data = {
        build_id: state.buildingId,
        comm_id: state.communityId,
        who_add: state.userId,
        amount: parseInt(state.amount, 10),
        where_cost: state.cost,
        which_month: state.selectedMonth.month
    }
    return data
}

export const generateWorkersData = (state) => {
    var data = {
        s_name: state.name,
        s_mail: '',
        s_phone: state.phone,
        type: state.type ? state.type : 'others',
        starttime: 0,
        endtime: 24,
        nid: state.nid ? state.nid : '',
        address: state.address ? state.address : [],
        s_array: splitData([state.name]).concat([state.phone]),
        s_bday: null
    }

    if (state.join !== null && state.join !== undefined) {
        data.experience = state.join._d
    } else {
        data.experience = null
    }

    return data
}

export const generateWorkerHistoryData = (state) => {
    var flatsId = []
    var flatsNo = []
    if (state.allFlats && state.allFlats > 0) {
        for (var i = 0; i < state.allFlats.length; i++) {
            if (state.flatStates[i]) {
                flatsId.push(state.allFlats[i].flat_id)
                flatsNo.push(state.allFlats[i].f_no)
            }
        }
    }

    var data = {
        build_id: state.buildingId,
        flatsId: flatsId,
        flatsNo: flatsNo
    }
    return data
}

export const getCurrentMonthName = (month) => {
    let months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    return months[parseInt(month)]
}

export const getMonthNameAndYear = (monthYear) => {
    let months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    const splitMonthYear = monthYear.split('/')
    const monthNumber = splitMonthYear[0]
    const monthName = months[monthNumber - 1]
    const monthNameWithYear = `${monthName} ${splitMonthYear[1]}`
    return monthNameWithYear
}
export const getMonthName = (monthYear) => {
    let months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'June',
        'July',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ]
    const splitMonthYear = monthYear.split('/')
    const monthNumber = splitMonthYear[0]
    const monthName = months[monthNumber - 1]

    return monthName;
}
export const removeNullValueObject = (object) => {
    Object.keys(object).forEach((key) => object[key] == null && delete object[key])
    return object
}
export const setCookie = (cname, cvalue, exdays) => {
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}
export const getCookie = (cname) => {
    var name = cname + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

export const getCurrentMonthWithYear = () => {
    const date = new Date()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    let monthString = month + '/' + year
    if (monthString.length === 6) monthString = '0' + monthString
    return monthString
}
export const getCurrentMonthRange = () => {
    var begin = moment().format('YYYY-MM-01')
    var end = moment().format('YYYY-MM-') + moment().daysInMonth()
    return [begin, end]
}
export const checkIsAmountZeroOrEmpty = (arr) => {
    // console.log(arr);
    const zeroAmountArray = Object.entries(arr).filter(
        (item) => parseInt(item[1].amount) <= 0 || item[1].amount === ''
    )
    console.log(zeroAmountArray)
    if (zeroAmountArray.length) {
        return false
    } else {
        return true
    }
}
export const getDayDiff = (d1, d2) => {
    const date1 = new Date(d1)
    const date2 = new Date(d2)
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
}

export const filterAddBillTypes = (dropdownItems, selectedValue) => {
    let arr = []

    for (let i = 0; i < selectedValue.length; i++) {
        for (let j = 0; j < dropdownItems.length; j++) {
            if (selectedValue[i] === dropdownItems[j].id) {
                arr.push(dropdownItems[j])
            }
        }
    }

    return arr
}
export const incomeExpenseSummary = (data) => {
    let expense = 0

    if (data.list && data.list.length > 0) {
        for (let j = 0; j < data.list.length; j++) {
            if (data.list[j].entryType === 'credit') {
                expense += data.list[j].amount
            }
        }
    }
    return [
        {
            title: `Start Balance of ${getCurrentMonthName(new Date().getMonth())}`,
            amount: data.startBalance || 0
        },
        { title: 'Expense', amount: expense || 0 },
        { title: 'Curr. Balance', amount: data.currentBalance || 0 }
    ]
}

export const billSummary = (data) => {
    return [
        { title: 'Total Bill', amount: parseFloat(data.totalBill) || 0 },
        { title: 'Due Bill', amount: parseFloat(data.dueBill) || 0 }
    ]
}
