import { DatePicker, Form } from 'antd'
import React from 'react'
import * as moment from 'moment'

const {RangePicker} = DatePicker
export default function TableFilterDateRangePicker(props) {
    const value = props.control.values[`${props.name}`]
    return (
        <RangePicker
            defaultValue={value ? [moment(value[0], "YYYY-MM-DD"),moment(value[1], "YYYY-MM-DD")] : undefined}
            placeholder={props.placeholder? [props.placeholder.startDate, props.placeholder.endDate] : ['Start date', 'End date']}
            format="YYYY-MM-DD"
            onChange={(date, dateString) => props.control.handleInputChange(props.name, dateString)}
        />
    )
}
