import BaseRequest from '../../../special/models/BaseRequest'

export default class AssignFingerPrintToUserRequest extends BaseRequest {
    fingerPrintRecordId = ''
    userId = ''

    constructor(data) {
        super()
        this.update(data)
    }
}
