import React from 'react'
import { withBaseIcon } from 'react-icons-kit'
import { building } from 'react-icons-kit/fa/building'
import { home } from 'react-icons-kit/fa/home'
import { users } from 'react-icons-kit/fa/users'
import { calendar } from 'react-icons-kit/fa/calendar'
import { calendarCheckO } from 'react-icons-kit/fa/calendarCheckO'
import { barChart } from 'react-icons-kit/fa/barChart'
import { calculator } from 'react-icons-kit/fa/calculator'
import { dashboard } from 'react-icons-kit/fa/dashboard'
import { userTimes } from 'react-icons-kit/fa/userTimes'
import { userPlus } from 'react-icons-kit/fa/userPlus'
import { userCheck } from 'react-icons-kit/icomoon/userCheck'
import { automobile } from 'react-icons-kit/fa/automobile'
import { arrows_plus } from 'react-icons-kit/linea/arrows_plus'
import { alignJustify } from 'react-icons-kit/fa/alignJustify'
import { userSecret } from 'react-icons-kit/fa/userSecret'
import { gear } from 'react-icons-kit/fa/gear'
import { creditCardAlt } from 'react-icons-kit/fa/creditCardAlt'
import { skyatlas } from 'react-icons-kit/fa/skyatlas'
import { plug } from 'react-icons-kit/fa/plug'
import { ticket } from 'react-icons-kit/fa/ticket'
import { plusSquare } from 'react-icons-kit/fa/plusSquare'
import { rotateRight } from 'react-icons-kit/fa/rotateRight'
import { credit } from 'react-icons-kit/entypo/credit'
import { shuffle } from 'react-icons-kit/ionicons/shuffle'
import { statsBars } from 'react-icons-kit/ionicons/statsBars'
import { table } from 'react-icons-kit/fa/table'
import { list } from 'react-icons-kit/fa/list'
import { person } from 'react-icons-kit/iconic/person'
import { androidSearch } from 'react-icons-kit/ionicons/androidSearch'
import { bank } from 'react-icons-kit/fa/bank'
import { columns } from 'react-icons-kit/fa/columns'
import { buildingO } from 'react-icons-kit/fa/buildingO'
import { trello } from 'react-icons-kit/fa/trello'
import { windowMaximize } from 'react-icons-kit/fa/windowMaximize'
import { child } from 'react-icons-kit/fa/child'
import { car } from 'react-icons-kit/fa/car'
import { motorcycle } from 'react-icons-kit/fa/motorcycle'
import { bus } from 'react-icons-kit/fa/bus'
import { truck } from 'react-icons-kit/fa/truck'
import { money } from 'react-icons-kit/fa/money'
import { folderPlus } from 'react-icons-kit/feather/folderPlus'
import { folderMinus } from 'react-icons-kit/feather/folderMinus'

import {
    CheckCircleOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    WarningOutlined
} from '@ant-design/icons'

// const NormalIcon = (props) => withBaseIcon({ size: 20, style: { color: props.color } })
const WhiteIconContainer = withBaseIcon({ size: 20, style: { color: '#fff' } })
// const GreenIconContainer = withBaseIcon({ size: 20, style: { color: '#14ab72' } })
// const RedIconContainer = withBaseIcon({ size: 20, style: { color: '#dc143c' } })
// const BlueIconContainer = withBaseIcon({ size: 20, style: { color: '#0079bf' } })
const WhiteIconContainerNavbar = withBaseIcon({ size: 16, style: { color: '#fff' } })
const BlackIconContainer = withBaseIcon({ size: 20, style: { color: '#1f2833' } })
const MoneyIconContainer = withBaseIcon({ size: 32, style: { color: '#1f2833' } })
const GreyIconContainer = withBaseIcon({ size: 12, style: { color: '#8d9091' } })

const GreenIconContainerSmall = withBaseIcon({ size: 16, style: { color: '#14ab72' } })
const BlueIconContainerSmall = withBaseIcon({ size: 16, style: { color: '#0079bf' } })
const WhiteIconContainerSmall = withBaseIcon({ size: 16, style: { color: '#fff' } })
const BlackIconContainerSmall = withBaseIcon({ size: 16, style: { color: '#1f2833' } })
// const GreyIconContainerSmall = withBaseIcon({ size: 16, style: { color: '#8d9091' } })
const RedIconContainerSmall = withBaseIcon({ size: 16, style: { color: '#dc143c' } })

export const MenuCollapseWhiteIcon = () => <WhiteIconContainerSmall icon={alignJustify} />
export const SearchWhiteIcon = () => <WhiteIconContainer icon={androidSearch} />

export const UserNotInsideWhiteIcon = () => <WhiteIconContainer icon={userTimes} />
export const UserInsideWhiteIcon = () => <WhiteIconContainer icon={userSecret} />
export const ChildrenIcon = () => <WhiteIconContainer icon={child} />
export const TotalVisitorWhiteIcon = () => <WhiteIconContainer icon={users} />
export const TotalUsersBlackIcon = () => <BlackIconContainer icon={users} />

export const CarBlackIcon = () => <BlackIconContainer icon={car} />
export const CarWhiteIcon = () => <WhiteIconContainer icon={car} />
export const BikeWhiteIcon = () => <WhiteIconContainer icon={motorcycle} />
export const BusWhiteIcon = () => <WhiteIconContainer icon={bus} />
export const TruckWhiteIcon = () => <WhiteIconContainer icon={truck} />

export const TotalBuildingIcon = () => <GreenIconContainerSmall icon={userTimes} />
export const TotalFlatIcon = () => <BlueIconContainerSmall icon={userSecret} />
export const TotalParkingIcon = () => <RedIconContainerSmall icon={automobile} />

export const TotalDebitWhiteIcon = () => <WhiteIconContainer icon={shuffle} />
export const TotalDebitBlackIcon = () => <BlackIconContainer icon={folderPlus} />
export const TotalCreditWhiteIcon = () => <WhiteIconContainer icon={credit} />
export const TotalCreditBlackIcon = () => <BlackIconContainer icon={folderMinus} />

export const TotalIncomeWhiteIcon = () => <WhiteIconContainer icon={statsBars} />

export const NoticeWhiteIcon = () => <WhiteIconContainer icon={table} />
export const ListWhiteIcon = () => <BlackIconContainer icon={list} />
export const AddAdminWhiteIcon = () => <WhiteIconContainer icon={person} />

export const TotalRequestWhiteIcon = () => <BlackIconContainerSmall icon={userPlus} />
export const RequestAcceptedWhiteIcon = () => <GreenIconContainerSmall icon={userCheck} />
export const RequestPendingWhiteIcon = () => <BlueIconContainerSmall icon={userSecret} />
export const RequestRejectedWhiteIcon = () => <RedIconContainerSmall icon={userTimes} />

export const TotalNoticeWhiteIcon = () => <WhiteIconContainer icon={columns} />
export const TotalNoticeBlackIcon = () => <BlackIconContainer icon={columns} />
export const UsersNoticeWhiteIcon = () => <WhiteIconContainer icon={users} />
export const GuardsNoticeWhiteIcon = () => <WhiteIconContainer icon={userSecret} />
export const CommonNoticeWhiteIcon = () => <WhiteIconContainer icon={buildingO} />

export const TotalFlatWhiteIcon = () => <WhiteIconContainer icon={buildingO} />
export const EmptyFlatWhiteIcon = () => <WhiteIconContainer icon={trello} />
export const OccupiedFlatWhiteIcon = () => <WhiteIconContainer icon={windowMaximize} />

export const RepairCostWhiteIcon = () => <WhiteIconContainerSmall icon={gear} />
export const MaintenanceCostWhiteIcon = () => <WhiteIconContainerSmall icon={plug} />
export const SalaryCostWhiteIcon = () => <WhiteIconContainerSmall icon={creditCardAlt} />
export const BillPayCostWhiteIcon = () => <WhiteIconContainerSmall icon={ticket} />
export const OtherCostWhiteIcon = () => <WhiteIconContainerSmall icon={skyatlas} />
export const TotalCostWhiteIcon = () => <WhiteIconContainerSmall icon={plusSquare} />

export const RefreshGreyIcon = () => <GreyIconContainer icon={rotateRight} />

export const ExpenseIcon = () => <WhiteIconContainer icon={creditCardAlt} />
export const DeviceIcon = () => <WhiteIconContainer icon={plug} />
export const VehicleIcon = () => <WhiteIconContainer icon={automobile} />
export const TotalVehicleIcon = () => <BlackIconContainer icon={automobile} />
export const BankIcon = () => <WhiteIconContainer icon={bank} />

export const AddUserWhiteIcon = () => <WhiteIconContainer icon={arrows_plus} />

export const CalenderIcon = () => <BlackIconContainer icon={calendar} />
export const CalenderIconWhite = () => <WhiteIconContainer icon={calendar} />
export const BuildingIconBlack = () => <BlackIconContainer icon={building} />
export const BuildingIconWhite = () => <WhiteIconContainer icon={building} />
export const MonthIcon = () => <BlackIconContainer icon={calendarCheckO} />
export const FlatIcon = () => <BlackIconContainer icon={home} />
export const MoneyIcon = () => <MoneyIconContainer icon={money} />
export const FlatIconWhite = () => <WhiteIconContainer icon={home} />

export const BarChart = () => <WhiteIconContainerNavbar icon={barChart} />
export const Group = () => <WhiteIconContainerNavbar icon={users} />
export const Calculator = () => <WhiteIconContainerNavbar icon={calculator} />
export const DashBoardIcon = () => <WhiteIconContainerNavbar icon={dashboard} />

export const CheckCircleGreenIcon = () => <CheckCircleOutlined style={{ color: 'green' }} />
export const CloseCircleRedIcon = () => <CloseCircleOutlined style={{ color: 'red' }} />

export const CheckGreenIcon = (props) => (
    <CheckOutlined
        style={{
            color: 'green',
            margin: props.margin ? props.margin : '',
            fontSize: props.fontSize ? props.fontSize : ''
        }}
    />
)
export const CloseRedIcon = (props) => (
    <CloseOutlined
        style={{
            color: 'red',
            margin: props.margin ? props.margin : '',
            fontSize: props.fontSize ? props.fontSize : '',
            position: 'relative',
            top: props.top ? props.top : ''
        }}
    />
)
export const WarningYellowIcon = (props) => (
    <WarningOutlined
        style={{
            color: 'yellow',
            margin: props.margin ? props.margin : '',
            fontSize: props.fontSize ? props.fontSize : '',
            position: 'relative',
            top: props.top ? props.top : ''
        }}
    />
)
