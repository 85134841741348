import { BaseModel } from 'sjs-base-model'
import moment from 'moment-timezone'

export default class BaseRequestWithoutPerspective extends BaseModel {
    data = {}
    region = moment.tz.guess()
    headers = {
        // authtoken: localStorage.getItem('AUTH_TOKEN'),
        jwtTokenHeader: localStorage.getItem('JWT_TOKEN'),
        'accept-language': 'en',
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }

    constructor(data) {
        super()
        if (data) this.data = data
    }
}
