import BaseReducer from '../../../utils/BaseReducer'
import CartAction from './CartAction'

export default class CartReducer extends BaseReducer {
    initialState = {
        cart: {},
        cartItems: {},
        cartDelete: {}
    };
    [CartAction.REQUEST_ADD_TO_CART_FINISHED](state, action) {
        return {
            ...state,
            cart: action.payload
        }
    }
    [CartAction.REQUEST_GET_ALL_CART_FINISHED](state, action) {
        return {
            ...state,
            cartItems: action.payload
        }
    }
    [CartAction.REQUEST_DELETE_CART_ITEM_FINISHED](state, action) {
        console.log("Action", action);
        return {
            ...state,
            cartDelete: action.payload
        }
    }
}
