import BaseRequest from '../../../special/models/BaseRequest'

export default class FunctionDetailsRequest extends BaseRequest {
    functionId = ''

    constructor(id) {
        super()
        this.functionId = id
    }
}
