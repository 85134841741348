import React, { Component } from 'react'
import firebase from '../../utils/firebase'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { StyledFirebaseAuth } from 'react-firebaseui'
import { Divider, Modal, Button } from 'antd'

class PhoneLogin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false
        }
        this.uiConfig = {
            signInFlow: 'popup',
            signInSuccessUrl: '/',
            signInOptions: [
                {
                    provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                    defaultCountry: 'BD'
                }
            ],
            callbacks: {
                signInSuccess: () => false
            }
        }
    }

    showModal = () => {
        this.setState({
            visible: true
        })
    }

    handleCancel = (e) => {
        this.setState({
            visible: false
        })
    }

    render() {
        return (
            <div>
                <Divider style={{fontSize:'20px'}}>Or</Divider>
                <Button className='phone-login-button button-block button' type='primary' onClick={this.showModal}>
                    Login with Phone
                </Button>
                <Modal
                    title='Rokkhi Admin Phone Login'
                    visible={this.state.visible}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
                </Modal>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

function mapStateToProps({}) {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(PhoneLogin)
