import { createSelector } from 'reselect'

export class FundSelector {
    static getFundsList = (state) => state.fund.fundList
    static getTransactionItemsList = (state) => state.fund.transactionList
    static getFundVouchersList = (state) => state.fund.fundVouchers
    static getBalanceSheetData = (state) => state.fund.balanceSheetData
}

export const makeSelectFundsList = createSelector(FundSelector.getFundsList, (fundList) =>
    fundList ? fundList.data : []
)

export const makeSelectFundVouchersList = createSelector(
    FundSelector.getFundVouchersList,
    (fundList) => (fundList ? fundList.data : [])
)

export const makeSelectIncomeData = createSelector(FundSelector.getBalanceSheetData, (fundList) =>
    fundList ? fundList.data.incomeData : []
)

export const makeSelectExpenseData = createSelector(FundSelector.getBalanceSheetData, (fundList) =>
    fundList ? fundList.data.expenseData : []
)

export const makeSelectTransactionItemsList = createSelector(
    FundSelector.getTransactionItemsList,
    (transactionItemList) =>
        transactionItemList
            ? transactionItemList.data.map((transactionItem) => {
                  return {
                      ...transactionItem,
                      typeName: transactionItem.createdBy ? transactionItem.createdBy.name : ''
                  }
              })
            : []
)
