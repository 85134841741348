import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Transfer } from 'antd'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import PrivilegeAction from '../../../stores/user-management/privilege/PrivilegeAction'
import FormInputDropDown from '../../component/form-input/FormInputDropDown'
import { DropDownConstants } from '../../../assets/constants/GeneralConstants'
import { makeSelectUserRolesList } from '../../../stores/user-management/privilege/PrivilegeSelector'
import UserAction from '../../../stores/user-management/user/UserAction'
import { makeSelectRolesOfUser } from '../../../stores/user-management/user/UserSelector'
import GetUserDetailsRequest from '../../../stores/user-management/user/requests/GetUserDetailsRequest'
import AssignRolesToUserRequest from '../../../stores/user-management/privilege/models/AssignRolesToUserRequest'
import BaseRequest from '../../../stores/special/models/BaseRequest'
import { PageBasicContainer } from '../../pages/PageStyles'
import GetFlatsRequest from '../../../stores/property-management/flat/request/GetFlatsRequest'
import FlatAction from '../../../stores/property-management/flat/FlatAction'

const INITIAL_STATE = {
    userId: '',
    buildingId: '',
    flatId: ''
}

const VALIDATION_SCHEMA = Yup.object({})

export default function FormAssignFunctionToRole(props) {
    const dispatch = useDispatch()

    const [targetKeys, setTargetKeys] = useState([])
    const [sourceItems, setSourceItemsWithKey] = useState([])

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [
            PrivilegeAction.REQUEST_ASSIGN_ROLE_TO_USER,
            PrivilegeAction.REQUEST_GET_USER_ROLES,
            UserAction.REQUEST_GET_ROLES_OF_USER
        ])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, PrivilegeAction.REQUEST_ASSIGN_ROLE_TO_USER)
    )

    const allUserRoles = useSelector(makeSelectUserRolesList)
    const rolesOfUser = useSelector(makeSelectRolesOfUser)

    useEffect(() => {
        dispatch(PrivilegeAction._requestGetUserRoles(new BaseRequest(values)))
    }, [])

    useEffect(() => {
        if (values.userId)
            dispatch(UserAction._requestGetRolesOfUser(new GetUserDetailsRequest(values)))
    }, [isFinished])

    useEffect(() => {
        if (values.userId)
            dispatch(UserAction._requestGetRolesOfUser(new GetUserDetailsRequest(values)))
    }, [values.userId])



    useEffect(() => {
        setSourceItemsWithKey(
            allUserRoles.map((item) => {
                return { ...item, key: item.id, title: item.name }
            })
        )
    }, [allUserRoles])

    useEffect(() => {
        console.log("ROlesOfUser", rolesOfUser);
        setTargetKeys(rolesOfUser.map((item) => item.id))
    }, [rolesOfUser])

    const filterOption = (inputValue, option) =>
        option.title.toLoweCase().indexOf(inputValue.toLoweCase()) > -1

    const handleChange = (targetKeys) =>{
        console.log("ROLES", targetKeys);
        dispatch(
            PrivilegeAction._requestAssignRoleToUser(
                new AssignRolesToUserRequest(values.userId, targetKeys, values.buildingId, values.flatId)
            )
        )
    }
    useEffect(() => {
        if (values.buildingId)
        dispatch(FlatAction._requestGetFlatList(new GetFlatsRequest(values)))
    }, [values.buildingId])

    return (
        <PageBasicContainer className='card'>
            <h2>Assign Role to User </h2>

            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridColumnGap: '10px'
                }}
            >
                <FormInputDropDown
                    name={'userId'}
                    label={'Select User'}
                    control={control}
                    dropDownFor={DropDownConstants.TYPE_USERS}
                />
                <FormInputDropDown
                    name={'buildingId'}
                    label={'Select Building'}
                    control={control}
                    dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                />

                <FormInputDropDown
                    flatOption={true}
                    name={'flatId'}
                    label={'Select Flat'}
                    control={control}
                    dropDownFor={DropDownConstants.TYPE_FLATS_WITHOUT_ALL}
                />
            </div>

            <div style={{ margin: '20px' }}>
                <Transfer
                    disabled={isRequesting}
                    dataSource={sourceItems.slice(1)}
                    showSearch
                    filterOption={filterOption}
                    targetKeys={targetKeys}
                    onChange={handleChange}
                    render={(item) => item.name}
                />
            </div>
        </PageBasicContainer>
    )
}
