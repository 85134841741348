import React, { useEffect, useState } from 'react'
import { Page, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer'
import InvoiceHeader from './InvoiceHeader'
import InvoiceFooter from './InvoiceFooter'
import { ButtonMildGreen } from '../buttons/CustomButtons'
import { getMonthNameAndYear } from '../../../utils/functions/functions'
import InvoiceBody from './InvoiceBody'
const ExpenseInvoice = (props) => {
    const [ready, setReady] = useState(false)
    const [click, setClick] = useState(false)
   useEffect(()=>{
     click && document.getElementById('autoClickBtn').click()
     setReady(false)
     setClick(false)
   },[click])
    return !ready ? (
        <ButtonMildGreen  key={props.data.id} onClick={() => setReady(true)} text={'PDF'} icon={'printer'} />
    ) : (
        props.data && (
            <PDFDownloadLink
                document={<PDFDocument data={props.data} key={props.data.id} />}
                fileName={`Invoice for ${props.data.building.name} ${getMonthNameAndYear(props.data.month)}`}
            >
                {({ blob, url, loading, error }) =>
                    loading ? (
                      <ButtonMildGreen text={'Loading'} icon={'printer'} />
                    ) : (
                        <a target='_blank' href={url} id="autoClickBtn" rel="noreferrer">
                            <ButtonMildGreen text={'PDF'} icon={'printer'} />
                            {setClick(true)}
                        </a>
                    )
                }
            </PDFDownloadLink>
        )
    )
}

export default ExpenseInvoice

function PDFDocument(props) {
    return (
        <Document>
            <Page size='A4' style={styles.body}>
                <View wrap={false}>
                    <InvoiceHeader
                        invoiceForExpense={true}
                        forWhom={'Management Copy'}
                        data={props.data}
                        invoiceHeader={props.data.building.name}
                    />
                    <InvoiceBody data={props.data} invoiceForExpense={true} />
                    <InvoiceFooter
                        item={['Community Authority', 'Building Authority', 'Prepared By']}
                    />
                </View>
                <View style={styles.horizontalDivider}></View>
                <View wrap={false}>
                    <InvoiceHeader
                        invoiceForExpense={true}
                        forWhom={'User Copy'}
                        data={props.data}
                        invoiceHeader={props.data.building.name}
                    />
                    <InvoiceBody invoiceForExpense={true} data={props.data} />
                    <InvoiceFooter
                        item={['Community Authority', 'Building Authority', 'Prepared By']}
                    />
                </View>
            </Page>
        </Document>
    )
}

const styles = StyleSheet.create({
    body: {
        fontFamily: 'Times-Roman',
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 25
    },
    horizontalDivider: {
        paddingTop: 15,
        marginBottom: 10
    }
})
