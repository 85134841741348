import React from 'react'
import { PageBasicContainer } from '../pages/PageStyles'
import FailedImg from '../../assets/images/transition-failed-once.gif'

const PaymentFail = ({ orderId }) => {
    return (
        <PageBasicContainer>
            <div style={{ textAlign: 'center', margin: '50px' }}>
                <img src={FailedImg} alt='' />
                <h1 style={{ marginTop: '25px', fontSize: '32px' }}>Transaction Failed!</h1>
                <h1>ORDER ID: {orderId}</h1>
            </div>
        </PageBasicContainer>
    )
}

export default PaymentFail
