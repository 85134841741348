import React, { useState } from 'react'
import firebase from '../../utils/firebase'
import { Button, Icon, Input } from 'antd'
import { message } from 'antd'
import PrimaryTopBar from '../component/navigation/PrimaryTopbar'
import './signin.scss'
import { LeftOutlined } from '@ant-design/icons'

const ResetPassword = (props) => {
    const [email, setEmail] = useState('')
    const [loading,setLoading] = useState(false)
    const resetPassword = (e) => {
        let actionCodeSettings = {
            // After password reset, the user will be give the ability to go back
            // to this page.
            url: 'https://home.web.rokkhi.com/',
            handleCodeInApp: false
          };
        setLoading(true) 
        e.preventDefault()
        firebase
            .auth()
            .sendPasswordResetEmail(email,actionCodeSettings)
            .then(() => {
                message.success('An email has been sent to your mail with password reset link.')
                setEmail('')
                setLoading(false) 
            })
            .catch((e) => {
                message.error(e.message)
                setLoading(false) 
            })
    }

    return (
        <>
            <PrimaryTopBar />
            <div className='signin-container'>
                <div class='form  signin-tab-group'>
                     <div style={{display:'flex', justifyContent:'space-between'}}>
                     <h1>Reset Password</h1>
                        <Button type="primary" danger style={{backgroundColor:'red', borderColor:'red', fontSize:'18px'}} onClick={props.forgotPassword}><LeftOutlined /> Cancel </Button>
                     </div>
                        <Input
                            size='large'
                            name='email'
                            style={{ margin: '10px 0px' }}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            prefix={<Icon type='mail' />}
                            placeholder='Email'
                        />
                         <Button
                                    loading={loading}
                                    type='submit'
                                    size='default'
                                    onClick={resetPassword}
                                    className='button button-block'
                         > Reset Password</Button>
                        
                </div>
            </div>
        </>
    )
}

export default ResetPassword
