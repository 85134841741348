import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import PreAuthorizationAction from './PreAuthorizationAction'

const PreAuthorizationPostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case PreAuthorizationAction.REQUEST_ADD_TO_PRE_AUTHORIZED_LIST_FINISHED:
                next(MiscAction._showModalSuccess('Entry Created Successfully'))
                break
            case PreAuthorizationAction.REQUEST_DELETE_FROM_PRE_AUTHORIZED_LIST_FINISHED:
                next(MiscAction._showModalSuccess('Entry Deleted Successfully'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default PreAuthorizationPostEffect
