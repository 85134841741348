import React from 'react'
import { Select } from 'antd'
import { Form } from 'antd'
import { filterAddBillTypes } from '../../../utils/functions/functions'

const { Option } = Select

export default function FormMultipleBillTypeDropdown(props) {
    const isTouched = props.control.touched[`${props.name}`]
    const error = props.control.errors[`${props.name}`]

    const { dropDownItems } = props

    return (
        <Form.Item
            label={props.label}
            hasFeedback
            validateStatus={isTouched ? (error ? 'error' : 'success') : ''}
            help={isTouched ? error : ''}
        >
            <Select
                mode='multiple'
                placeholder={props.label}
                showSearch
                style={{ width: '100%' }}
                optionFilterProp='children'
                optionLabelProp='label'
                size={'large'}
                allowClear={true}
                onChange={(selectedValue) => {
                    props.setSelectedBillItem([])
                    let filteredArray = filterAddBillTypes(dropDownItems, selectedValue)
                    return props.setSelectedBillItem((prevState) => {
                        return [...filteredArray]
                    })
                }}
                filterOption={(inputValue, option) =>
                    option.filter((obj) => !props.selectedBillItem.includes(obj))
                }
                // loading={isRequesting}
                // notFoundContent={isRequesting ? <p style={{textAlign:'center', fontWeight:'bold'}}>Loading Items...</p> : <Empty size={"small"} image={Empty.PRESENTED_IMAGE_SIMPLE} className={'ant-empty-small'}/>}
            >
                {dropDownItems &&
                    dropDownItems.length > 0 &&
                    dropDownItems.map((item, index) => (
                        <Option key={item.id} value={item.value} label={item.label}>
                            {item.label}
                        </Option>
                    ))}
            </Select>
        </Form.Item>
    )
}
