import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColorConstants, PaymentStatusConstants } from '../../../assets/constants/GeneralConstants'
import { Tabs } from 'antd'
import {
    CardInfoLeftColoredSvgIcon,
    FormTitleWithSvgIcon
} from '../../component/decoration/CardSingleInfo'
import { DataBuilderUtility } from '../../../utils/DataBuilderUtility'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { makeSelectBillVouchersList } from '../../../stores/money-management/bill/BillSelector'
import BillAction from '../../../stores/money-management/bill/BillAction'
import TableDataFilter from '../../tables/TableDataFilter'
import TableBillInvoices from '../../tables/bill/TableBillInvoices'
import { GetBillItemsRequest } from '../../../stores/money-management/bill/requests/GetBillItemsRequest'
import { PageBasicContainer, PageDecorationContainer, PageMainBarContainer } from '../PageStyles'
import TakaIcon from '../../../assets/images/taka-small.png'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../../../assets/constants/routes'
import { ButtonFillPurple } from '../../component/buttons/CustomButtons'

const { TabPane } = Tabs

export default function PageBillDetails(props) {
    const dispatch = useDispatch()
    const [decorationArrValues, setDecorationArrValues] = useState([])
    const [paymentStatus, setPaymentStatus] = useState(PaymentStatusConstants.ALL)
    const [filterValues, setFilterValues] = useState({})
    const isFinishedCreation = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_GENERATE_BILL_FOR_FLAT)
    )
    const isFinishedCollection = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_COLLECT_BILL)
    )

    const isFinishedDelete = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_DELETE_BILL_ITEM)
    )

    const dataList = useSelector(makeSelectBillVouchersList)

    const changePaymentStatus = (paymentStatus) => setPaymentStatus(paymentStatus)

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        if (filterValues.buildingId) {
            dispatch(
                BillAction._requestGetBillItems(
                    new GetBillItemsRequest(filterValues, paymentStatus)
                )
            )
        }
    }, [filterValues, paymentStatus, isFinishedCreation, isFinishedCollection, isFinishedDelete])

    useEffect(() => {
        setDecorationArrValues(DataBuilderUtility._getBillDetailsDataOverview(dataList))
    }, [dataList])

    const decor = decorationArrValues.map((item) => (
        <CardInfoLeftColoredSvgIcon key={Math.random()} data={item} />
    ))

    return (
        <PageBasicContainer>
            <PageDecorationContainer paddingTop={'0px'} item={3}>
                {decor}
            </PageDecorationContainer>
            <PageMainBarContainer>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '30px'
                    }}
                >
                    <FormTitleWithSvgIcon
                        title={'All Bills'}
                        subTitle={'Select Building and Month to View Created Bill List'}
                        icon={TakaIcon}
                        backgroundColor={ColorConstants.RED}
                    />
                    <div>
                        <Link to={AppRoutes.BILL_GENERATION}>
                            <ButtonFillPurple
                                icon={'edit'}
                                text={'Generate Bill Invoice'}
                                size={'large'}
                            />
                        </Link>
                    </div>
                </div>
                <TableDataFilter
                    setDefaultMonth={true}
                    showBuildingFilter={true}
                    showMonthFilter={true}
                    showFlatFilter={true}
                    sendDataToParent={receiveFilterData}
                />
                <Tabs
                    defaultActiveKey={PaymentStatusConstants.ALL}
                    onChange={changePaymentStatus}
                    type='card'
                >
                    <TabPane tab='ALL' key={PaymentStatusConstants.ALL} />
                    <TabPane tab='Paid' key={PaymentStatusConstants.PAID} />
                    <TabPane tab='Partial' key={PaymentStatusConstants.PARTIAL_PAID} />
                    <TabPane tab='Unpaid' key={PaymentStatusConstants.UNPAID} />
                </Tabs>
                <TableBillInvoices paymentStatus={paymentStatus} />
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '20px',
                            backgroundColor: '#FFF6F4',
                            padding: '15px 20px'
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h3 style={{ margin: '0 10px 0 0' }}>Total </h3>{' '}
                            <span>{dataList ? dataList.length : ''} Flat(s)</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h3 style={{ margin: '0 10px 0 0' }}>Fully Paid</h3>{' '}
                            <span>
                                {
                                    dataList.filter(
                                        (item) => item.paymentStatus === PaymentStatusConstants.PAID
                                    ).length
                                }{' '}
                                Flat(s)
                            </span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h3 style={{ margin: '0 10px 0 0' }}>Partially Paid </h3>{' '}
                            <span>
                                {
                                    dataList.filter(
                                        (item) =>
                                            item.paymentStatus ===
                                            PaymentStatusConstants.PARTIAL_PAID
                                    ).length
                                }{' '}
                                Flat(s)
                            </span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h3 style={{ margin: '0 10px 0 0' }}>Fully Unpaid </h3>{' '}
                            <span>
                                {
                                    dataList.filter(
                                        (item) =>
                                            item.paymentStatus === PaymentStatusConstants.UNPAID
                                    ).length
                                }{' '}
                                Flat(s)
                            </span>
                        </div>
                    </div>
                </>
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
