import React from 'react'

function FlatInvoicePdfHeader({ data, forWhom }) {
    return (
        <div>
            <table style={{ width: '100%' }}>
                <thead>
                    <th></th>
                </thead>
                <tbody>
                    <tr style={{ textAlign: 'center', fontSize: '20px' }}>
                        <td>{forWhom}</td>
                    </tr>
                    <tr style={{ textAlign: 'center', fontSize: '26px' }}>
                        <td>{data.building.name}</td>
                    </tr>
                    <hr></hr>
                    <tr
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '16px'
                        }}
                    >
                        <td>
                            Month: <span style={{ fontWeight: '600' }}>{data.month}</span>
                        </td>
                        <td>
                            Owner:{' '}
                            <span style={{ fontWeight: '600' }}>{data.flat.contactPerson}</span>{' '}
                        </td>
                        <td>
                            Flat No: <span style={{ fontWeight: '600' }}>{data.flatName}</span>{' '}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default FlatInvoicePdfHeader
