import MiscAction from '../../misc/MiscAction'
import HttpErrorResponseModel from '../../special/models/HttpErrorResponseModel'
import UserAction from './UserAction'

const UserPostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel

    if (!isError) {
        switch (action.type) {
            case UserAction.REQUEST_REGISTER_USER_FINISHED:
                next(MiscAction._showModalSuccess('User Registration Successful'))
                break
            case UserAction.REQUEST_DELETE_USER_FINISHED:
                next(MiscAction._showModalSuccess('User Profile Successfully deleted'))
                break
            default:
                break
        }
    }

    return next(action)
}
export default UserPostEffect
