import { ApiEndpoint } from '../../../assets/constants/ApiEndpoint'
import EffectUtility from '../../../utils/EffectUtility'
import ServerResponse from '../../special/models/ServerResponse'
import BaseRequest from '../../special/models/BaseRequest'

export default class FingerPrintEffect {
    static async _requestRegisterFingerPrintDevice(data) {
        const endPoint = ApiEndpoint.fingerPrint.registerFingerPrintDevice
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
    static async _requestDeleteFingerPrintDevice(data) {
        const endPoint = ApiEndpoint.fingerPrint.deleteFingerPrintDevice
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetFingerPrintDevices(data) {
        const endPoint = ApiEndpoint.fingerPrint.getFingerPrintDevices
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestGetFingerprints(data) {
        const endPoint = ApiEndpoint.fingerPrint.getRegisteredFingerPrints
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }

    static async _requestAssignFingerprintToUser(data) {
        const endPoint = ApiEndpoint.fingerPrint.assignFingerPrintToUser
        return EffectUtility._postToModel(ServerResponse, endPoint, new BaseRequest(data))
    }
}
