import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import TableDataFilter from '../../tables/TableDataFilter'
import { PageBasicContainer, PageMainBarContainer } from '../PageStyles'
import ReportAction from '../../../stores/reporting/ReportAction'
import LedgerReportRequest from '../../../stores/reporting/requests/LedgerReportRequest'
import TableExpenseReport from '../../tables/report/TableExpenseReport'

export default function PageExpenseReport(props) {
    const dispatch = useDispatch()
    const [filterValues, setFilterValues] = useState({})

    const receiveFilterData = (filterValues) => setFilterValues(filterValues)

    useEffect(() => {
        if (filterValues.buildingId) {
            dispatch(ReportAction._requestGetExpenseReport(new LedgerReportRequest(filterValues)))
        }
    }, [filterValues])

    return (
        <PageBasicContainer>
            <PageMainBarContainer>
                <h2>Expense Report</h2>
                <TableDataFilter
                    showBuildingFilter={true}
                    showDateRangeFilter={true}
                    sendDataToParent={receiveFilterData}
                />
                <TableExpenseReport />
            </PageMainBarContainer>
        </PageBasicContainer>
    )
}
