import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { ButtonFillBlue } from '../component/buttons/CustomButtons'
import * as Yup from 'yup'
import useFormInputValidation from '../component/form-input/useFormInputValidation'
import { DropDownConstants } from '../../assets/constants/GeneralConstants'
import FormInputDropdown from '../component/form-input/FormInputDropDown'
import BillAction from '../../stores/money-management/bill/BillAction'
import FlatAction from '../../stores/property-management/flat/FlatAction'
import GetFlatsRequest from '../../stores/property-management/flat/request/GetFlatsRequest'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../stores/special/finished/FinishedSelector'
import { AssignBillPackageRequest } from '../../stores/money-management/bill/requests/AssignBillPackageRequest'
import ButtonFormSubmitIcon from '../forms/ButtonFormSubmitIcon'

const INITIAL_STATE = {
    buildingId: '',
    flatId: '',
    packageId: ''
}

const VALIDATION_SCHEMA = Yup.object({
    //packageId: Yup.string().required('Please Select Package'),
    flatId: Yup.string().required('Please select Flat')
})

export default function ModalAssignBillPackage({ packageId }) {
    const dispatch = useDispatch()
    const [visibility, setVisibility] = useState(false)
    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [BillAction.REQUEST_ASSIGN_PACKAGE])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, [BillAction.REQUEST_ASSIGN_PACKAGE])
    )

    useEffect(() => {
        if (isFinished) setVisibility(false)
    }, [isFinished])

    useEffect(() => {
        if (control.values.buildingId)
            dispatch(FlatAction._requestGetFlatList(new GetFlatsRequest(control.values)))
    }, [control.values.buildingId])

    const onSubmit = () => {
        values['packageId'] = packageId
        dispatch(BillAction._requestAssignBillPackage(new AssignBillPackageRequest(values)))
    }

    return (
        <>
            <ButtonFillBlue
                icon={'border-outer'}
                text={'Assign'}
                onClick={() => setVisibility(true)}
            />
            <Modal
                title={`Assign Package to Flat`}
                centered
                visible={visibility}
                onCancel={() => setVisibility(false)}
                onOk={() => setVisibility(false)}
                okText={'Close'}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                {/* <FormIconContainer> */}
                <FormInputDropdown
                    label={'Select Building'}
                    control={control}
                    name={'buildingId'}
                    dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                />
                <FormInputDropdown
                    label={'Select Flat'}
                    control={control}
                    name={'flatId'}
                    dropDownFor={DropDownConstants.TYPE_FLATS}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ButtonFormSubmitIcon
                        icon='plus'
                        title={'Assign'}
                        isRequesting={isRequesting}
                        control={control}
                        onSubmit={onSubmit}
                    />
                </div>
            </Modal>
        </>
    )
}
