import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ModalDeleteConfirmation from '../../modals/ModalDeleteConfirmation'
import ComponentTable from '../ComponentTable'
import ExpenseAction from '../../../stores/money-management/expense/ExpenseAction'
import { makeSelectExpenseTypesList } from '../../../stores/money-management/expense/ExpenseSelector'
import { DeleteExpenseTypeRequest } from '../../../stores/money-management/expense/requests/type/DeleteExpenseTypeRequest'

export default function TableExpenseType(props) {
    const dispatch = useDispatch()
    const dataList = useSelector(makeSelectExpenseTypesList)
    const isRequesting = useSelector((state) => selectRequesting(state, [ExpenseAction.REQUEST_GET_EXPENSE_TYPES]))

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Code',
            dataIndex: 'code'
        },
        {
            title: 'Description',
            dataIndex: 'description'
        },
        {
            title: 'Default Amount',
            dataIndex: 'defaultAmount'
        },
        {
            title: 'Date',
            render: (text, record) => (
                <div> {record.createdDate && new Date(record.createdDate).toLocaleDateString()} </div>
            )
        },
        {
            title: '',
            align: 'center',
            render: (text, record) => (
                <ModalDeleteConfirmation
                    confirmationText={'Are you sure you want to delete this type ?'}
                    onConfirm={() =>
                        dispatch(ExpenseAction._requestDeleteExpenseType(new DeleteExpenseTypeRequest(record)))
                    }
                />
            )
        }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
