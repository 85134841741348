import * as React from 'react'
import { connect } from 'react-redux'
import ToastsAction from '../../../stores/special/toasts/ToastsAction'
import cogoToast from 'cogo-toast'

const mapStateToProps = (state, ownProps) => ({})

// const close = () => {}

const openNotification = (type, message) => {
    // const key = `open${Date.now()}`
    // const btn = (
    //     <Button type='alert' size='small' onClick={() => notification.close(key)}>
    //         Close
    //     </Button>
    // )
    // notification.open({
    //     message: message,
    //     description: '',
    //     btn,
    //     key,
    //     onClose: close
    // })

    cogoToast.error(message)
}

class ToastCard extends React.Component {
    componentDidMount() {
        const { item } = this.props
        openNotification(item.type, item.message)
        this.props.dispatch(ToastsAction.removeById(item.id)) // removing this toast so that it don't reappear.
    }

    render() {
        return <div></div>
    }

    _onClickRemoveNotification = (event, data) => {
        this.props.dispatch(ToastsAction.removeById(this.props.item.id))
    }
}

export { ToastCard as Unconnected }
export default connect(mapStateToProps)(ToastCard)
