import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import ButtonFormSubmit from '../ButtonFormSubmit'
import FormInputDropDown from '../../component/form-input/FormInputDropDown'
import { ColorConstants, DropDownConstants } from '../../../assets/constants/GeneralConstants'
import FlatAction from '../../../stores/property-management/flat/FlatAction'
import GetFlatsRequest from '../../../stores/property-management/flat/request/GetFlatsRequest'
import ComplainAction from '../../../stores/admin-management/complain/ComplainAction'
import CreateComplainRequest from '../../../stores/admin-management/complain/requests/CreateComplainRequest'
import { FormInputTextArea } from '../../component/form-input/FormInputTextArea'
import { FormTitleWithIcon } from '../../component/decoration/CardSingleInfo'
import { NoticeWhiteIcon } from '../../misc/IconsProvider'
import { FormIconContainer, FormItemContainer } from '../FormStyles'

const INITIAL_STATE = {
    title: '',
    body: '',
    buildingId: '',
    flatId: ''
}

const VALIDATION_SCHEMA = Yup.object({
    title: Yup.string()
        .required('Role Name is Required')
        .min(3, 'Minimum 3 Characters')
        .max(40, 'Max 40 Characters'),
    body: Yup.string().max(300, 'Maximum 300 Characters'),
    flatId: Yup.string().required('Flat is Required'),
    buildingId: Yup.string().required('Building is Required')
})

export default function FormCreateComplain() {
    const dispatch = useDispatch()

    const { control, values } = useFormInputValidation(INITIAL_STATE, VALIDATION_SCHEMA)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [ComplainAction.REQUEST_SUBMIT_COMPLAIN])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, ComplainAction.REQUEST_SUBMIT_COMPLAIN)
    )

    useEffect(() => control.resetData(), [isFinished])

    const onSubmit = () => {
        dispatch(ComplainAction._requestSubmitComplain(new CreateComplainRequest(values)))
    }

    useEffect(() => {
        if (control.values.buildingId) dispatch(FlatAction._requestGetFlatList(new GetFlatsRequest(control.values)))
        control.setValue('flatId', '')
    }, [control.values.buildingId])

    return (
        <FormIconContainer>
            <FormTitleWithIcon
                icon={<NoticeWhiteIcon />}
                color={ColorConstants.GREEN1}
                formTitle={'Add New Complain'}
            />

            <FormItemContainer>
                <FormInputDropDown
                    label={'Select Building'}
                    control={control}
                    name={'buildingId'}
                    dropDownFor={DropDownConstants.TYPE_BUILDINGS}
                />
                <FormInputDropDown
                    label={'Select Flat'}
                    control={control}
                    name={'flatId'}
                    dropDownFor={DropDownConstants.TYPE_FLATS_WITHOUT_ALL}
                />
            </FormItemContainer>
            <FormInputText label={'Title'} control={control} name={'title'} />
            <FormInputTextArea label={'Description'} control={control} name={'body'} />
            <ButtonFormSubmit isRequesting={isRequesting} control={control} onSubmit={onSubmit} />
        </FormIconContainer>
    )
}
