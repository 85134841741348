import React from 'react'
import logo from '../../../assets/images/rokkhi_logo.png'
import LanguageSwitcherButton from '../language-switcher/LanguageSwitcherButton'

export default function PrimaryTopBar(props) {

    return (
        <div className={'app-topbar'}>
            <div style={{justifySelf:'start'}}><img style={{height:'7vh', objectFit:'contain',textAlign:'start'}} src={logo} alt="rokkhi-logo"/> </div>
            <div></div>
            <LanguageSwitcherButton />
            
        </div>
    )
}
