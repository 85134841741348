import BaseReducer from "../../../utils/BaseReducer";
import DirectPaymentAction from "./DirectPaymentAction";


export default class DirectPaymentReducer extends BaseReducer {
    initialState = {
        directPayment: {}
    };

    [DirectPaymentAction.REQUEST_DYNAMIC_PAYMENT_FINISHED](state, action) {
        return {
            ...state,
            directPayment: action.payload
        }
    }
}