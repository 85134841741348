import React from 'react'
import { connect } from 'react-redux'
import ToastCard from './ToastCard'

const mapStateToProps = (state, ownProps) => ({
    toasts: state.toasts.items
})

class Toasts extends React.Component {
    render() {
        if (this.props.toasts.length > 0) {
            const message = this.props.toasts[this.props.toasts.length - 1].message
            const id = this.props.toasts[this.props.toasts.length - 1].id
            const code = this.props.toasts[this.props.toasts.length - 1].code
        }

        const { toasts } = this.props

        if (toasts.length === 0) {
            return null
        }

        return (
            <div>
                {toasts.map((model) => (
                    <ToastCard key={model.id} item={model} />
                ))}
            </div>
        )
    }
}

export { Toasts as Unconnected }
export default connect(mapStateToProps)(Toasts)
