import BaseRequest from '../../../../special/models/BaseRequest'

export class CreateExpenseTypeRequest extends BaseRequest {
    name = ''
    code = ''
    description = ''
    defaultAmount = 0
    buildingId = ''

    constructor(data) {
        super()
        this.update(data)
        this.defaultAmount = parseInt(data.defaultAmount)
    }
}
