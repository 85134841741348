import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import ComponentTable from '../ComponentTable'
import UserAction from '../../../stores/user-management/user/UserAction'
import ModalImage from '../../modals/ModalImage'
import { GreenTag, RedTag } from '../../component/misc/Tags'
import { ButtonMildGreen } from '../../component/buttons/CustomButtons'
import { AppRoutes } from '../../../assets/constants/routes'
import { push } from 'connected-react-router'
import { makeSelectUsersFormList } from '../../../stores/admin-management/forms/FormsSelector'
import FormsAction from '../../../stores/admin-management/forms/FormsAction'
import ModalPoliceFormDetails from '../../modals/ModalPoliceFormDetails'
import ModalTenantOwnerForm from '../../modals/ModalTenantOwnerForm'
import { encode } from 'base-64'
import ModalTenantDeparture from '../../modals/ModalTenantDeparture'
import NIDVerifationAction from '../../../stores/admin-management/nid-verification/NIDVerificationAction'
import GetNIDVerificationRequest from '../../../stores/admin-management/nid-verification/request/GetNIDVerificationRequest'
import { CheckGreenIcon, CloseRedIcon } from '../../misc/IconsProvider'
import TenantDeparturePdf from '../../component/pdf/TenantDeparturePdf'
import TenantOwnerPdf from '../../component/pdf/TenantOwnerPdf'

export default function TableUsersForAddForm(props) {
    const dispatch = useDispatch()

    const dataList = useSelector(makeSelectUsersFormList)
    console.log(dataList)
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [FormsAction.REQUEST_GET_ALL_USERS_FORM])
    )
    const isRequestingVerify = useSelector((state) =>
        selectRequesting(state, [NIDVerifationAction.REQUEST_GET_USER_VERIFICATION])
    )

    const goRoute = (routeName) => {
        dispatch(push(routeName))
    }
    const columns = [
        {
            title: 'Image',
            dataIndex: 'thumbImage',
            render: (text, record) => (
                <ModalImage imageUrl={record.thumbImage} imageTitle={'User Image'} />
            )
        },
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Phone',
            dataIndex: 'phone'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },

        {
            title: 'Tenant Form',
            align: 'center',
            render: (text, record) =>
                record.userDetails &&
                record.userDetails.forms &&
                record.userDetails.forms.tanentOwnerForm ? (
                    <ModalTenantOwnerForm
                        data={record.userDetails.forms.tanentOwnerForm}
                        key={record.id}
                    />
                ) : (
                    <ButtonMildGreen
                        text='ADD'
                        icon='plus'
                        onClick={() =>
                            goRoute(
                                `${AppRoutes.TENANT_FORM}/${encode(
                                    JSON.stringify({ userId: record.id })
                                ).toString()}`
                            )
                        }
                    />
                )
        },
        {
            title: 'Departure Form',
            align: 'center',
            render: (text, record) =>
                record.userDetails &&
                record.userDetails.forms &&
                record.userDetails.forms.tanentDepartureForm ? (
                    <ModalTenantDeparture
                        data={record.userDetails.forms.tanentDepartureForm}
                        key={record.id}
                    />
                ) : (
                    <ButtonMildGreen
                        text='ADD'
                        icon='plus'
                        onClick={() =>
                            goRoute(
                                `${AppRoutes.CREATE_TENANT_DEPARTURE_FORM}/${encode(
                                    JSON.stringify({ userId: record.id })
                                ).toString()}`
                            )
                        }
                    />
                )
        },
        {
            title: 'Police Form',
            align: 'center',
            render: (text, record) =>
                record.userDetails &&
                record.userDetails.forms &&
                record.userDetails.forms.policeForm ? (
                    <ModalPoliceFormDetails
                        data={record.userDetails.forms.policeForm}
                        key={record.id}
                    />
                ) : (
                    <ButtonMildGreen
                        text='ADD'
                        icon='plus'
                        onClick={() =>
                            goRoute(
                                `${AppRoutes.POLICE_FORM}/${encode(
                                    JSON.stringify({ userId: record.id })
                                ).toString()}`
                            )
                        }
                    />
                )
        },
        {
            title: 'NID Status',
            align: 'center',
            render: (text, record) => (
                <>
                    {record.userDetails &&
                        record.userDetails.forms &&
                        record.userDetails.forms.policeForm &&
                        record.userDetails.forms.policeForm.nidVerification === 'verified' && (
                            <GreenTag
                                text={'Verified'}
                                icon={<CheckGreenIcon margin='0px 5px 0px 0px' fontSize={'16px'} />}
                            />
                        )}
                    {record.userDetails &&
                        record.userDetails.forms &&
                        record.userDetails.forms.policeForm &&
                        record.userDetails.forms.policeForm.nidVerification === 'failed' && (
                            <RedTag
                                text={'Wrong NID'}
                                icon={<CloseRedIcon margin='0px 5px 0px 0px' fontSize={'16px'} />}
                            />
                        )}
                    {record.userDetails &&
                        record.userDetails.forms &&
                        record.userDetails.forms.policeForm &&
                        record.userDetails.forms.policeForm.nidVerification === undefined && (
                            <ButtonMildGreen
                                text='Verify'
                                icon='check'
                                isLoading={isRequestingVerify}
                                onClick={() =>
                                    dispatch(
                                        NIDVerifationAction._requestGetUserVerification(
                                            new GetNIDVerificationRequest({
                                                userIdToVerification: record.id
                                            })
                                        )
                                    )
                                }
                            />
                        )}
                </>
            )
        }
        // {
        //     title: 'Pdf',
        //     render: (text, record) => <TenantOwnerPdf />
        // }
    ]

    return <ComponentTable isRequesting={isRequesting} dataList={dataList} columns={columns} />
}
