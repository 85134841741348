import BaseRequest from '../../../special/models/BaseRequest'

export class DeleteBillTypeRequest extends BaseRequest {
    billTypeId = ''

    constructor(data) {
        super()
        this.update(data)
        this.billTypeId = data.id
    }
}
