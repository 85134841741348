import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import {
    PaymentMethodConstants,
    PaymentStatusConstants
} from '../../../assets/constants/GeneralConstants'
import BillAction from '../../../stores/money-management/bill/BillAction'
import CollectBillRequest from '../../../stores/money-management/bill/requests/CollectBillRequest'
import { selectFinished } from '../../../stores/special/finished/FinishedSelector'
import { selectRequesting } from '../../../stores/special/requesting/RequestingSelector'
import { FormInputText } from '../../component/form-input/FormInputText'
import useFormInputValidation from '../../component/form-input/useFormInputValidation'
import ButtonFormSubmitIcon from '../ButtonFormSubmitIcon'

export default function CollecBillInvoiceItem({ item }) {
    const VALIDATION_SCHEMA = Yup.object({
        paymentAmount: Yup.number()
            .typeError('Amount must be Number')
            .required('Amount is required.')
            .min(1, 'Must be greater than 0')
            .max(
                item.totalAmount &&
                    (item.paidAmount ? item.totalAmount - item.paidAmount : item.totalAmount),
                'Must be less than or equal Due Amount'
            )
    })
    const dispatch = useDispatch()
    const [visibility, setVisibility] = useState(false)
    const { control, values } = useFormInputValidation(
        {
            billInvoiceId: item.id,
            paymentAmount:
                item.totalAmount &&
                (item.paidAmount ? item.totalAmount - item.paidAmount : item.totalAmount),
            paymentMode: PaymentMethodConstants.CASH_EXCHANGE
        },
        VALIDATION_SCHEMA
    )
    const isRequesting = useSelector((state) =>
        selectRequesting(state, [BillAction.REQUEST_COLLECT_BILL])
    )
    const isFinished = useSelector((state) =>
        selectFinished(state, BillAction.REQUEST_COLLECT_BILL)
    )

    useEffect(() => {
        if (visibility) {
            control.setValues({
                ...values,
                paymentAmount: item.totalAmount - item.paidAmount,
                billInvoiceId: item.id,
                paymentMode: PaymentMethodConstants.CASH_EXCHANGE
            })
        }
    }, [visibility])

    const onSubmit = () => {
        dispatch(BillAction._requestCollectBill(new CollectBillRequest(values)))
    }

    useEffect(() => {
        if (isFinished) {
            control.resetData()
            setVisibility(false)
        }
    }, [isFinished])

    return (
        <div>
            <FormInputText
                disabled={item.paymentStatus === PaymentStatusConstants.PAID ? true : false}
                value={'paymentAmount'}
                label={"Amount to be collected (can't be more than due amount)"}
                control={control}
                name={'paymentAmount'}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonFormSubmitIcon
                    disabled={item.paymentStatus === PaymentStatusConstants.PAID ? true : false || isRequesting}
                    title={'Collect'}
                    icon={'border-outer'}
                    // isRequesting={isRequesting}
                    control={control}
                    onSubmit={onSubmit}
                    isFinished={isFinished}
                />
            </div>
        </div>
    )
}
